/**
 * Module for outputting log messages
 */

import EventBus from './EventBus';


class Logger {
	/**
	 * Singleton constructor
	 */
	constructor() {
		/** Levels */
		this.LEVELS = { DEBUG: 0, INFO: 1, WARNING: 2, ERROR: 3 };

		this.destinations = { local: true, remote: false }; // s.isDevelopment

		/** Minimum level to report on, below nothing gets outputtted */
		this.level = this.LEVELS.INFO;

		/** @type {Array} buffer of messages to send to remote syslog, if remote logging is enabled */
		this.remoteBuffer = [];

		/** @type {Array} Buffer of last 2000 log messages, for sending if a realtime request for it is received */
		this.historyBuffer = [];

		/** @type {Boolean} True while we are sending logs to remote syslog - used to ensure singleton run */
		this.isHandlingRemote = false;

		/** @type {Settings} */
		this.s = null; // populated by settings.js when it initialises - to avoid circular references

		/** @type {Function} Used sending logs in realtime to realtime clients */
		this.streamCallback = null;

		EventBus.$on('Auth:LoggedIn', () => {
			if (this.s?.isInDebugMode)	{
				this.destinations.remote = true;
				this.level = this.LEVELS.DEBUG;
			} else {
				this.destinations.remote = false;
				this.level = this.LEVELS.INFO;
				this.remoteBuffer = [];
			}
			this.handleRemote();
		});

		EventBus.$on('Realtime:Config:EMPLOYEE_UPDATED', async (data) => {
			if (this.s && data.id === this.s.auth.employee) {
				const res = await this.s.http.get('/auth/validateToken?extend=true&token=' + this.s.token);
				if (res.data.success) {
					if (res.data.employeeFeatures.includes('COMMUNICATOR_DEBUG')) {
						this.destinations.remote = true;
						this.level = this.LEVELS.DEBUG;
					} else {
						this.destinations.remote = false;
						this.level = this.LEVELS.INFO;
						this.remoteBuffer = [];
					}
				}
			}
		});

		if (!window.communicator) { window.communicator = {}; }
		window.communicator.logger = this; // making it available globally for debugging purposes
	}

	/**
	 * Register a singleton subsystem on window.communicator for easy access when debugging
	 * @param {String} name Name of the subsystem
	 * @param {Object} source Reference to the subsystem
	 */
	registerGlobal(name, source) {
		window.communicator[name] = source;
	}

	/**
	 * Common log handler
	 * @param {String} severity Severity level
	 * @param {String} message Log message
	 * @private
	 */
	async _log(severity, message) {
		if (this.LEVELS[severity] >= this.level) {
			if (this.destinations.local) {

				switch(severity) {
					case 'INFO':
						console.log(`${new Date().toLocaleTimeString()}: INFO: ${message}`); // eslint-disable-line
						break;
					case 'WARNING':
						console.warn(`${new Date().toLocaleTimeString()}: WARNING: ${message}`); // eslint-disable-line
						break;
					case 'ERROR':
						console.error(`${new Date().toLocaleTimeString()}: ERROR: ${message}`); // eslint-disable-line
						break;
					case 'DEBUG':
						console.log(`${new Date().toLocaleTimeString()}: DEBUG: ${message}`); // eslint-disable-line
						break;
				}
			}
			if (this.destinations.remote) {
				this.remoteBuffer.push({ severity: severity, message: message });
				this.handleRemote();
			}

			this.historyBuffer.push({ when: new Date(), severity: severity, message: message });
			if (this.historyBuffer.length > 2000) {
				this.historyBuffer.splice(0, this.historyBuffer.length - 2000);
			}
		}

		if (this.streamCallback) {
			this.streamCallback(severity, message);
		}
	}

	/**
	 * Handles transmission of log message to remote server
	 * @private
	 */
	async handleRemote() {
		if (this.s && this.destinations.remote && !this.isHandlingRemote && this.s.isAuthenticated && this.remoteBuffer.length > 0) {
			this.isHandlingRemote = true;

			try {
				for (let item of this.remoteBuffer) {
					item.host = this.s.instanceId;
					item.app = `Communicator ${this.s.version}${this.s.wrapperVersion ? 'A' + this.s.wrapperVersion : ''}`;
					await this.s.http.post('/tools/syslog', item);
				}
				this.remoteBuffer = [];
				this.isHandlingRemote = false;
				if (this.remoteBuffer.length > 0) {
					setTimeout(() => { this.handleRemote(); }, 0);
				}
			}
			catch(err) {
				console.error(`Failed to send syslog to remote server: ${err.message}`); // eslint-disable-line
				this.isHandlingRemote = false;
			}
		}
	}

	/**
	 * Log an informational message
	 * @param {String} message Log message
	 */
	info(message) { this._log('INFO', message); }

	/**
	 * Log an informational message
	 * @param {String} message Log message
	 */
	log(message) { this._log('INFO', message); }

	/**
	 * Log a warning messaage
	 * @param {String} message Log message
	 */
	warning(message) { this._log('WARNING', message); }

	/**
	 * Log an error message
	 * @param {String} message Log message
	 */
	error(message) { this._log('ERROR', message); }

	/**
	 * Log a debug message
	 * @param {String} message Log message
	 */
	debug(message) { this._log('DEBUG', message); }
}

export default new Logger();
