<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<call-draggable :data="employee" type="MyCalls" :target="true" :draggable="false" :targetTypes="['Employee','QueueCall']">
			<b-btn v-if="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" @click="headerClicked" :editMode="dashboards.editMode" :class="{'missed-calls':callHistory.missed>0}">
				<glyph-icon icon="465-call" color="#fff" size="14" style="margin-top:-2px;margin-right:2px;"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ $t('mycalls.header') }}</div>
				<div v-show="!dashboards.editMode">
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleDTMF()" v-if="s.myPhoneId=='SOFTPHONE'" :title="$t('mycalls.dtmf')">
						<glyph-icon :icon="showDTMF?'222-chevron-up':'120-table'" color="var(--txt-color-dashboard-header)" size="16"></glyph-icon>
					</b-btn>
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleRecentCalls()" :title="$t('mycalls.recentCalls')">
						<glyph-icon :icon="showRecentCalls?'222-chevron-up':'58-history'" color="var(--txt-color-dashboard-header)" size="16"></glyph-icon>
					</b-btn>
				</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="dash-settings-box">
				<b-row>
					<b-col cols="6">
						<b-form-group :label="$t('mycalls.maxShownCalls')" :description="$t('mycalls.maxShownCallsDescription')">
							<b-form-spinbutton v-model="data.maxCalls" min="1" max="100" size="sm"></b-form-spinbutton>
						</b-form-group>
					</b-col>
				</b-row>
				<b-container style="padding:0">
					<b-row>
						<b-col>
							<b-form-group :label="$t('mycalls.callVariableName')">
								<b-input type="text" size="sm" maxlength="64" v-model="data.variable"></b-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('mycalls.callVariableLabel')">
								<b-input type="text" size="sm" maxlength="32" v-model="data.variableName"></b-input>
							</b-form-group>
						</b-col>
					</b-row>
				</b-container>
				<div class="text-muted variable-info">{{ $t('mycalls.callVariableDescription') }}</div>
			</div>

			<!-- VIEW MODE -->
			<div v-if="!dashboards.editMode&&employee" class="dtmf-box" :class="{'show':showDTMF}">
				<div v-for="key in ['1','2','3','4','5','6','7','8','9','0','*','#']" @click="dtmf(key)" :key="key" class="dtmf-button">{{ key }}</div>
			</div>

			<div v-if="!dashboards.editMode&&employee" class="recent-calls-box" :class="{'show':showRecentCalls}">
				<div>
					<b-form-select size="sm" v-model="recentCategory" :options="recentCategories"></b-form-select>
				</div>
				<div v-for="(call,index) in recentCallsList" :key="index" class="dotdotdot recent-call-item" @click="dial(call)">
					<div v-if="recentCallsList.length==0" style="width:100%;text-align:center;color: var(--txt-color-primary);font-size: var(--font-size-secondary);">{{ $t('mycalls.noCallsFound') }}</div>
					<div v-if="recentCategory=='outbound'" style="transform: rotate(-45deg);margin-top:-4px"><glyph-icon :icon="'650-circle-right'" color="var(--cdr-success)" size="22"></glyph-icon></div>
					<div v-if="recentCategory=='inbound'" style="transform: rotate(-45deg);margin-top:-4px"><glyph-icon :icon="'649-circle-left'" color="var(--cdr-success)" size="22"></glyph-icon></div>
					<div v-if="recentCategory=='missed'" style="margin-top:-4px"><glyph-icon :icon="'847-square-alert'" color="var(--cdr-busy)" size="20"></glyph-icon></div>
					<div class="time">{{ call.started | formatDate('v HH:mm') }}</div>

					<div v-if="recentCategory=='outbound'" class="number">{{ call.callee.number | cutNationalPrefix }}</div>
					<div v-if="recentCategory=='outbound'&&call.callee.name" class="name dotdotdot">{{ call.callee.name }}</div>
					<div v-if="recentCategory=='inbound'" class="number">{{ call.caller.number | cutNationalPrefix }}</div>
					<div v-if="recentCategory=='inbound'&&call.caller.name" class="name dotdotdot">{{ call.caller.name }}</div>
					<div v-if="recentCategory=='inbound'&&!call.caller.number&&!call.caller.name" class="name">{{ $t('mycalls.hiddenNumber') }}</div>
					<div v-if="recentCategory=='missed'" class="number">{{ call.caller.number | cutNationalPrefix }}</div>
					<div v-if="recentCategory=='missed'&&call.caller.name" class="name dotdotdot">{{ call.caller.name }}</div>
					<div v-if="recentCategory=='missed'&&!call.caller.number&&!call.caller.name" class="name">{{ $t('mycalls.hiddenNumber') }}</div>
				</div>
			</div>

			<div v-if="!dashboards.editMode&&employee" class="view-box" :style="{'height': (data.maxCalls*51)+'px'}">
				<transition-group name="list" tag="div">
					<div v-for="(call, index) in calls" :key="call._id">
						<call :call="call" :index="index" :last="calls.length-1==index" :variable="data.variable" :variableName="data.variableName" :autoAnswer="autoAnswer" :phoneType="myPhoneType" :calls="calls"></call>
					</div>
				</transition-group>

			</div>
		</call-draggable>
	</panel-draggable>
</template>

<script>
import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import PanelDraggable from './PanelDraggable.vue';
import employees from '../../data/employees';
import Call from './MyCallsCall.vue';
import EventBus from '../../data/EventBus';
import CallDraggable from './CallDraggable';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';
import callHistory from '../../data/callHistory';
import logger from '../../data/logger';

export default {
	props: {
		data: Object
	},
	components: {
		GlyphIcon,
		PanelDraggable,
		Call,
		CallDraggable
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			callHistory: callHistory,
			isDraggable: false,
			employeeId: null,
			employee: null,
			autoAnswer: { enabled: false, timer: null, otherChannelId: null, last: null },
			showDTMF: false,
			showRecentCalls: false,
			s: s,
			recentCategory: 'outbound',
			recentCategories: [ { value: 'outbound', text: i18n.t('mycalls.recent.outbound') }, { value: 'inbound', text: i18n.t('mycalls.recent.inbound') }, { value: 'missed', text: i18n.t('mycalls.recent.missed') }],
			dialInitiatedAt: new Date()
		};
	},
	computed: {
		calls: function() {
			if (!this.employee) { return []; }
			return this.employee.calls.filter(o => o.device == s.myRealPhoneId);
		},
		recentCallsList: function() {
			return callHistory.calls[this.recentCategory];
		},
		myPhoneType: function() {
			if (!this.employee) { return null; }
			let type;
			this.employee.extensions.forEach(ext => {
				ext.phones.forEach(phone => {
					if (phone._id == s.myPhoneId) { type = phone.type; }
				}); 
			});
			if (s.myPhoneId == 'SOFTPHONE') { type = 'SOFTPHONE'; }
			return type;
		}
	},
	watch: {
		calls(newValue) {
			if (newValue.length == 0 && this.showDTMF == true) { this.showDTMF = false; }
		},
		's.selectedSection': function(value) {
			if (value != 'DASHBOARD') {
				this.showDTMF = false;
				this.showRecentCalls = false;
			} else {
				callHistory.missed = 0;
			}
		}
	},
	methods: {
		dtmf(key) {
			EventBus.$emit('MyCallsSendDTMF', key);
			logger.info(`MyCalls: User pressed DTMF TONE ${key}`);
		},
		toggleDTMF() {
			this.showDTMF = !this.showDTMF;
			logger.info(`MyCalls: User toggled visibility of DTMF panel - it is now ${this.showDTMF?'visible':'hidden'}`);
		},
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		headerClicked() {
			if (!dashboards.editMode && this.callHistory.missed > 0) {
				s.setSelectedSection('CALLS');
			}
		},
		async toggleRecentCalls() {
			this.showRecentCalls = !this.showRecentCalls;
			logger.info(`MyCalls: User toggles visibility of recent calls - it is now ${this.showRecentCalls?'visible':'hidden'}`);
		},
		dial(call) {
			if (new Date() - this.dialInitiatedAt < 2000) { return; }
			this.dialInitiatedAt = new Date();
			const direction = call.caller.extension == this.employee.primaryExtension._id ? 'OUT' : 'IN';
			const other = direction == 'IN' ? call.caller : call.callee;
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				const target = s.cutNationalPrefix(other.number);
				if (target && !target.privacy) {
					this.showRecentCalls = false;
					logger.info(`MyCalls: User calling ${target} from recent calls`);
					sipClient.call(target);
				} else {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.cannotDialHiddenHeader'), message: i18n.t('mycalls.cannotDialHiddenMessage') });
				}
			} else {
				const target = other.extension ? other.extension : s.cutNationalPrefix(other.number);
				if (target && !target.privacy) {
					this.showRecentCalls = false;
					logger.info(`MyCalls: User calling ${target} from recent calls`);
					s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
					});
				} else {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('mycalls.cannotDialHiddenHeader'), message: i18n.t('mycalls.cannotDialHiddenMessage') });
				}
			}
		}
	},
	created() {
		this.employeeId = s.auth.employee;
		employees.getMe()
			.then(employee => {
				this.employee = employee;
			})
			.catch(() => {});

		EventBus.$on('Employees:Loaded', () => {
			employees.getMe()
				.then(employee => {
					this.employee = employee;
				})
				.catch(() => {});
		});

		EventBus.$on('MyCallsAutoAnswerNextRinging', sessionId => {
			if (this.myPhoneType == 'SIPPHONE' || this.myPhoneType == 'SOFTPHONE') { // We cannot auto-answer other types of phones
				logger.info(`MyCalls: Told to auto-answer call with sessionId: ${sessionId}`);
				this.autoAnswer.enabled = true;
				this.autoAnswer.sessionId = sessionId;
				if (this.autoAnswer.timer) { clearTimeout(this.autoAnswer.timer); }
				this.autoAnswer.timer = setTimeout(() => { this.autoAnswer.enabled = false; this.autoAnswer.timer = null; }, 5000);
			}
		});
	}
	
};
</script>

<style scoped>
	.view-box {
		overflow: auto;
	}
	.list-enter-active, .list-leave-active {
		transition: all 0.4s;
	}
	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(15px);
	}
	.variable-info {
		margin-top: -7px;
		line-height: var(--font-size-small);
		font-size: var(--font-size-small);
	}
	.dtmf-box {
		max-height: 0px;
		transition: 500ms;
		overflow: hidden;
		background: var(--txt-color-primary-highlight);
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		column-gap: 2px;
		padding: 0px 2px 0px 2px;
	}
	.dtmf-box.show {
		max-height: 40px;
		box-shadow: var(--box-shadow-2);
		padding: 2px 2px 2px 2px;
	}
	.dtmf-button {
		cursor: pointer;
		background-color: var(--bg-color-dashboard-header);
		color: var(--txt-color-dashboard-header);
		text-align: center;
		padding: 3px;
		font-weight: 600;
		font-size: var(--font-size-primary);
		transition: background-color 1000ms, color 1000ms, transform 1000ms;
		border-radius: 5px 5px;
	}
	.dtmf-button:not(:first-child) {
		border-left: var(--divider-line-2);
	}
	.dtmf-button:hover {
		color: var(--button-background-primary);
		background-color: var(--txt-color-primary-highlight);
		transition: background-color 200ms, color 200ms;
	}
	.dtmf-button:active {
		transform: translate(0,2px);
		transition: 100ms;
	}
	.recent-calls-box {
		max-height: 0px;
		transition: 500ms;
		overflow: hidden;
		background: var(--txt-color-primary-highlight);
		margin-bottom: 5px;
	}
	.recent-calls-box.show {
		max-height: 270px;
		box-shadow: var(--box-shadow-2);
	}
	.recent-call-item {
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 2px;
		padding-bottom: 2px;
		display: flex;

	}
	.recent-call-item:not(:last-of-type) {
		border-bottom: var(--divider-line-2);
	}
	.recent-call-item:hover {
		background-color: var(--bg-color-hover);
		cursor: pointer;
	}
	.recent-call-item > .number {
		padding-left: 5px;
		color: var(--txt-color-primary);
		font-size: var(--font-size-secondary);
	}
	.recent-call-item:hover > .number {
		color: var(--txt-color-secondary-highlight);
	}
	.recent-call-item > .name {
		padding-left: 5px;
		color: var(--txt-color-secondary);
		font-size: var(--font-size-secondary);
	}
	.recent-call-item:hover > .name {
		color: var(--txt-color-secondary-highlight);
	}
	.recent-call-item > .time {
		padding-left: 5px;
		font-size: var(--font-size-small);
		width: 70px;
		text-align: right;
		color: var(--pn-primary-1);
	}

	.recent-call-item:hover > .time {
		color: var(--txt-color-secondary-highlight);
	}

</style>