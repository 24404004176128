<template>
	<div class="phonebook-edit-box">
		<b-row>
			<b-col cols="2" md="3" lg="3" xl="2">
				<b-form-group :label="$t('phonebook.sharing')">
					<b-form-select v-model="personal" :options="sharingOptions" size="sm" v-if="!item._id" />
					<div class="form-control form-control-sm" style="background:var(--bg-color-primary)" v-if="item._id">{{ sharingValue }}</div>
				</b-form-group>
				<b-form-group :label="$t('phonebook.type')">
					<b-form-select v-model="item.type" :options="typeOptions" size="sm" v-if="!item._id" />
					<div class="form-control form-control-sm" style="background:var(--bg-color-primary)" v-if="item._id">{{ typeValue }}</div>
				</b-form-group>
			</b-col>
			<b-col>
				<b-row v-if="item.type=='PERSON'">
					<b-col>
						<b-form-group :label="$t('phonebook.givenName')">
							<b-form-input type="text" v-model="item.givenName" maxlength="64" size="sm" :required="item.type=='PERSON'" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="$t('phonebook.familyName')">
							<b-form-input type="text" v-model="item.familyName" maxlength="64" size="sm" :required="item.type=='PERSON'" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group :label="$t('phonebook.company')">
					<b-form-input id="pbcompany" type="text" v-model="item.company" maxlength="64" size="sm" :required="item.type=='COMPANY'" />
				</b-form-group>
				<b-row v-if="item.type=='PERSON'">
					<b-col>
						<b-form-group :label="$t('phonebook.title')">
							<b-form-input type="text" v-model="item.title" maxlength="64" size="sm" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group :label="$t('phonebook.department')">
							<b-form-input type="text" v-model="item.department" maxlength="64" size="sm" />
						</b-form-group>
					</b-col>
				</b-row>

			</b-col>
		</b-row>

		<div class="dash-header">
			<glyph-icon icon="27-road" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
			<div style="margin-left:8px">{{ $t('phonebook.addresses') }}</div>
			<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="addAddress()" style="margin-right:-4px;margin-top:-1px">
				<glyph-icon icon="191-circle-empty-plus" color="#fff" size="18"></glyph-icon>
			</b-btn>
		</div>

		<b-card no-body style="border:0">
			<b-list-group flush>
				<b-list-group-item v-for="(address,index) in item.addresses" :key="index" style="background-color:var(--bg-color-secondary);padding-left:0 !important;padding-right:0 !important">
					<b-input-group>
						<b-input-group-prepend>
							<b-form-select style="border-radius: 4px 0 0 4px" v-model="address.type" :options="addressTypeOptions" size="sm" />
						</b-input-group-prepend>
						<b-form-input type="text" :id="'pbaddress'+index" v-model="address.street" maxlength="128" :placeholder="$t('phonebook.street')" size="sm" required />
						<b-input-group-append>
							<b-form-input type="text" v-model="address.zip" style="width:60px;border-radius:0" maxlength="6" :placeholder="$t('phonebook.zip')" size="sm" required />
							<b-form-input type="text" v-model="address.city" style="width:150px;border-radius:0;border-left:0" maxlength="40" :placeholder="$t('phonebook.city')" size="sm" required />
							<b-form-select v-model="address.country" :options="countries" size="sm" style="width:170px;border-radius:0;border-left:0" required />
							<b-button variant="primary" size="sm" :disabled="!address.street||!address.zip||!address.city" @click="showAddress(index)" :title="$t('phonebook.showAddress')"><glyph-icon icon="592-map" color="#fff" size="17" style="margin-top:-3px"></glyph-icon></b-button>
							<b-button variant="danger" size="sm" @click="deleteAddress(index)" :title="$t('common.delete')"><glyph-icon icon="17-bin" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-list-group-item>
			</b-list-group>
		</b-card>

		<div style="height:10px"></div>

		<b-row>
			<b-col cols="12" xl="6" lg="12" md="12" style="margin-bottom:10px">
				<div class="dash-header">
					<glyph-icon icon="465-call" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:8px">{{ $t('phonebook.phoneNumbers') }}</div>
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="addPhoneNumber()" style="margin-right:-4px;margin-top:-1px">
						<glyph-icon icon="191-circle-empty-plus" color="#fff" size="18"></glyph-icon>
					</b-btn>
				</div>

				<b-card no-body style="border:0">
					<b-list-group flush>
						<b-list-group-item v-for="(number,index) in item.phoneNumbers" :key="index" style="background-color:var(--bg-color-secondary);padding-left:0 !important;padding-right:0 !important">
							<b-input-group>
								<b-input-group-prepend>
									<b-form-select style="border-radius: 4px 0 0 4px" v-model="number.type" :options="phoneNumberOptions" size="sm" />
								</b-input-group-prepend>
								<b-form-input type="text" :id="'pbphonenumber'+index" v-model="number.number" maxlength="20" :placeholder="$t('phonebook.phoneNumber')" size="sm" required />
								<b-input-group-append>
									<b-button variant="primary" size="sm" :disabled="!number.number" @click="callPhoneNumber(index)" :title="$t('common.call')"><glyph-icon icon="465-call" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
									<b-button v-if="number.type=='MOBILE'" variant="success" size="sm" :disabled="!number.number" @click="showSendSms(number.number)" :title="$t('employee.sendSms')"><glyph-icon icon="238-chat-message" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
									<b-button variant="danger" size="sm" @click="deletePhoneNumber(index)" :title="$t('common.delete')"><glyph-icon icon="17-bin" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
								</b-input-group-append>
							</b-input-group>
						</b-list-group-item>
					</b-list-group>
				</b-card>

			</b-col>

			<b-col cols="12" xl="6" lg="12" md="12" style="margin-bottom:10px">
				<div class="dash-header">
					<glyph-icon icon="11-envelope" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:8px">{{ $t('phonebook.emailAddresses') }}</div>
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="addEmailaddress()" style="margin-right:-4px;margin-top:-1px">
						<glyph-icon icon="191-circle-empty-plus" color="#fff" size="18"></glyph-icon>
					</b-btn>
				</div>

				<b-card no-body style="border:0">
					<b-list-group flush>
						<b-list-group-item v-for="(email,index) in item.emailAddresses" :key="index" style="background-color:var(--bg-color-secondary);padding-left:0 !important;padding-right:0 !important">
							<b-input-group>
								<b-input-group-prepend>
									<b-form-select style="border-radius: 4px 0 0 4px" v-model="email.type" :options="emailOptions" size="sm" />
								</b-input-group-prepend>
								<b-form-input type="text" :id="'pbemailaddress'+index" v-model="email.address" maxlength="128" :placeholder="$t('phonebook.emailAddress')" size="sm" required />
								<b-input-group-append>
									<b-button variant="primary" size="sm" :disabled="!email.address" @click="sendToEmailAddress(index)" :title="$t('employee.sendEmail')"><glyph-icon icon="792-email" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
									<b-button variant="danger" size="sm" @click="deleteEmailAddress(index)" :title="$t('common.delete')"><glyph-icon icon="17-bin" color="#fff" size="17" style="margin-top:-1px"></glyph-icon></b-button>
								</b-input-group-append>
							</b-input-group>
						</b-list-group-item>
					</b-list-group>
				</b-card>

			</b-col>
		</b-row>

		<b-row>
			<b-col cols="12" sm="6" md="6" lg="3" xl="3">
				<b-form-group :label="$t('phonebook.externalId')">
					<b-form-input type="text" v-model="item.externalId" maxlength="64" size="sm" />
				</b-form-group>
			</b-col>
			<b-col cols="12" sm="6" md="6" lg="3" xl="2">
				<b-form-group :label="$t('phonebook.shortNumber')">
					<b-form-input type="text" v-model="item.shortNumber" maxlength="7" size="sm" @keypress="isNumber($event)" />
				</b-form-group>
			</b-col>
			<b-col md="12" col="12">
				<b-form-group :label="$t('phonebook.notes')">
					<b-form-textarea rows="4" max-rows="10" v-model="item.notes" maxlength="16384" size="sm" />
				</b-form-group>
			</b-col>
		</b-row>
		
		<div class="bottom">
			<b-button style="width:130px;margin-bottom:0;height:30px" variant="secondary" size="sm" @click="close">{{ $t('common.close') }}</b-button>
			<b-button style="width:130px;margin-bottom:0;height:30px" variant="danger" size="sm" v-if="item._id" @click="remove">{{ $t('common.delete') }}</b-button>
			<b-button style="width:130px;margin-bottom:0;height:30px" variant="primary" size="sm" :disabled="!dirty" @click="save">{{ item._id ? $t('common.save') : $t('common.create') }}</b-button>
		</div>

	</div>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';
import u from '../../utils/utils';
import s from '../../settings';
import EventBus from '../../data//EventBus.js';
import phonebook from '../../data/phonebook';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		GlyphIcon,
	},
	props: {
		item: { type: Object, required: true }
	},
	data() {
		return {
			$t: i18n.t,
			sharingOptions: [
				{ value: true, text: i18n.t('phonebook.sharingPersonal') },
				{ value: false, text: i18n.t('phonebook.sharingShared') }
			],
			typeOptions: [
				{ value: 'PERSON', text: i18n.t('phonebook.typePerson') },
				{ value: 'COMPANY', text: i18n.t('phonebook.typeCompany') }
			],
			addressTypeOptions: [
				{ value: 'WORK', text: i18n.t('phonebook.addressWork') },
				{ value: 'HOME', text: i18n.t('phonebook.addressPrivate') }
			],
			phoneNumberOptions: [
				{ value: 'MAIN', text: i18n.t('phonebook.phoneNumberTypes.MAIN') },
				{ value: 'WORK', text: i18n.t('phonebook.phoneNumberTypes.WORK') },
				{ value: 'MOBILE', text: i18n.t('phonebook.phoneNumberTypes.MOBILE') },
				{ value: 'HOME', text: i18n.t('phonebook.phoneNumberTypes.HOME') },
				{ value: 'OTHER', text: i18n.t('phonebook.phoneNumberTypes.OTHER') }
			],
			emailOptions: [
				{ value: 'MAIN', text: i18n.t('phonebook.emailTypes.MAIN') },
				{ value: 'WORK', text: i18n.t('phonebook.emailTypes.WORK') },
				{ value: 'HOME', text: i18n.t('phonebook.emailTypes.HOME') },
				{ value: 'OTHER', text: i18n.t('phonebook.emailTypes.OTHER') }
			],
			personal: false,
			u: u,
			dirty: false,
			countries: []
		};
	},
	methods: {
		addAddress() {
			this.item.addresses.push({ type: 'WORK', street: '', zip: '', city: '', state: '', country: 'DK' });
			const index = this.item.addresses.length - 1;
			setTimeout(() => {
				document.getElementById('pbaddress' + index).focus();
			},10);
		},
		deleteAddress(index) {
			this.item.addresses.splice(index, 1);
		},
		showAddress(index) {
			const address = this.item.addresses[index];
			const args = [address.street, address.zip, address.city].join(', ');
			const url = 'https://www.google.dk/maps?q=' + encodeURI(args);
			if (s.isEmbedded) {
				axios.get(`${s.localUrl}/openexternal?url=${encodeURIComponent(url)}`, { timeout: 5000 })
					.catch(err => {
						//console.log('Open external failed: ' + (err.message ? err.message : JSON.stringify(err.data)) );
					});
			} else {
				window.open('https://www.google.dk/maps?q=' + encodeURI(args), '_blank');
			}

		},
		addPhoneNumber() {
			this.item.phoneNumbers.push({ type: 'MAIN', number: '' });
			const index = this.item.phoneNumbers.length - 1;
			setTimeout(() => {
				document.getElementById('pbphonenumber' + index).focus();
			},10);
		},
		deletePhoneNumber(index) {
			this.item.phoneNumbers.splice(index, 1);
		},
		callPhoneNumber(index) {
			const number = this.item.phoneNumbers[index].number;
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(number);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(number)}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		addEmailaddress() {
			this.item.emailAddresses.push({ type: 'MAIN', address: '' });
			const index = this.item.emailAddresses.length - 1;
			setTimeout(() => {
				document.getElementById('pbemailaddress' + index).focus();
			},10);
		},
		deleteEmailAddress(index) {
			this.item.emailAddresses.splice(index, 1);
		},
		sendToEmailAddress(index) {
			const address = this.item.emailAddresses[index].address;
			window.location.href = `mailto:${address}`;
		},
		close() {
			this.$parent.selected = null;
		},
		save() {
			const data = u.extendObject({}, this.item);
			data.personal = this.personal;
			data.customer = s.auth.customer;
			let errors = [];
			if (data.type == 'COMPANY' && !data.company) { errors.push(i18n.t('phonebook.errorCompanyNameMissing')); }
			if (data.type == 'PERSON' && !data.givenName) { errors.push(i18n.t('phonebook.errorGivenNameMissing')); }
			data.addresses.forEach((item, index) => {
				if (!item.street || !item.zip.match(/^\d+$/) || !item.city) { errors.push(i18n.t('phonebook.errorAddress1') + ' ' + (index+1) + ' ' + i18n.t('phonebook.errorAddress2')); }
			});
			data.phoneNumbers.forEach((number, index) => {
				if (!number.number.match(/^\+?\d{1,20}$/)) { errors.push(i18n.t('phonebook.errorPhoneNumber1') + ' ' + (index+1) + ' ' + i18n.t('phonebook.errorPhoneNumber2')); }
			});
			data.emailAddresses.forEach((address, index) => {
				if (!u.isEmailAddress(address.address)) { errors.push(i18n.t('phonebook.errorEmail1') + ' ' + (index+1) + ' ' + i18n.t('phonebook.errorEmail2')); }
			});
			data.phoneNumbers.forEach(number => {
				if (number.number.startsWith('00')) { number.number = '+' + number.number.substr(2); }
				if (!number.number.startsWith('+') && number.number.length > 7) { number.number = s.pbxSettings.nationalPrefix + number.number; }
			});

			if (errors.length > 0) {
				let message = i18n.t('phonebook.errorListHeader') + '<br><ul>' + errors.map(o => { return '<li>' + o + '</li>'; }).join('') + '</ul>';
				EventBus.$emit('CommonErrorModal', { header: i18n.t('phonebook.header'), message: message });
				return;
			}

			if (this.item._id) {
				phonebook.update(data)
					.then(res => {
						this.dirty = false;
					})
					.catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('phonebook.header'), message: i18n.t('phonebook.errorUnknown') + ' ' + err.message});
					});
			} else {
			
				phonebook.create(data)
					.then(res => {
						this.dirty = false;
						this.item._id = res.data._id;
						if (this.personal) { this.item.employee = 'dummy-value-to-make-sharingvalue-correct-after-create'; }
						this.item.name = this.item.type === 'PERSON' ? [this.item.givenName, this.item.familyName].filter(o => o).join(' ') : this.item.company;
					})
					.catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('phonebook.header'), message: i18n.t('phonebook.errorUnknown') + ' ' + err.message});
					});
			}
		},
		remove() {
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('phonebook.deleteContactHeader'), message: i18n.t('phonebook.deleteContactMessage').replace('%%name%%',this.item.name), ok: i18n.t('common.delete'), emit: 'Phonebook:Delete', emitData: this.item._id});
		},
		isNumber(evt) {
			evt = evt ? evt : window.event;
			const charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode >= 48 && charCode <= 57) {
				return true;
			} else {
				evt.preventDefault();;
			}
		},
		showSendSms(number) {
			EventBus.$emit('CommonSendSmsModal', { employee: null, number: number });
		}

	},
	computed: {
		sharingValue: function() { return this.item.employee ? i18n.t('phonebook.sharingPersonal') : i18n.t('phonebook.sharingShared');  },
		typeValue: function() { return this.item.type == 'COMPANY' ? i18n.t('phonebook.typeCompany') : i18n.t('phonebook.typePerson'); }
	},
	watch: {
		item: {
			handler: function() {
				this.dirty = true;
			},
			deep: true
		}
	},
	created() {
		this.personal = this.item.employee != null;
	},
	mounted() {
		if (!this.item._id) {
			setTimeout(() => {
				document.getElementById('pbcompany').focus();
			}, 10);
		}
		Object.keys(u.ISO3166.country).forEach(key => {
			this.countries.push({ value: key, text: u.ISO3166.country[key] });
		});
	}
};
</script>

<style scoped>
	.phonebook-edit-box {
		background-color: var(--bg-color-secondary);
		padding: 10px 20px 0 20px;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
	}
	.bottom {
		height: 45px;
		background: var(--bg-color-4);
		border-top: 1px solid #00000010;
		padding-top: 6px;
		padding-right: 20px;
		text-align: right;
		margin-left: -20px;
		margin-right: -20px;
	}
	.bottom > BUTTON {
		min-width: 100px;
		margin-left: 10px;
	}
</style>