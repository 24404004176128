<template>
	<div>
		<div class="box" @click="doClick" :class="{'selected':selected}">
			<div style="position:absolute;width:95px;text-align:right;margin-top:-7px">
				<b-badge v-if="badge" variant="danger" style="border-radius:12px;min-width:18px;font-weight:400;">{{ badge }}</b-badge>
				<b-badge v-if="badgeNummeric" variant="danger" style="border-radius:12px;min-width:18px;font-weight:400;">{{ badgeNummeric }}</b-badge>
				<b-badge v-if="recording" variant="danger" style="border-radius:12px;min-width:18px;font-weight:400;">Rec</b-badge>
			</div>
			<glyph-icon :icon="icon" :color="iconColor" size="30" />
			<div class="label">{{ label }}</div>
		</div>
	</div>
</template>


<script>
import GlyphIcon from '../GlyphIcon.vue';
export default {
	components: {
		GlyphIcon
	},
	props: {
		icon: String,
		label: String,
		click: Function,
		selected: Boolean,
		id: String,
		badge: String,
		badgeNummeric: Number,
		recording: Boolean,
	},
	data() {
		return {
			iconColor: 'var(--pn-black-2)'
		};
	},
	methods: {
		doClick() {
			this.click(this.id);
		}
	},
	created() {
		this.$watch('selected', (newValue, oldValue) => {
			this.iconColor = newValue ? 'var(--topbar-button-color-selected)' : 'var(--topbar-button-color)';
		});
		this.iconColor = this.selected ? 'var(--topbar-button-color-selected)' : 'var(--topbar-button-color)';
	}
};
</script>

<style scoped>
	.box {
		margin-left: auto;
		margin-right: auto;
		width: 100px;
		border-radius: 5px;
		padding: 5px 0px 5px 0px;
		margin-top: 2px;
		margin-bottom: 2px;
		color: var(--topbar-button-color);
		transition: 0.2s;
	}

	.box:hover {
		cursor: pointer;
		box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.20);
		transition: 0.2s;
	}

	.box:active {
		cursor: pointer;
	}

	.box DIV {
		margin: 0 auto;

	}

	.box.selected {
		color: var(--topbar-button-color-selected);
		background: var(--topbar-button-background-selected);
		border: 1px solid var(--topbar-button-border-selected);
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.label {
		margin-top: -3px !important;
		font-size: 12px;
		font-weight: 400;
		padding-left: 5px;
		padding-right: 5px;
		text-align: center;
		white-space: nowrap;
		letter-spacing: 0.05em;
	}

@media (min-width:390px) and (max-width:1150px) {
	.label {
		display: none;
	}
	.box {
		width: 50px;
		height: 50px;
		padding-top: 10px;
	}
	.box.selected {
		padding-top: 10px;
	}
}
</style>