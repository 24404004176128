<template>
	<div class="center">
		<div class="box">
			<img :src="s.appLogo" style="width:300px;padding-bottom:50px" alt="Logo">
			<b-alert v-show="errorMessage" show variant="danger" style="margin-top:-30px;line-height:18px" v-html="errorMessage"></b-alert>
			<b-form>
				<b-row>
					<b-col cols="10" offset="1" v-show="mode=='USERNAME_PASSWORD'">
						<b-form-group :label="$t('login.email')">
							<b-form-input v-model="auth.username" type="email" id="email" v-on:keyup.enter="login" :disabled="isWorking"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="10" offset="1" v-show="mode=='USERNAME_PASSWORD'">
						<b-form-group :label="$t('login.password')">
							<b-form-input v-model="auth.password" type="password" id="password" v-on:keyup.enter="login" :disabled="isWorking"></b-form-input>
						</b-form-group>
						<div style="text-align:right;margin-top:-15px;margin-bottom:10px">
							<b-button style="font-size:var(--font-size-small);margin-top:-11px;padding:0;margin:0;color:var(--txt-color-secondary)" variant="link" @click="showReset()">{{ $t('login.resetPassword').toLowerCase() }}</b-button>
						</div>
					</b-col>
					<b-col cols="6" offset="3" v-show="mode=='SMS_ENTRY'">
						<b-form-group :label="$t('login.smsCode')">
							<b-form-input v-model="auth.smsKey" type="tel" id="smsKey" v-on:keyup.enter="login" :disabled="isWorking"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="6" offset="3" v-show="mode=='AUTHENTICATOR_SETUP'">
						<b-form-group :label="$t('login.authenticatorSetup')">
							<img :src="authenticatorQrUrl" alt="Authenticator QR code" />
						</b-form-group>
					</b-col>
					<b-col cols="6" offset="3" v-show="mode=='AUTHENTICATOR_ENTRY'||mode=='AUTHENTICATOR_SETUP'">
						<b-form-group :label="$t('login.authenticatorCode')">
							<b-form-input v-model="auth.authenticatorToken" type="text" id="authenticatorToken" autocomplete="section-login one-time-code" v-on:keyup.enter="login" :disabled="isWorking"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="10" offset="1" v-show="mode=='PASSWORD_RESET'">
						<div style="font-size:var(--font-size-xlarge);color:var(--txt-color-secondary);font-weight:300">{{ $t('login.resetPassword') }}</div>
						<div style="font-size:var(--font-size-primary);color:var(--txt-color-primary);margin-bottom:15px">{{ $t('login.resetPasswordHelp') }}</div>
						<b-form-group :label="$t('login.email')">
							<b-form-input v-model="resetData.username" type="email" id="resetemail" v-on:keyup.enter="reset" :disabled="isWorking" :state="validResetEmail" />
						</b-form-group>
						<b-form-group :label="$t('login.resetPasswordSendTo')">
							<b-form-select v-model="resetData.method" :options="resetMethods" />
						</b-form-group>
					</b-col>
					<b-col cols="10" offset="1" v-show="mode=='PASSWORD_RESET_EMAIL_COMPLETED'">
						<div style="font-size:var(--font-size-xlarge);color:var(--txt-color-secondary);font-weight:300">{{ $t('login.resetPassword') }}</div>
						<div style="font-size:var(--font-size-primary);color:var(--txt-color-primary);margin-bottom:15px">{{ $t('login.resetPasswordHelp2') }}</div>
					</b-col>
					<b-col cols="10" offset="1" v-show="mode=='PASSWORD_RESET_SMS_COMPLETED'">
						<div style="font-size:var(--font-size-xlarge);color:var(--txt-color-secondary);font-weight:300">{{ $t('login.resetPassword') }}</div>
						<div style="font-size:var(--font-size-primary);color:var(--txt-color-primary);margin-bottom:15px">{{ $t('login.resetPasswordHelp3') }}</div>
					</b-col>

					<b-col cols="10" offset="1" v-show="mode=='USERNAME_PASSWORD'">
						<b-form-checkbox class="switch" v-model="auth.persist" @click="auth.persist = !auth.persist" switch :disabled="isWorking">{{ $t('login.remember') }}</b-form-checkbox>
					</b-col>
					<b-col cols="10" offset="1" style="margin-top:20px" v-show="!mode.startsWith('PASSWORD_RESET')">
						<b-form-group>
							<b-button @click="login()" style="width:100%" :disabled="isWorking||!auth.username||!auth.password||(mode=='SMS_ENTRY'&&auth.smsKey.length<4)||(mode=='AUTHENTICATOR_ENTRY'&&auth.authenticatorToken.length<6)">{{ $t('login.loginButton') }}</b-button>
						</b-form-group>
					</b-col>
					<b-col cols="5" offset="1" style="margin-top:20px" v-show="mode=='PASSWORD_RESET'">
						<b-form-group>
							<b-button @click="gotoUsernamePassword()" style="width:100%" :disabled="isWorking">{{ $t('common.cancel') }}</b-button>
						</b-form-group>
					</b-col>
					<b-col cols="5" offset="0" style="margin-top:20px" v-show="mode=='PASSWORD_RESET'">
						<b-form-group>
							<b-button @click="reset()" style="width:100%" :disabled="isWorking||!resetData.username">{{ $t('login.next') }}</b-button>
						</b-form-group>
					</b-col>
					<b-col cols="10" offset="1" style="margin-top:20px" v-show="mode=='PASSWORD_RESET_EMAIL_COMPLETED'||mode=='PASSWORD_RESET_SMS_COMPLETED'">
						<b-form-group>
							<b-button @click="gotoUsernamePassword(true)" style="width:100%" :disabled="isWorking">{{ $t('login.ok') }}</b-button>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
			
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import utils from '../utils/utils';
import s from '../settings';
import EventBus from '../data/EventBus';
import u from '../utils/utils';
import i18n from '../utils/i18n';

export default {
	data() {
		return {
			$t: i18n.t,
			s: s,
			auth: {
				username: '',
				password: '',
				smsKey: '',
				authenticatorToken: '',
				persist: false
			},
			errorMessage: '',
			isWorking: false,
			mode: 'USERNAME_PASSWORD', // SMS_ENTRY, AUTHENTICATOR_ENTRY, AUTHENTICATOR_SETUP, PASSWORD_RESET, PASSWORD_RESET_EMAIL_COMPLETED, PASSWORD_RESET_COMPLETED
			authenticatorQrUrl: null,
			resetData: {
				username: '',
				method: 'EMAIL'
			},
			resetMethods: [
				{ value: 'EMAIL', text: i18n.t('login.sendToEmail') },
				{ value: 'SMS', text: i18n.t('login.sendToSms') }
			]
		};
	},
	methods: {
		login() {
			if (!this.auth.username || !this.auth.password) { return; }
			this.isWorking = true;
			this.errorMessage = null;
			axios.post(`${s.apiUrl}/auth/login`, this.auth, { timeout: 10000 })
				.then(res => {
					if (!res.data.customerFeatures.includes('PBX') || !res.data.communicatorAccess) {
						this.errorMessage = i18n.t('login.errorNoCommunicatorAccess');
						this.auth.smsKey = '';
						this.auth.authenticatorToken = '';
						this.mode = 'USERNAME_PASSWORD';
						setTimeout(() => { document.getElementById('password').focus(); }, 10);
						return;
					}
					//s.setToken(res.data.token);
					s.http.get(`/pbx/app/employees?ids=${res.data.employee}&token=${res.data.token}`).then(res2 => {
						let me = res2.data[0];
						if (res2.data.length == 0) {
							this.errorMessage = i18n.t('login.errorNoCommunicatorAccess');
							this.isWorking = false;
							return;
						}
						if (me.extensions.find(o => o.primary) == null) {
							this.errorMessage = i18n.t('login.errorNoExtension');
							this.isWorking = false;
							return;
						}
						s.setRememberMe(this.auth.persist);
						s.setEmailAddress(this.auth.username);
						s.setToken(res.data.token);
						res.data.employee = res.data._id;
						res.data.employeeName = res.data.name;
						delete res.data._id;
						delete res.data.name;
						delete res.data.token;
						s.auth = res.data;
						i18n.locale = s.auth.language;
						this.mode = 'USERNAME_PASSWORD';
						this.authenticatorQrUrl = null;
						// if (s.myPhoneId == 'SOFTPHONE') {
						// 	sipClient.register();
						// }
						EventBus.$emit('Auth:LoggedIn');
					}).catch(_ => {
						s.setToken(null);
						this.errorMessage = i18n.t('login.errorNoExtension');
					});
				})
				.catch(res => {
					this.isWorking = false;

					if (this.mode == 'SMS_ENTRY' || this.mode == 'AUTHENTICATOR_ENTRY') {
						this.auth.smsKey = '';
						this.auth.authenticatorToken = '';
						this.mode = 'USERNAME_PASSWORD';
						setTimeout(() => { document.getElementById('password').focus(); }, 10);
					}

					if (!utils.existsDeep(res, 'response.data.error')) {
						this.errorMessage = i18n.t('login.errorNoConnection');
						return;
					}

					const err = res.response.data.error;

					if (err.status == 500) {
						this.errorMessage = i18n.t('login.errorServer');
					} else {
						switch (err.path) {
							case 'credentials_invalid':
								this.errorMessage = i18n.t('login.errorCredentials');
								document.getElementById('password').focus();
								break;
							case 'account_disabled':
								this.errorMessage = i18n.t('login.errorDisabled');
								break;
							case 'ipaddress_invalid':
								this.errorMessage = i18n.t('login.errorWrongIp');
								break;
							case 'sms_error':
								this.errorMessage = i18n.t('login.errorServer');
								break;
							case 'sms_authenticate':
								this.mode = 'SMS_ENTRY';
								setTimeout(() => { document.getElementById('smsKey').focus(); }, 10);
								break;
							case 'sms_key_invalid':
								this.errorMessage = i18n.t('login.errorSmsCodeInvalid');
								break;
							case 'authenticator_authenticate':
								this.mode = 'AUTHENTICATOR_ENTRY';
								setTimeout(() => { document.getElementById('authenticatorToken').focus(); }, 10);
								break;
							case 'authenticator_key_invalid':
								this.errorMessage = i18n.t('login.authenticatorCodeInvalid');
								break;
							case 'authenticator_setup':
								this.mode = 'AUTHENTICATOR_SETUP';
								setTimeout(() => { document.getElementById('authenticatorToken').focus(); }, 10);
								this.authenticatorQrUrl = `https://api.telecomx.dk/tools/qr?text=${err.totp}`;
								break;
						}
					}
				});
		},
		showReset() {
			this.mode = 'PASSWORD_RESET';
			setTimeout(() => {document.getElementById('resetemail').focus(); }, 10);
		},
		reset() {
			if (!u.isEmailAddress(this.resetData.username, false)) { return; }
			this.isWorking = true;
			this.errorMessage = null;
			axios.post(`${s.apiUrl}/auth/reset`, this.resetData, { timeout: 10000 })
				.then(res => {
					this.isWorking = false;
					this.mode = 'PASSWORD_RESET_' + res.data.method + '_COMPLETED';
				})
				.catch(res => {
					this.isWorking = false;

					if (!utils.existsDeep(res, 'response.data.error')) {
						this.errorMessage = i18n.t('login.errorNoConnection');
						return;
					}

					const err = res.response.data.error;

					if (err.status == 500) {
						this.errorMessage = i18n.t('login.errorServer');
					} else {
						switch (err.path) {
							case 'account': this.errorMessage = i18n.t('login.errorDisabled'); break;
							case 'username': this.errorMessage = i18n.t('login.errorResetNotAvailable'); break;
							case 'time': this.errorMessage = i18n.t('login.errorResetAlreadyInProgress'); break;
						}
					}
				});
		},
		gotoUsernamePassword(fill) {
			this.mode = 'USERNAME_PASSWORD';
			if (fill) {
				this.auth.username = this.resetData.username;
			}
			this.resetData.username = '';
			this.resetData.method = 'EMAIL';
			setTimeout(() => {
				if (this.auth.username) {
					document.getElementById('password').focus();
				} else {
					document.getElementById('email').focus();
				}
			}, 10);
		}
	},
	computed: {
		validResetEmail: function() {
			if (this.resetData && this.resetData.username != null) {
				return u.isEmailAddress(this.resetData.username);
			} else {
				return false;
			}
		}
	},
	async mounted() {
		this.auth.username = s.emailAddress;
		setTimeout(() => {
			if (this.auth.username) {
				document.getElementById('password').focus();
			} else {
				document.getElementById('email').focus();
			}
		}, 10);
	},
};

</script>

<style scoped>
	.center {
		margin: auto;
		max-width: 500px;
		margin-top: 20px;
	}
	.box {
		padding: 30px;
		background-color: var(--bg-color-secondary);
		text-align:center;
		border-radius:10px;
		box-shadow: var(--box-shadow);
		border-width: 1px;
		border-style: solid;
		border-color: var(--bg-color-3);
	}
	.switch {
		font-size: var(--font-size-primary);
		color: var(--txt-color-secondary);
	}
</style>