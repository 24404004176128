<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<div>
			<b-btn v-if="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" :editMode="dashboards.editMode">
				<glyph-icon icon="417-globe" color="#fff" size="14" style="flex-shrink:0;margin-top:-2px;margin-right:2px;"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ data.title }}</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="settings-box">
				<b-form-group :label="$t('shortcuts.panelName')">
					<b-form-input type="text" maxlength="64" size="sm" v-model="data.title" :required="true"></b-form-input>
				</b-form-group>

				<b-form-group :label="$t('shortcuts.shortcuts')">
					<b-list-group>
						<draggable :list="data.shortcuts" class="list-group" ghost-class="ghost">
							<b-list-group-item class="d-flex justify-content-between align-items-center employee-item" v-for="item in data.shortcuts" :key="item._id" style="cursor:move">
								<div style="display:flex">
									<glyph-icon icon="750-resize-vertical" size="20" color="#555" style="margin-left:-8px"></glyph-icon>
									<b-btn size="sm" variant="danger" class="delete-item-btn" style="margin-right:5px;padding:3px" @click="removeItem(item)">
										<glyph-icon icon="17-bin" size="15" color="#fff"></glyph-icon>
									</b-btn>
									<b-form-input :id="item._id" type="text" maxlength="64" size="sm" :placeholder="$t('shortcuts.name')" v-model="item.name" style="width:178px;margin-right:5px"></b-form-input>
									<b-form-input type="text" maxlength="32" :placeholder="$t('shortcuts.number')" size="sm" style="width:115px" v-model="item.number"></b-form-input>
								</div>
							</b-list-group-item>
						</draggable>
						<b-list-group-item disabled v-if="data.shortcuts.length==0" style="text-align:center;background:#e0e0e0">{{ $t('shortcuts.noShortcuts') }}</b-list-group-item>
					</b-list-group>
				</b-form-group>
				<b-btn block variant="primary" style="margin-top:-8px" size="sm" @click="addItem()">{{ $t('shortcuts.add') }}</b-btn>
				<div style="height:4px"></div>


			</div>

			<!-- VIEW MODE -->
			<div v-if="!dashboards.editMode" class="view-box">
				<div v-for="item in data.shortcuts" v-bind:key="item._id">
					<b-button size="sm" @click="call(item.number)" variant="secondary" block style="margin-bottom:3px;box-shadow:none">
						<span style="font-size:var(--font-size-primary);font-weight:300">{{ item.name }}</span>
					</b-button>
				</div>
			</div>
		</div>
	</panel-draggable>
</template>

<script>
import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import PanelDraggable from './PanelDraggable.vue';
import Draggable from 'vuedraggable';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	props: {
		data: Object
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			isDraggable: false
		};
	},
	methods: {
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		removeItem(item) {
			const index = this.data.shortcuts.findIndex(o => o._id == item._id);
			if (index) {
				this.data.shortcuts.splice(index, 1);
			}
		},
		addItem() {
			const id = u.randomString(32);
			this.data.shortcuts.push({ _id: id, name: '', number: '' });
			setTimeout(() => { document.getElementById(id).focus(); }, 100);
		},
		async call(number) {
			if (!number.startsWith('+') && number.length > 7) { number = s.pbxSettings.nationalPrefix + number; }
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				sipClient.call(number);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(number)}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		noPhoneSelected() {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.noPhoneMessage') });
		}
	},
	components: {
		GlyphIcon,
		PanelDraggable,
		Draggable
	},
	mounted() {
		this.data.shortcuts.forEach(item => { item._id = u.randomString(32); });
	}
	
};
</script>

<style scoped>
	.settings-box {
		margin: 5px 10px;
	}
	.view-box {
		margin: 4px 4px;
		overflow: auto;
	}
	.ghost {
		opacity: 0.25;
	}
	.delete-employee-btn {
		padding: 0 2px 2px 2px;
		margin-right: 5px;
	}
	.employee-item {
		padding: 2px 5px 2px 5px !important;
	}

</style>
