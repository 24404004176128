/**
 * Detect if computer has been sleeping
 * - a.k.a. has time has skipped more than 5 seconds?
 */

class WakeUpDetector {
	/**
	 * Initialize wakeup detector
	 * @param {Function} callback Callback to invoke when wake has been detected
	 */
	constructor(callback) {
		this.lastTime = (new Date()).getTime();
		this.timer = setInterval(this.check.bind(this), 1000);
		this.callback = callback;
	}

	/**
	 * Check every seconds to see if time has skipped
	 * @private
	 */
	check() {
		const current = (new Date()).getTime();
		if (this.lastTime + 5000 < current) {
			this.lastTime = current;
			this.callback();
		} else {
			this.lastTime = current;
		}
	}

	/**
	 * Stop wakeup detector
	 */
	stop() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	}

}

export default WakeUpDetector;
