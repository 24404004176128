<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<div>
			<b-btn v-if="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" :editMode="dashboards.editMode" :class="{'queue-yellow':commonState=='YELLOW','queue-red':commonState=='RED'}">
				<glyph-icon icon="25-user-parents" color="#fff" size="14" style="flex-shrink:0;margin-top:-2px;margin-right:2px;"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ queue.name }}</div>
				<div v-show="!dashboards.editMode" style="margin-top:-3px;display:flex">
					<b-form-checkbox v-model="isMember" :disabled="isPermanentMember" size="md" switch @change="membershipChanged" style="margin-right:-5px"></b-form-checkbox>
					<b-btn size="sm" variant="none" class="dash-collapse-btn" style="padding-bottom:0 !important" @click="toggleCollapse()">
						<glyph-icon :icon="collapsed?'221-chevron-down':'222-chevron-up'" color="var(--collapse-button-color-dashboard-header)" size="18"></glyph-icon>
					</b-btn>
				</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="dash-settings-box">
				<b-form-group :label="$t('queue.queue')">
					<b-form-select v-model="data._id" :options="availableQueues" @change="queueSelected" value-field="_id" size="sm"></b-form-select>
				</b-form-group>
				<b-form-group :label="$t('queue.showSection')">
					<div style="display:flex;justify-content:space-between">
						<b-form-checkbox v-model="data.showCalls" size="sm" switch><div class="section-label">{{ $t('queue.showCallsInQueue') }}</div></b-form-checkbox>
						<b-form-checkbox v-model="data.showMembers" size="sm" switch><div class="section-label">{{ $t('queue.showMembers') }}</div></b-form-checkbox>
						<b-form-checkbox v-model="data.showStats" size="sm" switch><div class="section-label">{{ $t('queue.showStatistics') }}</div></b-form-checkbox>
					</div>
				</b-form-group>
				<b-row>
					<b-col>
						<b-form-group v-if="data.showCalls" size="sm" :label="$t('queue.numberOfCalls')" :description="$t('queue.numberOfCallsDescription')">
							<b-form-spinbutton size="sm" v-model="data.maxCalls" min="1" max="100"></b-form-spinbutton>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group v-if="data.showMembers" size="sm" :label="$t('queue.numberOfMembers')" :description="$t('queue.numberOfMembersDescription')">
							<b-form-spinbutton size="sm" v-model="data.maxMembers" min="1" max="100"></b-form-spinbutton>
						</b-form-group>
					</b-col>
				</b-row>
				<b-container style="padding:0" v-if="data.showCalls">
					<b-row>
						<b-col>
							<b-form-group :label="$t('queue.callVariableName')">
								<b-input type="text" size="sm" maxlength="64" v-model="data.variable"></b-input>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('queue.callVariableLabel')">
								<b-input type="text" size="sm" maxlength="32" v-model="data.variableName"></b-input>
							</b-form-group>
						</b-col>
					</b-row>
				</b-container>
				<div class="variable-info" v-if="data.showCalls">{{ $t('queue.callVariableDescription') }}</div>

				<b-form-group :label="$t('queue.statisticsElementsHeader')" v-if="data.showStats">
					<b-row style="margin-left:-5px">
						<b-col>
							<b-form-checkbox-group size="sm" v-model="data.stats" :options="statsOptions" switches class="switch-array-fix"></b-form-checkbox-group>
						</b-col>
						<b-col>
							<b-form-checkbox-group size="sm" v-model="data.stats" :options="statsOptions2" switches class="switch-array-fix"></b-form-checkbox-group>
						</b-col>
					</b-row>
				</b-form-group>
			</div>

			<!-- VIEW MODE -->
			<div v-if="!dashboards.editMode&&data._id" class="view-box" :style="{'max-height': (collapsed? '0px' : '2000px')}">

				<call-draggable :data="queue" type="QueueCallers" :target="true" :draggable="false" :targetTypes="['MyCallsCall','QueueCall']">
					<div :id="'QueueCalls-'+data._id" v-if="data.showCalls">
						<div class="section-header">
							<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleCollapseCallers()">
								<glyph-icon :icon="collapsedCallers?'221-chevron-down':'222-chevron-up'" color="var(--collapse-button-color-dashboard-header)" size="15"></glyph-icon>
							</b-btn>
							{{ $t('queue.queueSectionHeader') }} ({{ queue.callers.length }})
						</div>
						<div class="vertical-scroll" style="transition:500ms" :style="{'max-height': (collapsedCallers ? 0 : data.maxCalls*45 + 10)+'px','min-height': (collapsedCallers ? 0 : data.maxCalls*45 + 10)+'px'}">
							<transition-group name="list" tag="div">
								<div v-for="(call, index) in queue.callers" :key="call._id" class="list-item">
									<queue-call :call="call" :queueId="queue._id" :index="index" :variable="data.variable" :variableName="data.variableName" :last="queue.callers.length-1==index" :serviceLevel="queue.serviceLevel" :serviceLevelCritical="queue.serviceLevelCritical"></queue-call>
								</div>
							</transition-group>
						</div>
					</div>
				</call-draggable>

				<call-draggable :data="queue" type="QueueMembers" :target="true" :draggable="false" :targetTypes="['Employee','QueueMember']">
					<div :id="'QueueMembers-'+data._id" v-if="data.showMembers">
						<div class="section-header">
							<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleCollapseMembers()">
								<glyph-icon :icon="collapsedMembers?'221-chevron-down':'222-chevron-up'" color="var(--collapse-button-color-dashboard-header)" size="15"></glyph-icon>
							</b-btn>
							<div style="float:right" v-if="canManage" @click="addMember" :title="$t('queue.addMemberTitle')">
								<glyph-icon icon="191-circle-empty-plus" size="18" color="#fff" class="add-member"></glyph-icon>
							</div>
							{{ $t('queue.memberSectionHeader') }} ({{ queue.members.length }})
						</div>
						<div class="vertical-scroll" style="transition:500ms" :style="{'max-height': (collapsedMembers ? 0 : data.maxMembers*42 + 20)+'px','min-height': (collapsedMembers ? 0 : data.maxMembers*42 + 20)+'px'}">
							<transition-group name="list2" tag="div">
								<div v-for="(member, index) in orderedMembers" :key="member._id" class="list-item">
									<queue-member :member="member" :index="index" :queueId="queue._id" :last="queue.members.length-1==index"></queue-member>
								</div>
							</transition-group>
						</div>
						<v-contextmenu :ref="'QueueMembers-'+data._id+'-add'" :auto-placement="true">
							<v-contextmenu-item @click="addMembersExtensions">{{ $t('queue.addExtension') }}</v-contextmenu-item>
							<v-contextmenu-item @click="addMembersExternal">{{ $t('queue.addExternal') }}</v-contextmenu-item>
						</v-contextmenu>
					</div>
				</call-draggable>

				<div :id="'QueueStats-'+data._id" v-if="data.showStats">
					<div class="section-header">
						<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleCollapseStatistics()">
							<glyph-icon :icon="collapsedStatistics?'221-chevron-down':'222-chevron-up'" color="var(--collapse-button-color-dashboard-header)" size="15"></glyph-icon>
						</b-btn>
						{{ $t('queue.statisticsSectionHeader') }}<span v-if="collapsedStatistics">&nbsp;({{data.stats.length }})</span>
					</div>
					<div class="stats-box" style="transition:500ms" :style="{'max-height': (collapsedStatistics ? '0px' : statsHeight)}">
						<div v-for="key in data.stats" :key="key" :class="{'clickable': clickableStats.includes(key)}" @click="statsClicked(key)">
							<div v-if="key=='NOMEMBERS'" class="stats-head" v-html="$t('queue.stats.NOMEMBERS')"></div>
							<div v-if="key=='ALLBUSY'" class="stats-head" v-html="$t('queue.stats.ALLBUSY')"></div>
							<div v-if="key=='TIMEOUT'" class="stats-head">{{ $t('queue.stats.TIMEOUT1') }}<br>{{ s.dashboardColumnWidth &lt; 370 ? '' : $t('queue.stats.TIMEOUT2') }} {{ $t('queue.stats.TIMEOUT3') }}</div>
							<div v-if="key=='CANCELLED'" class="stats-head" v-html="$t('queue.stats.CANCELLED')"></div>
							<div v-if="key=='ANSWERED'" class="stats-head" v-html="$t('queue.stats.ANSWERED')"></div>
							<div v-if="key=='UNANSWERED'" class="stats-head" v-html="$t('queue.stats.UNANSWERED')"></div>
							<div v-if="key=='TOTAL'" class="stats-head" v-html="$t('queue.stats.TOTAL')"></div>
							<div v-if="key=='AVERAGEWAITTIME'" class="stats-head" v-html="$t('queue.stats.AVERAGEWAITTIME')"></div>
							<div v-if="key=='AVERAGETALKTIME'" class="stats-head" v-html="$t('queue.stats.AVERAGETALKTIME')"></div>
							<div v-if="key=='TOTALTALKTIME'" class="stats-head" v-html="$t('queue.stats.TOTALTALKTIME')"></div>
							<div v-if="key=='LONGESTWAITTIME'" class="stats-head" v-html="$t('queue.stats.LONGESTWAITTIME')"></div>
							<div v-if="key=='LASTWAITTIME'" class="stats-head" v-html="$t('queue.stats.LASTWAITTIME')"></div>
							<div v-if="key=='SERVICELEVEL'" class="stats-head" v-html="$t('queue.stats.SERVICELEVEL')"></div>
							<div v-if="key=='SERVICELEVELCRITICAL'" class="stats-head">{{ (s.dashboardColumnWidth &lt; 370 ? $t('queue.stats.SERVICELEVELCRITICAL1') : $t('queue.stats.SERVICELEVELCRITICAL2')) }}<br>{{ $t('queue.stats.SERVICELEVELCRITICAL3') }}</div>

							<div v-if="key=='NOMEMBERS'" class="stats-value">{{ queueStats.noMembers }}</div>
							<div v-if="key=='ALLBUSY'" class="stats-value">{{ queueStats.allBusy }}</div>
							<div v-if="key=='TIMEOUT'" class="stats-value">{{ queueStats.timeout }}</div>
							<div v-if="key=='CANCELLED'" class="stats-value">{{ queueStats.cancelled }}</div>
							<div v-if="key=='ANSWERED'" class="stats-value">{{ queueStats.answered }}</div>
							<div v-if="key=='UNANSWERED'" class="stats-value">{{ queueStats.unanswered }}</div>
							<div v-if="key=='TOTAL'" class="stats-value">{{ queueStats.total }}</div>
							<div v-if="key=='AVERAGEWAITTIME'" class="stats-value">{{ queueStats.averageWaitTime | shortduration(false) }}<span class="stats-value-label">{{ queueStats.averageWaitTime | shortduration(true) }}</span></div>
							<div v-if="key=='AVERAGETALKTIME'" class="stats-value">{{ queueStats.averageTalkTime | shortduration(false) }}<span class="stats-value-label">{{ queueStats.averageTalkTime | shortduration(true) }}</span></div>
							<div v-if="key=='TOTALTALKTIME'" class="stats-value">{{ queueStats.totalTalkTime | shortduration(false) }}<span class="stats-value-label">{{ queueStats.totalTalkTime | shortduration(true) }}</span></div>
							<div v-if="key=='LONGESTWAITTIME'" class="stats-value">{{ queueStats.longestWaitTime | shortduration(false) }}<span class="stats-value-label">{{ queueStats.longestWaitTime | shortduration(true) }}</span></div>
							<div v-if="key=='LASTWAITTIME'" class="stats-value">{{ queueStats.lastWaitTime | shortduration(false) }}<span class="stats-value-label">{{ queueStats.lastWaitTime | shortduration(true) }}</span></div>
							<div v-if="key=='SERVICELEVEL'" class="stats-value">{{ queueStats.serviceLevel }}<span class="stats-value-label">%</span></div>
							<div v-if="key=='SERVICELEVELCRITICAL'" class="stats-value">{{ queueStats.serviceLevelCritical }}<span class="stats-value-label">%</span></div>
						</div>
					</div>
				</div>

			</div>
			<queue-add-external-modal v-if="queue" :ref="addExternalModalRef" :queue="queue" />
			<queue-add-extension-modal v-if="queue" :ref="addExtensionModalRef" :queue="queue" />
			<queue-calls-modal v-if="queue" :ref="queueCallsModalRef" :queue="queue" />
		</div>
	</panel-draggable>
</template>

<script>
import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import PanelDraggable from './PanelDraggable.vue';
import u from '../../utils/utils';
import QueueCall from './QueueCall.vue';
import QueueMember from './QueueMember.vue';
import employees from '../../data/employees';
import Vue from 'vue';
import BindCache from '../../utils/BindCache';
import EventBus from '../../data/EventBus';
import queues from '../../data/queues';
import QueueAddExternalModal from '../modals/QueueAddExternalModal';
import QueueAddExtensionModal from '../modals/QueueAddExtensionModal';
import QueueCallsModal from '../modals/QueueCallsModal';
import CallDraggable from './CallDraggable';
import i18n from '../../utils/i18n';
import mediaDevices from '../../data/mediaDevices';

// queue object:
// _id, name, number, dynamic, member, 
//   members{ _id, extension, number, priority, calls, lastCallEnded, permanent },
//   callers{ _id, state, number, name, entered, answered, presentation, answeredByExtension, answeredByNumber }

export default {
	components: {
		GlyphIcon,
		PanelDraggable,
		QueueCall,
		QueueMember,
		QueueAddExternalModal,
		QueueAddExtensionModal,
		QueueCallsModal,
		CallDraggable
	},
	props: {
		data: Object
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			isDraggable: false,
			queue: {
				_id: null,
				name: 'Ny kø',
				callers: [],
				members: [],
				stats: [],
				serviceLevel: 0,
				serviceLevelCritical: 0
			},
			queueStats: { },
			availableQueues: [],
			commonState: 'GREEN',
			s: s,
			isMember: false, // If the user is a member of the queue
			isPermanentMember: false, // If the user is a permanent member of the queue
			myExtensionIds: [], // List of users extension ids
			myPrimaryExtension: null, // Extension id to use when adding or removing the user from/to this queue
			bindCache: new BindCache(this),
			initRunning: false,
			statsOptions: [
				{ value: 'UNANSWERED', 		html: `<span>${i18n.t('queue.stats2.UNANSWERED')}</span>` },
				{ value: 'TOTAL', 			html: `<span>${i18n.t('queue.stats2.TOTAL')}</span>` },
				{ value: 'ALLBUSY', 		html: `<span>${i18n.t('queue.stats2.ALLBUSY')}</span>` },
				{ value: 'CANCELLED', 		html: `<span>${i18n.t('queue.stats2.CANCELLED')}</span>` },
				{ value: 'LONGESTWAITTIME', html: `<span>${i18n.t('queue.stats2.LONGESTWAITTIME')}</span>` },
				{ value: 'AVERAGETALKTIME', html: `<span>${i18n.t('queue.stats2.AVERAGETALKTIME')}</span>` },
				{ value: 'SERVICELEVEL',	html: `<span>${i18n.t('queue.stats2.SERVICELEVEL')}</span>` }
			],
			statsOptions2: [
				{ value: 'ANSWERED', 		html: `<span>${i18n.t('queue.stats2.ANSWERED')}</span>` },
				{ value: 'NOMEMBERS', 		html: `<span>${i18n.t('queue.stats2.NOMEMBERS')}</span>` },
				{ value: 'TIMEOUT', 		html: `<span>${i18n.t('queue.stats2.TIMEOUT')}</span>` },
				{ value: 'AVERAGEWAITTIME', html: `<span>${i18n.t('queue.stats2.AVERAGEWAITTIME')}</span>` },
				{ value: 'LASTWAITTIME', 	html: `<span>${i18n.t('queue.stats2.LASTWAITTIME')}</span>` },
				{ value: 'TOTALTALKTIME', 	html: `<span>${i18n.t('queue.stats2.TOTALTALKTIME')}</span>` },
				{ value: 'SERVICELEVELCRITICAL',	html: `<span>${i18n.t('queue.stats2.SERVICELEVELCRITICAL')}</span>` }
			],
			collapsed: s.getGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsed`) || false,
			collapsedCallers: s.getGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedCallers`) || false,
			collapsedMembers: s.getGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedMembers`) || false,
			collapsedStatistics: s.getGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedStatistics`) || false,
			addExternalModalRef: Math.random().toString(),
			addExtensionModalRef: Math.random().toString(),
			queueCallsModalRef: Math.random().toString(),

			me: null,
			clickableStats: [ 'UNANSWERED', 'ANSWERED', 'NOMEMBERS', 'ALLBUSY', 'TIMEOUT', 'CANCELLED']
		};
	},
	computed: {
		orderedMembers: function() {
			const members = [];
			this.queue.members.forEach(member => { members.push(member); });
			if (this.queue) { return members.sort(u.dynamicSort('priority')); }
			return [];
		},
		canManage: function() {
			let can = false;
			if (this.me) {
				can = this.queue && this.me.permissions.queueMember;
			}
			if (this.queue && s.isAuthenticated) {
				if (!this.queue.dynamic && ['PERSONAL','VIEWER'].includes(s.auth.accessLevel)) {
					can = false;
				}
			}
			return can;
		},
		statsHeight: function() {
			if (this.data && this.data.showStats) {
				const rows = Math.ceil(this.data.stats.length / 4);
				return (rows * 54).toString() + 'px';
			} else {
				return '0px';
			}
		}
	},
	methods: {
		// #region EDIT functions
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		queueSelected(id) {
			this.queue = this.availableQueues.find(o => o._id == id);
		},
		// #endregion

		// #region VIEW functions
		toggleCollapse() {
			this.collapsed = !this.collapsed;
			s.setGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsed`, this.collapsed);
		},
		toggleCollapseCallers() {
			this.collapsedCallers = !this.collapsedCallers;
			s.setGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedCallers`, this.collapsedCallers);
		},
		toggleCollapseMembers() {
			this.collapsedMembers = !this.collapsedMembers;
			s.setGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedMembers`, this.collapsedMembers);
		},
		toggleCollapseStatistics() {
			this.collapsedStatistics = !this.collapsedStatistics;
			s.setGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsedStatistics`, this.collapsedStatistics);
		},
		eventQueueUpdated(queue) { },
		eventQueueDeleted(queue) {
			if (this.queue._id == queue._id) {
				dashboards.removeItem(this.data.column, this.data.position);
				dashboards.save()
					.then(() => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('queue.panelDeletedHeader'), message: i18n.t('queue.panelDeletedMessage').replace('%%name%%', queue.name) });
					})
					.catch(() => {});
			}
		},
		eventQueueEntered(args) { },
		eventQueueUpdate(args) { },
		eventQueueLeft(args) { // args = { queue: id, caller: { _id, state, number, name, privacy, entered, answered, answeredByExtension, answeredByNumber }, index: number }
			if (this.queue._id == args.queue) {
				if (args.caller.state == 'ANSWERED') {
					const waittime = Math.floor((new Date(args.caller.answered) - new Date(args.caller.entered)) / 1000);
					this.queueStats.lastWaitTime = waittime;
					this.queueStats.answered++;
					this.queueStats.total++;
				} else if (args.caller.state == 'CANCELLED') {
					this.queueStats.total++;
					this.queueStats.cancelled++;
					this.queueStats.unanswered++;
				} else if (args.caller.state == 'TIMED_OUT') {
					this.queueStats.total++;
					this.queueStats.timeout++;
					this.queueStats.unanswered++;
				} else if (args.caller.state == 'NO_MEMBERS') {
					this.queueStats.total++;
					this.queueStats.noMembers++;
					this.queueStats.unanswered++;
				} else if (args.caller.state == 'ALL_BUSY') {
					this.queueStats.total++;
					this.queueStats.allBusy++;
					this.queueStats.unanswered++;
				}
				this.queueStats.total++;
			}

		},
		eventQueueMemberJoin(args) { // args = { queue: id, member: { _id, extension, number, priority, calls, lastCallEnded, permanent }}
			if (this.queue._id == args.queue) {
				if (this.myExtensionIds.includes(args.member.extension)) {
					this.isMember = true;
					this.isPermanentMember = args.member.permanent || false;
				}
			}
		},
		eventQueueMemberUpdate(args) { // args = { queue: id, member: { _id, extension, number, priority, calls, lastCallEnded, permanent }, index: number }
			if (this.queue._id == args.queue) {
				if (this.myExtensionIds.includes(args.member.extension)) {
					this.isPermanentMember = args.member.permanent || false;
				}
			}
		},
		eventQueueMemberLeft(args) {  // args = { queue: id, member: { _id, extension, number, priority, calls, lastCallEnded, permanent }, index: number }
			if (this.queue._id == args.queue) {
				if (this.myExtensionIds.includes(args.member.extension)) {
					this.isMember = false;
					this.isPermanentMember = false;
				}
			}
		},
		addMember(e) {
			e.preventDefault();
			const bcr = e.currentTarget.getBoundingClientRect();
			setTimeout(() => { this.$refs[`QueueMembers-${this.data._id}-add`].show({ left: window.pageXOffset + bcr.left + 3, top: window.pageYOffset + bcr.bottom + 2 }); }, 1);
		},
		addMembersExtensions() {
			this.$refs[this.addExtensionModalRef].show();
		},
		addMembersExternal() {
			this.$refs[this.addExternalModalRef].show();
		},
		membershipChanged(newValue) {
			if (newValue) {
				s.http.post(`/pbx/queue/${this.queue._id}/member`, { extension: this.myPrimaryExtension._id })
					.catch(res => {
						this.isMember = false;
					});
			} else {
				// Ensure I am not a permanent member
				const member = this.queue.members.find(o => o.extension == this.myPrimaryExtension._id);
				if (member && !member.permanent) {
					s.http.delete(`/pbx/queue/${this.queue._id}/member/${this.myPrimaryExtension._id}`)
						.catch(res => {
							this.isMember = true;
						});
				}
			}
		},
		statsClicked(key) {
			if (!this.clickableStats.includes(key)) { return; }
			this.$refs[this.queueCallsModalRef].show(key);
		},
		async init() {
			if (this.initRunning) { return; }
			this.initRunning = true;
			this.me = await employees.getMe();
			if (!this.me) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
			if (this.data._id) { // Load queue, if selected
				this.queue = await queues.getQueue(this.data._id);
				if (!this.queue) {
					EventBus.$emit('Dashboard:DeleteItem', this.data);
				}
				this.loadStats();
			}
			if (this.me) {
				this.myExtensionIds = this.me.extensions.map(o => o._id);
				this.myPrimaryExtension = this.me.extensions.find(o => o.primary);
			}
			if (this.queue) {
				const member = this.queue.members.find(o => this.myExtensionIds.includes(o._id));
				this.isMember = member != null;
				this.isPermanentMember = member?.permanent || false;
			}
			if (this.data.showStats) {
				if (!this.data.stats || this.data.stats.length == 0) {
					this.data.stats = [ 'UNANSWERED','ANSWERED','AVERAGEWAITTIME','LASTWAITTIME'];
				}
			}
			this.initRunning = false;
		},
		loadStats() {
			if (this.data.showStats) {
				s.http.get(`/pbx/queue/${this.data._id}/stats`)
					.then(res => { this.queueStats = res.data; })
					.catch(() => {});
			}
		},
		updateCommonState() {
			let color = 'GREEN';
			if (this.queue && this.queue.callers) {
				this.queue.callers.forEach(call => {
					const seconds = Math.round((new Date() - call.entered)/1000);
					if (seconds > this.queue.serviceLevel && seconds <= this.queue.serviceLevelCritical) {
						color = 'YELLOW';
					} else if (seconds > this.queue.serviceLevelCritical) {
						color = 'RED';
					}
				});
				if (this.commonState != color) {
					this.commonState = color;
					if (color != 'GREEN' && s.queueCriticalSound) {
						this.playCommonStateSound();
					}
				}
			}
		},
		async playCommonStateSound() {
			const parent = document.getElementById('SipPhoneVideoContainer');
			const ae = document.createElement('audio');
			ae.id = 'RingTonePlayer';
			ae.autoplay = true;
			ae.loop = false;
			parent.appendChild(ae);
			if (mediaDevices.ringtoneDeviceId) {
				await ae.setSinkId(mediaDevices.ringtoneDeviceId);
			}
			ae.src = 'https://audio.telecomx.dk/619e5328aa69b00a66f57f6a.mp3';
			ae.currentTime = 0;
			ae.volume = mediaDevices.ringerVolume;
			ae.play();
			setTimeout(() => {
				parent.removeChild(ae);
				ae.src = '';
			}, 7000);

		}

		// #endregion

	},
	created() {
		this.init();
	},
	mounted() {
		if (dashboards.editMode) {
			queues.getList().then(queueList => {
				if (this.queue._id == null) {
					this.availableQueues = [{ _id: null, name: i18n.t('queue.selectAQueue'), text: i18n.t('queue.selectAQueue') }];
				}
				this.availableQueues.push(...queueList.sort(u.dynamicSort('name')));
			});
		}
		EventBus.$on('Queues:Updated', this.bindCache.bind('eventQueueUpdated', this.eventQueueUpdated));
		EventBus.$on('Queues:Deleted', this.bindCache.bind('eventQueueDeleted', this.eventQueueDeleted));
		EventBus.$on('Queues:Entered', this.bindCache.bind('eventQueueEntered', this.eventQueueEntered));
		EventBus.$on('Queues:Update',  this.bindCache.bind('eventQueueUpdate', this.eventQueueUpdate));
		EventBus.$on('Queues:Left',    this.bindCache.bind('eventQueueLeft', this.eventQueueLeft));
		EventBus.$on('Queues:MemberJoin',    this.bindCache.bind('eventQueueMemberJoin', this.eventQueueMemberJoin));
		EventBus.$on('Queues:MemberUpdate',  this.bindCache.bind('eventQueueMemberUpdate', this.eventQueueMemberUpdate));
		EventBus.$on('Queues:MemberLeft',    this.bindCache.bind('eventQueueMemberLeft', this.eventQueueMemberLeft));
		EventBus.$on('Queues:Loaded', this.bindCache.bind('init', this.init));
		EventBus.$on('OneSecond', this.bindCache.bind('updateCommonState', this.updateCommonState));
		EventBus.$on('OneMinute', this.bindCache.bind('loadStats', this.loadStats));
	},
	destroyed() {
		EventBus.$off('Queues:Updated', this.bindCache.unbind('eventQueueUpdated'));
		EventBus.$off('Queues:Deleted', this.bindCache.unbind('eventQueueDeleted'));
		EventBus.$off('Queues:Entered', this.bindCache.unbind('eventQueueEntered'));
		EventBus.$off('Queues:Update',  this.bindCache.unbind('eventQueueUpdate'));
		EventBus.$off('Queues:Left',    this.bindCache.unbind('eventQueueLeft'));
		EventBus.$off('Queues:MemberJoin',    this.bindCache.unbind('eventQueueMemberJoin'));
		EventBus.$off('Queues:MemberUpdate',  this.bindCache.unbind('eventQueueMemberUpdate'));
		EventBus.$off('Queues:MemberLeft',    this.bindCache.unbind('eventQueueMemberLeft'));
		EventBus.$off('Queues:Loaded', this.bindCache.unbind('init'));
		EventBus.$off('OneSecond', this.bindCache.unbind('updateCommonState'));
		EventBus.$off('OneMinute', this.bindCache.unbind('loadStats'));
	},
	watch: {
		// When edit mode is enabled, load the list of available queues
		'dashboards.editMode': function(newValue) {
			if (newValue) {
				queues.getList().then(queueList => {
					if (this.queue._id == null) {
						this.availableQueues = [{ _id: null, name: i18n.t('queue.selectAQueue'), text: i18n.t('queue.selectAQueue') }];
					}
					this.availableQueues.push(...queueList.sort(u.dynamicSort('name')));
				});
			} else {
				this.availableQueues = [];
				this.filter = '';
				this.init();
			}
		}
	}
};
</script>

<style scoped>
	.section-header {
		border-radius: 5px;
		background-color: var(--bg-color-dashboard-header);
		color: var(--txt-color-dashboard-header);
		padding-left: 10px;
		padding-right: 2px;
		margin: 4px;
		font-size: var(--font-size-secondary);
		font-weight: 300;
	}
	.settings-box {
		margin: 5px 5px;
	}
	.view-box {
		/* margin: 5px 5px; */
		overflow: auto;
		transition: 500ms;
	}
	.list-item {
		display: inline-block;
		transition: all 0.7s;
		width: 100%;
	}
	.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(15px);
	}
	/* .list-leave-active {
		position: absolute;
	} */
	.list2-enter, .list2-leave-to /* .list-leave-active below version 2.1.8 */ {
		opacity: 0;
		transform: translateY(15px);
	}
	/* .list2-leave-active {
		position: absolute;
	} */
	.vertical-scroll {
		overflow-y: scroll;
		overflow-x: hidden;
		margin-bottom: 5px;
	}
	.vertical-scroll::-webkit-scrollbar {
		width: 1px;
		background: transparent;
	}
	.vertical-scroll::-webkit-scrollbar-thumb {
		background: var(--pn-primary-1);
	}
	.add-member {
		margin-top: -1px;
		margin-left: 5px;
		cursor: pointer;
	}
	.stats-box {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		column-gap: 6px;
		margin-left: 4px;
		margin-right: 4px;
		overflow: hidden;
	}
	.stats-box > div {
		border-radius: 10px;
		background-color: var(--bg-color-3);
		box-shadow: 0px 0px 3px 0px #66666640;
		padding-top: 5px;
		padding-bottom: 4px;
		margin-bottom: 5px;
	}
	.stats-head {
		font-size: 10px;
		text-align: center;
		/* font-weight: 400; */
		line-height: 10px;
		color: var(--pn-black-3);
		padding-bottom: 2px;
	}
	.stats-value {
		font-size: 16px;
		line-height: 16px;
		color: var(--pn-primary-1);
		font-weight: 600;
		text-align: center;
	}
	.stats-value-label {
		font-size: 11px;
		color: var(--pn-black-3);
		font-weight: 300;
		margin-left:2px;
	}
	.variable-info {
		margin-top: -7px;
		line-height: 12px;
		font-size: 11px;
		margin-bottom:10px;
		color: var(--txt-color-secondary);
	}
	.section-label {
		font-size: var(--font-size-secondary);
		margin-left: -5px;
		margin-top: 1px;
	}
	.clickable {
		cursor: pointer;
	}

</style>