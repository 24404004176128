<template>
	<div @contextmenu="preventContextMenu">
		<div class="disconnected-alert" :class="{ visible: s.networkError||(!rt.connected&&s.isAuthenticated&&rt.firstConnectCompleted) }">{{ $t('app.noServerConnection') }}</div>
		<div v-if="isAuthenticated">
			<topbar v-if="rt.firstConnectCompleted&&dashboards.ready" />
			<div class="main-top-offset">
				<div class="background">
					<div class="logo">COMMUNICATOR</div>
					<div class="loading" :style="{'opacity':rt.firstConnectCompleted&&dashboards.ready?0:1}">Indlæser</div>
				</div>
				<dashboard-container :style="{'display': s.selectedSection.startsWith('DASHBOARD')?'block':'none'}" />
				<phonebook-container :style="{'display': s.selectedSection=='PHONEBOOK'?'block':'none'}" />
				<cdr-container :style="{'display': s.selectedSection=='CALLS'?'block':'none'}" />
				<recordings-container :style="{'display': s.selectedSection=='RECORDINGS'?'block':'none'}" />
				<voicemail-container :style="{'display': s.selectedSection=='VOICEMAIL'?'block':'none'}" />
			</div>
			<status-panel v-show="s.showStatusPanel"></status-panel>

		</div>
		<login v-if="showLogin"></login>
		<b-modal id="CommonErrorModal" ok-only :title="errorHeader" centered hide-header-close>
			<div style="display:flex;align-items:center">
				<div>
					<glyph-icon icon="79-triangle-empty-alert" size="64" color="red" class="icon"></glyph-icon>
				</div>
				<div style="margin-left:10px" v-html="errorMessage">
				</div>
			</div>
		</b-modal>
		<b-modal id="CommonQuestionModal" ok-only :title="questionHeader" centered hide-header-close hide-footer>
			<div style="display:flex;align-items:center">
				<div>
					<glyph-icon icon="195-circle-empty-question" size="64" color="#84ADB6" class="icon"></glyph-icon>
				</div>
				<div style="margin-left:10px">
					{{ questionMessage }}
				</div>
			</div>
			<div class="question-space-to-bottom"></div>

			<div class="question-bottom">
				<b-button variant="secondary" style="height:30px" size="sm" @click="questionCancel()" tabindex="100">{{ questionCancelLabel }}</b-button>
				<b-button variant="primary" style="height:30px" size="sm" type="submit" @click="questionOk()" tabindex="110">{{ questionOkLabel }}</b-button>
			</div>
		</b-modal>
		<set-presence-modal ref="SetPresenceModal" :employee="setPresenceEmployee" />
		<send-sms-modal ref="SendSmsModal" />
		<send-group-sms-modal ref="SendGroupSmsModal" />
		<employee-data ref="EmployeeDataModal" />
		<employee-redirect-modal ref="EmployeeRedirectModal" />
		<settings-modal ref="SettingsModal" />
		<audio-player-modal ref="AudioPlayerModal" />
		<div id="SipPhoneVideoContainer"></div>
	</div>
</template>

<script>
import EventBus from './data/EventBus';
import Login from './components/Login';
import Topbar from './components/topbar/Topbar.vue';
import GlyphIcon from './components/GlyphIcon.vue';
import s from './settings';
import rt from './data/realtime';
import StatusPanel from './components/StatusPanel.vue';
import DashboardContainer from './components/dashboard/Container.vue';
import SetPresenceModal from './components/modals/SetPresenceModal.vue';
import employees from './data/employees';
import SendSmsModal from './components/modals/SendSmsModal.vue';
import SendGroupSmsModal from './components/modals/SendGroupSmsModal.vue';
import EmployeeData from './components/modals/EmployeeData.vue';
import EmployeeRedirectModal from './components/modals/EmployeeRedirectModal.vue';
import SettingsModal from './components/modals/Settings.vue';
import PhonebookContainer from './components/phonebook/container.vue';
import CdrContainer from './components/cdr/container.vue';
import AudioPlayerModal from './components/modals/AudioPlayerModal.vue';
import RecordingsContainer from './components/recordings/container.vue';
import VoicemailContainer from './components/voicemail/container.vue';
// import adapter from 'webrtc-adapter';
import MyNotification from './utils/MyNotification';
import i18n from './utils/i18n';
import dashboards from './data/dashboards';
import logger from './data/logger';



export default {
	components: {
		Login,
		Topbar,
		GlyphIcon,
		StatusPanel,
		DashboardContainer,
		SetPresenceModal,
		SendSmsModal,
		SendGroupSmsModal,
		EmployeeData,
		EmployeeRedirectModal,
		SettingsModal,
		PhonebookContainer,
		CdrContainer,
		AudioPlayerModal,
		RecordingsContainer,
		VoicemailContainer
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			rt: rt,
			dashboards: dashboards,
			errorHeader: null,
			errorMessage: null,
			questionHeader: '',
			questionMessage: '',
			questionCancelLabel: 'Fortryd',
			questionOkLabel: 'Ok',
			questionEmit: '',
			questionEmitData: null,
			questionCancelledEmit: '',
			questionCancelledEmitData: null,
			setPresenceEmployee: null,
			commonErrorModalIsVisible: false,
			commonErrorModalQueue: [],
			commonErrorModalCurrent: null
		};
	},
	methods: {
		showErrorModal(data) {
			this.errorHeader = data.header;
			this.errorMessage = data.message;
			this.$bvModal.show('CommonErrorModal');
		},
		queueForErrorModal(data) {
			if (!data) { return; }
			if (!this.commonErrorModalIsVisible) {
				this.showErrorModal(data);
				this.commonErrorModalCurrent = data;
			} else {
				if (this.commonErrorModalCurrent && this.commonErrorModalCurrent.header == data.header && this.commonErrorModalCurrent.message == data.message) { return; } // Same message as currently showing - drop it
				if (this.commonErrorModalQueue.find(queued => { return queued.header == data.header && queued.message == data.message; })) { return; } // Same message is in queue - drop it
				this.commonErrorModalQueue.push(data);
			}
		},
		showQuestionModal(data) {
			this.questionHeader = data.header;
			this.questionMessage = data.message;
			this.questionCancelLabel = data.cancel || 'Fortryd';
			this.questionOkLabel = data.ok || 'Ok';
			this.questionEmit = data.emit || '';
			this.questionEmitData = data.emitData || null;
			this.questionCancelledEmit = data.cancelledEmit || '';
			this.questionCancelledEmitData = data.cancelledEmitData || null;
			this.$bvModal.show('CommonQuestionModal');
		},
		questionCancel() {
			if (this.questionCancelledEmit) { Eventbus.$emit(this.questionCancelledEmit, this.questionCancelledEmitData); }
			this.$bvModal.hide('CommonQuestionModal');
		},
		questionOk() {
			this.$bvModal.hide('CommonQuestionModal');
			if (this.questionEmit) { EventBus.$emit(this.questionEmit, this.questionEmitData); }
		},
		showSetPresenceModal(employeeId) {
			const that = this;
			employees.getEmployee(employeeId)
				.then(emp => {
					that.setPresenceEmployee = emp;
					setTimeout(() => {
						that.$refs.SetPresenceModal.show();
					}, 1);
				});
		},
		showEmployeeRedirectModal(employee) {
			this.$refs.EmployeeRedirectModal.show(employee);
		},
		showSendSmsModal(data) {
			this.$refs.SendSmsModal.show(data.employee, data.number);
		},
		showSendGroupSmsModal(data) {
			this.$refs.SendGroupSmsModal.show();
		},
		showEmployeeDataModal(employee) {
			this.$refs.EmployeeDataModal.show(employee);
		},
		showSettings() {
			this.$refs.SettingsModal.show()
				.catch(err => {
					this.showErrorModal({ header: i18n.t('settings.settings'), message: i18n.t('settings.failedToInitialize') + '<br><br>' + err.message });
				});
		},
		preventContextMenu(e) {
			if (!['localhost','127.0.0.1'].includes(window.location.hostname)) {
				e.preventDefault(); // Prevent browser contextmenu from showing up in production
			}
		},
		showAudioPlayerModal(settings) {
			this.$refs.AudioPlayerModal.show(settings);
		},
		// To enable audio playback when phone is ringing
		unlockAudioContext(audioCtx) {
			if (audioCtx.state !== 'suspended') {
				console.log('Audio context does not need unlocking - ' + audioCtx.state); // eslint-disable-line
				return;
			}
			console.log('Audio context needs unlocking - ' + audioCtx.state); // eslint-disable-line
			const b = document.body;
			const events = ['touchstart','touchend', 'mousedown','keydown'];
			events.forEach(e => b.addEventListener(e, unlock, false));
			function unlock() { audioCtx.resume().then(() => {
				clean();
				console.log('Audio context has been unlocked'); // eslint-disable-line
				if (s.myPhoneId == 'SOFTPHONE') {
					MyNotification.show(s.appName, i18n.t('app.ringtoneEnabled'));
				}
			}); }
			function clean() { events.forEach(e => b.removeEventListener(e, unlock)); }
		},
		wakeupEvent() {
			this.commonErrorModalQueue = [];
			this.commonErrorModalCurrent = null;
			this.$bvModal.hide('CommonErrorModal');
			this.$bvModal.hide('CommonQuestionModal');
			this.$bvModal.hide('AudioPlayerModal');
			this.$refs.EmployeeRedirectModal.close();
			this.$refs.SendSmsModal.close();
			this.$refs.SendGroupSmsModal.close();
			this.$refs.EmployeeDataModal.close();
			this.$refs.SettingsModal.close();
		}
	},
	computed: {
		isAuthenticated: () => { return s.isAuthenticated; },
		showLogin: () => { return !s.isAuthenticated && !s.token; }
	},
	mounted() {
		s.ready().then(() => {
			// Enable playback of audio without user interaction, e.g. playing ringtone on incoming calls
			const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
			this.unlockAudioContext(audioCtx);
			if (!s.isEmbedded && s.myPhoneId == 'SOFTPHONE' && audioCtx.state === 'suspended') {
				// Show popup for user to click so that we can have ringtone playback
				this.showErrorModal({ header: s.appName, message: i18n.t('app.enabledAudioByClicking') });
			}
		});
		this.$root.$on('bv::modal::show', (ev, modalId) => {
			if (modalId == 'CommonErrorModal') { this.commonErrorModalIsVisible = true; }
		});
		this.$root.$on('bv::modal::hidden', (ev, modalId) => {
			if (modalId == 'CommonErrorModal') {
				this.commonErrorModalIsVisible = false;
				if (this.commonErrorModalQueue.length > 0) {
					setTimeout(() => {
						this.commonErrorModalCurrent = this.commonErrorModalQueue.shift();
						this.showErrorModal(this.commonErrorModalCurrent);
					}, 200);
				}
			}
		});
	},
	created() {
		// Enable notifications in the browser
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			Notification.requestPermission(() => {});
		} else {
			Notification.requestPermission().catch(() => {});
		}

		EventBus.$on('CommonErrorModal', this.queueForErrorModal.bind(this));
		EventBus.$on('CommonQuestionModal', this.showQuestionModal.bind(this)); // { header, message, cancel, ok, emit, emitData }
		EventBus.$on('CommonSetPresenceModal', this.showSetPresenceModal.bind(this));
		EventBus.$on('CommonSendSmsModal', this.showSendSmsModal.bind(this));
		EventBus.$on('CommonSendGroupSmsModal', this.showSendGroupSmsModal.bind(this));
		EventBus.$on('CommonEmployeeDataModal', this.showEmployeeDataModal.bind(this));
		EventBus.$on('CommonSetRedirectModal', this.showEmployeeRedirectModal.bind(this));
		EventBus.$on('CommonSettings', this.showSettings.bind(this));
		EventBus.$on('AudioPlayerModal:Show', this.showAudioPlayerModal.bind(this));
		EventBus.$on('Wakeup', this.wakeupEvent.bind(this));

		document.addEventListener('keydown', e => {
			if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA' && !e.repeat) {
				if (['0','1','2','3','4','5','6','7','8','9','0','#','*'].includes(e.key)) {
					logger.info(`App: User pressed DTMF TONE ${e.key}`);
					EventBus.$emit('MyCallsSendDTMF', e.key);
				} else if (e.code === 'NumpadSubtract' || e.code === 'NumpadAdd') {
					logger.info('App: User pressed DTMF TONE #');
					EventBus.$emit('MyCallsSendDTMF', '#');
				}
			}
		});
	}
};

</script>
<style scoped>
	.background {
		position: absolute !important;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		padding-top: 120px;

	}
	.background .logo {
		font-size: 11vw;
		text-align: center;
		font-weight: 700;
		color: var(--bg-logo);
		text-shadow: 0 1px var(--bg-logo), -1px 0   var(--bg-color-primary),
				  -1px 2px var(--bg-logo), -2px 1px var(--bg-color-primary),
				  -2px 3px var(--bg-logo), -3px 2px var(--bg-color-primary),
				  -3px 4px var(--bg-logo), -4px 3px var(--bg-color-primary),
				  -4px 5px var(--bg-logo), -5px 4px var(--bg-color-primary),
				  -5px 6px var(--bg-logo), -6px 5px var(--bg-color-primary),
				  -6px 7px var(--bg-logo), -7px 6px var(--bg-color-primary),
				  -7px 8px var(--bg-logo), -8px 7px var(--bg-color-primary);
		transition: 1s;
	}
	.background .loading {
		font-size: 5vw;
		text-align: center;
		color: var(--bg-logo);
		transition: 1s;
	}
	.disconnected-alert {
		background: var(--disconnected-alert-background);
		height: 0px;
		color: var(--disconnected-alert-color);
		font-weight: 600;
		letter-spacing: 5px;
		font-size: 20px;
		animation: flashanim 1.5s; 
		animation-iteration-count: infinite;
		transition: 500ms;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1000;
		box-shadow: 0px 10px 26px 0px rgba(0,0,0,0.38);
		text-align: center;
		/* padding-top: 23px; */
		overflow: hidden;
	}
	.disconnected-alert.visible {
		height: 75px;
		padding-top: 23px;
		transition: 500ms 1s;
	}
	@media (max-width: 849px) {
		.disconnected-alert.visible {
			height: 200px;
			padding-top: 100px;
			letter-spacing: 1px;
		}
	}
	@keyframes flashanim {
		0% { color: var(--disconnected-alert-color); }
		40% { color: var(--disconnected-alert-background); }
		70% { color: var(--disconnected-alert-color); }
	}
	.question-space-to-bottom {
		height: 40px;
	}
	.question-bottom {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 45px;
		background: var(--bg-color-primary);
		border-top: 1px solid #00000010;
		padding-top: 6px;
		padding-right: 6px;
		text-align: right;
		z-index: 100;
	}
	.question-bottom > BUTTON {
		min-width: 100px;
		margin-left: 10px;
	}
	.main-top-offset {
		padding-top: 74px;
	}
	.main-top-offset DIV {
		position: relative;
	}
	@media (max-width: 849px) {
		.main-top-offset {
			padding-top: 200px;
		}
	}


</style>