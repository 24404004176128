/**
 * Module for communicating with Yealink headsets
 * - will use WebHID
 */

import EventBus from '../../data/EventBus';
import mediaDevices from '../../data/mediaDevices';
import logger from '../../data/logger';
import BindCache from '../BindCache';
import sipClient from '../../data/sipClient';


class Yealink {
	constructor() {
		logger.debug('Yealink: Constructor');
		this.bindCache = new BindCache(this);
		this.use = false;
		this.useDeviceName = null;
		this.callId = 0;
		this.hidDevice = null;
		this.holdActive = false;
		this.lastAction = -1;

		EventBus.$on('MediaDeviceChange', this.bindCache.bind('MediaChanged', this.mediaChanged));
		EventBus.$on('MediaDeviceSelected', this.bindCache.bind('MediaSelected', this.mediaChanged));
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'yealink', value: this.use });
			if (this.use) {
				EventBus.$emit('StatusReport', { key: 'yealinkDevice', value: this.useDeviceName });
			}
		});

		logger.registerGlobal('yealink', this);
	}

	mediaChanged(eventDevice) {
		if (eventDevice.kind == 'audioinput' || eventDevice.type == 'audioinput') { // MediaDeviceChange / MediaDeviceSelected
			const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
			if (isItYealink(device)) {
				if (this.isOpen) { this.close(); }
				this.init(device);
			} else {
				if (this.isOpen) { this.close(); }
				this.hidDevice = null;
				this.use = false;
				this.useDeviceName = null;
				logger.debug('Yealink: Selected device is not Yealink - terminating');
			}
		}
	}

	// #region Init

	/**
	 * Setup what needs to be setup to initialize the headset device
	 * @param {Object} audioDevice Audio device we want to control
	 * @return {Promise}
	 */
	async init(audioDevice) {
		logger.info(`Yealink: Initializing - looking for ${audioDevice.label} with ID ${audioDevice.productId}`);
		this.use = false;
		this.useDeviceName = null;

		// Do we have access to the device
		const hidDevices = await navigator.hid.getDevices();
		if (!hidDevices.find(o => o.vendorId === 0x6993)) {
			// We no not have access - ask browser for access
			let hidDevice;
			try {
				const filters = [ { usage: 0x0005, usagePage: 0x000B, vendorId: 0x6993 } ]; // Vendor ensures only Yealink devices are shown / 0x0005=Headset / 0x000B=telephony device
				[hidDevice] = await navigator.hid.requestDevice({ filters });
			}
			catch(_) { /** */ }
			if (!hidDevice) {
				logger.error('Yealink: InitBrowser: Failed to get access to the headset - user probably rejected us');
				return;
			}
			this.hidDevice = hidDevice;
		} else {
			this.hidDevice = hidDevices.find(o => o.vendorId === 0x6993);
		}

		// Test that we can connect and communicate
		try {
			await this.hidDevice.open(); // Open
			await this.hidDevice.sendReport(0x02, new Uint8Array([0])); // OnHook
			await this.hidDevice.close(); // Close
		}
		catch(err) {
			logger.error(`Yealink: InitBrowser: Failed to open communication with ${device.label} - ${err.message}`);
			return;
		}

		this.use = true;
		this.useDeviceName = this.hidDevice.productName;
		logger.info(`Yealink: InitBrowser: Headset connected: ${this.useDeviceName} - ${this.hidDevice.vendorId}:${this.hidDevice.productId}`);
	}

	get isOpen() { return this.hidDevice ? this.hidDevice.opened : false; }

	/**
	 * Open a connection to the headset so that we can start to control it
	 */
	async open() {
		if (!this.hidDevice.opened) {
			await this.hidDevice.open();
			this.hidDevice.addEventListener('inputreport', this.bindCache.bind('HeadsetEvent', this.headsetEvent));
			logger.debug(`Yealink: ${this.useDeviceName} is open and we are listening for events`);
		}
	}

	/**
	 * Closes the connection to the headset when we are done controlling it
	 */
	async close() {
		if (this.hidDevice.opened) {
			await this.hidDevice.close();
			this.hidDevice.removeEventListener('inputreport', this.bindCache.unbind('HeadsetEvent'));
			logger.debug(`Yealink: ${this.useDeviceName} is closed`);
		}
	}

	/**
	 * Handled events from the headset
	 * @param {Object} event Event listener report
	 */
	headsetEvent(event) {
		const { data, device, reportId } = event;
		const value = data.getUint8(0);
		// console.log(`${device.productId} - ${device.productName} -- reportId: ${reportId} - value: ${value}`);

		if (reportId === 2) {
			switch(value) {
				case 2: // Answer button pressed
					logger.info('Yealink: User pressed answer');
					sipClient.headsetAnswerEvent();
					break;
				case 0: // device is on-hook
					if (this.lastAction !== 0) {
						logger.info('Yealink: User pressed hangup');
						sipClient.headsetHangupEvent();
						try { this.close(); }
						catch(_) { /** */ }
					} else {
						logger.info('Yealink: Last action was hangup, so we will not emit Call:Hangup');
					}
					break;
				case 9: // Hold button pressed
					if (!this.holdActive) {
						this.holdActive = true;
						logger.info('Yealink: User pressed hold');
						sipClient.headsetActivateHoldEvent();
					} else {
						this.holdActive = false;
						logger.info('Yealink: User pressed unhold');
						sipClient.headsetDeactivateHoldEvent();
					}
					break;
				case 1: // Device is off hook
					break;
				case 5: // Mute pressed
					break;
				default: // Dont care
					break;
			}
		}
	}

	// #endregion

	// #region Actions - public

	async offHook() {
		logger.info('Yealink: Telling headset to go off hook');
		try {
			await this.open();
			await this.send(1);
		}
		catch(err) { logger.error(`Yealink: Off hook failed - ${err.message}`); }
	}

	async onHook() {
		logger.info('Yealink: Telling headset to go on hook');
		try {
			await this.send(0);
			await this.close();
		}
		catch(err) { logger.error(`Yealink: On hook failed - ${err.message}`); }
	}

	async ringOn() {
		logger.info('Yealink: Telling headset to start ringer');
		try {
			await this.open();
			await this.send(4);
		}
		catch(err) { logger.error(`Yealink: Start ringer failed - ${err.message}`); }
	}

	async ringOff() {
		logger.info('Yealink: Telling headset to stop ringer');
		try {
			await this.send(0);
			await this.close();
		}
		catch(err) { logger.error(`Yealink: Stop ringer failed - ${err.message}`); }
	}

	async hold() {
		logger.info('Yealink: Telling headset to go on hold');
		try {
			await this.send(8+1);
		}
		catch(err) { logger.error(`Yealink: Hold failed - ${err.message}`); }
	}

	async unhold() {
		logger.info('Yealink: Telling headset to unhold');
		try {
			if (!this.isOpen) {
				await this.open();
			}
			await this.send(1);
		}
		catch(err) { logger.error(`Yealink: Unhold failed - ${err.message}`); }
	}

	async send(value) {
		await this.hidDevice.sendReport(0x02, new Uint8Array([value]));
		this.lastAction = value;
	}

	// #endregion

}

/**
 * Check if an audio device is a Yealink headset
 * @param {Object} device Audio device to check
 * @returns True if it is a Yealink headset
 */
function isItYealink(device) {
	return device && (/^6993:/.test(device.productId) || device.label.toLowerCase().includes('yealink')); // deviceId only works in Chrome
}

// Singleton
export default new Yealink();