// Main vue entry point which uses:
// - public/index.html
// - src/App.vue
// - router/index.js

import Vue from 'vue';
import { BootstrapVue, IconsPlugin, OverlayPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App.vue';
import contextmenu from './utils/v-contextmenu/src/index';
import './utils/v-contextmenu/src/styles/index.css';
import './filters/nationalPrefix';
import './filters/duration';
import './filters/formatDate';
import VueObserveVisibility from 'vue-observe-visibility';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import i18n from './utils/i18n';

// Initialize data
import realtime from './data/realtime'; // NOSONAR
import employees from './data/employees'; // NOSONAR
import queues from './data/queues'; // NOSONAR
import phonebook from './data/phonebook'; // NOSONAR
import dashboards from './data/dashboards'; // NOSONAR
import integration from './data/integration'; // NOSONAR

if (!window.location.protocol.startsWith('https')) {
	window.location.href = 'https://' + window.location.hostname;
} else {
	Vue.use(contextmenu);
	Vue.use(BootstrapVue);
	Vue.use(IconsPlugin);
	Vue.use(OverlayPlugin);
	Vue.use(VueObserveVisibility);
	Vue.use(VueVirtualScroller);

	Vue.config.productionTip = false;


	new Vue({ 
		// router,
		el: '#app',
		render: h => h(App),
		i18n
	});
}