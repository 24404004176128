/*
 * Dashboard Employees (used by renderer)
 * 
 * - Holds the current set of employees, including settings, calls etc.
 * - Handles everything about employees from the dashboard
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2020-06-15
 */

//import u from '../utils/utils';
import Vue from 'vue';
import EventBus from './EventBus';
import s from '../settings';
import u from '../utils/utils';
import logger from './logger';

class Phonebook {
	// #region Init, load, setup

	constructor() {
		this.contacts = [];
		this._ready = false;

		EventBus.$on('Realtime:Config:PBX_PHONEBOOK_CREATED', this.createdEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_PHONEBOOK_UPDATED', this.updatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_PHONEBOOK_DELETED', this.deletedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_PHONEBOOK_DELETE_ALL', this.deleteAllEvent.bind(this));
		EventBus.$on('Realtime:Connected', this.init.bind(this));
		EventBus.$on('Auth:LoggedOut', () => {
			this.contacts = [];
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'phonebook', value: this._ready });
		});

		logger.registerGlobal('phonebook', this);
		this.init();
	}

	async init() {
		try {
			this._ready = false;
			await s.ready();
			await this.loadContacts();
		}
		catch(err) {
			// Dont care because realtime will ensure we see an error message if needed
			//EventBus.$emit('CommonErrorModal', { header: 'Telefonbog', message: 'Der opstod en uventet fejl under indlæsning af telefonbogen. Serveren siger: ' + err.message });
		}
	}

	makeSearchAndStuff(contact) {
		contact.search = ['givenName','familyName','company','title','department']
			.map(path => u.getDeepValue(contact, path))
			.filter(o => o)
			.map(o => o.trim().toLowerCase());
		contact.addresses.forEach(address => {
			contact.search.push(
				['street','zip','state','city'].map(path => address[path]).filter(o => o).map(o => o.trim().toLowerCase()).join(' ')
			);
		});
		contact.emailAddresses.forEach(address => {
			contact.search.push(address.address);
		});
		contact.search = contact.search.join('   ');

		contact.name = contact.type == 'PERSON' ? [contact.givenName, contact.familyName].filter(o => o).join(' ') : contact.company;

		contact.phoneNumbers.forEach(number => {
			number.number = s.cutNationalPrefix(number.number);
		});
	}


	async loadContacts() {
		this._ready = false;
		const res = await s.http.get('/pbx/phonebook?offset=0&limit=10000');
		this.contacts = res.data.contacts;
		this.contacts.forEach(contact => {
			this.makeSearchAndStuff(contact);
		});
		this.contacts.sort(u.dynamicSort('name'));
		this._ready = true;
	}

	async ready() {
		if (s.isAuthenticated && this._ready) { return Promise.resolve(); }
		await s.sleep(250);
		return this.ready();
	}


	createdEvent(d) {
		const { event, id, data } = d;
		if (data && data.multiple && s.isAuthenticated && data.personal == s.auth.employee) {
			//console.log('PBX phonebook created multiple event');
			this.loadContacts().then(() => { EventBus.$emit('Phonebook:Reloaded'); });
		} else if ((s.isAuthenticated && data && (data.personal == s.auth.employee || !data.personal)) || !data) {
			//console.log('PBX phonebook created single event');
			s.http.get(`/pbx/phonebook/${id}`)
				.then(res => {
					this.makeSearchAndStuff(res.data);
					//console.log('PBX phonebook created single added to list');
					this.contacts.push(res.data);
					this.contacts.sort(u.dynamicSort('name'));
					EventBus.$emit('Phonebook:Created', res.data._id);
				})
				.catch(() => {}); // probably 404 not found or belonging to another employee and we have no access to it
		}
	}

	updatedEvent(d) {
		const { event, id, data } = d;
		if (data && data.multiple && s.isAuthenticated && data.personal == s.auth.employee) {
			//console.log('PBX phonebook updated multiple event');
			this.loadContacts().then(() => { EventBus.$emit('Phonebook:Reloaded'); });
		} else if ((data && s.isAuthenticated && (data.personal == s.auth.employee || !data.personal)) || !data) {
			//console.log('PBX phonebook updated single event');
			s.http.get(`/pbx/phonebook/${id}`)
				.then(res => {
					this.makeSearchAndStuff(res.data);
					const index = this.contacts.findIndex(o => o._id == id);
					if (index != -1) {
						//console.log('PBX phonebook updated single updated in list');
						Vue.set(this.contacts, index, res.data);
						EventBus.$emit('Phonebook:Updated', res.data._id);
					} else {
						//console.log('PBX phonebook updated single added to list');
						this.contacts.push(res.data);
						this.contacts.sort(u.dynamicSort('name'));
						EventBus.$emit('Phonebook:Created', res.data._id);
					}
				})
				.catch(() => {}); // probably 404 not found or belonging to another employee and we have no access to it
		}
	}

	deletedEvent(d) {
		//console.log('PBX phonebook deleted event');
		const { event, id, data } = d;
		const index = this.contacts.findIndex(o => o._id == id);
		if (index != -1) {
			//console.log('PBX phonebook deleted event success');
			this.contacts.splice(index, 1);
			EventBus.$emit('Phonebook:Deleted', id);
		}
	}

	deleteAllEvent(d) {
		//console.log('PBX phonebook delete all event');
		const { event, id, data } = d;
		if (data) {
			if (data.deleted == 'PERSONAL' && s.isAuthenticated && data.for == s.auth.employee) {
				this.contacts = this.contacts.filter(o => o.employee != s.auth.employee);
			} else if (data.deleted == 'SHARED') {
				this.contacts = this.contacts.filter(o => o.employee != null);
			}
			EventBus.$emit('Phonebook:Reloaded');

		}
	}

	create(contact) {
		return s.http.post('/pbx/phonebook', contact);
	}

	update(contact) {
		return s.http.post('/pbx/phonebook/' + contact._id, contact);
	}

	remove(id) {
		return s.http.delete('/pbx/phonebook/' + id);
	}

	search(filter, type) {
		let res;
		if (filter) {
			filter = filter.toLowerCase().split(' ').map(o => o.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).filter(o => o).map(o => new RegExp(o));
			res = this.contacts.filter(o => {
				return filter.every(f => o.search.match(f) != null);
			});
		} else {
			res = this.contacts;
		}
		if (type == 'PERSONAL') {
			res = res.filter(o => o.employee);
		} else if (type == 'SHARED') {
			res = res.filter(o => !o.employee);
		}
		return res.sort(u.dynamicSort('name'));
	}

}

// Singleton
export default new Phonebook();
