<template>
	<div class="recording-list-item" @click="playRecording(item)" :class="{'odd':index % 2 === 0}" @contextmenu="showContextMenu">
		<div>
			<glyph-icon icon="664-equalizer-bars" color="var(--txt-color-primary)" size="32" style="margin-top:5px" />
		</div>
		<div v-if="direction=='IN'" style="display:flex;justify-content:center;flex-direction:column">
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="item.caller.privacy">{{ $t('recordings.hiddenNumber') }}</div>
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="!item.caller.privacy">{{ item.caller.name ? item.caller.name : item.caller.number | cutNationalPrefixAndFormat }}</div>
			<div style="font-size: var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-5px" v-if="!item.caller.privacy&&item.caller.name">{{ item.caller.number | cutNationalPrefixAndFormat }}</div>
		</div>
		<div v-if="direction=='OUT'" style="display:flex;justify-content:center;flex-direction:column">
			<div style="font-weight:400;font-size:var(--font-size-primary)">{{ item.callee.name ? item.callee.name : item.callee.number | cutNationalPrefixAndFormat }}</div>
			<div style="font-size: var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-5px" v-if="item.callee.name">{{ item.callee.number | cutNationalPrefixAndFormat }}</div>
		</div>
		<div style="display:flex;justify-content:center;flex-direction:column;align-items:flex-end">
			<div style="font-size:var(--font-size-secondary)">{{ item.date | formatDate('w d. MMM yyyy') }} {{ $t('common.at') }} {{ item.date | formatDate('HH:mm') }}</div>
			<div style="font-size: var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-3px;display:flex">
				<glyph-icon icon="17-bin" size="14" color="var(--label-otherparty-color)" style="margin-top:-1px;margin-right:2px" />
				<div>{{ expires }}</div>
				<div style="padding-left:5px;padding-right:5px">•</div>
				<div>{{ item.length | shortduration(false) }} {{ item.length | shortduration(true) }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import EventBus from '../../data/EventBus';
import u from '../../utils/utils';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		GlyphIcon
	},
	props: {
		item: { type: Object, required: true },
		index: { type: Number},
		click: { type: Function },
		me: { type: Object },
		contextMenu: { type: Function },
		playRecording: { type: Function }
	},
	data() {
		return {
			$t: i18n.t
		};
	},
	methods: {
		call() {
			const other = this.direction == 'IN' ? this.item.caller : this.item.callee;
			const target = s.cutNationalPrefix(other.number);
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(target);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		showContextMenu(e) {
			this.contextMenu(e, this.item);
		}
	},
	computed: {
		direction: function() {
			if (this.item.caller.employee == this.me._id) { return 'OUT'; }
			return 'IN';
		},
		expires: function() {
			const days = u.dateDiff(new Date(), this.item.expires, 'DAYS');
			if (days < 1) {
				const hours = u.dateDiff(new Date(), this.item.expires, 'HOURS');
				if (hours < 1) {
					const minutes = u.dateDiff(new Date(), this.item.expires, 'MINUTES');
					return `${minutes} min.`;
				} else { return `${hours} time${hours>1?'r':''}`; }
			} else { return `${days} dag${days>1?'e':''}`; }
		}
	}
};
</script>

<style scoped>
	.recording-list-item {
		display: grid;
		grid-template-columns: 45px 1fr auto;
		text-align: left;
		border-bottom: var(--divider-line);
		color: var(--txt-color-primary);
		padding-left: 10px;
		padding-right: 10px;
		cursor: pointer;
	}
	.recording-list-item.odd {
		background-color: var(--bg-color-3);
	}
	/* .recording-list-item:hover {
		background-color: var(--pn-primary-4);
		cursor: context-menu;
	} */
</style>