import Vue from 'vue';
import i18n from '../utils/i18n';

Vue.filter('formatDate', function(value, format) {
	if (value) {
		if (!format) { format = 'dd-MM-yyyy hh:mm'; }
		return formatDate(value, format);
	}
});

/**
 * Returns a date as a formatted string
 * @param {Date} date The date to represent
 * @param {String} format Formatting, accepting: yyyy, MM, M, dd, d, hh, h, mm, m, ss, s
 * @returns {string} The formatted date
 */
function formatDate(date, format) {
	let parts = [], buffer = '';
	for (let i=0; i<format.length; i++) {
		buffer += format[i];
		if (format[i] != format[i+1]) {
			parts.push(buffer);
			buffer = '';
		}
	}
	return parts.map(p => {
		if (p == 'yyyy') { return date.getFullYear().toString(); }
		else if (p == 'MMM') { return i18n.t(`common.month-long.${date.getMonth()}`); }
		else if (p ==  'MM') { return (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1); }
		else if (p ==   'M') { return (date.getMonth() + 1).toString(); }
		else if (p ==  'dd') { return (date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate()); }
		else if (p ==   'd') { return date.getDate().toString(); }
		else if (p ==  'hh') { return (date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()); }
		else if (p ==  'HH') { return (date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()); }
		else if (p ==   'h') { return date.getHours().toString(); }
		else if (p ==  'mm') { return (date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()); }
		else if (p ==   'm') { return date.getMinutes().toString(); }
		else if (p ==  'ss') { return (date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds()); }
		else if (p ==   's') { return date.getSeconds().toString(); }
		else if (p ==   'w') { return i18n.t(`common.day-long.${date.getDay()}`); }
		else if (p ==   'v') { return i18n.t(`common.day-short.${date.getDay()}`); }
		else { return p; }
	}).join('');
};
