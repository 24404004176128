/*
 * Realtime - socket.io connection for realtime notification of server events
 *
 * - Listens to events from the server and announces them to the subscribers
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2021-03-23
 */

import SocketIO from 'socket.io-client';
import s from '../settings';
import EventBus from './EventBus';
import axios from 'axios';
import browserdetect from '../utils/browserdetect';
import logger from './logger';

class Realtime {
	constructor() {
		logger.debug('Realtime: Constructor');
		this.socket = null;
		this.connected = false;
		this.firstConnectCompleted = false;
		this.run = false;
		this.logStreamRecipients = [];

		if (s.isAuthenticated) {
			this.start();
		}

		EventBus.$on('Auth:LoggedIn', () => {
			this.stop();
			this.start();
		});

		EventBus.$on('Auth:LoggedOut', originator => {
			if (originator != 'REALTIME') {
				this.stop();
			}
		});

		// Failsafe feature - if realtime did not reconnect after wakeup via Auth:LoggedIn, this will try again after 5 seconds
		EventBus.$on('Wakeup', () => {
			setTimeout(() => {
				if (this.run && !this.connected) {
					logger.warning('Realtime: Performing emergency restart of realtime after wakeup event');
					this.stop();
					this.start();
				}
			}, 5000);
		});

		this.statusReport = {};
		EventBus.$on('StatusReport', data => {
			this.statusReport[data.key] = data.value;
		});

		logger.registerGlobal('realtime', this);
	}

	async start() {
		if (!s.isAuthenticated || !s.token) { return; }
		logger.info('Realtime: Starting');
		this.run = true;

		let ip = null, version = '';
		try {
			ip = await axios.get('https://api.telecomx.dk/tools/myip');
			ip = ip.data.ip;
		}
		catch {}

		if (s.isEmbedded) {
			try {
				version = await axios.get(`${s.localUrl}/ping`, { timeout: 10000 });
				version = version.data.version;
				s.wrapperVersion = version;
			}
			catch {}
		}

		this.socket = SocketIO(s.apiUrl.replace('https:','wss:').replace('http:','ws:'), { transports: ['websocket'] });

		this.socket.on('connect', async () => {
			const engine = this.socket.io.engine;
			logger.debug(`Realtime: Connected using ${engine.transport.name}`);

			engine.once('upgrade', () => {
				// called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
				logger.debug(`Realtime: Connection upgraded to ${engine.transport.name}`);
			});

			if (s.isEmbedded) {
				this.socket.emit('authenticate', { token: s.token, client: `Communicator Desktop ${s.version}-${version} on ${browserdetect.platform}`, instanceId: s.instanceId, ip: ip });
			} else {
				this.socket.emit('authenticate', { token: s.token, client: `Communicator Desktop ${s.version} ${browserdetect.browser} on ${browserdetect.platform}`, instanceId: s.instanceId, ip: ip });
			}
		});

		this.socket.on('authenticated', () => {
			this.connected = true;
			this.firstConnectCompleted = true;
			EventBus.$emit('Realtime:Connected');
			logger.debug(`Realtime: Authenticated - subscribing to PBX and CONFIG-NOT-IPTV events for customer ${s.auth.customer}`);
			this.socket.emit('subscribe', { type: 'PBX', customer: s.auth.customer });
			this.socket.emit('subscribe', { type: 'CONFIG-NOT-IPTV', customer: s.auth.customer });
		});

		this.socket.on('subscribed', data => {
			logger.debug(`Realtime: Subscribed to: ${data.type} for ${data.customer}`);
		 });

		this.socket.on('disconnect', () => {
			this.connected = false;
			EventBus.$emit('Realtime:Disconnected');
			//EventBus.$emit('CommonErrorModal', { header: 'Realtime', message: 'Realtime forbindelsen til serveren blev afbrudt.' });
			logger.warning('Realtime: Disconnected');
		});

		this.socket.on('reconnect_attempt', () => {
			//EventBus.$emit('CommonErrorModal', { header: 'Realtime', message: 'Realtime forbindelsen til serveren forsøges genetableret.' });
			logger.debug('Realtime: Attempting to re-connect');
		});

		this.socket.on('forbidden', err => {
			s.auth = null;
			s.token = null;
			EventBus.$emit('Auth:LoggedOut', 'REALTIME');
			logger.error('Realtime: Forbidden: ' + (err ? err.message : ''));
			this.stop();
		});

		this.socket.on('CONFIG-NOT-IPTV', this.configEvent.bind(this));
		this.socket.on('PBX', this.pbxEvent.bind(this));
		this.socket.on('REQUEST', this.requestHandler.bind(this));
		this.socket.on('REPLY', this.replyHandler.bind(this));
	}

	stop() {
		if (!this.run) { return; }
		logger.info('Realtime: Stopping');
		this.run = false;
		if (this.socket) {
			this.socket.close();
		}
		if (this.connected) {
			this.connected = false;
			EventBus.$emit('Realtime:Disconnected');
		}
		this.socket = null;
	}

	configEvent(ev) {
		//logger.debug(`Realtime: Got config event: ${ev.event} - ${ev.id}`); // eslint-disable-line
		// if (ev.data) { console.dir(ev.data); }
		EventBus.$emit('Realtime:Config:' + ev.event, ev);
	}

	pbxEvent(ev) {
		//logger.debug(`Realtime: Got pbx event: ${ev.event} - ${JSON.stringify(ev)}`);  // eslint-disable-line
		// if (ev.data) { console.dir(ev.data); }
		if (!ev.eventId && ev._id) { ev.eventId = ev._id; }
		EventBus.$emit('Realtime:Pbx:' + ev.event, ev);
	}

	async requestHandler(req) {
		// { request, requestId, data, replyTo: { employee, instanceId } }
		logger.info(`Realtime: Got request for ${req.request} with id ${req.requestId}`);
		switch(req.request) {
			case 'CommunicatorLoggerGetHistory':
				if (req.replyTo) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true, log: logger.historyBuffer });
				}
				break;
			case 'CommunicatorLoggerClearHistory':
				logger.historyBuffer = [];
				if (req.replyTo) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}
				break;
			case 'CommunicatorLoggerStartStream':
				if (req.replyTo) {
					if (!logger.streamCallback) { logger.streamCallback = this.streamLogEvent.bind(this); }
					const exists = this.logStreamRecipients.find(o => o.employee === req.replyTo.employee && o.instanceId === req.replyTo.instanceId);
					if (!exists) {
						this.logStreamRecipients.push({ employee: req.replyTo.employee, instanceId: req.replyTo.instanceId, request: req.request, requestId: req.requestId });
					} else {
						exists.requestId = req.requestId; // In case he lost connection and connected again, we need to have  the right requestId
					}
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}
				break;
			case 'CommunicatorLoggerStopStream':
				if (req.replyTo) {
					const exists = this.logStreamRecipients.findIndex(o => o.employee === req.replyTo.employee && o.instanceId === req.replyTo.instanceId);
					if (exists !== -1) {
						this.logStreamRecipients.splice(exists, 1);
					}
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}
				break;
			case 'CommunicatorReload':
				if (req.replyTo) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}
				setTimeout(() => { location.reload(); }, 500);
				break;
			case 'CommunicatorTerminate':
				if (req.replyTo) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}

				setTimeout(() => {
					if (s.isEmbedded) {
						try { axios.get(`${s.localUrl}/terminate`, { timeout: 5000 }); }
						catch(_) {}
					} else {
						window.location.href = 'https://blank.page';
					}
				}, 500);
				break;
			case 'CommunicatorDevtoolsShow':
				if (s.isEmbedded) {
					try {
						const res = await axios.get(`${s.localUrl}/devtoolsshow`, { timeout: 5000 });
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: res.data.success });
					}
					catch(_) { /** */ }
				} else {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'NOT_IN_APP', message: 'Running in browser' });
				}
				break;
			case 'CommunicatorDevtoolsHide':
				if (s.isEmbedded) {
					try {
						const res = await axios.get(`${s.localUrl}/devtoolshide`, { timeout: 5000 });
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: res.data.success });
					}
					catch(_) { /** */ }
				} else {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'NOT_IN_APP', message: 'Running in browser' });
				}
				break;
			case 'CommunicatorImpersonate':
				if (req.replyTo) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: true });
				}
				setTimeout(() => {
					s.logout(true);
				}, 500);
				setTimeout(() => {
					s.loadAuth(req.data.token).catch(() => {});
				}, 1000);
				break;
			case 'CommunicatorGetStatus':
				if (req.replyTo) {
					try {
						const data = await this.collectStatus();
						data.success = true;
						data.realtime = this.connected;
						data.authenticated = s.isAuthenticated;
						data.appMode = s.isEmbedded;
						data.version = s.version;
						if (s.isEmbedded) {
							data.version = `${s.appName} ${s.version}-${s.wrapperVersion} on ${browserdetect.platform}`;
						} else {
							data.version = `${s.appName} ${s.version} ${browserdetect.browser} on ${browserdetect.platform}`;
						}
						data.skin = s.skin;

						logger.info('Realtime: Sent reply for CommunicatorGetStatus');
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, data);
					}
					catch(err) { logger.error(err.message); }
				}
				break;
			case 'CommunicatorZeroTierStatus': { // Is it available and if so list of networks joined
				// Returns { success, data, code, message } (code/message if error)
				if (!req.replyTo || req.replyTo.employee !== s.auth.employee) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'ACCESS_DENIED', message: 'Only permitted by user self' });
				}
				if (s.isEmbedded) {
					try {
						const res = await axios.get(`${s.localUrl}/zerotier?method=GET&url=/status`, { timeout: 5000 });
						const res2 = await axios.get(`${s.localUrl}/zerotier?method=GET&url=/network`, { timeout: 5000 });
						if (res2.data.success) {
							res.data.networks = res2.data.data.map(o => { return { id: o.id, status: o.status }; });
						} else {
							res.data.networks = [];
						}
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, res.data);
					}
					catch(err) { logger.error(err.message); }
				} else {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'NOT_IN_APP', message: 'Running in browser' });
				}
				break;
			}
			case 'CommunicatorZeroTierJoin': { // Join a network
				// Returns { success, data, code, message } (code/message if error)
				if (!req.replyTo || req.replyTo.employee !== s.auth.employee) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'ACCESS_DENIED', message: 'Only permitted by user self' });
				}
				if (s.isEmbedded) {
					try {
						const res = await axios.get(`${s.localUrl}/zerotier?method=POST&url=/network/${req.data.network}`, { timeout: 5000 });
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, res.data);
					}
					catch(err) { logger.error(err.message); }
				} else {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'NOT_IN_APP', message: 'Running in browser' });
				}
				break;
			}
			case 'CommunicatorZeroTierLeave': { // Leave a network
				// Returns { success, data, code, message } (code/message if error)
				if (!req.replyTo || req.replyTo.employee !== s.auth.employee) {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'ACCESS_DENIED', message: 'Only permitted by user self' });
				}
				if (s.isEmbedded) {
					try {
						const res = await axios.get(`${s.localUrl}/zerotier?method=DELETE&url=/network/${req.data.network}`, { timeout: 5000 });
						this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, res.data);
					}
					catch(err) { logger.error(err.message); }
				} else {
					this.sendReply(req.replyTo.employee, req.replyTo.instanceId, req.request, req.requestId, { success: false, code: 'NOT_IN_APP', message: 'Running in browser' });
				}
				break;
			}

		
		}
	}

	async collectStatus() {
		this.statusReport = {};
		EventBus.$emit('StatusRequest');
		await s.sleep(500);
		return this.statusReport;
	}

	// Here for future use - when we implement chat or similar
	replyHandler(ev) { // eslint-disable-line
		// Currently we do not send out requests from here - so we will not get replies to handle - yet :-)
	}

	async streamLogEvent(severity, message) {
		const failed = [];
		for (let recipient of this.logStreamRecipients) {
			const success = await this.sendReply(recipient.employee, recipient.instanceId, recipient.request, recipient.requestId, { severity: severity, message: message }, true);
			if (!success) {
				failed.push(recipient);
				logger.warning(`Realtime: Failed to stream log to ${recipient.employee} / ${recipient.instanceId} - now removed from recipent list`);
			}
		}
		failed.forEach(fail => {
			const index = this.logStreamRecipients.findIndex(o === fail);
			if (index != -1) { this.logStreamRecipients.splice(index, 1); }
		});
	}

	generateId() {
		return Math.random().toString().substring(2) + Math.random().toString().substring(2);
	}

	sendRequest(employee, instanceId, request, data, replyToEmployee, replyToInstanceId) {
		return new Promise(resolve => {
			const message = {
				to: {
					employee: employee,
					instanceId: instanceId,
				},
				request: request,
				data: data
			};
			if (replyToEmployee || replyToInstanceId) {
				message.replyTo = { employee: replyToEmployee, instanceId: replyToInstanceId };
			}
			this.socket.emit('request', message, response => {
				if (!response.success) {
					logger.warning(`Realtime: Failed to send request ${request} - error: ${response.error}`);
					resolve(false);
				} else {
					logger.info(`Realtime: Sent request ${request} with id ${response.requestId} to ${employee} / ${instanceId}`);
					resolve(response.requestId);
				}
			});
		});
	}

	sendReply(employee, instanceId, request, requestId, data, noLog) {
		return new Promise(resolve => {
			this.socket.emit('reply', { to: { employee: employee, instanceId: instanceId }, request: request, requestId: requestId, data: data, from: { employee: s.auth.employee, instanceId: s.instanceId } }, response => {
				if (!response.success) {
					if (noLog != true) { // avoids self repeating loop
						logger.warning(`Realtime: Failed to send reply to request ${request} with id ${requestId} - error: ${response.error}`);
					}
					resolve(false);
				} else {
					if (noLog != true) { // avoids self repeating loop
						logger.info(`Realtime: Sent reply to request ${request} with id ${requestId} to ${employee} / ${instanceId}`);
					}
					resolve(true);
				}
			});
		});
	}

}

export default new Realtime();
