<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			{{ $t('queue.addExtensionToQueueHeader') }} {{ queue.name }}
		</template>

		<div style="display:grid;grid-template-columns: auto 30px 30px;grid-gap:2px">
			<b-form-input type="search" v-model="filter" :placeholder="$t('common.filter')" :autofocus="true" size="sm" @update="buildListOfAvailableExtensions" />
			<b-button variant="primary" :disabled="isSaving" size="sm" @click="selectAll()" tabindex="105" style="box-shadow:none">+</b-button>
			<b-button variant="primary" :disabled="isSaving" size="sm" @click="clearAll()" tabindex="106" style="box-shadow:none">-</b-button>
		</div>

		<b-list-group style="height:380px;overflow:scroll">
			<b-list-group-item @click="toggle(item)" href="#" :style="{'background':selected.includes(item._id)?'var(--bg-color-selected)':'var(--bg-color-secondary)'}" class="d-flex justify-content-between align-items-center" v-for="item in extensionsNotInQueue" :key="item._id">
				<div class="title">{{ item.name }}</div>
				<b-badge variant="primary" pill>{{ item.number }}</b-badge>
			</b-list-group-item>
			<b-list-group-item disabled v-if="extensionsNotInQueue.length==0" style="text-align:center;background:var(--bg-color-primary);margin:10px;">{{ $t('common.noneAvailable') }}</b-list-group-item>
		</b-list-group>

		<div style="height:10px"></div>

		<b-form-group :label="$t('queue.priority')" style="width:25%">
			<b-form-select v-model="priority" :options="priorities" size="sm"></b-form-select>
		</b-form-group>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" :disabled="isSaving" size="sm" @click="close()" tabindex="110">{{ $t('common.cancel') }}</b-button>
			<b-button variant="primary" style="height:30px" :disabled="selected.length==0||isSaving" size="sm" type="submit" @click="save()" tabindex="120">{{ $t('common.add') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import s from '../../settings';
import u from '../../utils/utils';
import employees from '../../data/employees';
import i18n from '../../utils/i18n';

export default {
	props: {
		queue: Object
	},
	data() {
		return {
			$t: i18n.t,
			modalId: 'QueueAddExtensionModal' + Math.random().toString(),
			priority: 1,
			selected: [],
			isSaving: false,
			employees: [],
			extensionsNotInQueue: [],
			filter: '',
			priorities: []
		};
	},
	computed: {
		numberIsValid: function() {
			return u.isPhoneNumber(this.number, false);
		}
	},
	methods: {
		buildListOfAvailableExtensions() {
			if (!this.queue || !this.employees) { return []; }
			this.extensionsNotInQueue = [];
			const members = this.queue.members.map(o => o.extension).filter(o=>o);

			this.employees.forEach(employee => {
				employee.extensions.forEach(extension => {
					if (extension.primary) {
						if (!members.includes(extension._id)) {
							if (this.filter) {
								if (employee.name.toLowerCase().includes(this.filter.toLowerCase()) ||
									(employee.department && employee.department.toLowerCase().includes(this.filter.toLowerCase())) ||
									(employee.title && employee.title.toLowerCase().includes(this.filter.toLowerCase())) ||
									(employee.emailAddress && employee.emailAddress.toLowerCase().includes(this.filter.toLowerCase())) ||
									extension.number && extension.number.includes(this.filter) ||
									extension.direct && extension.direct.includes(this.filter) ||
									(Array.isArray(extension.mobile) && extension.mobile.join(' ').includes(this.filter))
								) {
									this.extensionsNotInQueue.push({ name: employee.name, number: extension.number, _id: extension._id });
								}
							} else {
								this.extensionsNotInQueue.push({ name: employee.name, number: extension.number, _id: extension._id });
							}
						}
					}
				});
			});
			this.extensionsNotInQueue.sort(u.dynamicSort('name'));
		},
		show() {
			this.selected = [];
			this.priority = 1;
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
			if (this.selected.length == 0) { return; }
			this.isSaving = true;
			this.saveMember();
		},
		saveMember() {
			const id = this.selected.shift();
			s.http.post(`/pbx/queue/${this.queue._id}/member`, { extension: id, priority: this.priority })
				.then(() => {
					if (this.selected.length == 0) {
						this.isSaving = false;
						this.close();
					} else {
						this.saveMember();
					}
				})
				.catch(err => {
					this.isSaving = false;
					const item = this.extensionsNotInQueue.find(o => o._id == id);
					const name = item ? item.name : 'Ukendt';
					switch (err.status) {
						case 403:
							if (err.code == 'dynamic') { this.showError(i18n.t('queue.queueDoesNotAllowDynamicMembers')); }
							else if (err.code == 'extension') { this.showError(i18n.t('queue.memberMayNotJoinQueue').replace('%%name%%', name)); }
							break;
						case 404:
							this.showError(i18n.t('queue.memberToJoinNotFound').replace('%%name%%', name));
							break;
						case 409:
							if (err.code == 'member') { this.close(); }
							break;
						default:
							this.closeAndShowError(`${i18n.t('common.genericErrorHeader')} - code ${err.status}: ${err.message}`);
							break;
					}
				});
		},
		showError(message, header) {
			EventBus.$emit('CommonErrorModal', { header: header || i18n.t('queue.addMemberToQueueErrorHeader'), message: message });
		},
		closeAndShowError(message, header) {
			this.close();
			this.showError(message, header);
		},
		toggle(item) {
			const index = this.selected.indexOf(item._id);
			if (index != -1) {
				this.selected.splice(index, 1);
			} else {
				this.selected.push(item._id);
			}
		},
		selectAll() {
			this.selected = [];
			this.extensionsNotInQueue.forEach(emp => {
				this.selected.push(emp._id);
			});
		},
		clearAll() {
			this.selected = [];
		},

	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	},
	async created() {
		for (let i=1; i<=20; i++) {
			this.priorities.push({ value: i, text: `${i}` });
		}
		this.employees = await employees.getAll();
		this.buildListOfAvailableExtensions();
	}
};
</script>

<style scoped>
	.title {
		font-size: var(--font-size-primary);
		color: var(--txt-color-primary);
	}
</style>
