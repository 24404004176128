<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex">
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ $t('sms.header') }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ $t('sms.toGroup') }}</div>
				</div>
			</div>
		</template>

		<div style="margin-left:10px;margin-right:10px">
			<b-form-group :label="$t('sms.group')">
				<b-form-select size="sm" v-model="group" :options="groups"></b-form-select>
			</b-form-group>

			<b-form-group :label="$t('sms.message')">
				<b-form-textarea ref="SmsMessage" class="sms-message" v-model="message" tabindex="20" size="sm" maxlength="400" :autofocus="true" rows="3" no-resize @keydown="keyDownHandler"></b-form-textarea>
			</b-form-group>

		</div>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" size="sm" @click="close()" tabindex="100">{{ $t('common.cancel') }}</b-button>
		<b-button variant="primary" style="height:30px" :disabled="!message||isSaving" size="sm" type="submit" @click="save()" tabindex="110">{{ $t('sms.send') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import s from '../../settings';
import i18n from '../../utils/i18n';

export default {
	data() {
		return {
			$t: i18n.t,
			modalId: 'SendGroupSmsModal' + Math.random().toString(),
			message: '',
			group: null,
			groups: [],
			isSaving: false
		};
	},
	methods: {
		keyDownHandler(e) {
			if (e.key == 'Enter' && (e.shiftKey || e.ctrlKey || e.altKey)) {
				this.save();
			}
		},
		async show() {
			s.http.get('/pbx/group?limit=1000&sort=name')
				.then(res => {
					res.data.groups = res.data.groups.filter(group => group.sms === true);
					if (res.data.groups.length === 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('sms.errorHeader'), message: i18n.t('sms.noGroups') });
						return;
					}
					this.groups = [];
					res.data.groups.forEach(group => {
						this.groups.push({ value: group._id, text: group.name + ' (' + group.members + ')' });
					});
					this.group = this.groups.length > 0 ? this.groups[0].value : null;
					this.isSaving = false;
					this.$bvModal.show(this.modalId);
				})
				.catch(err => {
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					}
				});
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
			if (!this.message || !this.group) { return; }
			this.isSaving = true;
			s.http.post('/sms', { type: 'SMS', message: this.message, group: this.group })
				.then(() => { this.close(); })
				.catch(err => {
					this.isSaving = false;
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					} else {
						if (err.status >= 400) {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('sms.errorHeader'), message: i18n.t('sms.errorUnknown') + ' ' + err.status });
						} else {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('sms.errorHeader'), message: i18n.t('sms.errorUnknown') + ` ${err.status} ${err.path}\n${err.message}` });
						}
					}
				});
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.sms-message {
		height: auto !important;
	}
</style>
