<template>
	<form enctype="multipart/form-data" novalidate>
		<input type="file" :id="hiddenid" :disabled="isUploading" @change="filesChange($event.target.files)" :accept="fileType" :style="{'width':width,'height':height}">
		<slot></slot>
	</form>
</template>
<script>
import s from '../settings';

export default {
	props: {
		targetUrl: { type: String, required: true },
		property: { type: String, required: true },
		fileType: { type: String, required: false, defualt: '*/*' },
		onEvent: { type: Function, required: false },
	},
	data() {
		return {
			isUploading: false,
			currentFile: '',
			width: '0px',
			height: '0px',
			hiddenid: Math.random()
		};
	},
	computed: {
	},
	methods: {
		upload(formData) {
			if (this.onEvent) { this.onEvent({ state: 'UPLOADING', file: this.currentFile }); }
			s.http.post(this.targetUrl, formData)
				.then(res => {
					if (this.onEvent) { this.onEvent({ state: 'COMPLETED', file: this.currentFile, response: res.data }); }
				})
				.catch(err => {
					if (this.onEvent) { this.onEvent({ state: 'FAILED', file: this.currentFile, response: err.data }); }
				});
		},
		filesChange(fileList) {
			const formData = new FormData();
			if (!fileList.length) return;
			formData.append(this.property, fileList[0], fileList[0].name);
			this.currentFile = fileList[0].name;
			this.upload(formData);
		},
		dragOver(e) {
			//console.log('Drag over');
		},
		dragLeave(e) {
			//console.log('Drag leave');
		},
		showPicker() {
			document.getElementById(this.hiddenid).click();
		}
	},
	mounted (){
		this.width = this.$slots['default'][0].elm.clientWidth + 'px';
		this.height = this.$slots['default'][0].elm.clientHeight + 'px';
	}
};
</script>
<style scoped>
	INPUT {
		position: absolute;
		z-index: 2;
		opacity: 0;
	}
</style>