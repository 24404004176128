<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			{{ $t('queue.addExternalToQueueHeader')}} {{ queue.name }}
		</template>

		<b-row>
			<b-col>
				<b-form-group :label="$t('queue.externalPhoneNumber')">
					<b-form-input type="text" :state="numberIsValid" v-model="number" size="sm" maxlength="20" :autofocus="true" tabindex="100" @keydown="keyDownHandler" />
				</b-form-group>
			</b-col>

			<b-col cols="3">
				<b-form-group :label="$t('queue.priority')">
					<b-form-select v-model="priority" :options="priorities" size="sm"></b-form-select>
				</b-form-group>
			</b-col>

		</b-row>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" :disabled="isSaving" size="sm" @click="close()" tabindex="110">{{ $t('common.cancel') }}</b-button>
			<b-button variant="primary" style="height:30px" :disabled="!numberIsValid||isSaving" size="sm" type="submit" @click="save()" tabindex="120">{{ $t('common.add') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import s from '../../settings';
import u from '../../utils/utils';
import i18n from '../../utils/i18n';
// import employees from '../../data/employees';

export default {
	props: {
		queue: Object
	},
	data() {
		return {
			$t: i18n.t,
			modalId: 'QueueAddExternalModal' + Math.random().toString(),
			number: '',
			priority: 1,
			isSaving: false,
			priorities: []
		};
	},
	computed: {
		numberIsValid: function() {
			return u.isPhoneNumber(this.number, false);
		}
	},
	methods: {
		keyDownHandler(e) {
			if (e.key == 'Enter') {
				this.save();
			}
		},
		show() {
			this.number = '';
			this.priority = 1;
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
			if (!this.numberIsValid) { return; }
			let number = this.number;
			if (number.startsWith('00')) { number = '+' + number.substr(2); }
			if (!number.startsWith('+')) { number = s.pbxSettings.nationalPrefix + number; }
			this.isSaving = true;
			s.http.post(`/pbx/queue/${this.queue._id}/member`, { number: number, priority: this.priority })
				.then(() => { this.isSaving = false; this.close(); })
				.catch(err => {
					this.isSaving = false;
					if (err.status == 0) {
						this.closeAndShowError(i18n.t('common.error.commErrorMessage'), i18n.t('common.error.commErrorHeader'));
					} else {
						if (err.status == 409 && err.code == 'member') { this.close(); } // Already a member
						else if (err.status == 403 && err.code == 'dynamic') { this.closeAndShowError(i18n.t('queue.queueDoesNotAllowDynamicMembers')); }
						else if (err.status == 409 && err.code == 'extension') { this.closeAndShowError(i18n.t('queue.queueOnlyAcceptExtensions')); }
						else {
							this.closeAndShowError(`${i18n.t('common.genericErrorHeader')}: ${err.status} ${err.path}\n${err.message}`);
						}
					}
				});
		},
		showError(message, header) {
			EventBus.$emit('CommonErrorModal', { header: header || i18n.t('queue.addMemberToQueueErrorHeader'), message: message });
		},
		closeAndShowError(message, header) {
			this.close();
			this.showError(message, header);
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	},
	created() {
		for (let i=1; i<=20; i++) {
			this.priorities.push({ value: i, text: `${i}` });
		}
	}
};
</script>

<style scoped>
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
</style>
