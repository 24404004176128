import Vue from 'vue';
import EventBus from '../data/EventBus';
import s from '../settings';
import i18n from '../utils/i18n';

Vue.filter('cutNationalPrefix', function(value) {
	if (!value || typeof value !== 'string') return '';
	if (value.startsWith(s.pbxSettings.nationalPrefix)) {
		value = value.replace(s.pbxSettings.nationalPrefix, '');
	}
	return value;
});

Vue.filter('cutNationalPrefixAndFormat', function(value) {
	if (!value || typeof value !== 'string') return '';
	if (!value.match(/^\+?\d+$/) && value !== 'SECRET') { return value; }
	if (value.startsWith(s.pbxSettings.nationalPrefix)) {
		value = value.replace(s.pbxSettings.nationalPrefix, '');
	}
	if (value === 'SECRET') { value = i18n.t('common.secret'); }
	else if (value.length == 8) { value = value.match(/\d\d/g).join(' '); }
	return value;
});
