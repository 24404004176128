<template>
	<div>
		<action-bar></action-bar>
		<div class="column-box" :style="{'margin-bottom': offset+'px'}">
			<column v-for="index in dashboards.getColumnIndexes()" :key="index" :index="index"></column>
			<column v-if="dashboards.editMode&&dashboards.getItemsInColumn(0)>0" :index="dashboards.getColumnCount()"></column>
		</div>
	</div>
</template>

<script>
import ActionBar from './ActionBar.vue';
import s from '../../settings';
import EventBus from '../../data/EventBus';
import Column from './Column.vue';
import dashboards from '../../data/dashboards';
import i18n from '../../utils/i18n';

export default {
	components: {
		ActionBar,
		Column
	},
	data() {
		return {
			dashboards: dashboards,
			offset: 0
		};
	},
	created() {
		EventBus.$on('Dashboard:Create', () => { // invoked from menu to create a new dashboard
			if (!dashboards.mayEdit) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('layout.createDeniedHeader'), message: i18n.t('layout.createDeniedMessage') });
			} else {
				dashboards.create();
			}
		});

		EventBus.$on('Dashboard:Edit', () => { // invoked from menu to edit current dashboard
			if (!dashboards.mayEdit) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('layout.editDeniedHeader'), message: i18n.t('layout.editDeniedMessage') });
			} else {
				if (dashboards.dashboard) {
					dashboards.edit();
				}
			}
		});

		EventBus.$on('Dashboard:Wallboard', mode => {
			if (mode) {
				// Switch to wallboard rendering
				dashboards.mode = 'WALLBOARD';
			} else {
				// Switch to normal rendering
				dashboards.mode = 'NORMAL';
			}
			s.setWindowWallboardMode(mode);
		});

		EventBus.$on('Dashboard:Select', _id => { dashboards.select(_id); });
		EventBus.$on('Dashboard:Delete', _id => { dashboards.remove(_id); });
	},
	mounted() {
		setTimeout(() => {
			if (document.getElementById('status-panel') != null) {
				this.offset = 17;
			}
		}, 500);
	}
};
</script>

<style scoped>
	.column-box {
		display: flex;
		margin-top: 10px;
		margin-left: 5px;
		margin-right: 5px;
		text-align: left;
	}
</style>