<template>
	<div>
		<div class="recordings-container">
			<div class="recordings-listbox">
				<div class="dash-header">
					<glyph-icon icon="664-equalizer-bars" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:4px">{{ $t('recordings.header') }}</div>
					<div style="color:var(--txt-color-secondary-highlight);font-size:var(--font-size-small);margin-top:1px;">{{ $t('recordings.tip') }}</div>
				</div>

				<div style="background-color:var(--bg-color-secondary);padding: 5px 20px 0 20px;border-bottom:var(--divider-line)">
					<b-row>
						<b-col>
							<b-form-group :label="$t('recordings.from')" style="margin-bottom:4px !important">
								<b-form-datepicker v-model="from" :value-as-date="true" class="mb-2" :min="minDate" :max="maxDate" locale="da" size="sm" :placeholder="$t('recordings.from')" start-weekday="1" v-bind="labels" :hide-header="true" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-form-datepicker>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('recordings.to')" style="margin-bottom:0 !important">
								<b-form-datepicker v-model="to" :value-as-date="true" class="mb-2" :min="minDate" :max="maxDate" locale="da" size="sm" :placeholder="$t('recordings.to')" start-weekday="1" v-bind="labels" :hide-header="true" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-form-datepicker>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('common.filter')" style="margin-bottom:0 !important">
								<b-form-input type="search" v-model="filter" size="sm" debounce="500" />
							</b-form-group>
						</b-col>
						<b-col v-if="s.auth.accessLevel!='PERSONAL'">
							<b-form-group :label="$t('recordings.type')" style="margin-bottom:0 !important">
								<b-form-select v-model="type" :options="types" @change="loadList" size="sm" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div v-if="!isLoading&&list.length==0" style="text-align:center;margin:10px;background:var(--pn-primary-2);color:#fff;padding:4px;border-radius:10px;font-weight:300;font-size:16px">{{ $t('common.noneFound') }}</div>
				<div class="loading" :class="{'active':isLoading}">
					<div>
						<b-spinner variant="light" style="width:30px;height:30px" />
						<br/>{{ $t('recordings.loading') }}
					</div>
				</div>
				<div class="recordings-list">
					<RecycleScroller class="my-scroller" :items="list" :item-size="39" key-field="_id" v-slot="{ item, index }">
						<list-item :item="item" :click="select" :index="index" :me="me" :contextMenu="showContextMenu" :playRecording="playRecording" />
					</RecycleScroller>
				</div>
				<v-contextmenu ref="recordingsmenu" id="recordingsmenu">
					<v-contextmenu-item @click="call">{{ $t('recordings.action.call') }}</v-contextmenu-item>
					<v-contextmenu-item @click="filterOnThis">{{ $t('recordings.action.filter') }}</v-contextmenu-item>
					<v-contextmenu-item @click="playRecording()">{{ $t('recordings.action.play') }}</v-contextmenu-item>
					<v-contextmenu-item divider />
					<v-contextmenu-item @click="remove">{{ $t('common.delete') }}</v-contextmenu-item>
				</v-contextmenu>
			</div>

		</div>
	</div>
</template>

<script>
import ListItem from './ListItem.vue';
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		ListItem,
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			me: null,
			list: [],
			selected: null,
			filter: '',
			from: new Date(),
			to: new Date(),
			type: 'PRIVATE',
			types: [
				{ value: 'PRIVATE', text: i18n.t('recordings.personal') },
				{ value: 'COMMON', text: i18n.t('recordings.common') }
			],
			isLoading: false,
			loadAgain: false,
			minDate: null,
			maxDate: null,
			labels: i18n.messages[i18n.locale].datepicker
		};
	},
	methods: {
		showContextMenu(e, item) {
			this.selected = item;
			e.preventDefault();
			setTimeout(() => { this.$refs['recordingsmenu'].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		call() {
			const direction = this.selected.caller.employee == s.auth.employee ? 'OUT' : 'IN';
			const other = direction == 'IN' ? this.selected.caller : this.selected.callee;
			const target = other.extension ? other.extension : s.cutNationalPrefix(other.number);
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(target);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		filterOnThis() {
			const direction = this.selected.caller.extension == this.me.primaryExtension._id ? 'OUT' : 'IN';
			const other = direction == 'IN' ? this.selected.caller : this.selected.callee;
			this.filter = other.number;
		},
		playRecording(item) {
			if (item) { this.selected = item; }
			EventBus.$emit('AudioPlayerModal:Show', {
				header: `${this.$t('cdr.recordingHeader')} ${u.formatDate(this.selected.date,'d/M-yyyy hh:mm')}`,
				audioId: this.selected._id,
				length: this.selected.length,
				filename: `${this.$t('cdr.recordingHeader')} ${u.formatDate(this.selected.date,'dd-MM-yyyy hh.mm')} - ${s.cutNationalPrefix(item.caller.number)} - ${s.cutNationalPrefix(item.callee.number)}`
			});
		},
		remove() {
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('recordings.deleteQuestionHeader'), message: i18n.t('recordings.deleteQuestionMessage'), ok: i18n.t('common.delete'), emit: 'Recordings:Delete', emitData: this.selected._id});
		},
		removeDo(id) {
			s.http.delete(`/pbx/recording/${id}`)
				.then(() => {
					this.loadList();
				})
				.catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('recordings.deleteQuestionHeader'), message: i18n.t('recordings.deleteFailed') });
				});
		},
		async select(item) {
			this.selected = item;
		},
		async loadList() {
			if (this.isLoading) { this.loadAgain = true; return; }
			this.isLoading = true;
			let query = { offset: 0, limit: 2000 };

			if (this.filter) { query.filter = this.filter; }
			if (this.type == 'COMMON') { query.common = true; }

			query.from = new Date(this.from);
			query.to = new Date(this.to);
			if (query.to < query.from) { query.to = new Date(query.from); query.to.setHours(23, 59, 59, 999); }
			query.from.setHours(0, 0, 0, 0);
			query.to.setHours(23, 59, 59, 999);

			s.http.get('/pbx/recording', { params: query })
				.then(res => {
					this.isLoading = false;
					if (this.loadAgain) {
						this.loadAgain = false;
						this.loadList();
					}
					res.data.recordings.forEach(item => {
						['date','expires'].forEach(key => {
							if (item[key]) { item[key] = new Date(item[key]); }
						});
					});
					this.list = [];
					this.$nextTick(() => {
						this.list = res.data.recordings.reverse();
					});
				})
				.catch(err => {
					this.isLoading = false;
					this.loadAgain = false;
					EventBus.$emit('CommonErrorModal', { header: i18n.t('recordings.header'), message: i18n.t('recordings.loadError') + '<br><br>' + err.message });
				});
		},
		pbxEventHandler(ev) {
			if (this.type == 'COMMON' && !ev.employee) {
				this.loadList();
			} else if (this.type == 'PRIVATE' && ev.employee == this.me._id) {
				this.loadList();
			}
		}
	},
	created() {
		employees.getMe().then(me => {
			this.me = me;
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
			EventBus.$on('Recordings:Delete', this.removeDo.bind(this));
			// EventBus.$on('Realtime:Pbx:RECORDING_START', this.pbxEventHandler.bind(this)); // because the list will not changed before it has completed recording
			EventBus.$on('Realtime:Pbx:RECORDING_END', this.pbxEventHandler.bind(this));
			EventBus.$on('Realtime:Pbx:RECORDING_UPDATE', this.pbxEventHandler.bind(this));
			EventBus.$on('Realtime:Pbx:RECORDING_DELETED', this.pbxEventHandler.bind(this));
		}).catch(() => {});
	},
	mounted() {
	},
	watch: {
		'filter': function() { this.loadList(); },
		'from': function() { this.loadList(); },
		'to': function() { this.loadList(); },
		's.selectedSection': function(value) {
			if (value == 'RECORDINGS') {
				this.minDate = new Date();
				this.minDate.setDate(this.minDate.getDate() - 720);
				this.minDate.setHours(0,0,0,0);
				this.maxDate = new Date();
				this.maxDate.setHours(23,59,59,999);
				this.loadList();
			}
		}
	}
};
</script>

<style scoped>
	.recordings-container {
		margin: 10px 10px 10px 10px;
		height: calc(100vh - 95px);
		text-align: center;
	}
	@media (max-width: 769px) {
		.recordings-container {
			height: calc(100vh - 190px);
		}
	}
	.recordings-listbox {
		background: var(--bg-color-secondary);
		border-radius: 5px;
		height: 100%;
		max-width: 600px;
		margin: 0 auto;
	}
	.recordings-list {
		height: calc(100% - 92px); 
		/* overflow-y: scroll; */
	}
	.my-scroller {
		height: 100%;
	}
	.loading {
		position: absolute;
		transition: opacity 500ms, max-height 1ms 500ms, transform 500ms;
		z-index: 1000;
		overflow: hidden;
		max-height: 0;
		width: 600px;
		height: 110px;
		opacity: 0;
	}
	.loading > DIV {
		position: absolute;
		text-align: center;
		background: var(--pn-primary-2);
		color: var(--txt-color-primary-highlight);
		padding: 20px 10px;
		border-radius: 20px;
		font-weight: 300;
		font-size: 18px;
		margin-left: 200px;
		margin-right: 200px;
		margin-top: 10px;
		margin-bottom: 10px;
		overflow: hidden;
		width: 200px;
		height: 95px;
	}
	.loading.active {
		transition: opacity 500ms 1s, max-height 1ms 1s, transform 500ms 1s;
		max-height: 200px;
		opacity: 1;
		transform: translateY(10px);
	}
</style>