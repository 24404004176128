<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex">
				<div style="position:relative;flex-shrink:0">
					<presence-icon :state="employee.presence.state" :size="25" class="presence-state" />
					<employee-picture :picture="employee.picture" :size="90" :spinner="true" :initials="employee.initials" />
					<img v-if="isBirthday" src="/assets/images/birthdayflag.png" class="birthday" alt="Flag">
				</div>
				<div style="margin-left:10px;display:flex;flex-direction:column;justify-content:center;width:385px">
					<div class="dotdotdot" style="font-size:24px;margin-top:-7px;color:var(--pn-black-2);font-weight:300">{{ employee.name }}</div>
					<div v-if="employee.title" class="dotdotdot" style="font-size:14px;color:var(--pn-black-3);margin-top:-5px">{{ employee.title }}</div>
					<div v-if="employee.department" class="dotdotdot" style="font-size:14px;color:var(--pn-black-3);margin-top:-5px">{{ employee.department }}</div>
					<div class="status">{{ statusLabel }}</div>
				</div>
			</div>
		</template>

		<div style="margin-left:10px;margin-right:10px" v-if="employee">
			<table style="width:100%">
				<tr v-if="employee.address" @click="showAddress" class="address">
					<td class="label" style="vertical-align:top">{{ $t('employeeData.address') }}</td>
					<td class="value">
						<div style="width:350px;" class="value">{{ employee.address.address }}</div>
						<div style="width:350px;" class="value">{{ employee.address.zip }} {{ employee.address.city }}</div>
						<div class="value" v-if="employee.address.country&&employee.address.country!='DK'">{{ u.ISO3166.country[employee.address.country] }}</div>
					</td>
				</tr>
				<tr v-if="employee.birthdate">
					<td class="label">{{ $t('employeeData.birthdate') }}</td>
					<td class="value">{{ employee.birthdate | formatDate('d. MMM yyyy') }}</td>
				</tr>
				<tr v-if="employee.emailAddress&&!employee.emailAddress.includes('xtele.dk')">
					<td class="label" v-html="$t('employeeData.emailAddress')"></td>
					<td class="value selectable">{{ employee.emailAddress }}</td>
				</tr>
				<tr v-if="employee.extensions.map(o=>o.number).filter(o=>o).length>0">
					<td class="label">{{ employee.extensions.length > 1 ? $t('employeeData.localNumbers') : $t('employeeData.localNumber') }}</td>
					<td class="value selectable">{{ employee.extensions.map(o=>o.number).filter(o=>o).join(' • ') }}</td>
				</tr>
				<tr v-if="mobileNumbers!=''">
					<td class="label">{{ $t('employeeData.mobile') }}</td>
					<td class="value selectable">{{ mobileNumbers }}</td>
				</tr>
				<tr v-if="directNumbers!=''">
					<td class="label">{{ $t('employeeData.direct') }}</td>
					<td class="value selectable">{{ directNumbers }}</td>
				</tr>
			</table>

		</div>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" size="sm" style="height:30px" @click="close()" tabindex="100">{{ $t('common.close') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import EmployeePicture from '../EmployeePicture.vue';
import PresenceIcon from '../PresenceIcon.vue';
import s from '../../settings';
import u from '../../utils/utils';
import axios from 'axios';
import i18n from '../../utils/i18n';
import logger from '../../data/logger';

export default {
	components: {
		PresenceIcon,
		EmployeePicture//,
		// GlyphIcon

	},
	data() {
		return {
			$t: i18n.t,
			modalId: 'EmployeeDataModal' + Math.random().toString(),
			employee: null
		};
	},
	computed: {
		isBirthday: function() {
			if (this.employee?.birthdate) {
				const bd = this.employee.birthdate;
				const today = new Date();
				return bd.getMonth() == today.getMonth() && bd.getDate() == today.getDate();
			} else {
				return false;
			}
		},
		statusLabel: function() {
			if (this.employee?.presence) {
				let lbl = i18n.t2('common.presence.' + this.employee.presence.state);

				if (this.employee.presence.expire) {
					const expire = new Date(this.employee.presence.expire);

					const inAWeek = new Date();
					inAWeek.setDate(inAWeek.getDate() + 7);
					inAWeek.setHours(0, 0, 0, 0);
					
					const tomorrow = new Date();
					tomorrow.setDate(tomorrow.getDate() + 1);

					const today = new Date();

					if (expire.getFullYear() == today.getFullYear() && expire.getMonth() == today.getMonth() && expire.getDate() == today.getDate()) {
						// kl. 18:00
						lbl += ` ${i18n.t2('employeeData.until')} ${u.formatDate(expire, 'hh:mm')}`;
					} else if (expire.getFullYear() == tomorrow.getFullYear() && expire.getMonth() == tomorrow.getMonth() && expire.getDate() == tomorrow.getDate()) {
						// imorgen kl. 18:00
						if (u.formatDate(expire, 'hh:mm') == '00:00') {
							lbl += ' ' + i18n.t2('employeeData.restOfDay');
						} else {
							lbl += ' ' + i18n.t2('employeeData.untilTomorrow') + ' ' + u.formatDate(expire, 'hh:mm');
						}
					} else if (expire < inAWeek) {
						lbl += ' ' + i18n.t2('employeeData.until2') + ' ' + u.formatDate(expire, 'w');
						if (u.formatDate(expire, 'hh:mm') != '00:00') {
							lbl += ' ' + i18n.t2('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
						}
					} else {
						lbl += ' ' + i18n.t2('employeeData.until2') + ' ' + u.formatDate(expire, 'w');
						lbl += ' ' + i18n.t2('employeeData.onThe') + ' ' + u.formatDate(expire, 'd/M');
						if (u.formatDate(expire, 'hh:mm') != '00:00') {
							lbl += ' ' + i18n.t2('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
						}
					}
				}

				return lbl;
			} else {
				return '';
			}
		},
		mobileNumbers() {
			let numbers = [];
			this.employee.extensions.forEach(ext => {
				ext.mobile.forEach(mob => {
					numbers.push(mob.replace(s.pbxSettings.nationalPrefix, ''));
				});
			});
			return numbers.join(' • ');
		},
		directNumbers() {
			return this.employee.extensions.map(o=>o.direct).filter(o=>o).map(o => o.replace(s.pbxSettings.nationalPrefix, '')).join(' • ');
		}
	},
	methods: {
		show(employee) {
			this.employee = employee;
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		showAddress() {
			const address = this.employee.address;
			const args = [address.address, address.zip, address.city].join(', ');
			const url = 'https://www.google.dk/maps?q=' + encodeURI(args);
			if (s.isEmbedded) {
				axios.get(`${s.localUrl}/openexternal?url=${encodeURIComponent(url)}`, { timeout: 5000 })
					.catch(err => {
						logger.error('Open external failed: ' + (err.message ? err.message : JSON.stringify(err.data)) );
					});
			} else {
				window.open('https://www.google.dk/maps?q=' + encodeURI(args), '_blank');
			}
		},

	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
	.status {
		font-size: var(--font-size-small);
		color: var(--label-expires-color);
		line-height: var(--font-size-small);
		display: inline-flex;
	}
	.presence-state {
		position: absolute;
		left: 69px;
		top: 63px;
		z-index: 1;
	}
	.birthday {
		width: 30px;
		position: absolute;
		top: 66px;
	}
	.address {
		cursor: pointer;
		line-height: 18px;
	}
	.address > .label {
		padding-top: 2px !important;
	}
	.address:hover {
		background-color: var(--context-menu-highlight-background);
	}
	.address:hover > .label {
		color: var(--txt-color-primary-highlight);
	}
	.address:hover > td > .value {
		color: var(--txt-color-primary-highlight);
	}
	.selectable {
		-webkit-user-select: text;
		user-select: text;
	}
	.label {
		font-weight: 300;
		font-size: var(--font-size-small);
		padding-right: 10px;
		width: 1%;
		text-align: right;
		text-transform: uppercase;
		white-space: nowrap;
		color: var(--txt-color-secondary);
		padding-top: 4px;
	}
	.value {
		font-size: var(--font-size-primary);
		font-weight: normal;
		color: var(--txt-color-primary);
	}

</style>
