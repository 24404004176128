<template>
	<div
		class="panel"
		:class="{'is-dragging':isDragging}"
		:draggable="draggable"
		@dragstart="dragStart"
		@dragend="dragEnd"
		@dragenter="dragEnter"
		@dragleave="dragLeave"
		@dragover="dragOver"
		@drop="dragDrop"
	>
		<div
			class="drop-target"
			:class="{'drop-target-border':dragOffset>0}"
			:style="{'height': dragOffset+'px'}"
		></div>
		<slot></slot>
	</div>
</template>

<script>
import dashboards from '../../data/dashboards';

export default {
	props: {
		draggable: { type: Boolean, required: true },
		data: { type: Object, required: true }
	},
	data() {
		return {
			isDragging: false,
			isOver: false,
			dashboards: dashboards,
			leaveTimer: null,
			dragOffset: 0
		};
	},
	methods: {
		dragStart(ev) {
			if (this.draggable) {
				dashboards.dragItem = this.data;
				dashboards.dragItemHeight = ev.srcElement.clientHeight;
				this.isDragging = true;
			}
		},
		dragEnd() {
			if (this.draggable || this.isDragging) {
				this.isDragging = false;
				dashboards.dragItem = null;
			}
		},
		dragEnter(ev) {
			if (!dashboards.dragItem) { return; }
			dashboards.dragTarget = this.data;
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			if (dashboards.dragItem != this.data) {
				this.isOver = true;
				if (this.data.column == dashboards.dragItem.column && dashboards.dragItem.position == this.data.position - 1) {
					return;
				}
				this.dragOffset = dashboards.dragItemHeight;
			}
		},
		dragOver(ev) {
			if (!dashboards.dragItem) { return; }
			ev.preventDefault();
			dashboards.dragTarget = this.data;
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			if (dashboards.dragItem != this.data) {
				this.isOver = true;
				if (this.data.column == dashboards.dragItem.column && dashboards.dragItem.position == this.data.position - 1) {
					return;
				}
				this.dragOffset = dashboards.dragItemHeight;
			}
		},
		dragLeave(ev) {
			if (!dashboards.dragItem) { return; }
			dashboards.dragTarget = null;
			if (dashboards.dragItem != this.data) {
				this.leaveTimer = setTimeout(() => {
					this.dragOffset = 0;
					this.isOver = false;
					this.isOverSelf = false;
					this.leaveTimer = null;
				}, 100);
			}
		},
		dragDrop(ev) {
			if (!dashboards.dragItem) { return; }
			ev.preventDefault();
			if (dashboards.dragItem != this.data) {
				this.dragOffset = 0;
			}
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			dashboards.dragDropped();
		}
	}
};
</script>

<style scoped>
	.is-dragging {
		opacity: 0.2;
	}
	.panel {
		background: var(--bg-color-secondary);
		border-radius: 5px;
		transition: padding-top 500ms;
	}
	.drop-target {
		transition: 500ms;
		border-radius: 5px 5px;
	}
	.drop-target-border {
		border: 1px dashed var(--border-color-dragdrop);
		background-color: var(--bg-color-dragdrop);
		margin-bottom: 10px;
	}
</style>