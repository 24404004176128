<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer size="lg">
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex" v-if="employee">
				<employee-picture :picture="employee.picture" :size="58" :spinner="true"></employee-picture>
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ $t('settings.header') }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ employee.name }}</div>
				</div>
			</div>
		</template>

		<div style="margin:-16px" v-if="employee!=null&&extension!=null">
			<b-tabs pills card vertical v-model="tabIndex" @activate-tab="tabSelected">
				
				<b-tab :title="$t('settings.common.header')" lazy active>
					<b-card-text style="min-height:550px">

						<b-list-group class="listgroup">

							<!-- Call handling -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header">
								<div>{{ $t('settings.common.callHandling') }}</div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.callWaiting') }}
								<b-form-checkbox v-model="extension.callWaiting" size="lg" switch @change="callWaitingChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.blockSecret') }}
								<b-form-checkbox v-model="extension.blockSecret" size="lg" switch @change="blockSecretChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div style="margin-right:20px">
									{{ $t('settings.common.mobileDelay') }}<br>
									<div class="dotdotdot" style="margin-top:-4px;font-size:11px;color:var(--pn-black-3)">{{ $t('settings.common.mobileDelay2') }}</div>
								</div>
								<b-form-select v-model="extension.mobileDelay" size="sm" :options="mobileDelayOptions" style="width:150px" @change="mobileDelayChanged" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.mobileUseOwnNumber') }}
								<b-form-checkbox v-model="extension.mobileUseOwnNumber" size="lg" switch @change="mobileUseOwnNumberChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

						</b-list-group>
						<b-list-group class="listgroup">

							<!-- Security -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header">
								<div>{{ $t('settings.common.security') }}</div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.password') }}
								<b-input-group style="width:200px" size="sm">
									<b-form-input type="password" v-model="password" size="sm" maxlength="32" :state="passwordIsValid2" :placeholder="passwordPlaceholder" :disabled="isSaving" />
									<b-input-group-append>
										<b-button variant="primary" size="sm" :disabled="!passwordIsValid||isSaving" @click="savePassword">{{ $t('common.save') }}</b-button>
									</b-input-group-append>
								</b-input-group>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.pincode') }}
								<b-input-group style="width:200px" size="sm">
									<b-form-input type="password" v-model="pincode" size="sm" :state="pinCodeIsValid2" :placeholder="pincodePlaceholder" :disabled="isSaving" />
									<b-input-group-append>
										<b-button variant="primary" size="sm" :disabled="!pinCodeIsValid||isSaving" @click="savePincode">{{ $t('common.save') }}</b-button>
									</b-input-group-append>
								</b-input-group>
							</b-list-group-item>

						</b-list-group>
						<b-list-group class="listgroup">

							<!-- UI -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header">
								<div>{{ $t('settings.common.ui') }}</div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.presenceBackground') }}
								<b-form-checkbox v-model="s.presenceBackground" size="lg" switch @change="presenceBackgroundChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.showStatusPanel') }}
								<b-form-checkbox v-model="s.showStatusPanel" size="lg" switch @change="showStatusPanelChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="s.isEmbedded">
								{{ $t('settings.common.autolaunch') }}
								<b-form-checkbox v-model="s.autolaunch" size="lg" switch @change="autolaunchChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="s.isEmbedded">
								{{ $t('settings.common.autolaunchHidden') }}
								<b-form-checkbox v-model="s.autolaunchHidden" size="lg" switch @change="autolaunchHiddenChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.dashboardColumnWidth') }}
								<b-form-select v-model="s.dashboardColumnWidth" size="sm" :options="dashboardColumnWidths" style="width:200px" @change="dashboardColumnWidthChanged" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.queueCriticalSound') }}
								<b-form-checkbox v-model="s.queueCriticalSound" size="lg" switch @change="queueCriticalSoundChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								{{ $t('settings.common.skin') }}
								<b-form-select v-model="s.skin" size="sm" :options="skins" style="width:200px" @change="skinChanged" />
							</b-list-group-item>


						</b-list-group>

					</b-card-text>
				</b-tab>

				<b-tab :title="$t('settings.phone.header')" lazy>
					<b-card-text style="min-height:550px">

						<b-alert variant="danger" :show="!mediaDevices.hasAudioPermission&&mediaDevices.isReady">{{ $t('settings.phone.accessBlocked' + (browserDetect.isMac ? 'Macos' : 'Windows')) }}</b-alert>

						<b-list-group class="listgroup">

							<!-- phone selection -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header"><div>{{ $t('settings.phone.phone') }}</div></b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="464-phone-old" color="var(--icon-color-dark)" size="18" class="icon" />
										<div>{{ $t('settings.phone.phone2') }}</div>
									</div>
								</div>
								<b-form-select v-model="myPhoneId" size="sm" :options="myPhones" class="select" @change="myPhoneChanged" />
							</b-list-group-item>

						</b-list-group>
						<b-list-group class="listgroup" v-if="s.myPhoneId=='SOFTPHONE'">

							<!-- Ringtone -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header"><div>{{ $t('settings.phone.ringtone') }}</div></b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="19-music-alt" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.ringtoneExternal') }}
									</div>
								</div>
								<b-form-select v-model="s.ringtone" size="sm" :options="ringtones" class="select" @change="ringtoneChanged" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="19-music-alt" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.ringtoneLocal') }}
									</div>
								</div>
								<b-form-select v-model="s.ringtoneLocal" size="sm" :options="ringtones" class="select" @change="ringtoneLocalChanged" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="browserDetect.isChromium||browserDetect.isOpera||browserDetect.isIEedge">
								<div>
									<div>
										<glyph-icon icon="536-speakers" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.ringtonePlayOn') }}
									</div>
								</div>
								<b-form-select v-model="ringerDeviceId" size="sm" :options="mediaDevices.audioOutDevices" class="select" @change="ringtoneDeviceIdChanged" :disabled="!mediaDevices.hasAudioPermission" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="536-speakers" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.ringtoneVolume') }}
									</div>
								</div>
								<b-form-select v-model="ringerVolume" size="sm" :options="ringtoneVolumes" class="select" @change="ringtoneVolumeChanged" :disabled="!mediaDevices.hasAudioPermission" />
							</b-list-group-item>

						</b-list-group>
						<b-list-group class="listgroup" v-if="s.myPhoneId=='SOFTPHONE'">

							<!-- audio devices -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header" v-if="browserDetect.isChromium||browserDetect.isOpera||browserDetect.isIEedge">
								<div>{{ $t('settings.phone.devices') }}</div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="browserDetect.isChromium||browserDetect.isOpera||browserDetect.isIEedge">
								<div>
									<div>
										<glyph-icon icon="341-mic" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.microphone') }}
									</div>
								</div>
								<b-form-select v-model="microphoneDeviceId" size="sm" :options="mediaDevices.audioInDevices" class="select" @change="softphoneMicrophoneDeviceIdChanged" :disabled="!mediaDevices.hasAudioPermission" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="browserDetect.isChromium||browserDetect.isOpera||browserDetect.isIEedge">
								<div>
									<div>
										<glyph-icon icon="77-headphones" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.speaker') }}
									</div>
								</div>
								<b-form-select v-model="speakerDeviceId" size="sm" :options="mediaDevices.audioOutDevices" class="select" @change="softphoneSpeakerDeviceIdChanged" :disabled="!mediaDevices.hasAudioPermission" />
							</b-list-group-item>

							<!-- Video device -->
							<!-- Disabled until we want to support video -->
							<!-- <b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="181-video" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.camera') }}
									</div>
								</div>
								<b-form-select v-model="videoDeviceId" size="sm" :options="mediaDevices.videoInDevices" class="select" @change="softphoneVideoDeviceIdChanged" :disabled="!mediaDevices.hasVideoPermission" />
							</b-list-group-item> -->

							<b-list-group-item v-if="isChrome&&!s.isEmbedded" class="d-flex justify-content-between align-items-center list-item" style="height:45px;background-color:var(--bg-color-dashboard-header)">
								<div style="color:var(--txt-color-dashboard-header);font-size:11px;font-weight:bold">{{ $t('settings.phone.jabraInfo') }}</div>
								<div style="display:flex;flex-direction:column;margin-right:0">
									<div v-if="isMac" class="text-nowrap"><a style="color:var(--txt-color-dashboard-header);letter-spacing:0px;font-size:11px;text-transform:uppercase" href="https://doc.telecomx.dk/624eb3aeff8df25b4919ff2c/JabraChromeHost.pkg">{{ $t('settings.phone.jabraChromeHost') }}</a></div>
									<div v-if="!isMac" class="text-nowrap"><a style="color:var(--txt-color-dashboard-header);letter-spacing:0px;font-size:11px;text-transform:uppercase" href="https://doc.telecomx.dk/624eb381ff8df25b4919ff2b/JabraChromeHost.msi">{{ $t('settings.phone.jabraChromeHost') }}</a></div>
									<div class="text-nowrap"><a style="color:var(--txt-color-dashboard-header);letter-spacing:0px;font-size:11px;text-transform:uppercase" target="_blank" href="https://chrome.google.com/webstore/detail/jabra-sdk-generation-3/kpmedpgagnidddimmioekjdhfllmdfia">{{ $t('settings.phone.jabraChromePlugin') }}</a></div>
								</div>
							</b-list-group-item>

						</b-list-group>
						<b-list-group class="listgroup" v-if="s.myPhoneId=='SOFTPHONE'">

							<!-- busylight -->
							<b-list-group-item class="d-flex justify-content-between align-items-center section-header">
								<div>{{ $t('settings.phone.busylight') }}</div>
								<div v-if="isMac" style="padding-top:10px"><a style="color:var(--txt-color-dashboard-header);letter-spacing:0px;font-size:11px" href="https://doc.telecomx.dk/60deccc4bd0a7661d66471c6/BusylightMac.pkg">{{ $t('settings.phone.busylightDriver') }}</a></div>
								<div v-if="!isMac" style="padding-top:10px"><a style="color:var(--txt-color-dashboard-header);letter-spacing:0px;font-size:11px" href="https://doc.telecomx.dk/60decd0abd0a7661d66471c7/BusyLightWin.msi">{{ $t('settings.phone.busylightDriver') }}</a></div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item">
								<div>
									<div>
										<glyph-icon icon="510-light-beacon" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.busylightEnable') }}
									</div>
								</div>
								<b-form-checkbox v-model="kuando.use" size="lg" switch @change="kuandoChanged" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="s.myPhoneId=='SOFTPHONE'&&kuando.use">
								<div>
									<div>
										<glyph-icon icon="19-music-alt" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.busylightRingtone') }}
									</div>
								</div>
								<b-form-select v-model="kuando.ringTone" size="sm" :options="kuandoRingTones" class="select" @change="kuandoChanged" />
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-if="s.myPhoneId=='SOFTPHONE'&&kuando.use">
								<div>
									<div>
										<glyph-icon icon="536-speakers" color="var(--icon-color-dark)" size="18" class="icon" />
										{{ $t('settings.phone.busylightVolume') }}
									</div>
								</div>
								<b-form-select v-model="kuando.ringVolume" size="sm" :options="kuandoRingVolumes" class="select" @change="kuandoChanged" />
							</b-list-group-item>

						</b-list-group>

					</b-card-text>
				</b-tab>

				<b-tab :title="$t('settings.dnd.header')" lazy>
					<b-card-text style="min-height:550px">

						<b-alert show>{{ $t('settings.dnd.help') }}</b-alert>

						<b-list-group class="listgroup">
							<b-list-group-item class="d-flex justify-content-between align-items-center list-item" v-for="item in extension.phones" :key="item._id">
								<div style="display:flex">
									<glyph-icon :icon="phoneTypeToIcon(item)" color="var(--icon-color-dark)" size="18" style="margin-top:-2px;margin-right:3px" />
									{{ item.identification | cutNationalPrefix }}
								</div>
								<b-form-checkbox v-model="item.dnd" size="lg" switch @change="dndChanged(item)" style="margin-top:-7px;margin-right:-12px" />
							</b-list-group-item>
						</b-list-group>

					</b-card-text>
				</b-tab>

				<b-tab :title="$t('settings.shortcuts.header')" lazy>
					<b-card-text style="min-height:550px">

						<b-alert v-if="s.isEmbedded" show>{{ $t('settings.shortcuts.help') }}</b-alert>

						<b-alert variant="danger" :show="!s.isEmbedded">{{ $t('settings.shortcuts.notInBrowser') }}</b-alert>
						
						<div style="margin-left:10px;margin-right:10px;" v-if="globalShortcuts&&s.isEmbedded">
							<b-form-group :label="item.label" v-for="item in globalShortcutsKeys" :key="item.key" >
								<b-input-group>
									<b-input-group-prepend>
										<b-form-checkbox switch size="lg" v-model="globalShortcuts[item.key].enabled" style="margin-right:5px" @change="globalShortcutsDirty=true" />
										<b-button size="sm" style="width:100px" :variant="globalShortcuts[item.key].commandOrControl?'success':'outline-success'" :pressed="globalShortcuts[item.key].commandOrControl" @click="gsToggle(item.key, 'commandOrControl')" :disabled="!globalShortcuts[item.key].enabled">{{ isMac ? 'Command' : 'Control' }}</b-button>
										<b-button size="sm" style="width:100px" :variant="globalShortcuts[item.key].alt?'success':'outline-success'" :pressed="globalShortcuts[item.key].alt" @click="gsToggle(item.key, 'alt')" :disabled="!globalShortcuts[item.key].enabled">{{ isMac ? 'Option' : 'Alt' }}</b-button>
										<b-button size="sm" style="width:100px" :variant="globalShortcuts[item.key].shift?'success':'outline-success'" :pressed="globalShortcuts[item.key].shift" @click="gsToggle(item.key, 'shift')" :disabled="!globalShortcuts[item.key].enabled">Shift</b-button>
									</b-input-group-prepend>
									<b-form-select v-model="globalShortcuts[item.key].key" :options="keys" size="sm" @change="globalShortcutsDirty=true" :disabled="!globalShortcuts[item.key].enabled"></b-form-select>
								</b-input-group>
							</b-form-group>

							<div style="height:10px"></div>

							<div style="display:flex;justify-content:space-between">
								<b-button style="width:48%;margin-bottom:0;height:30px" variant="primary" size="sm" :disabled="!globalShortcutsDirty" @click="revertGlobalShortcuts">{{ $t('settings.shortcuts.cancel') }}</b-button>
								<b-button style="width:48%;margin-bottom:0;height:30px" variant="primary" size="sm" :disabled="!globalShortcutsDirty" @click="saveGlobalShortcuts">{{ $t('settings.shortcuts.save') }}</b-button>
							</div>

						</div>
						
					</b-card-text>
				</b-tab>

				<b-tab :title="$t('settings.integration.header')" lazy>
					<b-card-text style="min-height:550px">

						<div style="height:500px">

							<b-alert variant="danger" :show="!s.isEmbedded">{{ $t('settings.integration.notInBrowser') }}</b-alert>

							<b-card no-body v-if="s.isEmbedded">
								<b-tabs card class="subtab-title-bg" content-class="subtab-content">

									<b-tab :title="$t('settings.integration.sendKeys.header')" active lazy style="background-color:var(--bg-color-secondary)" title-link-class="subtab-title">
										<b-card-text style="overflow-x:hidden">
											<b-row>
												<b-col>
													<b-form-group :label="$t('settings.integration.sendKeys.use')">
														<b-form-select v-model="integration.sendkeys.when" :options="whenOptions" size="sm" @change="integrationSendkeysChanged"></b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group :label="$t('settings.integration.sendKeys.delay')">
														<b-form-select v-model="integration.sendkeys.delay" :options="delayOptions" size="sm" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'"></b-form-select>
													</b-form-group>
												</b-col>
											</b-row>
											<b-form-group :label="$t('settings.integration.sendKeys.process')">
												<b-form-input v-model="integration.sendkeys.focusProcess" type="text" size="sm" maxlength="64" @input="integrationDirty=true" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'"></b-form-input>
											</b-form-group>
											<div v-if="isMac" class="help" v-html="$t('settings.integration.sendKeys.macHelp')"></div>
											<div v-if="!isMac" class="help" v-html="$t('settings.integration.sendKeys.winHelp')"></div>
											<b-form-group style="text-align:left">
												<b-form-checkbox v-model="integration.sendkeys.maximizeProcess" size="lg" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'" switch>{{ $t('settings.integration.sendKeys.maximizeProcess') }}</b-form-checkbox>
												<b-form-checkbox v-show="!isMac" v-model="integration.sendkeys.useAutoit" size="lg" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'" switch>{{ $t('settings.integration.sendKeys.useAutoit') }}</b-form-checkbox>
												<b-form-checkbox v-model="integration.sendkeys.requireArgs" size="lg" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'" switch>{{ $t('settings.integration.sendKeys.requireArgs') }}</b-form-checkbox>
											</b-form-group>
											<b-form-group :label="$t('settings.integration.sendKeys.keys')">
												<b-input-group>
													<b-form-input v-model="integration.sendkeys.keys" type="text" size="sm" maxlength="256" @input="integrationDirty=true" @change="integrationDirty=true" :disabled="integration.sendkeys.when=='NEVER'"></b-form-input>
													<b-input-group-append>
														<div style="border:var(--input-border);border-radius: 0 4px 4px 0;padding-left:5px;padding-right:5px;cursor:pointer" v-b-toggle="'collapse-1'">
															<glyph-icon icon="635-circle-question" color="#555" size="24"></glyph-icon>
														</div>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
											<div class="help">{{ $t('settings.integration.sendKeys.variables') }}: <span style="user-select:text">{NUMBER}</span>, <span style="user-select:text">{NUMBER-NO-PREFIX}</span>, <span style="user-select:text">{NAME}</span>, <span style="user-select:text">{VAR:{{ $t('settings.integration.sendKeys.variableName') }}}</span></div>
											<b-collapse id="collapse-1" style="text-align:left;border:var(--divider-line);margin-bottom:5px;border-radius: 4px 4px 0 0">
												<div style="background:var(--bg-color-dashboard-header) !important;color:var(--txt-color-dashboard-header);padding-left:5px;font-weight:300;">{{ $t('settings.integration.sendKeys.keysHelp') }}</div>
												<div style="font-size:12px;color:var(--txt-color-primary) !important;">
													<b-table v-if="!isMac&&!integration.sendkeys.useAutoit" striped small sticky-header="200px" :items="helpStandard" class="help-table"></b-table>
													<b-table v-if="!isMac&&integration.sendkeys.useAutoit" striped small sticky-header="200px" :items="helpAutoit" class="help-table"></b-table>
													<b-table v-if="isMac" striped small  :items="helpMacOs" sticky-header="200px" class="help-table"></b-table>
												</div>
											</b-collapse>
											<b-button style="margin-left:25%;width:50%;margin-bottom:5px;height:30px" variant="primary" size="sm" :disabled="integration.sendkeys.when=='NEVER'" @click="integrationSendKeysTest">{{ $t('settings.integration.sendKeys.test') }}</b-button>
										</b-card-text>
									</b-tab>

									<b-tab :title="$t('settings.integration.browser.header')" lazy style="background-color:var(--bg-color-secondary)" title-link-class="subtab-title">
										<b-card-text style="overflow-x:hidden;min-height:331px">

											<b-row>
												<b-col cols="5">
													<b-form-group :label="$t('settings.integration.browser.use')">
														<b-form-select v-model="integration.browser.when" :options="whenOptions" size="sm" @change="integrationBrowserChanged"></b-form-select>
													</b-form-group>
												</b-col>
												<b-col cols="7">
													<b-form-group :label="$t('settings.integration.browser.website')" :description="$t('settings.integration.browser.website2')">
														<b-form-input v-model="integration.browser.website" type="text" size="sm" maxlength="64" @input="integrationDirty=true" @change="integrationDirty=true" :disabled="integration.browser.when=='NEVER'"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-form-group :label="$t('settings.integration.browser.type')" :description="$t('settings.integration.browser.typeHelp')">
												<b-form-select v-model="integration.browser.spa" :options="browserTypeOptions" size="sm" @change="integrationDirty=true" :disabled="integration.browser.when=='NEVER'"></b-form-select>
											</b-form-group>

											<b-form-group style="text-align:left">
												<b-form-checkbox v-model="integration.browser.requireArgs" size="lg" @change="integrationDirty=true" :disabled="integration.browser.when=='NEVER'" switch>{{ $t('settings.integration.browser.requireArgs') }}</b-form-checkbox>
											</b-form-group>

											<b-list-group>
												<b-list-group-item style="background-color:var(--bg-color-dashboard-header);color:var(--txt-color-dashboard-header);font-weight:300;font-size:var(--font-size-secondary)">{{ $t('settings.integration.browser.actions') }} ({{ integration.browser.actions.length }})</b-list-group-item>
												<b-list-group-item v-for="(item,index) in integration.browser.actions" :key="index" class="d-flex align-content-stretch align-items-top" style="background-color:var(--bg-color-3)">
													<b-form-select v-model="item.action" size="sm" style="width:150px" :options="browserActions" @change="item.value=null;integrationDirty=true;" />
													<div v-if="item.action=='sleep'">
														<b-input-group size="sm" :append="$t('settings.integration.browser.milliseconds')">
															<b-form-input no-wheel type="number" min="0" max="10000" size="sm" v-model="item.value" @input="integrationDirty=true" @change="integrationDirty=true" required />
														</b-input-group>
													</div>
													<div v-if="item.action=='target'">
														<b-input-group size="sm" :append="$t('settings.integration.browser.csssel')">
															<b-form-input type="text" size="sm" v-model="item.value" required style="width:224px" @change="integrationDirty=true" />
														</b-input-group>
													</div>
													<div v-if="item.action=='insert'">
														<b-form-input type="text" size="sm" v-model="item.value" required :placeholder="$t('settings.integration.browser.value')" @input="integrationDirty=true" @change="integrationDirty=true" />
														<div v-if="item.action=='insert'" class="help" style="margin-top:0px;margin-bottom:0">{{ $t('settings.integration.browser.variables') }}: <span style="user-select:text">{NUMBER}</span>, <span style="user-select:text">{NUMBER-NO-PREFIX}</span>, <span style="user-select:text">{NAME}</span>, <span style="user-select:text">{VAR:{{ $t('settings.integration.browser.variablesName') }}}</span></div>
													</div>
													<div v-if="item.action=='js'">
														<b-form-textarea v-model="item.value" style="width:300px" size="sm" rows="5" max-rows="5" no-resize @change="integrationDirty=true"/>
													</div>
													<b-button variant="danger" @click="integration.browser.actions.splice(index, 1);integrationDirty=true;" style="height:24px;padding:2px 5px;margin-top:1px">
														<glyph-icon icon="17-bin" size="15" color="white" style="margin-top:-10px" />
													</b-button>
												</b-list-group-item>
												<b-list-group-item style="background-color:var(--bg-color-3)">
													<b-dropdown :text="$t('settings.integration.browser.addAction')" size="sm" block variant="primary" :disabled="integration.browser.when=='NEVER'">
														<b-dropdown-item v-for="(item,index) in browserActions" :key="index" @click="integration.browser.actions.push({action:item.value, value: null});integrationDirty=true">{{ item.text }}</b-dropdown-item>
													</b-dropdown>
												</b-list-group-item>
											</b-list-group>
											<b-button style="margin-left:25%;width:50%;margin-bottom:5px;margin-top:10px;height:30px" variant="primary" size="sm" :disabled="integration.browser.when=='NEVER'" @click="integrationBrowserTest">{{ $t('settings.integration.browser.test') }}</b-button>

										</b-card-text>
									</b-tab>

									<b-tab :title="$t('settings.integration.run.header')" lazy style="background-color:var(--bg-color-secondary)" title-link-class="subtab-title">
										<b-card-text style="overflow-x:hidden">

											<b-row>
												<b-col>
													<b-form-group :label="$t('settings.integration.run.use')">
														<b-form-select v-model="integration.run.when" :options="whenOptions" size="sm" @change="integrationRunChanged"></b-form-select>
													</b-form-group>
												</b-col>
												<b-col>
													<b-form-group :label="$t('settings.integration.run.delay')">
														<b-form-select v-model="integration.run.delay" :options="delayOptions" size="sm" @change="integrationDirty=true" :disabled="integration.run.when=='NEVER'"></b-form-select>
													</b-form-group>
												</b-col>
											</b-row>
											<b-form-group style="text-align:left">
												<b-form-checkbox v-model="integration.run.requireArgs" size="lg" @change="integrationDirty=true" :disabled="integration.run.when=='NEVER'" switch>{{ $t('settings.integration.run.requireArgs') }}</b-form-checkbox>
											</b-form-group>
											<b-form-group :label="$t('settings.integration.run.command')">
												<b-form-input v-model="integration.run.command" type="text" size="sm" maxlength="256" @input="integrationDirty=true" @change="integrationDirty=true" :disabled="integration.run.when=='NEVER'"></b-form-input>
											</b-form-group>
											<div v-if="isMac" class="help" v-html="$t('settings.integration.run.macHelp')"></div>
											<div v-if="!isMac" class="help" v-html="$t('settings.integration.run.winHelp')"></div>
											<div class="help">{{ $t('settings.integration.run.variables') }}: <span style="user-select:text">{NUMBER}</span>, <span style="user-select:text">{NUMBER-NO-PREFIX}</span>, <span style="user-select:text">{NAME}</span>, <span style="user-select:text">{VAR:{{ $t('settings.integration.run.variablesName') }}}</span></div>
											<b-button style="margin-left:25%;width:50%;margin-bottom:5px;height:30px" variant="primary" size="sm" :disabled="integration.run.when=='NEVER'" @click="integrationRunTest">{{ $t('settings.integration.run.test') }}</b-button>

										</b-card-text>
									</b-tab>

									<b-tab :title="$t('settings.integration.clipboard.header')" lazy style="background-color:var(--bg-color-secondary)" title-link-class="subtab-title">
										<b-card-text style="overflow-x:hidden">

											<b-row>
												<b-col cols="6">
													<b-form-group :label="$t('settings.integration.clipboard.use')">
														<b-form-select v-model="integration.copyToClipboard.when" :options="whenOptions" size="sm" @change="integrationCopyToClipboardWhenChanged"></b-form-select>
													</b-form-group>
												</b-col>
											</b-row>

											<b-form-group style="text-align:left">
												<b-form-checkbox v-model="integration.copyToClipboard.requireArgs" size="lg" @change="integrationDirty=true" :disabled="integration.copyToClipboard.when=='NEVER'" switch>{{ $t('settings.integration.clipboard.requireArgs') }}</b-form-checkbox>
											</b-form-group>

											<b-form-group :label="$t('settings.integration.clipboard.what')">
												<b-form-input v-model="integration.copyToClipboard.what" type="text" size="sm" maxlength="256" @input="integrationDirty=true" @change="integrationDirty=true" :disabled="integration.copyToClipboard.when=='NEVER'"></b-form-input>
											</b-form-group>
											<div class="help">{{ $t('settings.integration.clipboard.variables') }}: <span style="user-select:text">{NUMBER}</span>, <span style="user-select:text">{NUMBER-NO-PREFIX}</span>, <span style="user-select:text">{NAME}</span>, <span style="user-select:text">{VAR:{{ $t('settings.integration.clipboard.variablesName') }}}</span></div>
											<b-button style="margin-left:25%;width:50%;margin-bottom:5px;height:30px" variant="primary" size="sm" :disabled="integration.copyToClipboard.when=='NEVER'" @click="integrationCopyToClipboardTest">{{ $t('settings.integration.clipboard.test') }}</b-button>

										</b-card-text>
									</b-tab>

								</b-tabs>
							</b-card>

						</div>

						<div style="height:10px"></div>
						<div style="display:flex;justify-content:space-between" v-if="s.isEmbedded">
							<b-button style="width:48%;margin-bottom:0;height:30px" variant="primary" size="sm" :disabled="!integrationDirty" @click="revertIntegration">{{ $t('settings.integration.cancel') }}</b-button>
							<b-button style="width:48%;margin-bottom:0;height:30px" variant="primary" size="sm" :disabled="!integrationDirty" @click="saveIntegration">{{ $t('settings.integration.save') }}</b-button>
						</div>

					</b-card-text>
				</b-tab>

				<b-tab :title="$t('settings.info.header')" lazy>
					<b-card-text style="min-height:550px">
						<b-list-group class="listgroup">

							<b-list-group-item class="d-flex justify-content-between align-items-center" style="font-weight:300;letter-spacing:1px;font-size:13px;background-color:var(--bg-color-dashboard-header);color:var(--txt-color-dashboard-header)">
								<div style="padding-top:10px">{{ $t('settings.info.systemStatus') }}</div>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.version') }}
								<span>{{ s.version }} {{ s.isEmbedded ? ' A ' + s.wrapperVersion : ' B' }}</span>
							</b-list-group-item>

							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.realtime') }}
								<span><glyph-icon :icon="rt.connected?'634-circle-check':'633-circle-remove'" size="20" :color="rt.connected?'#00cc00':'#cc0000'" style="margin-top:-1px"></glyph-icon></span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.authentication') }}
								<span><glyph-icon :icon="s.isAuthenticated?'634-circle-check':'633-circle-remove'" size="20" :color="s.isAuthenticated?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon></span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.employees') }}
								<span style="display:flex">
									<span style="margin-right:10px;font-size:var(--font-size-small);color:var(--txt-color-secondary);margin-top:2px">{{ employees.employees.length }} {{ $t('settings.info.units') }}</span>
									<glyph-icon :icon="employees._ready?'634-circle-check':'633-circle-remove'" size="20" :color="employees._ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
								</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.layout') }}
								<span style="display:flex">
									<span style="margin-right:10px;font-size:var(--font-size-small);color:var(--txt-color-secondary);margin-top:2px">{{ dashboards.dashboards.length }} {{ $t('settings.info.units') }}</span>
									<glyph-icon :icon="dashboards.ready?'634-circle-check':'633-circle-remove'" size="20" :color="dashboards.ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
								</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.phonebook') }}
								<span style="display:flex">
									<span style="margin-right:10px;font-size:var(--font-size-small);color:var(--txt-color-secondary);margin-top:2px">{{ phonebook.contacts.length }} {{ $t('settings.info.contacts') }}</span>
									<glyph-icon :icon="phonebook._ready?'634-circle-check':'633-circle-remove'" size="20" :color="phonebook._ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
								</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item" v-if="s.isEmbedded">
								{{ $t('settings.info.appApi') }}
								<span><glyph-icon :icon="integrationAPI.localApiConnected?'634-circle-check':'633-circle-remove'" size="20" :color="integrationAPI.localApiConnected?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon></span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.softphone') }}
								<span v-if="s.myPhoneId=='SOFTPHONE'" style="display:flex">
									<glyph-icon :icon="sipClient.registered?'634-circle-check':'633-circle-remove'" size="20" :color="sipClient.registered?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
								</span>
								<span v-if="s.myPhoneId!='SOFTPHONE'">{{ $t('settings.info.notInUse') }}</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item" v-if="jabra.use">
								{{ $t('settings.info.headset') }}
								<span style="font-size:var(--font-size-secondary);color:var(--txt-color-secondary);margin-top:2px">{{ jabra.useDeviceName }}</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item" v-if="sennheiser.use">
								{{ $t('settings.info.headset') }}
								<span style="font-size:var(--font-size-secondary);color:var(--txt-color-secondary);margin-top:2px">{{ sennheiser.useDeviceName }}</span>
							</b-list-group-item>
							<b-list-group-item class="d-flex justify-content-between align-items-center status-item">
								{{ $t('settings.info.busylight') }}
								<span><glyph-icon :icon="kuando.use?'634-circle-check':'633-circle-remove'" size="20" :color="kuando.use?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon></span>
							</b-list-group-item>
						
						</b-list-group>
					</b-card-text>
				</b-tab>

			</b-tabs>

			<div class="modal-space-to-bottom"></div>

			<div class="modal-bottom">
				<b-button variant="secondary" size="sm" style="height:30px" @click="close()" tabindex="100">{{ $t('common.close') }}</b-button>
				<!-- <b-button variant="primary" :disabled="!employee||isSaving" size="sm" type="submit" @click="save()" tabindex="120">Gem</b-button> -->
			</div>
			<div class="version"><b>{{ $t('settings.info.version') }}</b> {{ s.version }}{{ s.isEmbedded ? '-' + s.wrapperVersion : '' }}</div>

		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import EmployeePicture from '../EmployeePicture.vue';
import s from '../../settings';
import u from '../../utils/utils';
import employees from '../../data/employees';
import GlyphIcon from '../GlyphIcon.vue';
import sipClient from '../../data/sipClient';
import jabra from '../../utils/headsets/jabra';
import sennheiser from '../../utils/headsets/sennheiser';
import kuando from '../../utils/headsets/kuando';
import realtime from '../../data/realtime';
import phonebook from '../../data/phonebook';
import dashboards from '../../data/dashboards';
import integration from '../../data/integration';
import mediaDevices from '../../data/mediaDevices';
import browserDetect from '../../utils/browserdetect';
import axios from 'axios';
import i18n from '../../utils/i18n';


export default {
	components: {
		EmployeePicture,
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			rt: realtime,
			employees: employees,
			kuando: kuando,
			jabra: jabra,
			sennheiser: sennheiser,
			browserDetect: browserDetect,
			sipClient: sipClient,
			phonebook: phonebook,
			dashboards: dashboards,
			integrationAPI: integration,
			mediaDevices: mediaDevices,
			visible: false,
			ringtones: [],
			microphoneDeviceId: null,
			speakerDeviceId: null,
			videoDeviceId: null,
			ringerDeviceId: null,
			ringerVolume: 1,
			employee: null,
			extension: null,
			modalId: 'SettingsModal' + Math.random().toString(),
			isSaving: false,
			tabIndex: 0,
			password: '',
			passwordPlaceholder: '',
			pincode: '',
			pincodePlaceholder: '',
			mobileUseOwnNumber: false,
			mobileDelayOptions: [
				{ value: 0, text: i18n.t('settings.common.mobileDelayNone') },
				{ value: 3, text: '3 ' + i18n.t2('common.seconds') },
				{ value: 5, text: '5 ' + i18n.t2('common.seconds') },
				{ value: 8, text: '8 ' + i18n.t2('common.seconds') },
				{ value: 10, text: '10 ' + i18n.t2('common.seconds') },
				{ value: 15, text: '15 ' + i18n.t2('common.seconds') },
				{ value: 20, text: '20 ' + i18n.t2('common.seconds') },
				{ value: 30, text: '30 ' + i18n.t2('common.seconds') }
			],
			globalShortcuts: null,
			globalShortcutsKeys: [
				{ key: 'ANSWER', label: i18n.t('settings.shortcuts.answer') },
				{ key: 'HANGUP', label: i18n.t('settings.shortcuts.hangup') },
				{ key: 'TOGGLE_WINDOW', label: i18n.t('settings.shortcuts.visibility') },
				{ key: 'CALL', label: i18n.t('settings.shortcuts.call') },
				{ key: 'SEARCH', label: i18n.t('settings.shortcuts.search') }
			],
			keys: [
				{ value: 'NONE', text: i18n.t('settings.integration.doNotUse') },
				{ value: '0', text: '0' },
				{ value: '1', text: '1' },
				{ value: '2', text: '2' },
				{ value: '3', text: '3' },
				{ value: '4', text: '4' },
				{ value: '5', text: '5' },
				{ value: '6', text: '6' },
				{ value: '7', text: '7' },
				{ value: '8', text: '8' },
				{ value: '9', text: '9' },
				{ value: 'A', text: 'A' },
				{ value: 'B', text: 'B' },
				{ value: 'C', text: 'C' },
				{ value: 'D', text: 'D' },
				{ value: 'E', text: 'E' },
				{ value: 'F', text: 'F' },
				{ value: 'G', text: 'G' },
				{ value: 'H', text: 'H' },
				{ value: 'I', text: 'I' },
				{ value: 'J', text: 'J' },
				{ value: 'K', text: 'K' },
				{ value: 'L', text: 'L' },
				{ value: 'M', text: 'M' },
				{ value: 'N', text: 'N' },
				{ value: 'O', text: 'O' },
				{ value: 'P', text: 'P' },
				{ value: 'Q', text: 'Q' },
				{ value: 'R', text: 'R' },
				{ value: 'S', text: 'S' },
				{ value: 'T', text: 'T' },
				{ value: 'U', text: 'U' },
				{ value: 'V', text: 'V' },
				{ value: 'W', text: 'W' },
				{ value: 'X', text: 'X' },
				{ value: 'Y', text: 'Y' },
				{ value: 'Z', text: 'Z' },
				{ value: 'F1', text: 'F 1' },
				{ value: 'F2', text: 'F 2' },
				{ value: 'F3', text: 'F 3' },
				{ value: 'F4', text: 'F 4' },
				{ value: 'F5', text: 'F 5' },
				{ value: 'F6', text: 'F 6' },
				{ value: 'F7', text: 'F 7' },
				{ value: 'F8', text: 'F 8' },
				{ value: 'F9', text: 'F 9' },
				{ value: 'F10', text: 'F 10' },
				{ value: 'F11', text: 'F 11' },
				{ value: 'F12', text: 'F 12' },
				{ value: 'F13', text: 'F 13' },
				{ value: 'F14', text: 'F 14' },
				{ value: 'F15', text: 'F 15' },
				{ value: 'F16', text: 'F 16' },
				{ value: 'F17', text: 'F 17' },
				{ value: 'F18', text: 'F 18' },
				{ value: 'F19', text: 'F 19' },
				{ value: 'F20', text: 'F 20' },
				{ value: 'F21', text: 'F 21' },
				{ value: 'F22', text: 'F 22' },
				{ value: 'F23', text: 'F 23' },
				{ value: 'F24', text: 'F 24' },
				{ value: 'Plus', text: '+' },
				{ value: 'Space', text: i18n.t('settings.integration.key.space') },
				{ value: 'Tab', text: i18n.t('settings.integration.key.tab') },
				{ value: 'Capslock', text: i18n.t('settings.integration.key.shiftlock') },
				{ value: 'Numlock', text: i18n.t('settings.integration.key.numlock') },
				{ value: 'Scrolllock', text: i18n.t('settings.integration.key.scrolllock') },
				{ value: 'Backspace', text: i18n.t('settings.integration.key.backspace') },
				{ value: 'Delete', text: i18n.t('settings.integration.key.delete') },
				{ value: 'Insert', text: i18n.t('settings.integration.key.insert') },
				{ value: 'Return', text: i18n.t('settings.integration.key.return') },
				{ value: 'Up', text: i18n.t('settings.integration.key.up') },
				{ value: 'Down', text: i18n.t('settings.integration.key.down') },
				{ value: 'Left', text: i18n.t('settings.integration.key.left') },
				{ value: 'Right', text: i18n.t('settings.integration.key.right') },
				{ value: 'Home', text: i18n.t('settings.integration.key.home') },
				{ value: 'End', text: i18n.t('settings.integration.key.end') },
				{ value: 'PageUp', text: i18n.t('settings.integration.key.pageUp') },
				{ value: 'PageDown', text: i18n.t('settings.integration.key.pageDown') },
				{ value: 'Escape', text: 'ESC' },
				{ value: 'VolumeUp', text: i18n.t('settings.integration.key.volumeUp') },
				{ value: 'VolumeDown', text: i18n.t('settings.integration.key.volumeDown') },
				{ value: 'VolumeMute', text: i18n.t('settings.integration.key.volumeMute') },
				{ value: 'MediaNextTrack', text: i18n.t('settings.integration.key.mediaNextTrack') },
				{ value: 'MediaPreviousTrack', text: i18n.t('settings.integration.key.mediaPreviousTrack') },
				{ value: 'MediaStop', text: i18n.t('settings.integration.key.mediaStop') },
				{ value: 'MediaPlayPause', text: i18n.t('settings.integration.key.mediaPlayPause') },
				{ value: 'PrintScreen', text: i18n.t('settings.integration.key.printScreen') },
				{ value: 'num0', text: i18n.t2('settings.integration.key.nummeric') + ' 0' },
				{ value: 'num1', text: i18n.t2('settings.integration.key.nummeric') + ' 1' },
				{ value: 'num2', text: i18n.t2('settings.integration.key.nummeric') + ' 2' },
				{ value: 'num3', text: i18n.t2('settings.integration.key.nummeric') + ' 3' },
				{ value: 'num4', text: i18n.t2('settings.integration.key.nummeric') + ' 4' },
				{ value: 'num5', text: i18n.t2('settings.integration.key.nummeric') + ' 5' },
				{ value: 'num6', text: i18n.t2('settings.integration.key.nummeric') + ' 6' },
				{ value: 'num7', text: i18n.t2('settings.integration.key.nummeric') + ' 7' },
				{ value: 'num8', text: i18n.t2('settings.integration.key.nummeric') + ' 8' },
				{ value: 'num9', text: i18n.t2('settings.integration.key.nummeric') + ' 9' },
				{ value: 'numdec', text: i18n.t2('settings.integration.key.nummeric') + ' .' },
				{ value: 'numadd', text: i18n.t2('settings.integration.key.nummeric') + ' +' },
				{ value: 'numsub', text: i18n.t2('settings.integration.key.nummeric') + ' -' },
				{ value: 'nummult', text: i18n.t2('settings.integration.key.nummeric') + ' *' },
				{ value: 'numdiv', text: i18n.t2('settings.integration.key.nummeric') + ' /' },
				{ value: '~', text: '~' },
				{ value: '!', text: '!' },
				{ value: '@', text: '@' },
				{ value: '#', text: '#' },
				{ value: '$', text: '$' },
				{ value: '%', text: '%' },
				{ value: '^', text: '^' },
				{ value: '&', text: '&' },
				{ value: '*', text: '*' },
				{ value: '(', text: '(' },
				{ value: ')', text: ')' },
				{ value: '=', text: '=' },
				{ value: '?', text: '?' },
				{ value: '.', text: '.' },
				{ value: ',', text: ',' },
				{ value: '<', text: '<' },
				{ value: '>', text: '>' },
				{ value: '_', text: '_' },
				{ value: '\'', text: '\'' }
			],
			globalShortCutsClone: null,
			globalShortcutsDirty: false,
			whenOptions: [
				{ value: 'NEVER', text: i18n.t('settings.integration.when.never') },
				{ value: 'RINGING', text: i18n.t('settings.integration.when.ringing') },
				{ value: 'ANSWERED', text: i18n.t('settings.integration.when.answered') }
			],
			delayOptions: [
				{ value: 0, text: i18n.t('settings.integration.delayNone') },
				{ value: 100, text: '100 ms' },
				{ value: 200, text: '200 ms' },
				{ value: 500, text: '500 ms' },
				{ value: 750, text: '750 ms' },
				{ value: 1000, text: '1 ' + i18n.t2('common.second') },
				{ value: 2000, text: '2 ' + i18n.t2('common.seconds') },
				{ value: 3000, text: '3 ' + i18n.t2('common.seconds') },
				{ value: 4000, text: '4 ' + i18n.t2('common.seconds') },
				{ value: 5000, text: '5 ' + i18n.t2('common.seconds') }
			],
			helpMacOs: [
				{ Tast: '200ms delay', Indsæt: '{W0.2}' }, // delay 0.2
				{ Tast: 'Escape', Indsæt: '{ESCAPE}' }, // 53
				{ Tast: 'F1 - F20', Indsæt: '{F1-F20}' }, // 122, 120, 99, 118, 96, 97, 98, 100, 101, 109, 103, 111, 105, 107, 113, 106, 64, 79, 80, 90
				{ Tast: 'Delete', Indsæt: '{DELETE}' }, // 51
				{ Tast: 'Tab', Indsæt: '{TAB}' }, // 48
				{ Tast: 'Return', Indsæt: '{RETURN}' }, // 36
				{ Tast: 'Enter', Indsæt: '{ENTER}' }, // 76
				{ Tast: 'Shift', Indsæt: '{SHIFT|text}, {SHIFT:F1}' },
				{ Tast: 'Control', Indsæt: '{CONTROL|text}, {CONTROL:F1}' },
				{ Tast: 'Option', Indsæt: '{OPTION|text}, {OPTION:F1}' },
				{ Tast: 'Command', Indsæt: '{COMMAND|text}, {COMMAND:F1}' },
				{ Tast: 'Multiple modifiers', Indsæt: '{COMMAND,OPTION,SHIFT|text}, {OPTION,SHIFT:F1}' },
				{ Tast: 'Cursor left', Indsæt: '{LEFT}' }, // 123
				{ Tast: 'Cursor up', Indsæt: '{UP}' }, // 126
				{ Tast: 'Cursor down', Indsæt: '{DOWN}' }, // 125
				{ Tast: 'Cursor right', Indsæt: '{RIGHT}' }, // 124
				{ Tast: 'Home', Indsæt: '{HOME}' }, // 115
				{ Tast: 'End', Indsæt: '{END}' }, // 119
				{ Tast: 'Page up', Indsæt: '{PGUP}' }, // 116
				{ Tast: 'Page down', Indsæt: '{PGDN}' }, // 121
				{ Tast: 'Keypad 0-9', Indsæt: '{PAD-0 - PAD-9}' }, // 82, 83, 84, 85, 86, 87, 88, 89, 91, 92
				{ Tast: 'Keypad *', Indsæt: '{PAD-MULTIPLY}' }, // 67
				{ Tast: 'Keypad /', Indsæt: '{PAD-DIVIDE}' }, // 75
				{ Tast: 'Keypad +', Indsæt: '{PAD-PLUS}' }, // 69
				{ Tast: 'Keypad -', Indsæt: '{PAD-MINUS}' }, // 78
				{ Tast: 'Keypad =', Indsæt: '{PAD-EQUALS}' }, // 81
				{ Tast: 'Keypad .', Indsæt: '{PAD-PERIOD}' }, // 65
				{ Tast: 'Keypad clear', Indsæt: '{PAD-CLEAR}' } // 71
			],
			helpStandard: [
				{ Tast: '200ms delay', Indsæt: '{W200}' },
				{ Tast: 'Shift', Indsæt: '+' },
				{ Tast: 'Ctrl', Indsæt: '^' },
				{ Tast: 'Alt', Indsæt: '%' },
				{ Tast: 'Backspace', Indsæt: '{BACKSPACE}, {BS}, {BKSP}' },
				{ Tast: 'Break', Indsæt: '{BREAK}' },
				{ Tast: 'Caps lock', Indsæt: '{CAPSLOCK}' },
				{ Tast: 'Del, Delete', Indsæt: '{DELETE}, {DEL}' },
				{ Tast: 'Cursor left', Indsæt: '{LEFT}' },
				{ Tast: 'Cursor up', Indsæt: '{UP}' },
				{ Tast: 'Cursor right', Indsæt: '{RIGHT}' },
				{ Tast: 'Cursor down', Indsæt: '{DOWN}' },
				{ Tast: 'End', Indsæt: '{END}' },
				{ Tast: 'Enter', Indsæt: '{ENTER}, ~' },
				{ Tast: 'Escape', Indsæt: '{ESC}' },
				{ Tast: 'Help', Indsæt: '{HELP}' },
				{ Tast: 'Home', Indsæt: '{HOME}' },
				{ Tast: 'Ins, Insert', Indsæt: '{INSERT} or {INS}' },
				{ Tast: 'Num lock', Indsæt: '{NUMLOCK}' },
				{ Tast: 'Page down', Indsæt: '{PGDN}' },
				{ Tast: 'Page up', Indsæt: '{PGUP}' },
				{ Tast: 'Print screen', Indsæt: '{PRTSC}' },
				{ Tast: 'Scroll lock', Indsæt: '{SCROLLLOCK}' },
				{ Tast: 'Tab', Indsæt: '{TAB}' },
				{ Tast: 'Function key', Indsæt: '{F1-F16}' },
				{ Tast: 'Keypad add', Indsæt: '{ADD}' },
				{ Tast: 'Keypad subtract', Indsæt: '{SUBTRACT}' },
				{ Tast: 'Keypad multiply', Indsæt: '{MULTIPLY}' },
				{ Tast: 'Keypad divide', Indsæt: '{DIVIDE}' }
			],
			helpAutoit: [
				{ Tast: '!', Indsæt: '{!}' },
				{ Tast: '#', Indsæt: '{#}' },
				{ Tast: '+', Indsæt: '{+}' },
				{ Tast: '^', Indsæt: '{^}' },
				{ Tast: '{', Indsæt: '{{}' },
				{ Tast: '}', Indsæt: '{}' },
				{ Tast: 'space', Indsæt: '{SPACE}' },
				{ Tast: 'enter', Indsæt: '{ENTER}' },
				{ Tast: 'alt', Indsæt: '{ALT}' },
				{ Tast: 'backspace', Indsæt: '{BACKSPACE}' },
				{ Tast: 'delete', Indsæt: '{DELETE}' },
				{ Tast: 'Cursor up', Indsæt: '{UP}' },
				{ Tast: 'Cursor down', Indsæt: '{DOWN}' },
				{ Tast: 'Cursor left', Indsæt: '{LEFT}' },
				{ Tast: 'Cursor right', Indsæt: '{RIGHT}' },
				{ Tast: 'Home', Indsæt: '{HOME}' },
				{ Tast: 'End', Indsæt: '{END}' },
				{ Tast: 'Esc', Indsæt: '{ESCAPE}' },
				{ Tast: 'Insert', Indsæt: '{INSERT}, {INS}' },
				{ Tast: 'Page up', Indsæt: '{PGUP}' },
				{ Tast: 'Page down', Indsæt: '{PGDN}' },
				{ Tast: 'Function key', Indsæt: '{F1}-{F12}' },
				{ Tast: 'Tab', Indsæt: '{TAB}' },
				{ Tast: 'Print screen', Indsæt: '{PRINTSCREEN}' },
				{ Tast: 'Left windows', Indsæt: '{LWIN}' },
				{ Tast: 'Right windows', Indsæt: '{RWIN}' },
				{ Tast: 'Num lock', Indsæt: '{NUMLOCK on/off}' },
				{ Tast: 'Caps lock', Indsæt: '{CAPSLOCK on/off}' },
				{ Tast: 'Scroll lock', Indsæt: '{SCROLLLOCK on/off}' },
				{ Tast: 'Ctrl+break', Indsæt: '{BREAK}' },
				{ Tast: 'Pause', Indsæt: '{PAUSE}' },
				{ Tast: 'Num pad 0-9', Indsæt: '{NUMPAD0-9}' },
				{ Tast: 'Num pad *', Indsæt: '{NUMPADMULT}' },
				{ Tast: 'Num pad -', Indsæt: '{NUMPADSUB}' },
				{ Tast: 'Num pad +	', Indsæt: '{NUMPADADD}' },
				{ Tast: 'Num pad /', Indsæt: '{NUMPADDIV}' },
				{ Tast: 'Num pad .', Indsæt: '{NUMPADDOT}' },
				{ Tast: 'Num pad enter', Indsæt: '{NUMPADENTER}' },
				{ Tast: 'Windows app key', Indsæt: '{APPSKEY}' },
				{ Tast: 'Left ALT', Indsæt: '{LALT}' },
				{ Tast: 'Right ALT', Indsæt: '{RALT}' },
				{ Tast: 'Left CTRL', Indsæt: '{LCTRL}' },
				{ Tast: 'Right CTRL', Indsæt: '{RCTRL}' },
				{ Tast: 'Left SHIFT', Indsæt: '{LSHIFT}' },
				{ Tast: 'Right SHIFT', Indsæt: '{RSHIFT}' },
				{ Tast: 'Computer sleep', Indsæt: '{SLEEP}' },
				{ Tast: 'Hold alt until ALTUP', Indsæt: '{ALTDOWN}' },
				{ Tast: 'Ends ALTDOWN', Indsæt: '{ALTUP}' },
				{ Tast: 'Hold ctrl until CTRLUP', Indsæt: '{CTRLDOWN}' },
				{ Tast: 'Ends CTRLDOWN', Indsæt: '{CTRLUP}' },
				{ Tast: 'Hold left win until LWINUP', Indsæt: '{LWINDOWN}' },
				{ Tast: 'Ends LWINDOWN', Indsæt: '{LWINUP}' },
				{ Tast: 'Hold right win until RWINUP', Indsæt: '{RWINDOWN}' },
				{ Tast: 'Ends RWINDOWN', Indsæt: '{RWINUP}' },
				{ Tast: 'Send ALT+nnnn key', Indsæt: '{ASC nnnn}' }
			],
			integration: null,
			integrationDirty: false,
			browserActions: [
				{ value: 'sleep', text: i18n.t2('settings.integration.browser.actionOption.sleep') },
				{ value: 'target', text: i18n.t2('settings.integration.browser.actionOption.target') },
				{ value: 'click', text: i18n.t2('settings.integration.browser.actionOption.click') },
				{ value: 'focus', text: i18n.t2('settings.integration.browser.actionOption.focus') },
				{ value: 'clear', text: i18n.t2('settings.integration.browser.actionOption.clear') },
				{ value: 'insert', text: i18n.t2('settings.integration.browser.actionOption.insert') },
				{ value: 'js', text: i18n.t2('settings.integration.browser.actionOption.js') },
				{ value: 'scrolltop', text: i18n.t2('settings.integration.browser.actionOption.scrolltop') }
			],
			browserTypeOptions: [
				{ value: true, text: 'SPA (Single Page App)' },
				{ value: false, text: 'MPA (Multi page App)' }
			],
			myPhones: [],
			ringtoneVolumes: [
				{ value: 0, text: i18n.t('settings.common.ringtoneVolumeOff') },
				{ value: 0.1, text: '10%' },
				{ value: 0.2, text: '20%' },
				{ value: 0.3, text: '30%' },
				{ value: 0.4, text: '40%' },
				{ value: 0.5, text: '50%' },
				{ value: 0.6, text: '60%' },
				{ value: 0.7, text: '70%' },
				{ value: 0.8, text: '80%' },
				{ value: 0.9, text: '90%' },
				{ value: 1, text: '100%' }
			],
			kuandoRingTones: [
				{ value: 0, text: i18n.t2('settings.common.kuandoRingtone.0') },
				{ value: 1, text: i18n.t2('settings.common.kuandoRingtone.1') },
				{ value: 2, text: i18n.t2('settings.common.kuandoRingtone.2') },
				{ value: 3, text: i18n.t2('settings.common.kuandoRingtone.3') },
				{ value: 4, text: i18n.t2('settings.common.kuandoRingtone.4') },
				{ value: 5, text: i18n.t2('settings.common.kuandoRingtone.5') },
				{ value: 6, text: i18n.t2('settings.common.kuandoRingtone.6') },
				{ value: 7, text: i18n.t2('settings.common.kuandoRingtone.7') },
				{ value: 8, text: i18n.t2('settings.common.kuandoRingtone.8') }
			],
			kuandoRingVolumes: [
				{ value: 100, text: '100%' },
				{ value: 75, text: '75%' },
				{ value: 50, text: '50%' },
				{ value: 25, text: '25%' },
				{ value: 0, text: '0%' }
			],
			dashboardColumnWidths: [
				{ value: 250, text: '250 ' + i18n.t2('settings.common.pixels') },
				{ value: 300, text: '300 ' + i18n.t2('settings.common.pixels') },
				{ value: 325, text: '325 ' + i18n.t2('settings.common.pixels') },
				{ value: 370, text: '370 ' + i18n.t2('settings.common.pixelsDefault') },
				{ value: 400, text: '400 ' + i18n.t2('settings.common.pixels') },
				{ value: 450, text: '450 ' + i18n.t2('settings.common.pixels') },
				{ value: 500, text: '500 ' + i18n.t2('settings.common.pixels') },
				{ value: 550, text: '550 ' + i18n.t2('settings.common.pixels') },
				{ value: 600, text: '600 ' + i18n.t2('settings.common.pixels') }
			],
			skins: [
				{ value: 'DEFAULT', text: i18n.t2('settings.common.skin_DEFAULT') },
				{ value: 'EARTH', text: i18n.t2('settings.common.skin_EARTH') },
				{ value: 'TEAL', text: i18n.t2('settings.common.skin_TEAL') },
				{ value: 'DARK', text: i18n.t2('settings.common.skin_DARK') }
			]
		};
	},
	computed: {
		isMac: function() {
			return browserDetect.isMac;
		},
		isChrome: function() {
			return browserDetect.isChrome;
		},
		pinCodeIsValid: function() {
			return this.pincode.match(/^\d{4,10}$/) != null;
		},
		pinCodeIsValid2: function() {
			if (this.pincode.length == 0) { return null; }
			return this.pinCodeIsValid;
		 },
		passwordIsValid: function() {
			return this.validatePasswordComplexity(this.password);
		},
		passwordIsValid2: function() {
			if (this.password.length == 0) { return null; }
			return this.passwordIsValid;
		}
	},
	methods: {
		tabSelected(index) {
			if (index == 1) {
				this.ensureAudioVideoDevicesAreValid();
			}
		},
		ringtoneChanged(id) { s.setRingtone(id); },
		ringtoneLocalChanged(id) { s.setRingtoneLocal(id); },
		ringtoneDeviceIdChanged(id) { mediaDevices.ringerDeviceId = id; },
		ringtoneVolumeChanged(vol) { mediaDevices.ringerVolume = vol; },
		softphoneSpeakerDeviceIdChanged(id) { mediaDevices.speakerDeviceId = id; },
		softphoneMicrophoneDeviceIdChanged(id) {
			mediaDevices.microphoneDeviceId = id;
			const device = mediaDevices.microphoneDevice;
			const group = mediaDevices.deviceGroups.find(o => o.groupId == device.groupId);
			const speakerDevice = group.devices.find(o => o.kind == 'audiooutput');
			if (speakerDevice) {
				mediaDevices.speakerDeviceId = speakerDevice.deviceId;
				this.ensureAudioVideoDevicesAreValid();
			} else {
				// In case devices microphone and speaker does not share groupId - try to match on name instead
				const speakerDevice = mediaDevices.devices.find(o => o.kund == 'audiooutput' && o.label == device.label);
				if (speakerDevice) {
					mediaDevices.speakerDeviceId = speakerDevice.deviceId;
					this.ensureAudioVideoDevicesAreValid();
				}
			}
		},
		softphoneVideoDeviceIdChanged(id) { mediaDevices.videoDeviceId = id; },
		myPhoneChanged(phoneId) {
			if (phoneId == s.myPhoneId) { return; } // no change
			if (s.myPhoneId == 'SOFTPHONE' && phoneId != 'SOFTPHONE') {
				sipClient.deregister();
				s.setMyPhoneId(phoneId);
			} else if (phoneId == 'SOFTPHONE') {
				s.setMyPhoneId(phoneId);
				sipClient.register();
			} else {
				s.setMyPhoneId(phoneId);
			}
		},
		skinChanged() {
			s.setSkin(s.skin);
		},
		presenceBackgroundChanged() {
			s.setPresenceBackground(s.presenceBackground);
		},
		showStatusPanelChanged() {
			s.setShowStatusPanel(s.showStatusPanel);
		},
		autolaunchChanged() {
			if (s.isEmbedded) {
				axios.get(`${s.localUrl}/autolaunch/${s.autolaunch?'on':'off'}`, { timeout: 5000 })
					.then(res => {
						if (res.data.success) {
							s.setAutolaunch(s.autolaunch);
						} else {
							s.autolaunch = !s.autolaunch;
							EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.autolaunchFailedHeader'), message: i18n.t('settings.common.autolaunchFailedMessage') });
						}
					})
					.catch(err => {
						s.autolaunch = !s.autolaunch;
						EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.autolaunchFailedHeader'), message: i18n.t('settings.common.autolaunchFailedMessage') });
					});
			}
		},
		autolaunchHiddenChanged() {
			s.setAutolaunchHidden(s.autolaunchHidden);
		},
		queueCriticalSoundChanged() {
			s.setQueueCriticalSound(s.queueCriticalSound);
		},
		dashboardColumnWidthChanged() {
			s.setDashboardColumnWidth(s.dashboardColumnWidth);
		},
		kuandoChanged() {
			kuando.save();
		},
		integrationSendkeysChanged() {
			this.integrationDirty = true;
			if (this.integration.sendkeys.when == 'NEVER') {
				this.integration.sendkeys.delay = 0;
				this.integration.sendkeys.focusProcess = '';
				this.integration.sendkeys.maximizeProcess = false;
				this.integration.sendkeys.useAutoit = false;
				this.integration.sendkeys.keys = '';
				this.integration.sendkeys.requireArgs = false;
			}
		},
		integrationSendKeysTest() {
			integration.sendkeys(null, this.integration.sendkeys);
		},
		integrationBrowserChanged() {
			this.integrationDirty = true;
			if (this.integration.browser.when == 'NEVER') {
				this.integration.browser.website = '';
				this.integration.browser.actions = [];
				this.integration.browser.requireArgs = false;
			}
		},
		integrationBrowserTest() {
			integration.browser(null, this.integration.browser);
		},
		integrationRunChanged() {
			this.integrationDirty = true;
			if (this.integration.run.when == 'NEVER') {
				this.integration.run.delay = 0;
				this.integration.run.command = '';
				this.integration.run.requireArgs = false;
			}
		},
		integrationRunTest() {
			integration.run(null, this.integration.run);
		},
		integrationCopyToClipboardWhenChanged() {
			this.integrationDirty = true;
			if (this.integration.copyToClipboard.when == 'NEVER') {
				this.integration.copyToClipboard.what = '';
				this.integration.copyToClipboard.requireArgs = false;
			}
		},
		integrationCopyToClipboardTest() {
			integration.clipboard(null, this.integration.copyToClipboard);
		},
		revertIntegration() {
			this.integration = u.extendObject({}, s.integration);
			this.integrationDirty = false;
		},
		saveIntegration() {
			s.integration = this.integration;
			s.saveIntegration();
			this.integrationDirty = false;
		},
		revertGlobalShortcuts() {
			this.globalShortcuts = u.extendObject({}, s.globalShortcuts);
			this.globalShortcutsDirty = false;
		},
		saveGlobalShortcuts() {
			s.globalShortcuts = this.globalShortcuts;
			s.saveGlobalShortcuts();
			this.globalShortcutsDirty = false;
		},
		gsToggle(key, property) {
			this.globalShortcuts[key][property] = !this.globalShortcuts[key][property];
			this.globalShortcutsDirty = true;
		},
		phoneTypeToIcon(phone) {
			if (phone.type == 'SIP') { return '464-phone-old'; }
			if (phone.type == 'MVNO') { return '166-mobile-phone'; }
			if (phone.type == 'EXTERNAL') { return '417-globe'; }
		},
		validatePasswordComplexity(password) {
			if (typeof password === 'undefined' || password == null || typeof password !== 'string') { return false; }
			if (password.length < 8) { return false; }
			if (RegExp(/[a-zæøå]+/).exec(password) == null) { return false; }
			if (RegExp(/[A-ZÆØÅ]+/).exec(password) == null) { return false; }
			return RegExp(/\d/).exec(password) != null;
		},
		async show() {
			this.isSaving = false;
			this.password = '';
			this.pincode = '';
			this.pincodePlaceholder = '';
			this.passwordPlaceholder = '';
			this.employee = await employees.getMe();
			if (!this.employee) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
			const res = await s.http.get(`/pbx/extension/${this.employee.primaryExtension._id}?includePhones=true`);
			this.extension = res.data;
			this.globalShortcuts = u.extendObject({}, s.globalShortcuts);
			this.globalShortcutsDirty = false;
			this.integration = u.extendObject({}, s.integration);
			this.integrationDirty = false;
			this.myPhoneId = s.myPhoneId;
			this.ensureAudioVideoDevicesAreValid();

			this.myPhones = [
				{ value: null, text: i18n.t('settings.phone.noPhone') },
				{ value: 'SOFTPHONE', text: i18n.t('settings.phone.softphone') }
			];
			this.employee.primaryExtension.phones.forEach(phone => {
				if (phone.modelId != '60ae09e685ee72fc47faf5b1') {
					let label = `${s.cutNationalPrefix(phone.name)} (${phone.model || 'mobil'})`;
					if (phone?.name.match(/^[a-fA-F0-9]{12}$/))  {
						label = phone.model;
					} else if (!phone.name) {
						label = phone.model || 'mobil';
					}
					this.myPhones.push({
						value: phone._id,
						text: label
					});
				}
			});

			this.$bvModal.show(this.modalId);
			this.visible = true;
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
		},
		savePassword() {
			if (confirm(i18n.t('settings.common.changePasswordWarning'))) {
				this.isSaving = true;
				s.http.post(`/employee/${this.employee._id}`, { password: this.password })
					.then(() => {
						this.password = '';
						this.passwordPlaceholder = i18n.t('settings.common.passwordSaved');
						this.isSaving = false;
					})
					.catch(() => {
						this.isSaving = false;
						this.passwordPlaceholder = '';
						EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.passwordFailedHeader'), message: i18n.t('settings.common.passwordFailedMessage') });
					});
			}
		},
		savePincode() {
			this.isSaving = true;
			s.http.post(`/employee/${this.employee._id}`, { pinCode: this.pincode })
				.then(() => {
					this.pincode = '';
					this.pincodePlaceholder = i18n.t('settings.common.passwordSaved');
					this.isSaving = false;
				})
				.catch(() => {
					this.isSaving = false;
					this.pincodePlaceholder = '';
					EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.pinFailedHeader'), message: i18n.t('settings.common.pinFailedMessage') });
				});
		},
		callWaitingChanged() {
			s.http.post(`/pbx/extension/${this.extension._id}`, { callWaiting: this.extension.callWaiting })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.callWaitingFailedHeader'), message: i18n.t('settings.common.callWaitingFailedMessage') }); });
		},
		blockSecretChanged() {
			s.http.post(`/pbx/extension/${this.extension._id}`, { blockSecret: this.extension.blockSecret })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.blockSecretFailedHeader'), message: i18n.t('settings.common.blockSecretFailedMessage') }); });
		},
		mobileDelayChanged() {
			s.http.post(`/pbx/extension/${this.extension._id}`, { mobileDelay: this.extension.mobileDelay })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.mobileDelayFailedHeader'), message: i18n.t('settings.common.mobileDelayFailedMessage') }); });
		},
		mobileUseOwnNumberChanged() {
			s.http.post(`/pbx/extension/${this.extension._id}`, { mobileUseOwnNumber: this.extension.mobileUseOwnNumber })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.common.mobileUseOwnNumberFailedHeader'), message: i18n.t('settings.common.mobileUseOwnNumberFailedMessage') }); });
		},
		dndChanged(phone) {
			let args = '';
			switch (phone.type) {
				case 'SIP': args = `?sip=${phone._id}&state=${phone.dnd}`; break;
				case 'MVNO': args = `?mvno=${phone._id}&state=${phone.dnd}`; break;
				case 'EXTERNAL': args = `?external=${phone._id}&state=${phone.dnd}`; break;
			}
			s.http.get(`/pbx/extension/${this.extension._id}/dnd${args}`)
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.dnd.updateFailedHeader'), message: i18n.t('settings.dnd.updateFailedMessage') }); });
		},
		async ensureAudioVideoDevicesAreValid() {
			if (!this.visible) { return; }
			await mediaDevices.ready();
			this.microphoneDeviceId = mediaDevices.microphoneDeviceId;
			this.speakerDeviceId = mediaDevices.speakerDeviceId;
			this.videoDeviceId = mediaDevices.videoDeviceId;
			this.ringerDeviceId = mediaDevices.ringerDeviceId;
			this.ringerVolume = mediaDevices.ringerVolume;
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
		EventBus.$on('MediaDeviceChange', this.ensureAudioVideoDevicesAreValid.bind(this));
		this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
			if (modalId == this.modalId) {
				this.visible = false;
			}
    	});
		this.ringtones.push({ value: null, text: i18n.t('settings.ringtoneDoNotUse') });
		sipClient.ringtones.forEach(tone => { this.ringtones.push({ value: tone.id, text: tone.name }); });
	}
};
</script>

<style scoped>
	.version {
		position: absolute;
		left: 10px;
		bottom: 15px;
		z-index: 101;
		font-size: var(--font-size-small);
		font-weight: 200;
	}
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
	.settings-modal > .modal-body {
		padding: 0 !important;
	}
	.selectable {
		-webkit-user-select: all !important;
		user-select: all !important;
	}
	.help {
		font-size: 11px;
		color: var(--pn-black-3);
		margin-top: -10px;
		margin-bottom: 10px;
	}
	.section-header {
		font-weight: 300;
		letter-spacing: 2px;
		font-size: 13px;
		text-transform: uppercase;
		background: var(--bg-color-dashboard-header);
		color: var(--txt-color-dashboard-header);
	}
	.section-header > div {
		padding-top: 10px;
	}
	.list-item {
		height: 34px;
		background-color: var(--bg-color-3);
		border: var(--list-item-border);
	}
	.list-item > div {
		margin-right: 20px;
	}
	.list-item > div > div {
		display: flex;
	}
	.help-table {
		margin-bottom: 0;
	}
	.help-table > table {
		margin-bottom: 0;
	}
	.status-item {
		height: 25px;
		background-color: var(--bg-color-3);
		border: var(--list-item-border);
	}
	.listgroup {
		font-size: 13px;
		margin-bottom: 15px;
	}
	.icon {
		margin-top: -2px;
		margin-right: 3px;
	}
	.select {
		width: 250px;
	}
</style>
<style>
	TD + TD {
		-webkit-user-select: all !important;
		user-select: all !important;
	}
</style>