/**
 * Module for communicating with Jabra headsets
 * - will use Jabra browser plugin if available and not embedded
 * - will use localhost:61337 api if embedded
 */

import s from '../../settings';
import axios from 'axios';
import EventBus from '../../data/EventBus';
//import * as jabraBrowser from '@gnaudio/jabra-browser-integration';
import * as jabraSDK from '@gnaudio/jabra-js';
import BindCache from '../BindCache';
// import browserDetect from './browserdetect';
import mediaDevices from '../../data/mediaDevices';
import i18n from '../i18n';
import logger from '../../data/logger';
import sipClient from '../../data/sipClient';

// axios.get(s.localUrl + '/get?client=MAIN&timeout=' + timeout, { timeout: 40000 })

class Jabra {
	constructor() {
		logger.debug('Jabra: Constructor');
		this.use = false;
		this.useDeviceId = null;
		this.useDeviceName = null;
		this.jabraBrowserInitialized = false;
		this.bindCache = new BindCache(this);
		this.initErrorCount = 0;
		this.skipSpecificNextHeadsetEvent = null;

		EventBus.$on('Jabra:PresentWebHid', this.bindCache.bind('PresentWebHid', this.PresentWebHid));
		EventBus.$on('MediaDeviceChange', device => {
			if (device.kind == 'audioinput') {
				const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
				if (device && device.label.toLowerCase().includes('jabra')) {
					this.init();
				} else {
					this.use = false;
					this.useDeviceId = null;
					this.useDeviceName = null;
				}
			}
		});
		EventBus.$on('MediaDeviceSelected', data => {
			if (data.type == 'audioinput') {
				const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
				if (device && device.label.toLowerCase().includes('jabra')) {
					this.init();
				} else {
					this.use = false;
					this.useDeviceId = null;
					this.useDeviceName = null;
				}
			}
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'jabra', value: this.use });
			if (this.use) {
				EventBus.$emit('StatusReport', { key: 'jabraDevice', value: this.useDeviceName });
			}
		});

		logger.registerGlobal('jabra', this);
	}

	// #region Init

	/**
	 * Setup what needs to be setup to initialize the headset device
	 */
	async init() {
		logger.info('Jabra: Initializing');
		this.use = false;
		this.callControl = null;
		this.gotLock = false;
		await mediaDevices.ready();
		if (mediaDevices.microphoneDeviceId == null || mediaDevices.microphoneDeviceId == 'default') {
			// No device specified
			logger.info('Jabra: No device specified - initialization cancelled');
			return;
		}

		const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
		if (device && device.label.toLowerCase().includes('jabra')) {
			await this.initBrowser(device);
		} else {
			logger.info('Jabra: Selected audio device is not from Jabra - initalization cancelled');
		}
	}

	/**
	 * Initialize for browser based usage
	 * @param {Object} device Audio device we want to control
	 * @return {Promise}
	 */
	async initBrowser(device) {
		logger.info(`Jabra: InitBrowser: Initializing - looking for ${device.label} with ID ${device.productId}`); // eslint-disable-line
		if (!this.jabraBrowserInitialized) {
			this.jabraApi = await jabraSDK.init({
				partnerKey: '0f77-948c163b-67b8-4014-8271-de2220f89736',
				appId: 'dk_telecomx_communicator',
				appName: 'Communicator',
				transport: jabraSDK.RequestedBrowserTransport.CHROME_EXTENSION_WITH_WEB_HID_FALLBACK//,
				// tracking: 'track-all',
				// logger: {
				// 	write(logEvent) {
				// 		logger.debug(`Jabra: SDK: ${logEvent.level}: ${logEvent.message}`);
				// 	}
				// }
			});
			if (this.jabraApi.transportContext == jabraSDK.TransportContext.CHROME_EXTENSION) {
				logger.info('Jabra: InitBrowser: Using Chrome extension for transport');
			} else {
				logger.info('Jabra: InitBrowser: Using WEB-HID for transport');
			}
		}
		this.jabraBrowserInitialized = true;

		const hwDevice = await this.browserLookupDevice(device.productId);
		if (!hwDevice && this.jabraApi.transportContext == jabraSDK.TransportContext.WEB_HID && !s.isEmbedded) {
			// Fallback to WEB HID and no headset found - ask for permission
			logger.info('Jabra: InitBrowser: Fallback to WEB HID and no headset found - asking for permission to access HID device');
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('jabra.webhidHeader'), message: i18n.t('jabra.webhidMessage'), ok: i18n.t('jabra.showDialog'), emit: 'Jabra:PresentWebHid', emitData: device });
		} else {
			await this.initBrowser2(device, hwDevice);
		}
	}

	/**
	 * Finish browser based initialization
	 * @param {Object} device Audio device we want to control
	 * @param {Object} hwDevice Jabra device we want to control
	 * @returns {Promise}
	 */
	async initBrowser2(device, hwDevice) {
		if (hwDevice) {
			logger.info(`Jabra: InitBrowser: Found HW device as ${hwDevice.name} with ID ${hwDevice.browserLabel}`);
			const ccf = new jabraSDK.CallControlFactory(this.jabraApi);
			if (!ccf.supportsCallControl(hwDevice)) {
				logger.warning('Jabra:initBrowser: Device does not support call control');
				EventBus.$emit('CommonErrorModal', { header: i18n.t('jabra.webhidHeader'), message: i18n.t('jabra.deviceDoesNotSupportCallControl') });
				return;
			}
			this.callControl = await ccf.createCallControl(hwDevice);
			if (!this.callControl) {
				logger.warning('Jabra: InitBrowser: Device does not support call control');
				EventBus.$emit('CommonErrorModal', { header: i18n.t('jabra.webhidHeader'), message: i18n.t('jabra.deviceDoesNotSupportCallControl') });
				return;
			}
			this.use = true;
			this.useDeviceId = hwDevice.browserLabel;
			this.useDeviceName = hwDevice.name;
			this.initErrorCount = 0;

			const gotLock = await this.callControl.takeCallLock();
			if (gotLock) {
				try { this.callControl.ring(false); }
				catch(err) {
					logger.warning(`Jabra: Failed to set ring to false - ${err.message}`);
				}
				this.callControl.offHook(false);
				this.callControl.releaseCallLock();
			}
		} else {
			this.initErrorCount++;
			if (this.initErrorCount < 10) {
				await s.sleep(2000);
				this.init();
			} else {
				logger.warning(`Jabra: InitBrowser: Did not find device ${device.label}`);
				EventBus.$emit('CommonErrorModal', { header: i18n.t('jabra.errorHeader'), message: i18n.t('jabra.errorInitFailedDeviceNotFound').replace('%%name%%', device.label) });
			}
		}
	}

	/**
	 * Invoked by initBrowser when the device was not found and we are running in HID mode (not jabra integration mode) and needs the user to permit access to the device
	 * @param {Object} device The audio device we wish to find the HID device for
	 * @private
	 */
	async PresentWebHid(device) {
		logger.info('Jabra: Presenting WEB HID paring dialog');
		await jabraSDK.webHidPairing();
		const hwDevice = await this.browserLookupDevice(device.productId);
		this.initBrowser2(device, hwDevice);
	}

	/**
	 * Lookup the Jabra device we wish to control and return it
	 * @param {String} id Id of device to lookup
	 * @returns {Promise<Object>} Jabra device
	 */
	async browserLookupDevice(id) {
		logger.info('Jabra: LookupDevice: Looking for device: ' + id);
		const started = new Date();
		let hwDevice = null, deviceList = [];
		const subscription = this.jabraApi.deviceList.subscribe(devices => {
			// console.log(`Got devices: ${devices.length} - looking for ${id}`);
			if (!id && devices.length > 0) {
				hwDevice = devices[0];
			} else {
				hwDevice = devices.find(o => o.browserLabel == id);
			}
			deviceList = devices;
		});
		while (!hwDevice && new Date() - started < 5000) {
			await s.sleep(250);
		}
		if (hwDevice && hwDevice.type == jabraSDK.DeviceType.DONGLE) {
			while (deviceList.find(o => o.type == jabraSDK.DeviceType.HEADSET && o.name.toLowerCase().includes('evolve') && o.name.match(/(65|75|85)/) != null) == null && new Date() - started < 5000) {
				await s.sleep(250);
			}
		}
		subscription.unsubscribe();

		logger.debug('Jabra: Connected devices:');
		const ccf = new jabraSDK.CallControlFactory(this.jabraApi);
		deviceList.forEach(dev => {
			const cc = ccf.supportsCallControl(dev);
			logger.debug(`Jabra: Found device: ${dev.name} of type ${dev.type} (${jabraSDK.DeviceType[dev.type]}) - ${dev.browserLabel} - CallControl=${cc}`);
			//console.dir(dev);
		});

		if (hwDevice && hwDevice.type == jabraSDK.DeviceType.DONGLE) {
			// Device type is a USB bluetooth dongle, looking for the headset behind the dongle
			logger.info('Jabra: Selected device is a USB bluetooth dongle - now looking for the headset behind the dongle');
			hwDevice = null;
			// Lookup for Evolve device of type headset with version 65, 75, 85 (because they are all bluetooth devices)
			let hsDevice = deviceList.find(o => o.type == jabraSDK.DeviceType.HEADSET && o.name.toLowerCase().includes('evolve') && o.name.match(/(65|75|85)/) != null);
			if (!hsDevice) { hsDevice = deviceList.find(o => o.type == jabraSDK.DeviceType.HEADSET); } // Just find any headset device
			if (hsDevice) {
				hwDevice = hsDevice;
				logger.info(`Jabra: Selected hedset behind USB dongle: ${hsDevice.name}`);
			}
		}
		return hwDevice;
	}

	// #endregion

	deviceNameToJabraName(a) {
		let from = a.toLowerCase().indexOf('jabra');
		let to = a.toLowerCase().indexOf(')');
		if (from != -1 && to != -1) { a = a.substring(from, to); }
		else if (from != -1) { a = a.substr(from); }
		return a;
	}

	// #region Event handling

	/**
	 * Start listening to headset event - can only be invoked when headset is ringing or off-hook
	 * @private
	 */
	startListenToEvents() {
		if (!this.signalsSubscription) {
			this.skipSpecificNextHeadsetEvent = null; // Just to be sure
			logger.debug('Jabra: Starting to listening to headset events - because headset is ringing or off-hook');
			this.signalsSubscription = this.callControl.deviceSignals.subscribe(signal => {
				logger.debug(`Jabra: Event: ${jabraSDK.SignalType[signal.type]} - val=${signal.value}`);
				switch (signal.type) {
					case jabraSDK.SignalType.HOOK_SWITCH:
						if (signal.value) {
							/** If we send a callControl event to the Jabra SDK for onHook(false), we get that event here, as if the event was triggered by the headset.
							 * So far, we've only seen that it happens for this specific event, and not for onHook(true) etc. We then set this flag, so that we know we should ignore
							 * the given event.
							 */
							if (this.skipSpecificNextHeadsetEvent === 'OFF_HOOK') {
								logger.info('Jabra: User pressed answer - we are skipping the event, because it was triggered by ourselves');
								this.skipSpecificNextHeadsetEvent = null;
								return;
							}
							logger.info('Jabra: User pressed answer');
							sipClient.headsetAnswerEvent();
						} else {
							logger.info('Jabra: User pressed hangup');
							sipClient.headsetHangupEvent();
						}
						break;
					case jabraSDK.SignalType.FLASH: // Resume call
						logger.info('Jabra: User pressed resume - resuming call on hold');
						sipClient.headsetAnswerEvent();
						break;
					case jabraSDK.SignalType.REJECT_CALL:
						logger.info('Jabra: User pressed reject');
						this.ringOff();
						sipClient.headsetRejectEvent();
						// if (s.isEmbedded) {
						// 	axios.get(s.localUrl + '/jabra/ringoff', { timeout: 5000 });
						// } else {
						// 	this.ringOff();
						// }
						// EventBus.$emit('Call:Reject');
						break;
						
					default:
						// If we have received another event than the one we were supposed to skip, we probably are not going to receive the event - just remove it.
						this.skipSpecificNextHeadsetEvent = null;
						break;
					
				}
				// if (signal.type == jabraSDK.SignalType.HOOK_SWITCH) { // 32
				// 	if (signal.value == true) {
				// 		logger.info('Jabra: User pressed answer');
				// 		sipClient.headsetAnswerEvent();
				// 		EventBus.$emit('Call:Answer');
				// 	} else {
				// 		logger.info('Jabra: User pressed hangup');
				// 		sipClient.headsetHangupEvent();
				// 		EventBus.$emit('Call:Hangup');
				// 	}
				// } else if (signal.type == jabraSDK.SignalType.REJECT_CALL) { // 65533
				// 	logger.info('Jabra: User pressed reject');
				// 	this.ringOff();
				// 	// if (s.isEmbedded) {
				// 	// 	axios.get(s.localUrl + '/jabra/ringoff', { timeout: 5000 });
				// 	// } else {
				// 	// 	this.ringOff();
				// 	// }
				// 	EventBus.$emit('Call:Reject');
				// }
			});
		}
	}

	/**
	 * Stop listening to events
	 * @private
	 */
	stopListenToEvents() {
		if (this.signalsSubscription) {
			logger.debug('Jabra: Stopped listening to headset events - because headset is idle');
			this.signalsSubscription.unsubscribe();
			this.signalsSubscription = null;
			this.skipSpecificNextHeadsetEvent = null; // Just to be sure
		}
	}

	// #endregion

	// #region Actions - public

	/**
	 * Take headset off-hook (ensure not ringing, open audio channels and start listening to events)
	 */
	async offHook() {
		logger.info('Jabra: Taking headset off-hook');
		// if (s.isEmbedded) {
		// 	axios.get(s.localUrl + '/jabra/offhook', { timeout: 5000 }).catch(() => { });
		// } else
		if (this.callControl) {
			if (!this.gotLock) { this.gotLock = await this.callControl.takeCallLock(); }
			if (this.gotLock) {
				try { this.callControl.ring(false); }
				catch(err) {
					logger.warning(`Jabra: Failed to set ring to false - ${err.message}`);
				}
				this.skipSpecificNextHeadsetEvent = 'OFF_HOOK';
				this.callControl.offHook(true);
				this.startListenToEvents();
			}
		}
	}

	/**
	 * Put headset on-hook (ensure not ringing, audio channels are closed and stop listening to events)
	 */
	onHook() {
		logger.info('Jabra: Putting headset on-hook');
		// if (s.isEmbedded) {
		// 	axios.get(s.localUrl + '/jabra/onhook', { timeout: 5000 }).catch(() => { });
		// } else
		if (this.callControl && this.gotLock) {
			this.callControl.offHook(false);
			try { this.callControl.ring(false); }
			catch(err) {
				logger.warning(`Jabra: Failed to set ring to false - ${err.message}`); // eslint-disable-line
			}
			this.stopListenToEvents();
			this.callControl.releaseCallLock();
			this.gotLock = false;
			logger.info('Jabra: Done putting headset on-hook - stopped listening to events and released call lock');
		} else {
			logger.info(`Jabra: Done putting headset on-hook - did not stop listening to events nor released call lock, gotLock is ${this.gotLock}`);
		}
	}

	/**
	 * Start ringing on the headset (and listening to events)
	 */
	async ringOn() {
		logger.info('Jabra: Starting ringer');
		// if (s.isEmbedded) {
		// 	axios.get(s.localUrl + '/jabra/ringon', { timeout: 5000 }).catch(() => { });
		// } else
		if (this.callControl) {
			if (!this.gotLock) { this.gotLock = await this.callControl.takeCallLock(); }
			if (this.gotLock) {
				try { this.callControl.ring(true); }
				catch(err) {
					logger.warning(`Jabra: Failed to set ring to true - ${err.message}`); // eslint-disable-line
				}
				this.startListenToEvents();
			}
		}
	}

	/**
	 * Stop ringing on the headset (and stop listening to events)
	 */
	ringOff() {
		logger.info('Jabra: Stopping ringer');
		// if (s.isEmbedded) {
		// 	axios.get(s.localUrl + '/jabra/ringoff', { timeout: 5000 }).catch(() => { });
		// } else
		if (this.callControl && this.gotLock) {
			try { this.callControl.ring(false); }
			catch(err) {
				logger.warning(`Jabra: Failed to set ring to false - ${err.message}`);
			}
			this.stopListenToEvents();
			this.callControl.releaseCallLock();
			this.gotLock = false;
		}
	}
	
	hold() {
		logger.info('Jabra: Putting headset on hold');
		if (this.callControl && this.gotLock) {
			try { this.callControl.hold(true); }
			catch(err) {
				logger.warning(`Jabra: Failed to set hold to true - ${err.message}`);
			}
		}
	}
	
	unhold() {
		logger.info('Jabra: Putting headset off hold');
		if (this.callControl && this.gotLock) {
			try { this.callControl.hold(false); }
			catch(err) {
				logger.warning(`Jabra: Failed to set hold to false - ${err.message}`);
			}
		}
	}

	// #endregion

}

// Singleton
export default new Jabra();