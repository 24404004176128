/*
 * Used for creating bindings for event listeners, that can also be un-bound again
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2020-09-04
 */

class BindCache {
	constructor(context) {
		this.context = context;
		this.cache = {};
	}

	/**
	 * Get a bound function and cache it for future use
	 * @param {String} name Name of function - for future use
	 * @param {Function} func Function to return a bound version of and to cache
	 * @returns {Function} bound version of the function
	 */
	bind(name, func) {
		if (!this.cache[name]) {
			this.cache[name] = func.bind(this.context);
		}
		return this.cache[name];
	}

	/**
	 * Unregister a bound function and return the previous cached function
	 * @param {String} name Name of function
	 * @returns {Function} The now uncached function
	 */
	unbind(name) {
		if (this.cache[name]) {
			const func = this.cache[name];
			delete this.cache[name];
			return func;
		}
	}
}

export default BindCache;
