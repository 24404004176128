<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex" v-if="queue">
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ queue.name }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ $t('queue.calls.header1') }} {{ types[callsType] }} {{ $t('queue.calls.header2') }}</div>
				</div>
			</div>
		</template>

		<b-list-group style="height:380px;overflow:scroll" v-if="calls">
			<b-list-group-item @click.prevent="showMenu($event,item)" class="call-list-item" v-for="(item,index) in calls" :key="item._id" :class="{'odd':index % 2 === 0}">
				<div style="display:flex;justify-content:center;flex-direction:column" class="dotdotdot">
					<div class="title dotdotdot">{{ item.aNumber | cutNationalPrefixAndFormat }}{{ item.aNumberName ? ' • ' + item.aNumberName : '' }}</div>
					<div v-if="item.answeredByExtensionName" style="margin-top:-3px;display:flex;font-size:var(--font-size-small);color:var(--label-otherparty-color)">
						<glyph-icon style="margin-top:-1px" icon="465-call" size="12" color="var(--label-otherparty-color)" />
						{{ item.answeredByExtensionName }}
						<span style="color:var(--label-expires-color);font-size:var(--font-size-small)">{{ item.status != 'ANSWERED' ? '&nbsp;(håndteret)' : '' }}</span>
					</div>
				</div>
				<div style="display:flex;justify-content:center;flex-direction:column;text-align:right">
					<div style="font-size:var(--font-size-secondary)">{{ $t('common.at') }} {{ item.entered | formatDate('HH:mm') }}</div>
					<div style="font-size:var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-3px">{{ item.duration | shortduration(false) }} {{ item.duration | shortduration(true) }}</div>
				</div>
			</b-list-group-item>
			<b-list-group-item disabled v-if="calls.length==0" style="text-align:center;background:#e0e0e0">{{ $t('queue.calls.noCallsFound') }}</b-list-group-item>
		</b-list-group>

		<v-contextmenu ref="CallMenu" :auto-placement="true">
			<v-contextmenu-item v-if="currentMenu" @click="call">{{ $t('queue.call') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="currentMenu" :disabled="currentMenu.answeredByExtension!=null||currentMenu.answeredByNumber!=null" @click="markAsHandled">{{ $t('queue.markAsHandled') }}</v-contextmenu-item>
		</v-contextmenu>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" size="sm" @click="close()" tabindex="110">{{ $t('common.close') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import s from '../../settings';
import sipClient from '../../data/sipClient';
import GlyphIcon from '../GlyphIcon.vue';
import i18n from '../../utils/i18n';
import employees from '../../data/employees';
import Vue from 'vue';

export default {
	props: {
		queue: Object
	},
	components: {
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			modalId: 'QueueCallsModal' + Math.random().toString(),
			types: {
				'UNANSWERED': i18n.t('queue.calls.UNANSWERED'),
				'ANSWERED': i18n.t('queue.calls.ANSWERED'),
				'NOMEMBERS': i18n.t('queue.calls.NOMEMBERS'),
				'ALLBUSY': i18n.t('queue.calls.ALLBUSY'),
				'TIMEOUT': i18n.t('queue.calls.TIMEOUT'),
				'CANCELLED': i18n.t('queue.calls.CANCELLED')
			},
			calls: null,
			callsType: null,
			currentMenu: null
			// filter: ''
		};
	},
	methods: {
		load() {
			if (!this.queue || !this.callsType) { this.calls = []; return; }
			let status = this.callsType;
			if (status == 'UNANSWERED') { status = '!ANSWERED'; }
			if (status == 'ALLBUSY') { status = 'ALL_BUSY'; }
			if (status == 'NOMEMBERS') { status = 'NO_MEMBERS'; }
			s.http.get(`/pbx/queue/${this.queue._id}/calls?reverse=true&limit=100&status=${status}`)
				.then(res => {
					res.data.calls.forEach(call => {
						if (call.entered) { call.entered = new Date(call.entered); }
						if (call.answered) { call.answered = new Date(call.answered); }
						if (call.ended) { call.ended = new Date(call.ended); }
						call.duration = Math.floor((call.ended - call.entered)/1000);
					});
					this.calls = res.data.calls;
				})
				.catch(_ => {
					//console.dir(res);
				});

		},
		show(key) {
			this.callsType = key;
			this.calls = null;
			this.load();
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.calls = null;
			this.callsType = null;
			this.$bvModal.hide(this.modalId);
		},
		showMenu(e, item) {
			this.currentMenu = item;
			setTimeout(() => { this.$refs['CallMenu'].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		call() {
			const item = this.currentMenu;
			if (s.myPhoneId == 'SOFTPHONE') {
				const target = s.cutNationalPrefix(item.aNumber);
				if (target) {
					sipClient.call(target);
					this.close();
				}
			} else {
				const target = s.cutNationalPrefix(item.aNumber);
				if (target) {
					s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
					});
					this.close();
				}
			}
		},
		async markAsHandled() {
			const item = this.currentMenu;
			if (item.answeredByExtension || item.answeredByNumber) { return; }
			const me = await employees.getMe();
			if (me) {
				const extension = me.extensions.find(o => o.primary);
				if (extension) {
					s.http.post(`/pbx/queue/${item.queue}/calls/${item._id}/handled`, { extension: extension._id })
						.then(() => {
							Vue.set(item, 'answeredByExtension', extension._id);
							Vue.set(item, 'answeredByExtensionName', me.name);
						})
						.catch(err => {
							//console.log(err.message);
							EventBus.$emit('CommonErrorModal', { header: i18n.t('queue.markAsHandled'), message: i18n.t('queue.markAsHandledFailed') });
						});
				}
			}
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.title {
		font-size: var(--font-size-primary);
		color: var(--txt-color-primary);
	}
	.call-list-item {
		display: grid;
		grid-template-columns: 1fr auto;
		text-align: left;
		border-bottom: var(--divider-line-2);
		color: var(--txt-color-primary);
		padding-left: 10px;
		padding-right: 10px;
		background-color: var(--bg-color-secondary);
		cursor: pointer;
		/* cursor: url(/assets/images/callcursor.png) 16 16, auto; */
	}
	.call-list-item.odd {
		background-color: var(--bg-color-3);
	}
	.call-list-item:hover {
		background-color: var(--bg-color-selected);
	}
</style>
