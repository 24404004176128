import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	'en': {
		'common': {
			'error': {
				'noPhoneHeader': 'Phone selection',
				'noPhoneMessage': 'You have not choosen which phone to use!',
				'callFailedHeader': 'Call failed',
				'callFailedMessage': 'The call failed due to an unexpected error',
				'noUserProfileHeader': 'Data error',
				'noUserProfileMessage': 'Unable to access your user profile. Please ask your administrator for access.',
				'transferFailedHeader': 'Transfer failed',
				'transferFailedMessage': 'Call transfer failed, probably because the call has already ended or because you do not own the call anymore.',
				'pickupFailedHeader': 'Transfer failed',
				'pickupFailedMessage': 'Call pick failed, probably because the call has already ended or because you do not have permission to pick it up.',
				'stealFailedHeader': 'Transfer failed',
				'stealFailedMessage': 'Call takeover failed, probably because the call has already ended or because you do not have permission to take it over.',
				'spyFailedHeader': 'Listen in failed',
				'spyFailedMessage': 'It was not possible to listen in on the call, probably because the call has already ended or because you do not have permission to do so.',
				'setPresenceFailedHeader': 'Set presence failed',
				'setPresenceFailedMessage': 'It is currently not possible to set presence for %%name%%.',
				'commErrorHeader': 'Disconnected from server',
				'commErrorMessage': 'The requested action could not be performed, as you are currently disconnected from the server.',
				'inviteFailedHeader': 'Add to call failed',
				'inviteFailedMessage': 'Failed to add %%number%% to the call.'
			},
			'month-long': {
				0: 'January',
				1: 'February',
				2: 'March',
				3: 'April',
				4: 'May',
				5: 'June',
				6: 'July',
				7: 'August',
				8: 'September',
				9: 'October',
				10: 'November',
				11: 'December'
			},
			'month-short': {
				0: 'Jan',
				1: 'Feb',
				2: 'Mar',
				3: 'Apr',
				4: 'May',
				5: 'Jun',
				6: 'Jul',
				7: 'Aug',
				8: 'Sep',
				9: 'Oct',
				10: 'Nov',
				11: 'Dec'
			},
			'day-long': {
				0: 'Sunday',
				1: 'Monday',
				2: 'Tuesday',
				3: 'Wednesday',
				4: 'Thursday',
				5: 'Friday',
				6: 'Saturday'
			},
			'day-short': {
				0: 'Sun',
				1: 'Mon',
				2: 'Tue',
				3: 'Wed',
				4: 'Thu',
				5: 'Fri',
				6: 'Sat'
			},
			'hours': 'Hours',
			'hour': 'Hour',
			'minute': 'minute',
			'minutes': 'minutes',
			'second': 'second',
			'seconds': 'seconds',
			'minutes-short': 'min.',
			'seconds-short': 'sec.',
			'create': 'Create',
			'save': 'Save',
			'delete': 'Delete',
			'cancel': 'Cancel',
			'add': 'Add',
			'close': 'Close',
			'presence': {
				'AVAILABLE': 'Available',
				'BUSY': 'Busy',
				'OUT_OF_OFFICE': 'Out of office',
				'OFF_WORK': 'Off work',
				'HOLIDAY': 'Holiday'
			},
			'at': 'at',
			'noneSelected': 'None selected',
			'noneAvailable': 'None available',
			'noneFound': 'None found!',
			'filter': 'Filter',
			'genericErrorHeader': 'An unexpected error occured',
			'download': 'Download',
			'deactivate': 'Deactivate',
			'activate': 'Activate',
			'update': 'Update',
			'yes': 'Yes',
			'no': 'No',
			'secret': 'Secret number',
			'call': 'Call'
		},
		'cdr': {
			'title': 'Calls',
			'from': 'From',
			'to': 'To',
			'noneFound': 'None found!',
			'loading': 'Loading...',
			'call': 'Call',
			'applyFilter': 'Filter on this',
			'playRecording': 'Play recording',
			'types': {
				'all': 'Any',
				'in': 'Inbound',
				'out': 'Outbound',
				'unanswered': 'Missed',
				'failed': 'Failed'
			},
			'recordingHeader': 'Recording from',
			'loadError': 'An unexpected error occured during loading of your calls',
			'filter': 'Filter',
			'type': 'Type',
			'unlistedNumber': 'Hidden number',
			'callEndReason': {
				'CANCEL': 'Cancelled',
				'HANGUP': 'Completed',
				'BUSY': 'Busy',
				'UNREACHABLE': 'Unreachable',
				'ERROR': 'Failed',
				'BLOCKED': 'Blocked',
				'NOT_FOUND': 'Not found'
			}
		},
		'datepicker': {
			'labelPrevDecade': 'Previous decade',
			'labelPrevYear': 'Previous year',
			'labelPrevMonth': 'Previous month',
			'labelCurrentMonth': 'This month',
			'labelNextMonth': 'Next month',
			'labelNextYear': 'Next year',
			'labelNextDecade': 'Next decade',
			'labelToday': 'Todat',
			'labelSelected': 'Selected date',
			'labelNoDateSelected': 'No date selected',
			'labelCalendar': 'Calendar',
			'labelNav': 'Calendar navigation',
			'labelHelp': 'Use arrow keys to navigate through the dates',
			'labelHours': 'Hours',
			'labelMinutes': 'Minutes',
			'labelSeconds': 'Seconds',
			'labelIncrement': 'Increment',
			'labelDecrement': 'Decrement',
			'labelNoTimeSelected': 'Not selected',
			'labelCloseButton': 'Ok'
		},
		'actionbar': {
			'newLayoutName': 'New layout name',
			'layoutName': 'Layout name',
			'deleteHeader': 'Layout',
			'deleteMessage': 'Do you wish to delete the current layout',
			'availability': 'Availability',
			'personal': 'Personal',
			'shared': 'Shared',
			'forMembersOf': 'Only members of',
			'forMembersOfAny': '<not used>'
		},
		'layout': {
			'addPanel': 'Add panel',
			'type': {
				'MYCALLS': 'My calls',
				'QUEUE': 'Queue',
				'EXTENSIONS': 'Coworkers',
				'WEB': 'Web content',
				'SWITCHVAR': 'Variables',
				'SHORTCUTS': 'Shortcuts'
			},
			'moveHere': 'More here',
			'createDeniedHeader': 'Layout',
			'createDeniedMessage': 'You do not have permission to create layouts',
			'editDeniedHeader': 'Layout',
			'editDeniedMessage': 'You do not have permission to edit layouts'
		},
		'employee': {
			'hiddenNumber': 'Hidden number',
			'until': 'until',
			'restOfDay': 'the rest of the day',
			'untilTomorrow': 'until tomorrow',
			'until2': 'until',
			'onThe': 'the',
			'call': 'Call',
			'transfer': 'Transfer',
			'toPersonalQueue': 'to personal queue',
			'toHere': '',
			'addToCall': 'Add to current call',
			'notifyWhenAvailable': 'Notify when available',
			'pickupRingingCall': 'Pickup ringing call',
			'pickupCurrentCall': 'Pickup current call',
			'listenIn': 'Listen in',
			'sendSms': 'Send SMS',
			'sendEmail': 'Send e-mail',
			'copyNumberToClipboard': 'Copy number to clipboard',
			'setPresence': 'Set presence',
			'quickSetPresence': 'Quick presence',
			'redirect': 'Unconditional call forward',
			'showInfo': 'Show contact details',
			'notifyAvailableHeader': 'is now available',
			'notifyAvailableMessage': 'Click to dial',
			'emailMessageWithName': 'Please call %%name%%, who wish to get in touch with you, on %%number%%.',
			'emailMessage': 'Please call %%number%% who wish to get in touch with you.',
			'emailSubject': 'Phone message'
		},
		'extensions': {
			'panelName': 'Panel name',
			'selectedEmployees': 'Selected employees',
			'availableEmployees': 'Available employees'
		},
		'mycalls': {
			'header': 'My calls',
			'maxShownCalls': 'Max. shown calls',
			'maxShownCallsDescription': 'number of shown before scroll',
			'callVariableName': 'Call variable name',
			'callVariableLabel': 'Label',
			'callVariableDescription': 'can show the value of a call variable that belongs to the call, e.g. a customer number',
			'noCallsFound': 'No calls found',
			'hiddenNumber': 'Hidden number',
			'recent': {
				'outbound': 'Outbound',
				'inbound': 'Inbound',
				'missed': 'Missed'
			},
			'cannotDialHiddenHeader': 'Phone',
			'cannotDialHiddenMessage': 'Cannot dial a hidden number.',
			'action': {
				'answer': 'Answer',
				'hold': 'Put on hold',
				'resume': 'Resume',
				'reject': 'Reject',
				'hangup': 'Hang up',
				'transferTo': 'Transfer to',
				'stopRecording': 'Stop recording',
				'startRecording': 'Start recording',
				'copyVariableToClipboard': 'Copy %%name%% to clipboard',
				'copyNumberToClipboard': 'Copy number to clipboard'
			},
			'recordingHeader': 'Recording',
			'recordingNotAllowed': 'You do not have permission to record calls.',
			'recordingNoCall': 'No current call found, that could be recorded.',
			'failedToAnswerCall': 'Call answer failed.',
			'failedToHoldCall': 'Put on hold failed.',
			'failedToResumeCall': 'Resume call failed.',
			'failedToHangupCall': 'Hang up failed.',
			'clickToAnswer': 'Click to answer',
			'toggleSecret': 'Hide/show my phone number',
			'recentCalls': 'Recent calls',
			'dtmf': 'Nummeric keyboard'
		},
		'queue': {
			'queue': 'Queue',
			'showSection': 'Show',
			'showCallsInQueue': 'Calls',
			'showMembers': 'Members',
			'showStatistics': 'Statistics',
			'numberOfCalls': 'Call count',
			'numberOfCallsDescription': 'max. shown before scroll',
			'numberOfMembers': 'Member count',
			'numberOfMembersDescription': 'max. shown before scroll',
			'callVariableName': 'Call variable name',
			'callVariableLabel': 'Call variable label',
			'callVariableDescription': 'can show the value of a call variable with the call, e.g. a customer number',
			'statisticsElementsHeader': 'Statistics elements',
			'queueSectionHeader': 'Calls',
			'addMemberTitle': 'Add',
			'memberSectionHeader': 'Members',
			'addExtension': 'Add coworker',
			'addExternal': 'Add external',
			'statisticsSectionHeader': 'Statistics',
			'stats': {
				'NOMEMBERS': 'NO<br>MEMBERS',
				'ALLBUSY': 'ALL<BR>BUSY',
				'TIMEOUT1': 'WAITED',
				'TIMEOUT2': 'TOO',
				'TIMEOUT3': 'LONG',
				'CANCELLED': '<br>CANCELLED',
				'ANSWERED': '<br>ANSWERED',
				'UNANSWERED': '<br>UNANSWERED',
				'TOTAL': 'TOTAL<br>CALLS',
				'AVERAGEWAITTIME': 'AVG.<br>WAIT TIME',
				'AVERAGETALKTIME': 'AVG.<br>TALK TIME',
				'TOTALTALKTIME': 'TOTAL<br>TALK TIME',
				'LONGESTWAITTIME': 'LONGEST<br>WAIT TIME',
				'LASTWAITTIME': 'LAST<br>WAIT TIME',
				'SERVICELEVEL': 'SERVICE<br>LEVEL',
				'SERVICELEVELCRITICAL1': 'SRV-LVL',
				'SERVICELEVELCRITICAL2': 'SERVICE LEVEL',
				'SERVICELEVELCRITICAL3': 'CRITICAL'
			},
			'stats2': {
				'UNANSWERED': 'Unanswered',
				'TOTAL': 'Total calls',
				'ALLBUSY': 'All busy',
				'CANCELLED': 'Cancelled',
				'LONGESTWAITTIME': 'Longest wait time',
				'AVERAGETALKTIME': 'Avg. talk time',
				'SERVICELEVEL': 'Service level',
				'ANSWERED': 'Answered',
				'NOMEMBERS': 'No members',
				'TIMEOUT': 'Waited too long',
				'AVERAGEWAITTIME': 'Avg. wait time',
				'LASTWAITTIME': 'Last wait time',
				'TOTALTALKTIME': 'Total talk time',
				'SERVICELEVELCRITICAL': 'Service level critical'
			},
			'panelDeletedHeader': 'Queue panel deleted',
			'panelDeletedMessage': 'The queue panel %%name%% has been removed, because the queue was deleted on the server.',
			'selectAQueue': 'Select a queue',
			'hiddenNumber': 'Hidden number',
			'action': {
				'answer': 'Answer',
				'copyVariableToClipboard': 'Copy %%name%% to clipboard',
				'copyNumberToClipboard': 'Copy number to clipboard',
				'memberLeave': 'Leave',
				'memberSetPriority': 'Set priority'
			},
			'failedAnswerHeader': 'Answer queue call',
			'failedAnswerMessage': 'Failed to answer the queue call. The call does not exist.',
			'externalTag': 'EXT',
			'memberPriority': 'PRI',
			'memberCalls': 'CALLS',
			'memberTime': 'TIME',
			'failedToRemoveMemberHeader': 'Remove member',
			'failedToRemoveMemberMessage': 'An error occured - %%name%% could not be removed from the queue..',
			'failedToSetPriorityHeader': 'set priority for member',
			'failedToSetPriorityMessage': 'Failed to set priority for %%name%%.',
			'addExtensionToQueueHeader': 'Add coworkers to the queue',
			'priority': 'Priority',
			'queueDoesNotAllowDynamicMembers': 'This queue does not allow dynamic members.',
			'memberMayNotJoinQueue': '%%name%% cannot join this queue.',
			'memberToJoinNotFound': 'The coworker %%name%% was not found.',
			'addMemberToQueueErrorHeader': 'Add member to queue',
			'addExternalToQueueHeader': 'Add external member to queue',
			'externalPhoneNumber': 'Phone number',
			'queueOnlyAcceptExtensions': 'The queue only permits local extensions.',
			'calls': {
				'header1': 'Newest',
				'header2': 'calls',
				'noCallsFound': 'No calls found',
				'UNANSWERED': 'unanswered',
				'ANSWERED': 'answered',
				'NOMEMBERS': 'no members',
				'ALLBUSY': 'all busy',
				'TIMEOUT': 'waited too long',
				'CANCELLED': 'cancelled'
			},
			'call': 'Call',
			'markAsHandled': 'Mark as handled',
			'markAsHandledFailed': 'Failed to mark call as handled'
		},
		'shortcuts': {
			'panelName': 'Panel name',
			'shortcuts': 'Shortcuts',
			'noShortcuts': 'No shortcuts',
			'add': 'Add shortcut',
			'name': 'Label',
			'number': 'Phonenumber'
		},
		'switchvar': {
			'panelName': 'Panel name',
			'editHeader': 'Variables & switches',
			'newVariable': 'New variable',
			'label': 'Label',
			'variableName': 'Variable name',
			'type': 'Type',
			'owner': 'Owner',
			'values': 'Values',
			'noValues': 'No values',
			'addValue': 'Add value',
			'noVariables': 'No variables',
			'add': 'Add variable',
			'switch': 'Switch',
			'variable': 'Variable',
			'personal': 'Personal',
			'shared': 'Shared',
			'system': 'System',
			'setErrorHeader': 'System error',
			'setSwitchErrorMessage': 'An unexpected error occured - the switch could not be set.',
			'setVariableErrorMessage': 'An unexpected error occured - the variable could not be set.'
		},
		'web': {
			'panelName': 'Panel name',
			'width': 'Width',
			'height': 'Height',
			'type': 'Type',
			'interval': 'Interval',
			'url': 'URL',
			'urlDescription': 'to fetch content from',
			'closeContent': 'Close content',
			'ignoreLocalCalls': 'Ignore local calls',
			'variableHelp': 'URL variables',
			'aNumber': 'A number',
			'aNumberNoPrefix': 'A number no prefix',
			'bNumber': 'B number',
			'bNumberNoPrefix': 'B number no prefix',
			'aName': 'A name',
			'bName': 'B name',
			'variable': 'Call variable',
			'tcxtoken': 'Token from TCX',
			'user': 'Communicator user',
			'aOrBNumber': 'Number when using calling and receving calls',
			'aOrBNumberNoPrefix':
				'Number when using calling and receving calls no prefix',
			'types': {
				'static': 'Static',
				'reloaded': 'Periodic reload',
				'ringing':  'Show when call rings',
				'answered': 'Show when call answered',
				'callinginorout': 'Show when calling out and when call answered'
			},
			'intervals': {
				'0': 'Not used',
				'10': '10 seconds',
				'30': '30 seconds',
				'45': '45 seconds',
				'60': '1 minute',
				'120': '2 minutes',
				'300': '5 minutes',
				'600': '10 minutes',
				'1800': '30 minutes',
				'3600': '1 hour',
				'7200': '2 hours'
			},
			'closeContents': {
				'never': 'Never',
				'onEnd': 'When call ends',
				'button': 'Button for manual closing'
			}
		},
		'employeeData': {
			'address': 'Address',
			'birthdate': 'Birthdate',
			'emailAddress': 'Email&nbsp;address',
			'localNumbers': 'Extensions',
			'localNumber': 'Extension',
			'mobile': 'Mobile',
			'direct': 'Direct',
			'until': 'until ',
			'restOfDay': 'rest of the day',
			'untilTomorrow': 'until tomorrow at',
			'until2': 'until',
			'onThe': ''
		},
		'redirectModal': {
			'header': 'Unconditional call forward for',
			'number': 'Number',
			'errorHeader': 'Unconditional call forward',
			'errorNotFound': 'Action failed, user extension not found.',
			'errorInvalid': 'Action failed, phone number invalid.',
			'errorDenied': 'Action failed, you do not have permission to set call forward for this user.',
			'errorUnknown': 'Unexpected error occured:'
		},
		'sms': {
			'header': 'Send SMS to',
			'toAnybody': 'mobile recipient',
			'phoneNumber': 'Mobile phone number',
			'message': 'Message',
			'insertCallback': 'Insert callback',
			'insertCallme': 'Insert call me',
			'send': 'Send',
			'errorHeader': 'Send SMS',
			'errorUnknown': 'An unexpected error occured:',
			'callbackMessage': 'Please call %%name%%, who wish to get in touch with you, on %%number%%.',
			'callbackMessageNoName': 'Please call %%number%%, who wish to get in touch with you.',
			'callbackMessageRaw': 'Please call NAME, who wish to get in touch with you, on NUMBER.',
			'callmeMessage': 'Call me when possible, on %%number%%. Regards %%name%%.',
			'toGroup': 'Group',
			'group': 'Group',
			'noGroups': 'There are no groups available for sending SMS messages to.'
		},
		'setPresence': {
			'header': 'Set presence for',
			'message': 'Message',
			'location': 'Where',
			'until': 'Until',
			'selectDate': 'Select date',
			'time': 'Time',
			'errorHeader': 'Set presence',
			'errorUnknown': 'An unexpected error occured:'
		},
		'phonebook': {
			'header': 'Phonebook',
			'of': 'of',
			'newContact': 'New contact',
			'types': {
				'ALL': 'All',
				'SHARED': 'Shared',
				'PERSONAL': 'Personal'
			},
			'sharing': 'Sharing',
			'type': 'Type',
			'givenName': 'Given name',
			'familyName': 'Family name',
			'company': 'Company',
			'title': 'Title',
			'department': 'Department',
			'addresses': 'Addresses',
			'street': 'Address',
			'zip': 'Zip',
			'city': 'City',
			'phoneNumbers': 'Phone numbers',
			'phoneNumber': 'Phone number',
			'emailAddresses': 'E-mail addresses',
			'emailAddress': 'E-mail address',
			'externalId': 'External id',
			'shortNumber': 'Short number',
			'notes': 'Notes',
			'sharingPersonal': 'Personal',
			'sharingShared': 'Shared',
			'typePerson': 'Person',
			'typeCompany': 'Company',
			'addressWork': 'Work',
			'addressPrivate': 'Private',
			'phoneNumberTypes': {
				'MAIN': 'Main',
				'WORK': 'Direct',
				'MOBILE': 'Mobile',
				'HOME': 'Home',
				'OTHER': 'Other'
			},
			'emailTypes': {
				'MAIN': 'Company',
				'WORK': 'Work',
				'HOME': 'Home',
				'OTHER': 'Other'
			},
			'errorCompanyNameMissing': 'Company name missing.',
			'errorGivenNameMissing': 'Given name missing.',
			'errorAddress1': 'Address no.',
			'errorAddress2': 'is missing.',
			'errorPhoneNumber1': 'Phone number no.',
			'errorPhoneNumber2': 'is invalid.',
			'errorEmail1': 'E-mail address no.',
			'errorEmail2': 'is invalid.',
			'errorListHeader': 'The following error must be fixed:',
			'errorUnknown': 'An error occured - the contact could not be updated -',
			'deleteContactHeader': 'Delete contact',
			'deleteContactMessage': 'Do you wish to delete %%name%%?',
			'showAddress': 'Show address on map'
		},
		'recordings': {
			'header': 'Recordings',
			'tip': 'To record, right click on the call in My Calls',
			'from': 'From',
			'to': 'To',
			'type': 'Type',
			'loading': 'Loading...',
			'action': {
				'call': 'Call',
				'filter': 'Filter on this',
				'play': 'Play recording'
			},
			'personal': 'Personal',
			'common': 'Common',
			'deleteQuestionHeader': 'Delete recording',
			'deleteQuestionMessage': 'Do you wish to delete the selected recording?',
			'deleteFailed': 'Deletion of the selected recording failed.',
			'loadError': 'An unexpected error occured while loading the list of recordings.',
			'hiddenNumber': 'Hidden number'
		},
		'mypresence': {
			'action': {
				'sendSms': 'Send SMS',
				'sendGroupSms': 'Send group SMS',
				'setPresence': 'Set presence',
				'quickSetPresence': 'Quick presence change',
				'busy1Hour': 'Busy for 1 hour',
				'busy2Hours': 'Busy for 2 hours',
				'busyRestOfDay': 'Busy the rest of the day',
				'offWorkRestOfDay': 'Off work the rest of the day',
				'offWorkRestOfWeek': 'Off work the rest of the week',
				'offWorkRestOfMonth': 'Off work the rest of the month',
				'numberPresentation': 'Number presentation',
				'numberPresentationWorking': 'When presence available/busy/out of office',
				'numberPresentationOffWork': 'When presence off work/holiday',
				'numberPresentationNextCall': 'For the next call',
				'unconditionalCallForward': 'Unconditional call forward',
				'endFullScreen': 'End fullscreen',
				'startFullScreen': 'Start fullscreen',
				'changeProfilePicture': 'Change profile picture',
				'layout': 'Layout',
				'layoutNew': 'Create new',
				'layoutEdit': 'Edit',
				'layoutDelete': 'Delete',
				'settings': 'Settings',
				'logout': 'Log out',
				'numberPresentationSecret': 'Secret number',
				'numberPresentationNone': 'Not in use'
			},
			'errorUploadHeader': 'Upload of profile picture',
			'errorUploadMessage': 'The selected file is not a valid image file. Try again.',
			'setPresenceErrorHeader': 'Set presence',
			'setPresenceErrorMessage': 'Failed to set your presence - an unexpected error occured.',
			'layoutDeleteHeader': 'Layout',
			'layoutDeleteMessage': 'Do you wish to delete the current layout \'%%name%%\'?',
			'logoutHeader': 'Log out',
			'logoutMessage': 'Are you sure you want to log out?',
			'numberPresentationErrorHeader': 'Number presentation',
			'numberPresentationErrorMessage1': 'Failed to set your number presentation when available/busy/out of office..',
			'numberPresentationErrorMessage2': 'Failed to set your number presentation when off work/holiday.',
			'numberPresentationErrorMessage3': 'Failed to set your number presentation for the next call.',
			'endImpersonation': 'End and restore previous session'
		},
		'topbar': {
			'buttons': {
				'dashboard': 'Overview',
				'phonebook': 'Phonebook',
				'recordings': 'Recordings',
				'voicemail': 'Voicemail',
				'calls': 'Calls',
				'secretEnable': 'Hide number',
				'secretDisable': 'Show number'
			},
			'searchtip': '. = only coworkers',
			'searchPlaceholder': 'Search / call',
			'searchCall': 'Call',
			'searchTransferTo': 'Transfer to',
			'searchInvite': 'Add %%number%% to call',
			'softphone': 'Builtin (soft)',
			'noPhoneSelected': 'None',
			'mobile': 'mobile',
			'unknown': 'Unknown',
			'unknownEmployee': 'Unknown (emp)'
		},
		'voicemail': {
			'header': 'Voicemail',
			'newMessage': 'new message',
			'newMessages': 'new messages',
			'and': 'and',
			'oldMessage': 'old message',
			'oldMessages': 'old messages',
			'type': 'Type',
			'deleteAll': 'Delete all',
			'loading': 'Loading...',
			'actions': {
				'call': 'Call',
				'filterOnThis': 'Filter on this',
				'playMessage': 'Play message'
			},
			'types': {
				'all': 'All',
				'new': 'New only',
			},
			'playInfo': 'Message received %%date%% from %%from%%',
			'deleteHeader': 'Delete message',
			'deleteMessage': 'Do you wish to delete the selected message?',
			'deleteFailed': 'Failed to delete the selected message.',
			'deleteAllHeader': 'Delete all messages',
			'deleteAllMessage': 'Do you wish to delete all messages?',
			'deleteAllFailed': 'Failed to delete all messages.',
			'loadingError': 'An unexpected error occured while loading the list of messages',
			'hiddenNumber': 'Hidden number'
		},
		'login': {
			'email': 'E-mail',
			'password': 'Password',
			'resetPassword': 'Reset password',
			'smsCode': 'SMS code',
			'authenticatorSetup': 'Register this QR key with your authenticator app and enter the one-time code below',
			'authenticatorCode': 'One-time code',
			'resetPasswordHelp': 'Enter your e-mail address and click next to reset your password.',
			'resetPasswordSendTo': 'Send to',
			'resetPasswordHelp2': 'In a moment you will receive an e-mail with a reset link.',
			'resetPasswordHelp3': 'In a moment you will receive an SMS with a reset link.',
			'remember': 'Stay logged in',
			'next': 'Next',
			'ok': 'OK',
			'sendToEmail': 'E-mail',
			'sendToSms': 'Mobile phone',
			'errorNoCommunicatorAccess': 'You do not have permission to use Communicator.',
			'errorNoExtension': 'You do not have an extension, and therefore you cannot use this application.',
			'errorNoConnection': 'Network error. Could not establish a connection to the server.',
			'errorServer': 'Server error - try again later.',
			'errorCredentials': 'E-mail and/or password is invalid.',
			'errorDisabled': 'Your account has been blocked - contact your provider.',
			'errorWrongIp': 'You are not permitted to logon from your current IP address.',
			'errorSmsCodeInvalid': 'SMS code is invalid',
			'authenticatorCodeInvalid': 'One-time code is invalid',
			'errorResetNotAvailable': 'Your account uses 2-factor authentication, and therefore you cannot reset your password. Pkease contact your provider who can assist you instead.',
			'errorResetAlreadyInProgress': 'A password reset has already been ordered. Try again in 30 minutes.',
			'loginButton': 'Login'
		},
		'statuspanel': {
			'realtime': 'Realtime',
			'authentication': 'Authentication',
			'employees': 'Coworkers',
			'layout': 'Layout',
			'phonebook': 'Phonebook',
			'appApi': 'App API',
			'sipClient': 'SIP client',
			'headset': 'Headset',
			'busylight': 'Busylight'
		},
		'settings': {
			'globalShortcuts': 'Global shortcuts',
			'globalShortcutsFailedMessage': 'Failed to initialize global shortcuts. There may be a conflict with another application.',
			'networkError': 'Network error. Unable to connect to the server.',
			'header': 'Settings for',
			'ringtoneDoNotUse': 'Do not use',
			'settings': 'Settings',
			'failedToInitialize': 'An unexpected error occured while initializing settings:',
			'common': {
				'header': 'Common',
				'callHandling': 'Call handling',
				'callWaiting': 'Call waiting',
				'blockSecret': 'Block calls with hidden number',
				'mobileDelay': 'Delay mobile call',
				'mobileDelay2': 'when calling SIP and mobile concurrently',
				'mobileDelayNone': 'No delay',
				'mobileUseOwnNumber': 'Mobile phone always presents own number',
				'security': 'Security',
				'password': 'Password',
				'pincode': 'Pincode',
				'ui': 'UI',
				'presenceBackground': 'Background color follows employee\'s presence state',
				'showStatusPanel': 'Show status bottom bar',
				'autolaunch': 'Autostart at login',
				'autolaunchHidden': 'Start minified',
				'dashboardColumnWidth': 'Column with in overview',
				'queueCriticalSound': 'Warning sound when service level is not upheld in a queue',
				'ringtoneVolumeOff': 'Off',
				'kuandoRingtone': {
					'0': 'None',
					'1': 'Adventure',
					'2': 'Funky',
					'3': 'Melodic',
					'4': 'Dream',
					'5': 'Quiet',
					'6': 'Old time',
					'7': 'Digital',
					'8': 'Energic'
				},
				'pixels': 'pixels',
				'pixelsDefault': 'pixels (default)',
				'autolaunchFailedHeader': 'Settings',
				'autolaunchFailedMessage': 'Configuration of autolaunch failed.',
				'changePasswordWarning': 'Please notice that all of your devices currently logged on to your account, will be logged out when you change your password. Do you still want to change your password?',
				'passwordSaved': 'Password updated!',
				'passwordFailedHeader': 'Update password',
				'passwordFailedMessage': 'Failed to update password. The server rejected your password.',
				'pinFailedHeader': 'Update pincode',
				'pinFailedMessage': 'Failed to update pincode. The server rejected your pin.',
				'callWaitingFailedHeader': 'Call waiting',
				'callWaitingFailedMessage': 'Failed to update call waiting.',
				'blockSecretFailedHeader': 'Block hidden number calls',
				'blockSecretFailedMessage': 'Failed to update block hidden number calls.',
				'mobileDelayFailedHeader': 'Mobile delay',
				'mobileDelayFailedMessage': 'Failed to update mobile delay.',
				'mobileUseOwnNumberFailedHeader': 'Mobile presentation',
				'mobileUseOwnNumberFailedMessage': 'Failed to update mobile number presentation.',
				'skin': 'UI theme',
				'skin_DEFAULT': 'Default',
				'skin_EARTH': 'Earth',
				'skin_TEAL': 'Teal',
				'skin_DARK': 'Night'
			},
			'phone': {
				'header': 'Phone',
				'accessBlockedMacos': 'Access to the microphone has been blocked, and audio devices cannot be configured. Remove the blocking in your browser and try again.',
				'accessBlockedWindows': 'Access to the microphone has been blocked, and audio devices cannot be configured. Remove the blocking in your browser and/or Windows control panel and try again.',
				'phone': 'Phone',
				'phone2': 'Device to use/control',
				'ringtone': 'Ringtone',
				'ringtoneExternal': 'When external caller',
				'ringtoneLocal': 'When local caller',
				'ringtonePlayOn': 'Play on',
				'ringtoneVolume': 'Volume',
				'devices': 'Audio/video devices',
				'speaker': 'Speaker',
				'microphone': 'Microphone',
				'camera': 'Camera',
				'busylight': 'Busylight',
				'busylightDriver': 'Download driver',
				'busylightEnable': 'Use',
				'busylightRingtone': 'Audio',
				'busylightVolume': 'Volume',
				'errorHeader': 'Phone',
				'noPhone': 'None',
				'softphone': 'Builtin (softphone)',
				'jabraInfo': 'Install Jabra driver and Chrome plugin for the best integration with Jabra devices.',
				'jabraChromeHost': 'Download jabra driver',
				'jabraChromePlugin': 'Install Chrome plugin'
			},
			'dnd': {
				'header': 'DND',
				'help': 'Here you can choose which of your phones that should not receive calls (Do-Not-Disturb).',
				'updateFailedHeader': 'DND',
				'updateFailedMessage': 'Failed to update DND.'
			},
			'shortcuts': {
				'header': 'Global shortcuts',
				'help': 'The shortcut keys gives direct access to select actions in the application, regardless of which app has focus.',
				'notInBrowser': 'Global shortcuts are not available, because you are using a webbrowser.',
				'cancel': 'Cancel changes',
				'save': 'Save changes',
				'answer': 'Answer call',
				'hangup': 'End call',
				'visibility': 'Show/hide window',
				'call': 'Call - the number in the clipboard',
				'search': 'Search and call'
			},
			'integration': {
				'header': 'Integration',
				'notInBrowser': 'Integration is not available, because you are using a webbrowser..',
				'doNotUse': 'Do not use',
				'key': {
					'space': 'Space',
					'tab': 'Tab',
					'shiftlock': 'Shift-lock',
					'numlock': 'Nummerisk lock',
					'scrolllock': 'Scroll lock',
					'backspace': 'Backspace',
					'delete': 'Delete',
					'insert': 'Insert',
					'return': 'Return',
					'up': 'Up',
					'down': 'Down',
					'left': 'Left',
					'right': 'Right',
					'home': 'Home',
					'end': 'End',
					'pageUp': 'Page up',
					'pageDown': 'Page down',
					'volumeUp': 'Volume up',
					'volumeDown': 'Volume down',
					'volumeMute': 'Volume mute',
					'mediaNextTrack': 'Media next',
					'mediaPreviousTrack': 'Media previous',
					'mediaStop': 'Media stop',
					'mediaPlayPause': 'Media play/pause',
					'printScreen': 'Print screen',
					'nummeric': 'Nummeric'
				},
				'when': {
					'never': 'Not',
					'ringing': 'When the phone rings',
					'answered': 'When call is answered'
				},
				'delayNone': 'None',

				'sendKeys': {
					'header': 'Send keys',
					'use': 'Use',
					'delay': 'Delay becore execution',
					'process': 'Process name to send keys to',
					'macHelp': 'Find process name with the app <i>Aktivitetsovervågning</i>',
					'winHelp': 'Find process name with the app <i>Jobliste</i>',
					'maximizeProcess': 'Maximize process window',
					'useAutoit': 'Send keys with AutoIT',
					'requireArgs': 'Only run if used variables has value',
					'keys': 'Keys to send',
					'variables': 'Variables',
					'variableName': 'call-variable-name',
					'keysHelp': 'Keys help',
					'test': 'Test integration'
				},
				'browser': {
					'header': 'Browser',
					'use': 'Use',
					'website': 'Website name',
					'website2': 'Name or RegEx',
					'type': 'Website type',
					'typeHelp': 'SPA for on ajax based website, MPA if the whole page is loaded on submit',
					'requireArgs': 'Only run if used variables has value',
					'actions': 'Actions',
					'actionOption': {
						'sleep': 'Wait for',
						'target': 'Select element',
						'click': 'Perform mouseclick',
						'focus': 'Give focus',
						'clear': 'Clear content',
						'insert': 'Insert text',
						'js': 'Run javascript',
						'scrolltop': 'Scroll to page top'
					},
					'milliseconds': 'milliseconds',
					'csssel': 'CSS-sel',
					'value': 'Text to insert',
					'variables': 'Variables',
					'variablesName': 'call-variable-name',
					'addAction': 'Add action',
					'test': 'Test integration'
				},
				'run': {
					'header': 'Run',
					'use': 'Use',
					'delay': 'Delay before execution',
					'requireArgs': 'Only run if used variables has value',
					'command': 'Program path',
					'macHelp': 'Example: "open -a /Applications/&lt;app-navn&gt;.app --args &lt;argumenter&gt;"',
					'winHelp': 'Exanoke: "C:\Program files\&lt;App-mappe&gt;\&lt;app-navn&gt;.exe &lt;argumenter&gt;"',
					'variables': 'Variables',
					'variablesName': 'call-variable-name',
					'test': 'Test integration'
				},
				'clipboard': {
					'header': 'Clipboard',
					'use': 'Use',
					'requireArgs': 'Only run if used variables has value',
					'what': 'What',
					'variables': 'Variables',
					'variablesName': 'call-variable-name',
					'test': 'Test integration'
				},
				'cancel': 'Cancel changes',
				'save': 'Save changes'
			},
			'info': {
				'header': 'Information',
				'version': 'Version',
				'systemStatus': 'System status',
				'realtime': 'Realtime',
				'authentication': 'Authentication',
				'employees': 'Coworkers',
				'units': 'items',
				'layout': 'Layout',
				'phonebook': 'Phonebook',
				'contacts': 'Contacts',
				'appApi': 'App API',
				'softphone': 'Softphone',
				'channels': 'channels',
				'notInUse': 'Not in use',
				'headset': 'Headset',
				'busylight': 'Busylight'
			}
		},
		'callDrag': {
			'transferToCalledEmployee': 'transfer the call to the selected employee',
			'transferCallToTheQueue': 'transfer the call to the queue',
			'transferFailedHeader': 'Transfer failed',
			'failedToPickupCall': 'Failed to pickup the call, because the call has ended or because of insufficient access rights.',
			'transferTheCallToTheEmployee': 'transfer the call to the selected employee',
			'transferTheCallFromEmployeeToQueue': 'transfer the call from the employee to the queue',
			'failedToPickupQueueCall': 'Failed to pickup the call from the queue, because it has ended or because of insufficent access rights.',
			'failedToMoveCallFromQueueToEmployee': 'Failed to transfer the call from the queue to the selected employee, because the call has ended or because of insufficient access rights.',
			'failedToMoveCallFromQueueToQueue': 'Failed to transfer the call to the other queue, because it has ended or because of insufficient access rights.',
			'blindTransferError': 'Failed to %%message%%, because the call has ended or because of insufficient access rights.',
			'attendedTransferError': 'Failed to transfer the call, because it has ended, or because of insufficient rights.',
			'queueNotDynamic': 'This queue does not permit dynamic joining of members.',
			'employeeNotValidForQueue': 'The coworker cannot join this queue.',
			'queueJoinOtherDenied': 'Cannot add coworker to this queue due to insufficient access rights.',
			'queueNotFound': 'This queue does not exist.',
			'employeeNotFound': 'The coworker has been deleted.',
			'employeeAlreadyMember': 'The coworker is already a member of the queue.',
			'unexpectedError': 'An unexpected error occured - code',
			'noPermissionToAddExternalToQueue': 'Cannot add external number to this queue due to insufficient access rights.',
			'externalAlreadyMember': 'The external number is already a member of the queue.',
			'addNewMemberHeader': 'Add queue member',
			'blindTransferLegNotFound': 'Failed lookup the call to transfer.'
		},
		'dashboards': {
			'coworkerPanelWithoutName': 'A coworker panel in column %%column%% is missing a name.',
			'coworkerPanelHasNoContent': 'A coworker panel in column %%column%% has no coworkers.',
			'queuePanelWithQueue': 'A queue panel i column %%column%% has no queue.',
			'webPanelHasInvalidWidth': 'A web panel in column %%column%% has an invalid width.',
			'webPanelHasInvalidHeight': 'A web panel in column %%column%% has an invalid height.',
			'webPanelWithoutName': 'A web panel in column %%column%% is missing a name.',
			'webPanelWithoutUrl': 'A web panel in column is missing a URL.',
			'shortcutsPanelWithoutName': 'A shortcuts panel in column %%column%% is misisng a name.',
			'shortcutsPanelInvalidShortcuts': 'A shortcuts panel in column %%column%% has one or more shortcuts that are missing a name or number.',
			'switchvarPanelWithoutName': 'A variables panels in column %%column%% is missing a name',
			'switchvarPanelInvalidSwitches': 'A variables panel in column %%column%% has one or more variables missing a name or label.',
			'panelHasError': 'Error in layout:',
			'unexpectedErrorHeader': 'Create new layout',
			'unexpectedErrorMessage': 'An unexpected error occured while creating a new layout. Server response:',
			'unexpectedUpdateErrorHeader': 'Update layout',
			'unexpectedUpdateErrorMessage': 'An unexpected error occured while updating the layout. Server response:',
			'deletedAndOtherSelected': ', and %%name%% has been selected in instead.',
			'deletedHeader': 'Layout deleted',
			'deletedMessage': 'has been deleted',
			'deleteFailedHeader': 'Delete layout',
			'deleteFailedMessage': 'An unexpected error occured while deleting the layout. Server response:',
			'newExtensionsTitle': 'New list',
			'newWebTitle': 'Web',
			'newSwitchvarTitle': 'Variables',
			'newShortcutsTitle': 'Shortcuts',
			'sharedEditAccessDeniedHeader': 'Editing not allowed',
			'sharedEditAccessDeniedMessage': 'This layout is a shared layout, and you do not have permission to edit it.',
			'sharedDeleteAccessDeniedHeader': 'Deletion not allowed',
			'sharedDeleteEditAccessDeniedMessage': 'This layout is a shared layout, and you do not have permission to delete it.'
		},
		'employees': {
			'errorHeader': 'Employees',
			'errorLoadingNewEmployeeMessage': 'An unexpected error occured while loading a newly created employee. Server response:',
			'errorLoadingUpdatedEmployeeMessage': 'An unexpected error occured while loading an updated employee. Server response:',
			'voicemail': 'Voicemail',
			'dnd': 'Do Not Disturb',
			'setSwitch': 'Set switch',
			'mainMenu': 'Main menu',
			'transfer': 'Call forwarding',
			'greeting': 'Greeting',
			'recordings': 'Recordings',
			'queueMenu': 'Queue menu',
			'queueJoin': 'Joining queue',
			'queueIsMember': 'Member of queue',
			'queueMemberList': 'Reading queue memberships',
			'queueLeaveAll': 'Leaving all queues',
			'queueLeave': 'Leaving queue',
			'blackWiteList': 'Black/white list',
			'pickupMenu': 'Pickup menu',
			'pickupRinging': 'Pickup ringing phone',
			'pickupRingingFrom': 'Pickup ringing phone from',
			'stealCallFrom': 'Takeover call from',
			'spyOn': 'Listen in on',
			'setPresence': 'Set presence',
			'hotdesk': 'Hotdesk',
			'moveCall': 'Move call'
		},
		'integration': {
			'errorHeader': 'Integration',
			'errorClipboardMessage': 'Clipboard integration failed to execute.',
			'errorRunMessage': 'Run app integration failed to execute.',
			'errorSendkeysMessage': 'Send keys integration failed to execute.',
			'errorBrowserMessage': 'Browser integration failed to execute.',
			'myCallsHeader': 'My calls',
			'myCallsFailedToAnswer': 'Failed to answer call.',
			'myCallsFailedHangup': 'Failed to hangup call.',
			'myCallsFailedReject': 'Failed to reject call.'
		},
		'mediaDevices': {
			'builtin': 'Built in',
			'communication': 'Communication',
			'virtual': 'Virtual',
			'default': 'Default'
		},
		'queues': {
			'errorHeader': 'Queues',
			'errorLoadingNewlyCreatedQueueMessage': 'An unexpected error occured while loading a newly created queue with id %%id%%. Server response: %%response%%',
			'errorLoadingUpdatedQueueMessage': 'An unexpected error occured while updating a queue with %%id%%. Server response: %%response%%'
		},
		'sipClient': {
			'ringtones': {
				'old': 'Old fashioned',
				'digital': 'Digital',
				'digital2': 'Digital 2',
				'marimba': 'Marimba',
				'iphoneremix': 'iPhone remix',
				'behappy': 'Be happy',
				'marimba2': 'Marimba 2',
				'guitar': 'Guitar',
				'nokia': 'Nokia',
				'christmastime': 'Christmas time'
			},
			'audioDeviceUnavailableInsteadUsing': '%%old%% is no longer available, now using %%new%%.',
			'audioDeviceChangedHeader': 'Audio device changed',
			'audioDeviceNowUsing': 'Now using %%new%%.',
			'videoDeviceUnavailableInsteadUsing': '%%old%% is no longer available, now using %%new%%.',
			'videoDeviceChangedHeader': 'Vidoe device changed',
			'videoDeviceNowUsing': 'Now using %%new%%.',
			'errorHeader': 'Phone',
			'errorWebRtcInitFailedMessage': 'Failed to initialize the builtin softphone - the server rejected to initialize it:',
			'errorBrowserHasNoWebRtc': 'Failed to initialize phone, your browser is incompatible.',
			'errorFailedToConnectToServer': 'Failed to initialize phone, could not connect to server',
			'errorFailedToConnectToServerGeneric': 'Phone is not working, failed to initialize it:',
			'errorFailedToAttachToSip': 'Failed to initialize phone, SIP is unavailable:',
			'errorUnexpectedSipError': 'An unexpected error occured: %%message%% - trying to re-establish connection.',
			'errorFailedToAuthenticate': 'Unable to connect to server: %%code%% %%reason%% - trying to re-establish connection.',
			'errorFailedToAuthenticateFatal': 'Unable to connect to server.',
			'errorRetry': 'Try again',
			'errorTerminate': 'Terminate',
			'errorSipMessageReceived': 'Message received from %%from%%: %%message%%',
			'errorSipInfoReceived': 'Info received from %%from%%: %%message%%',
			'errorSipNotifyReceived': '%%notify%% received: %%message%%',
			'errorFailedToAnswer': 'Failed to answer call:',
			'errorFailedToCall': 'An unexpected error occured - the call could not be established: %%error%%',
			'errorFailedToInitSipChannel': 'Failed to initialize SIP channel:'
		},
		'jabra': {
			'errorHeader': 'Jabra integration',
			'errorFailedToInit': 'Jabra integration failed to initialize:',
			'errorNoAccessToDevices': 'no access to Jabra devices - has Jabra Direct been installed?',
			'errorInitFailedDeviceNotFound': 'Initialization of %%name%% failed. The device was not found.',
			'webhidHeader': 'Jabra',
			'webhidMessage': 'You have choosen a Jabra device, but have not installed the jabra plugin. To control the device, you must grant permission. Click below on "Show dialog", select the device on the list, and clock "Connect".',
			'showDialog': 'Show dialog',
			'deviceDoesNotSupportCallControl': 'The selected Jabra device could not be controlled. Ensure it is turned on and connected.'
		},
		'sennheiser': {
			'errorHeader': 'Sennheiser EPOS integration',
			'errorFailedToInit': 'Sennheiser EPOS integration failed to initialize:',
			'errorNoAccessToDevices': 'no access to Sennheiser devices - has Epos Connect been installed?',
			'errorInitFailedDeviceNotFound': 'Initialization of %%name%% failed. The device was not found.',
			'webhidHeader': 'Sennheiser EPOS',
			'webhidMessage': 'You have choosen a Sennheiser EPOS device, but have not installed the Sennheiser Epos Connect app. To control the device, you must grant permission. Click below on "Show dialog", select the device on the list, and clock "Connect".',
			'showDialog': 'Show dialog',
			'deviceDoesNotSupportCallControl': 'The selected Sennheiser device could not be controlled. Ensure it is turned, connected and compatible.'
		},
		'mynotification': {
			'errorHeader': 'Notification',
			'errorMessage': 'Failed to show notification in the browser'
		},
		'app': {
			'noServerConnection': 'SERVER CONNECTION BROKEN!',
			'ringtoneEnabled': 'Ringtone has been enabled',
			'enabledAudioByClicking': 'Your browser has blocked access to playing audio. Click OK to enabled audio playback.'
		}
	},
	'da': {
		'common': {
			'error': {
				'noPhoneHeader': 'Valg af telefon',
				'noPhoneMessage': 'Du har ikke valgt hvilken telefon du vil benytte!',
				'callFailedHeader': 'Opkald mislykkedes',
				'callFailedMessage': 'Der opstod en uventet fejl',
				'noUserProfileHeader': 'Data fejl',
				'noUserProfileMessage': 'Der er ikke adgang til din brugerprofil. Kontakt din administrator for adgang.',
				'transferFailedHeader': 'Omstilling mislykkedes',
				'transferFailedMessage': 'Det var ikke muligt at omstille kaldet, enten fordi det allerede er afsluttet eller fordi det ikke længere tilhører dig.',
				'pickupFailedHeader': 'Omstilling mislykkedes',
				'pickupFailedMessage': 'Det var ikke muligt at trække kaldet, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
				'stealFailedHeader': 'Omstilling mislykkedes',
				'stealFailedMessage': 'Det var ikke muligt at overtage kaldet, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
				'spyFailedHeader': 'Medlyt mislykkedes',
				'spyFailedMessage': 'Det var ikke muligt at lytte med på kaldet, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
				'setPresenceFailedHeader': 'Sæt status mislykkedes',
				'setPresenceFailedMessage': 'Det er pt. ikke muligt at sætte status for %%name%%.',
				'commErrorHeader': 'Ingen forbindelse til serveren',
				'commErrorMessage': 'Handlingen kunne ikke udføres, da der er pt. ikke forbindelse til serveren.',
				'inviteFailedHeader': 'Tilføj til samtale mislykkedes',
				'inviteFailedMessage': 'Det var ikke muligt at invitere %%number%% til samtalen.'
			},
			'month-long': {
				0: 'Januar',
				1: 'Februar',
				2: 'Marts',
				3: 'April',
				4: 'Maj',
				5: 'Juni',
				6: 'Juli',
				7: 'August',
				8: 'September',
				9: 'Oktober',
				10: 'November',
				11: 'December'
			},
			'month-short': {
				0: 'Jan',
				1: 'Feb',
				2: 'Mar',
				3: 'Apr',
				4: 'Maj',
				5: 'Jun',
				6: 'Jul',
				7: 'Aug',
				8: 'Sep',
				9: 'Okt',
				10: 'Nov',
				11: 'Dec'
			},
			'day-long': {
				0: 'Søndag',
				1: 'Mandag',
				2: 'Tirsdag',
				3: 'Onsdag',
				4: 'Torsdag',
				5: 'Fredag',
				6: 'Lørdag'
			},
			'day-short': {
				0: 'Søn',
				1: 'Man',
				2: 'Tir',
				3: 'Ons',
				4: 'Tor',
				5: 'Fre',
				6: 'Lør'
			},
			'hours': 'Timer',
			'hour': 'Time',
			'minute': 'minut',
			'minutes': 'minutter',
			'second': 'sekund',
			'seconds': 'sekunder',
			'minutes-short': 'min.',
			'seconds-short': 'sek.',
			'create': 'Opret',
			'save': 'Gem',
			'delete': 'Slet',
			'cancel': 'Fortryd',
			'add': 'Tilføj',
			'close': 'Luk',
			'presence': {
				'AVAILABLE': 'Ledig',
				'BUSY': 'Optaget',
				'OUT_OF_OFFICE': 'Ude af huset',
				'OFF_WORK': 'Fri',
				'HOLIDAY': 'Ferie'
			},
			'at': 'kl.',
			'noneSelected': 'Ingen valgt',
			'noneAvailable': 'Ingen tilgængelige',
			'noneFound': 'Ingen fundet!',
			'filter': 'Filter',
			'genericErrorHeader': 'Der opstod en uventet fejl',
			'download': 'Download',
			'deactivate': 'Deaktiver',
			'activate': 'Aktiver',
			'update': 'Opdater',
			'yes': 'Ja',
			'no': 'Nej',
			'secret': 'Hemmeligt nummer',
			'call': 'Ring op'
		},
		'cdr': {
			'title': 'Samtaler',
			'from': 'Fra',
			'to': 'Til',
			'noneFound': 'Ingen fundet!',
			'loading': 'Indlæser...',
			'call': 'Ring op',
			'applyFilter': 'Filtrer på denne',
			'playRecording': 'Afspil optagelse',
			'types': {
				'all': 'Alle',
				'in': 'Indgående',
				'out': 'Udgående',
				'unanswered': 'Ubesvaret',
				'failed': 'Fejlet'
			},
			'recordingHeader': 'Optagelse',
			'loadError': 'Der opstod en uventet fejl under indlæsning af samtaler',
			'filter': 'Filter',
			'type': 'Type',
			'unlistedNumber': 'Hemmligt nummer',
			'callEndReason': {
				'CANCEL': 'Annulleret',
				'HANGUP': 'Afsluttet',
				'BUSY': 'Optaget',
				'UNREACHABLE': 'Utilgængelig',
				'ERROR': 'Fejlet',
				'BLOCKED': 'Blokeret',
				'NOT_FOUND': 'Findes ikke'
			}
		},
		'datepicker': {
			'labelPrevDecade': 'Forrige årti',
			'labelPrevYear': 'Forrige år',
			'labelPrevMonth': 'Forrige måned',
			'labelCurrentMonth': 'Denne måned',
			'labelNextMonth': 'Næste måned',
			'labelNextYear': 'Næste år',
			'labelNextDecade': 'Næste årti',
			'labelToday': 'I dag',
			'labelSelected': 'Valgte dato',
			'labelNoDateSelected': 'Ingen dato valgt',
			'labelCalendar': 'Kalender',
			'labelNav': 'Kalender navigation',
			'labelHelp': 'Brug piletasterne til at navigere i datoerne',
			'labelHours': 'Timer',
			'labelMinutes': 'Minutter',
			'labelSeconds': 'Sekunder',
			'labelIncrement': 'Forøg',
			'labelDecrement': 'Formindsk',
			'labelNoTimeSelected': 'Ikke valgt',
			'labelCloseButton': 'Ok'
		},
		'actionbar': {
			'newLayoutName': 'Nyt layout navn',
			'layoutName': 'Layout navn',
			'deleteHeader': 'Layout',
			'deleteMessage': 'Ønsker du at slette det aktuelle layout',
			'useLayoutInTopbar': 'Brug layout i top menu',
			'topbarPriority': 'Ja, med prioritet',
			'availability': 'Tilgængelighed',
			'personal': 'Personlig',
			'shared': 'Delt',
			'forMembersOf': 'Kun medlemmer af',
			'forMembersOfAny': '<benyt ikke>'
		},
		'layout': {
			'addPanel': 'Tilføj panel',
			'type': {
				'MYCALLS': 'Mine kald',
				'QUEUE': 'Kø',
				'EXTENSIONS': 'Kollegaer',
				'WEB': 'Web indhold',
				'SWITCHVAR': 'Variabler',
				'SHORTCUTS': 'Genveje'
			},
			'moveHere': 'Flyt hertil',
			'createDeniedHeader': 'Layout',
			'createDeniedMessage': 'Du har ikke tilladelse til at oprette layouts',
			'editDeniedHeader': 'Layout',
			'editDeniedMessage': 'Du har ikke tilladelse til at redigere layouts'
		},
		'employee': {
			'hiddenNumber': 'Skjult nummer',
			'until': 'indtil kl.',
			'restOfDay': 'resten af dagen',
			'untilTomorrow': 'indtil imorgen kl.',
			'until2': 'indtil',
			'onThe': 'd.',
			'call': 'Ring op',
			'transfer': 'Omstil',
			'toPersonalQueue': 'til personlig kø',
			'toHere': 'hertil',
			'addToCall': 'Tilføj til samtale',
			'notifyWhenAvailable': 'Giv besked når ledig',
			'pickupRingingCall': 'Træk ringende kald',
			'pickupCurrentCall': 'Træk igangværende kald',
			'listenIn': 'Lyt med',
			'sendSms': 'Send SMS',
			'sendEmail': 'Send e-mail',
			'copyNumberToClipboard': 'Kopier nummer til udklipsholder',
			'setPresence': 'Sæt status',
			'quickSetPresence': 'Hurtig status skift',
			'redirect': 'Ubetinget viderestilling',
			'showInfo': 'Vis kontaktdata',
			'notifyAvailableHeader': 'er nu ledig',
			'notifyAvailableMessage': 'Klik for at ringe op',
			'emailMessageWithName': 'Venligst ring til %%name%%, som ønsker at komme i kontakt med dig, på %%number%%.',
			'emailMessage': 'Venligst ring til %%number%% som ønsker at komme i kontakt med dig.',
			'emailSubject': 'Telefon besked'
		},
		'extensions': {
			'panelName': 'Panel navn',
			'selectedEmployees': 'Valgte kollegaer',
			'availableEmployees': 'Tilgængelige kollegaer'
		},
		'mycalls': {
			'header': 'Mine kald',
			'maxShownCalls': 'Max. viste kald',
			'maxShownCallsDescription': 'antal viste kald før scroll',
			'callVariableName': 'Kaldsvariabel navn',
			'callVariableLabel': 'Label',
			'callVariableDescription': 'kan vise værdien af en kaldsvariabel sammen med kaldet, f.eks. et indstastet kundenummer',
			'noCallsFound': 'Ingen kald fundet',
			'hiddenNumber': 'Skjult nummer',
			'recent': {
				'outbound': 'Udgående',
				'inbound': 'Indgående',
				'missed': 'Ubesvaret'
			},
			'cannotDialHiddenHeader': 'Telefon',
			'cannotDialHiddenMessage': 'Kan ikke ringe op, da nummeret er skjult.',
			'action': {
				'answer': 'Besvar',
				'hold': 'Sæt på hold',
				'resume': 'Genoptag',
				'reject': 'Afvis',
				'hangup': 'Læg på',
				'transferTo': 'Omstil til',
				'stopRecording': 'Stop optagelse',
				'startRecording': 'Start optagelse',
				'copyVariableToClipboard': 'Kopier %%name%% til udklipsholder',
				'copyNumberToClipboard': 'Kopier nummer til udklipsholder'
			},
			'recordingHeader': 'Optagelse',
			'recordingNotAllowed': 'Du har ikke tilladelse til at optage samtaler.',
			'recordingNoCall': 'Der blev ikke fundet en igangværende samtale som kan optages.',
			'failedToAnswerCall': 'Besvarelse af kaldet mislykkedes.',
			'failedToHoldCall': 'Sæt på hold mislykkedes.',
			'failedToResumeCall': 'Det lykkedes ikke at genoptage kald på hold.',
			'failedToHangupCall': 'Pålægning af kaldet mislykkedes.',
			'clickToAnswer': 'Klik for at besvare',
			'toggleSecret': 'Skjul/Vis mit telefon nummer',
			'recentCalls': 'Seneste kald',
			'dtmf': 'Nummerisk tastatur'
		},
		'queue': {
			'queue': 'Kø',
			'showSection': 'Vis',
			'showCallsInQueue': 'Kald i kø',
			'showMembers': 'Medlemmer',
			'showStatistics': 'Statistik',
			'numberOfCalls': 'Antal kald',
			'numberOfCallsDescription': 'max. viste før scroll',
			'numberOfMembers': 'Antal medlemmer',
			'numberOfMembersDescription': 'max. viste før scroll',
			'callVariableName': 'Kaldsvariabel navn',
			'callVariableLabel': 'Kaldsvariabel Label',
			'callVariableDescription': 'kan vise værdien af en kaldsvariabel sammen med kaldet, f.eks. et indstastet kundenummer',
			'statisticsElementsHeader': 'Statistik elementer',
			'queueSectionHeader': 'I kø',
			'addMemberTitle': 'Tilføj',
			'memberSectionHeader': 'Medlemmer',
			'addExtension': 'Tilføj kollegaer',
			'addExternal': 'Tilføj ekstern',
			'statisticsSectionHeader': 'Statistik',
			'stats': {
				'NOMEMBERS': 'INGEN<br>MEDLEMMER',
				'ALLBUSY': 'ALLE<BR>OPTAGET',
				'TIMEOUT1': 'VENTET',
				'TIMEOUT2': 'FOR',
				'TIMEOUT3': 'LÆNGE',
				'CANCELLED': '<br>OPGIVET',
				'ANSWERED': '<br>BESVARET',
				'UNANSWERED': '<br>UBESVARET',
				'TOTAL': 'KALD<br>I ALT',
				'AVERAGEWAITTIME': 'GNS.<br>VENTETID',
				'AVERAGETALKTIME': 'GNS.<br>TALETID',
				'TOTALTALKTIME': 'TOTAL<br>TALETID',
				'LONGESTWAITTIME': 'LÆNGSTE<br>VENTETID',
				'LASTWAITTIME': 'SENESTE<br>VENTETID',
				'SERVICELEVEL': 'SERVICE<br>LEVEL',
				'SERVICELEVELCRITICAL1': 'SRV-LVL',
				'SERVICELEVELCRITICAL2': 'SERVICE LEVEL',
				'SERVICELEVELCRITICAL3': 'KRITISK'
			},
			'stats2': {
				'UNANSWERED': 'Ubesvaret',
				'TOTAL': 'Total kald',
				'ALLBUSY': 'Alle optaget',
				'CANCELLED': 'Opgivet',
				'LONGESTWAITTIME': 'Længste ventetid',
				'AVERAGETALKTIME': 'Gns. samtaletid',
				'SERVICELEVEL': 'Service level',
				'ANSWERED': 'Besvaret',
				'NOMEMBERS': 'Ingen medlemmer',
				'TIMEOUT': 'Ventet for længe',
				'AVERAGEWAITTIME': 'Gns. ventetid',
				'LASTWAITTIME': 'Seneste ventetid',
				'TOTALTALKTIME': 'Total tid',
				'SERVICELEVELCRITICAL': 'Service level kritisk'
			},
			'panelDeletedHeader': 'Kø panel slettet',
			'panelDeletedMessage': 'Kø panelet %%name%% er fjernet, da køen blev slettet på serveren.',
			'selectAQueue': 'Vælg en kø',
			'hiddenNumber': 'Skjult nummer',
			'action': {
				'answer': 'Besvar',
				'copyVariableToClipboard': 'Kopier %%name%% til udklipsholder',
				'copyNumberToClipboard': 'Kopier nummer til udklipsholder',
				'memberLeave': 'Meld ud',
				'memberSetPriority': 'Sæt prioritet'
			},
			'failedAnswerHeader': 'Besvar kø kald',
			'failedAnswerMessage': 'Besvarelse af kø kaldet mislykkedes. Kaldet blev ikke fundet.',
			'externalTag': 'EXT',
			'memberPriority': 'PRI',
			'memberCalls': 'KALD',
			'memberTime': 'TID',
			'failedToRemoveMemberHeader': 'Udmeld medlem',
			'failedToRemoveMemberMessage': 'Der opstod en fejl - %%name%% kunne ikke meldes ud af køen.',
			'failedToSetPriorityHeader': 'Sæt prioritet for medlem',
			'failedToSetPriorityMessage': 'Det lykkedes ikke at sætte prioriten for %%name%%.',
			'addExtensionToQueueHeader': 'Tilføj kollegaer til køen',
			'priority': 'Prioritet',
			'queueDoesNotAllowDynamicMembers': 'Denne kø tillader ikke indmeldelse af nye medlemmer.',
			'memberMayNotJoinQueue': '%%name%% må ikke meldes ind i denne kø.',
			'memberToJoinNotFound': 'Den valgte kollega %%name%% findes ikke længere.',
			'addMemberToQueueErrorHeader': 'Tilføj medlem til kø',
			'addExternalToQueueHeader': 'Tilføj eksternt medlem til køen',
			'externalPhoneNumber': 'Telefonnummer',
			'queueOnlyAcceptExtensions': 'Køen tillader kun lokalnumre.',
			'calls': {
				'header1': 'Nyeste',
				'header2': 'kald',
				'noCallsFound': 'Ingen kald fundet',
				'UNANSWERED': 'ubesvaret',
				'ANSWERED': 'besvaret',
				'NOMEMBERS': 'ingen medlemmer',
				'ALLBUSY': 'alle optaget',
				'TIMEOUT': 'ventet for længe',
				'CANCELLED': 'opgivet'
			},
			'call': 'Ring op',
			'markAsHandled': 'Marker som håndteret',
			'markAsHandledFailed': 'Det lykkedes ikke at markere kaldet som håndteret'
		},
		'shortcuts': {
			'panelName': 'Panel navn',
			'shortcuts': 'Genveje',
			'noShortcuts': 'Ingen genveje',
			'add': 'Tilføj genvej',
			'name': 'Label',
			'number': 'Nummer'
		},
		'switchvar': {
			'panelName': 'Panel navn',
			'editHeader': 'Variabler & kontakter',
			'newVariable': 'Ny variabel',
			'label': 'Label',
			'variableName': 'Variabel navn',
			'type': 'Type',
			'owner': 'Ejerskab',
			'values': 'Værdier',
			'noValues': 'Ingen værdier',
			'addValue': 'Tilføj værdi',
			'noVariables': 'Ingen variabler',
			'add': 'Tilføj variabel',
			'switch': 'Kontakt',
			'variable': 'Variabel',
			'personal': 'Personlig',
			'shared': 'Fælles',
			'system': 'System',
			'setErrorHeader': 'Systemfejl',
			'setSwitchErrorMessage': 'Der opstod en uventet fejl, som gør at kontakten ikke kunne opdateres.',
			'setVariableErrorMessage': 'Der opstod en uventet fejl, som gør at variablen ikke kunne opdateres.'
		},
		'web': {
			'panelName': 'Panel navn',
			'width': 'Bredde',
			'height': 'Højde',
			'type': 'Type',
			'interval': 'Interval',
			'url': 'URL',
			'urlDescription': 'som indhold skal hentes fra',
			'closeContent': 'Luk indhold',
			'ignoreLocalCalls': 'Ignorer lokale kald',
			'variableHelp': 'URL og Body variabler',
			'aNumber': 'A nummer',
			'aNumberNoPrefix': 'A nummer uden prefix',
			'bNumber': 'B nummer',
			'bNumberNoPrefix': 'B nummer uden prefix',
			'aName': 'A navn',
			'bName': 'B navn',
			'variable': 'Kaldsvariabel',
			'tcxtoken': 'Token fra TCX',
			'email': 'Din e-mail adresse',
			'other': 'Anden parts nummer',
			'otherNoPrefix': 'Nummeret på den anden part uden prefix',
			'types': {
				'static': 'Statisk',
				'reloaded': 'Periodisk genindlæsning',
				'ringing':  'Vis når telefon ringer',
				'answered': 'Vis når telefon besvares',
				'callRing': 'Vis når telefon ringer & ved udgående kald',
				'callAnswer': 'Vis når telefon besvares & ved udgående kald'
			},
			'intervals': {
				'0': 'Benyt ikke',
				'10': '10 sekunder',
				'30': '30 sekunder',
				'45': '45 sekunder',
				'60': '1 minut',
				'120': '2 minutter',
				'300': '5 minutter',
				'600': '10 minutter',
				'1800': '30 minutter',
				'3600': '1 time',
				'7200': '2 timer'
			},
			'closeContents': {
				'never': 'Aldrig',
				'onEnd': 'Når kald er slut',
				'button': 'Knap til manuel lukning'
			}
		},
		'employeeData': {
			'address': 'Adresse',
			'birthdate': 'Fødselsdag',
			'emailAddress': 'Email&nbsp;adresse',
			'localNumbers': 'Lokalnumre',
			'localNumber': 'Lokalnummer',
			'mobile': 'Mobil',
			'direct': 'Direkte',
			'until': 'indtil kl.',
			'restOfDay': 'resten af dagen',
			'untilTomorrow': 'indtil imorgen kl.',
			'until2': 'indtil',
			'onThe': 'd.'
		},
		'redirectModal': {
			'header': 'Ubetinget viderestilling for',
			'number': 'Nummer',
			'errorHeader': 'Ubetinget viderestilling',
			'errorNotFound': 'Handlingen mislykkedes, brugerens lokalnummer findes ikke længere.',
			'errorInvalid': 'Handlingen mislykkedes, telefonnummeret er ugyldigt.',
			'errorDenied': 'Du har ikke tilladelse til at sætte ubetinget viderestilling på den valgte bruger.',
			'errorUnknown': 'Der opstod en uventet fejl:'
		},
		'sms': {
			'header': 'Send SMS til',
			'toAnybody': 'Valgfri modtager',
			'phoneNumber': 'Mobil telefon nummer',
			'message': 'Besked',
			'insertCallback': 'Indsæt ring tilbage',
			'insertCallme': 'Indsæt ring til mig',
			'send': 'Send',
			'errorHeader': 'Send SMS',
			'errorUnknown': 'Der opstod en uventet fejl:',
			'callbackMessage': 'Venligst ring til %%name%%, som ønsker at komme i kontakt med dig, på %%number%%.',
			'callbackMessageNoName': 'Venligst ring til %%number%%, som ønsker at komme i kontakt med dig.',
			'callbackMessageRaw': 'Venligst ring til NAVN, som ønsker at komme i kontakt med dig, på NUMMER.',
			'callmeMessage': 'Ring til mig når det er muligt, på %%number%%. Hilsen %%name%%.',
			'toGroup': 'Gruppe',
			'group': 'Gruppe',
			'noGroups': 'Der blev ikke fundet nogen grupper, som der kan sendes SMS beskeder til.'
		},
		'setPresence': {
			'header': 'Sæt status for',
			'message': 'Besked',
			'location': 'Hvor',
			'until': 'Indtil',
			'selectDate': 'Vælg dato',
			'time': 'Tidspunkt',
			'errorHeader': 'Sæt status',
			'errorUnknown': 'Der opstod en uventet fejl:'
		},
		'phonebook': {
			'header': 'Telefonbog',
			'of': 'af',
			'newContact': 'Ny kontakt',
			'types': {
				'ALL': 'Alle',
				'SHARED': 'Delte',
				'PERSONAL': 'Personlige'
			},
			'sharing': 'Deling',
			'type': 'Type',
			'givenName': 'Fornavn',
			'familyName': 'Efternavn',
			'company': 'Virksomhed',
			'title': 'Titel',
			'department': 'Afdeling',
			'addresses': 'Adresser',
			'street': 'Adresse',
			'zip': 'Postnr',
			'city': 'By',
			'phoneNumbers': 'Telefonnumre',
			'phoneNumber': 'Telefonnummer',
			'emailAddresses': 'E-mail adresser',
			'emailAddress': 'E-mail adresse',
			'externalId': 'Ekstern id',
			'shortNumber': 'Kortnummer',
			'notes': 'Noter',
			'sharingPersonal': 'Personlig',
			'sharingShared': 'Delt',
			'typePerson': 'Person',
			'typeCompany': 'Virksomhed',
			'addressWork': 'Arbejde',
			'addressPrivate': 'Privat',
			'phoneNumberTypes': {
				'MAIN': 'Hoved',
				'WORK': 'Direkte',
				'MOBILE': 'Mobil',
				'HOME': 'Privat',
				'OTHER': 'Anden'
			},
			'emailTypes': {
				'MAIN': 'Virksomhed',
				'WORK': 'Arbejde',
				'HOME': 'Privat',
				'OTHER': 'Anden'
			},
			'errorCompanyNameMissing': 'Virksomhedens navn mangler.',
			'errorGivenNameMissing': 'Fornavn mangler.',
			'errorAddress1': 'Adressen nr.',
			'errorAddress2': 'er ikke udfyldt.',
			'errorPhoneNumber1': 'Telefonnummer nr.',
			'errorPhoneNumber2': 'er ugyldigt.',
			'errorEmail1': 'Email adresse nr.',
			'errorEmail2': 'er ugyldig.',
			'errorListHeader': 'Følgende fejl skal rettes:',
			'errorUnknown': 'Der opstod en fejl - kontakten kunne ikke opdateres -',
			'deleteContactHeader': 'Slet kontakt',
			'deleteContactMessage': 'Ønsker du at slette %%name%%?',
			'showAddress': 'Vis adresse på kort'
		},
		'recordings': {
			'header': 'Optagelser',
			'tip': 'For at optage, højreklik på kaldet i mine kald',
			'from': 'Fra',
			'to': 'Til',
			'type': 'Type',
			'loading': 'Indlæser...',
			'action': {
				'call': 'Ring op',
				'filter': 'Filtrer på denne',
				'play': 'Afspil optagelse'
			},
			'personal': 'Personlige',
			'common': 'Fælles',
			'deleteQuestionHeader': 'Slet optagelse',
			'deleteQuestionMessage': 'Ønsker du at slette den valgte optagelse?',
			'deleteFailed': 'Sletning af den valgte optagelse mislykkedes.',
			'loadError': 'Der opstod en uventet fejl under indlæsning af optagelser.',
			'hiddenNumber': 'Hemmligt nummer'
		},
		'mypresence': {
			'action': {
				'sendSms': 'Send SMS',
				'sendGroupSms': 'Send gruppe SMS',
				'setPresence': 'Sæt status',
				'quickSetPresence': 'Hurtig status skift',
				'busy1Hour': 'Optaget 1 time',
				'busy2Hours': 'Optaget 2 timer',
				'busyRestOfDay': 'Optaget resten af dagen',
				'offWorkRestOfDay': 'Fri resten af dagen',
				'offWorkRestOfWeek': 'Fri resten af ugen',
				'offWorkRestOfMonth': 'Fri måneden ud',
				'numberPresentation': 'Nummerpræsentation',
				'numberPresentationWorking': 'Ved status ledig/optaget/ude af huset',
				'numberPresentationOffWork': 'Ved status fri/ferie',
				'numberPresentationNextCall': 'For næste kald',
				'unconditionalCallForward': 'Ubetinget viderestilling',
				'endFullScreen': 'Afslut fuld skærm',
				'startFullScreen': 'Start fuld skærm',
				'changeProfilePicture': 'Skift profil billede',
				'layout': 'Layout',
				'layoutNew': 'Opret nyt',
				'layoutEdit': 'Rediger',
				'layoutDelete': 'Slet',
				'settings': 'Indstillinger',
				'logout': 'Log ud',
				'numberPresentationSecret': 'Hemmeligt nummer',
				'numberPresentationNone': 'Benyttes ikke'
			},
			'errorUploadHeader': 'Upload af profil billede',
			'errorUploadMessage': 'Den valgte fil er ikke en gyldig billedfil. Prøv igen.',
			'setPresenceErrorHeader': 'Sæt status',
			'setPresenceErrorMessage': 'Det lykkedes ikke at sætte din status - der opstod en uventet fejl.',
			'layoutDeleteHeader': 'Layout',
			'layoutDeleteMessage': 'Ønsker du at slette det aktuelle layout \'%%name%%\'?',
			'logoutHeader': 'Log ud',
			'logoutMessage': 'Er du sikker på at du vil logge ud?',
			'numberPresentationErrorHeader': 'Nummerpræsentation',
			'numberPresentationErrorMessage1': 'Det lykkedes ikke at indstille nummerpræsentation ved status ledig/optaget/ude af huset.',
			'numberPresentationErrorMessage2': 'Det lykkedes ikke at indstille nummerpræsentation ved status fri/ferie.',
			'numberPresentationErrorMessage3': 'Det lykkedes ikke at indstille nummerpræsentation for det næste kald.',
			'endImpersonation': 'Afslut og gendan forrige session'
		},
		'topbar': {
			'buttons': {
				'dashboard': 'Oversigt',
				'phonebook': 'Telefonbog',
				'recordings': 'Optagelser',
				'voicemail': 'Telefonsvarer',
				'calls': 'Samtaler',
				'secretEnable': 'Skjul nummer',
				'secretDisable': 'Vis nummer'
			},
			'searchtip': '. = kun kollegaer',
			'searchPlaceholder': 'Søg / Ring op',
			'searchCall': 'Ring til',
			'searchTransferTo': 'Omstil til',
			'searchInvite': 'Tilføj %%number%% til samtale',
			'softphone': 'Indbygget (soft)',
			'noPhoneSelected': 'Ingen',
			'mobile': 'mobil',
			'unknown': 'Ukendt',
			'unknownEmployee': 'Ukendt (emp)'
		},
		'voicemail': {
			'header': 'Telefonsvarer',
			'newMessage': 'ny besked',
			'newMessages': 'nye beskeder',
			'and': 'og',
			'oldMessage': 'gammel besked',
			'oldMessages': 'gamle beskeder',
			'type': 'Type',
			'deleteAll': 'Slet alle',
			'loading': 'Indlæser...',
			'actions': {
				'call': 'Ring op',
				'filterOnThis': 'Filtrer på denne',
				'playMessage': 'Afspil besked'
			},
			'types': {
				'all': 'Alle',
				'new': 'Kun nye',
			},
			'playInfo': 'Besked modtaget d. %%date%% fra %%from%%',
			'deleteHeader': 'Slet besked',
			'deleteMessage': 'Ønsker du at slette den valgte besked?',
			'deleteFailed': 'Sletning af den valgte besked mislykkedes.',
			'deleteAllHeader': 'Slet alle beskeder',
			'deleteAllMessage': 'Ønsker du at slette alle beskeder?',
			'deleteAllFailed': 'Sletning af alle beskeder mislykkedes.',
			'loadingError': 'Der opstod en uventet fejl under indlæsning af beskeder',
			'hiddenNumber': 'Hemmligt nummer'
		},
		'login': {
			'email': 'E-mail',
			'password': 'Adgangskode',
			'resetPassword': 'nulstil adgangskode',
			'smsCode': 'SMS kode',
			'authenticatorSetup': 'Register denne QR nøgle med din authenticator app og indtast engangskoden nedenfor',
			'authenticatorCode': 'Engangskode',
			'resetPasswordHelp': 'Indtast din e-mail adresse og klik næste for at nulstille din adgangskode.',
			'resetPasswordSendTo': 'Send til',
			'resetPasswordHelp2': 'Om et øjeblik modtager du en e-mail med et nulstillingslink.',
			'resetPasswordHelp3': 'Om et øjeblik modtager du en sms med et nulstillingslink.',
			'remember': 'Forbliv logget på',
			'next': 'Næste',
			'ok': 'OK',
			'sendToEmail': 'E-mail',
			'sendToSms': 'Mobiltelefon',
			'errorNoCommunicatorAccess': 'Du har ikke tilladelse til at benytte Communicator',
			'errorNoExtension': 'Du har ikke et lokalnummer og kan derfor ikke benytte Communicator.',
			'errorNoConnection': 'Netværksfejl. Kunne ikke etablere forbindelse til serveren.',
			'errorServer': 'Server fejl - prøv igen senere',
			'errorCredentials': 'E-mail og/eller adgangskode er ugyldig',
			'errorDisabled': 'Din konto er spærret - kontakt din leverandør',
			'errorWrongIp': 'Du har ikke tilladelse til at logge på<br>fra din nuværende IP adresse',
			'errorSmsCodeInvalid': 'SMS koden er ikke gyldig',
			'authenticatorCodeInvalid': 'Engangskoden er ikke gyldig',
			'errorResetNotAvailable': 'Kontoen bruger 2-faktor autorisering, og du kan derfor ikke nulstille din adgangskode. Kontakt din leverandør som kan assistere dig med at nulstille din adgangskode.',
			'errorResetAlreadyInProgress': 'Der er allerede bestilt en nulstilling. Prøv igen om 30 minutter.',
			'loginButton': 'Log på'
		},
		'statuspanel': {
			'realtime': 'Realtime',
			'authentication': 'Autorisering',
			'employees': 'Kollegaer',
			'layout': 'Layout',
			'phonebook': 'Telefonbog',
			'appApi': 'App API',
			'sipClient': 'SIP klient',
			'headset': 'Headset',
			'busylight': 'Busylight'
		},
		'settings': {
			'globalShortcuts': 'Globale genvejstaster',
			'globalShortcutsFailedMessage': 'Det lykkedes ikke at initialisere alle globale genvejstaster. Der er muligvis en konflikt med en anden applikation.',
			'networkError': 'Netværksfejl. Kunne ikke etablere forbindelse til serveren.',
			'header': 'Indstillinger for',
			'ringtoneDoNotUse': 'Benyt ikke',
			'settings': 'Indstillinger',
			'failedToInitialize': 'Der opstod en uventet fejl under initaliseringen af indstillinger:',
			'common': {
				'header': 'Generelt',
				'callHandling': 'Kaldshåndtering',
				'callWaiting': 'Banke på',
				'blockSecret': 'Bloker kald med hemmeligt nummer',
				'mobileDelay': 'Forsinket tilringing af mobil',
				'mobileDelay2': 'når der ringes på SIP og mobil samtidigt',
				'mobileDelayNone': 'Ingen forsinkelse',
				'mobileUseOwnNumber': 'Mobiltelefon præsenterer altid eget nummer',
				'security': 'Sikkerhed',
				'password': 'Adgangskode',
				'pincode': 'Pinkode',
				'ui': 'Brugerflade',
				'presenceBackground': 'Baggrundsfarve følger status på kollegaer',
				'showStatusPanel': 'Vis status bjælke',
				'autolaunch': 'Start automatisk ved login',
				'autolaunchHidden': 'Start minimeret',
				'dashboardColumnWidth': 'Kolonne bredde i oversigt',
				'queueCriticalSound': 'Advarselslyd når service level på en kø er overskredet',
				'ringtoneVolumeOff': 'Slukket',
				'kuandoRingtone': {
					'0': 'Ingen',
					'1': 'Eventyr',
					'2': 'Funky',
					'3': 'Melodisk',
					'4': 'Drøm',
					'5': 'Stille',
					'6': 'Gammeldags',
					'7': 'Digital',
					'8': 'Energisk'
				},
				'pixels': 'pixels',
				'pixelsDefault': 'pixels (standard)',
				'autolaunchFailedHeader': 'Indstillinger',
				'autolaunchFailedMessage': 'Opsætning af automatisk start mislykkedes.',
				'changePasswordWarning': 'Bemærk at alle dine enheder som er logget på din konto logges af når du skifter din adgangskode. Skal adgangskoden forsat skiftes?',
				'passwordSaved': 'Koden er gemt!',
				'passwordFailedHeader': 'Gem adgangskode',
				'passwordFailedMessage': 'Serveren afviste dit forsøg på at opdatere din adgangskode. Prøv igen.',
				'pinFailedHeader': 'Gem pinkode',
				'pinFailedMessage': 'Serveren afviste dit forsøg på at opdatere din pinkode. Prøv igen.',
				'callWaitingFailedHeader': 'Banke på',
				'callWaitingFailedMessage': 'Serveren afviste dit forsøg på at opdatere banke på. Prøv igen.',
				'blockSecretFailedHeader': 'Bloker hemmeligt nummer',
				'blockSecretFailedMessage': 'Serveren afviste dit forsøg på at opdatere blokering af hemmeligt nummer. Prøv igen.',
				'mobileDelayFailedHeader': 'Mobil forsinkelse',
				'mobileDelayFailedMessage': 'Serveren afviste dit forsøg på at opdatere mobil forsinkelse. Prøv igen.',
				'mobileUseOwnNumberFailedHeader': 'Mobil præsentation',
				'mobileUseOwnNumberFailedMessage': 'Serveren afviste dit forsøg på at opdatere mobil præsentation. Prøv igen.',
				'skin': 'UI tema',
				'skin_DEFAULT': 'Standard',
				'skin_EARTH': 'Jord',
				'skin_TEAL': 'Tyrkis',
				'skin_DARK': 'Nat'
			},
			'phone': {
				'header': 'Telefon',
				'accessBlockedMacos': 'Der er blokeret adgang til mikrofonen, og derfor kan lydenheder ikke indstilles. Slet blokeringen i din browser og prøv igen.',
				'accessBlockedWindows': 'Der er blokeret for adgang til mikrofonen (eller ingen er tilsluttet), og derfor kan lydenheder ikke indstilles. Slet blokeringen i din browser og/eller Windows kontrol panel (se Windows -> Indstillinger -> Beskyttelse af personlige oplysninger -> Kamera) og prøv igen.',
				'phone': 'Telefon',
				'phone2': 'Enhed som skal benyttes/styres',
				'ringtone': 'Ringetone',
				'ringtoneExternal': 'Ved eksterne kald',
				'ringtoneLocal': 'Ved lokale kald',
				'ringtonePlayOn': 'Afspil på',
				'ringtoneVolume': 'Lydstyrke',
				'devices': 'Lyd/video enheder',
				'speaker': 'Højttaler',
				'microphone': 'Mikrofon',
				'camera': 'Kamera',
				'busylight': 'Busylight',
				'busylightDriver': 'Download driver',
				'busylightEnable': 'Benyt',
				'busylightRingtone': 'Lyd',
				'busylightVolume': 'Lydstyrke',
				'errorHeader': 'Telefon',
				'noPhone': 'Ingen',
				'softphone': 'Indbygget (softphone)',
				'jabraInfo': 'Installer Jabra driver og Chrome plugin for den bedste integration med Jabra enheder.',
				'jabraChromeHost': 'Download jabra driver',
				'jabraChromePlugin': 'Installer Chrome plugin'
			},
			'dnd': {
				'header': 'Forstyr ikke',
				'help': 'Her vælger du, på hvilke af dine telefoner, at forstyr ikke skal være aktivt. Når forstyr ikke er aktivt på en telefon, ringer den ikke med når du modtager et kald.',
				'updateFailedHeader': 'Forstyr ikke',
				'updateFailedMessage': 'Serveren afviste dit forsøg på at opdatere forstyr ikke. Prøv igen.'
			},
			'shortcuts': {
				'header': 'Globale genvejstaster',
				'help': 'Genvejstasterne giver direkte adgang til udvalgte handlinger i Communicator, uanset hvilket program der har fokus på din computer.',
				'notInBrowser': 'Globale genvejstaster er ikke aktive da Communicator kører i en webbrowser.',
				'cancel': 'Fortryd ændringer',
				'save': 'Gem ændringer',
				'answer': 'Besvar kald',
				'hangup': 'Afslut kald',
				'visibility': 'Vis/skjul vindue',
				'call': 'Ring op - til nummer i udklipsholder',
				'search': 'Søg og ring op'
			},
			'integration': {
				'header': 'Integration',
				'notInBrowser': 'Integration er ikke aktive da Communicator kører i en webbrowser.',
				'doNotUse': 'Benyt ikke',
				'key': {
					'space': 'Mellemrum',
					'tab': 'Tabulator',
					'shiftlock': 'Shift-lås',
					'numlock': 'Nummerisk lås',
					'scrolllock': 'Scroll lås',
					'backspace': 'Backspace',
					'delete': 'Slet',
					'insert': 'Indsæt',
					'return': 'Retur',
					'up': 'Op',
					'down': 'Ned',
					'left': 'Venstre',
					'right': 'Højre',
					'home': 'Home',
					'end': 'End',
					'pageUp': 'Side op',
					'pageDown': 'Side ned',
					'volumeUp': 'Volume op',
					'volumeDown': 'Volume ned',
					'volumeMute': 'Volume lydløs',
					'mediaNextTrack': 'Media næste',
					'mediaPreviousTrack': 'Media forrige',
					'mediaStop': 'Media stop',
					'mediaPlayPause': 'Media play/pause',
					'printScreen': 'Print skærm',
					'nummeric': 'Nummerisk'
				},
				'when': {
					'never': 'Ikke',
					'ringing': 'Når telefon ringer',
					'answered': 'Når kald besvares'
				},
				'delayNone': 'Ingen',

				'sendKeys': {
					'header': 'Tastetryk',
					'use': 'Benyt',
					'delay': 'Forsinkelse før udførelse',
					'process': 'Processnavn tastetryk skal sendes til',
					'macHelp': 'Find process navn med programmet <i>Aktivitetsovervågning</i>',
					'winHelp': 'Find process navn med programmet <i>Jobliste</i>',
					'maximizeProcess': 'Maksimer process vindue',
					'useAutoit': 'Send tastetryk med AutoIT',
					'requireArgs': 'Udfør kun hvis benyttede variabler har en værdi',
					'keys': 'Tastetryk der skal sendes',
					'variables': 'Variabler',
					'variableName': 'kalds-variabel-navn',
					'keysHelp': 'Tastetryk hjælp',
					'test': 'Test integrationen'
				},
				'browser': {
					'header': 'Browser',
					'use': 'Benyt',
					'website': 'Website navn',
					'website2': 'Navn eller RegEx',
					'type': 'Website type',
					'typeHelp': 'SPA for ajax baserede websider, MPA hvis hele websiden indlæses ved submit',
					'requireArgs': 'Udfør kun hvis benyttede variabler har en værdi',
					'actions': 'Handlinger',
					'actionOption': {
						'sleep': 'Vent i',
						'target': 'Vælg element',
						'click': 'Udfør museklik',
						'focus': 'Giv fokus',
						'clear': 'Ryd indhold',
						'insert': 'Indsæt tekst',
						'js': 'Kør javascript',
						'scrolltop': 'Scroll til sidetop'
					},
					'milliseconds': 'millisekunder',
					'csssel': 'CSS-sel',
					'value': 'Tekst der skal indsættes',
					'variables': 'Variabler',
					'variablesName': 'kalds-variabel-navn',
					'addAction': 'Tilføj handling',
					'test': 'Test integrationen'
				},
				'run': {
					'header': 'Kør program',
					'use': 'Benyt',
					'delay': 'Forsinkelse før udførelse',
					'requireArgs': 'Udfør kun hvis benyttede variabler har en værdi',
					'command': 'Program sti',
					'macHelp': 'Eksempel: "open -a /Applications/&lt;app-navn&gt;.app --args &lt;argumenter&gt;"',
					'winHelp': 'Eksempel: "C:\Program files\&lt;App-mappe&gt;\&lt;app-navn&gt;.exe &lt;argumenter&gt;"',
					'variables': 'Variabler',
					'variablesName': 'kalds-variabel-navn',
					'test': 'Test integrationen'
				},
				'clipboard': {
					'header': 'Udklipsholder',
					'use': 'Benyt',
					'requireArgs': 'Udfør kun hvis benyttede variabler har en værdi',
					'what': 'Hvad',
					'variables': 'Variabler',
					'variablesName': 'kalds-variabel-navn',
					'test': 'Test integrationen'
				},
				'cancel': 'Fortryd ændringer',
				'save': 'Gem ændringer'
			},
			'info': {
				'header': 'Information',
				'version': 'Version',
				'systemStatus': 'System status',
				'realtime': 'Realtime',
				'authentication': 'Autorisering',
				'employees': 'Kollegaer',
				'units': 'stk.',
				'layout': 'Layout',
				'phonebook': 'Telefonbog',
				'contacts': 'kontakter',
				'appApi': 'App API',
				'softphone': 'Softphone',
				'channels': 'kanaler',
				'notInUse': 'Ikke i brug',
				'headset': 'Headset',
				'busylight': 'Busylight'
			}
		},
		'callDrag': {
			'transferToCalledEmployee': 'omstille kaldet til den valgte medarbejder',
			'transferCallToTheQueue': 'overføre kaldet til køen',
			'transferFailedHeader': 'Omstilling mislykkedes',
			'failedToPickupCall': 'Det var ikke muligt at trække kaldet, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'transferTheCallToTheEmployee': 'flytte kaldet til den valgte medarbejder',
			'transferTheCallFromEmployeeToQueue': 'overføre kaldet fra medarbejderen til køen',
			'failedToPickupQueueCall': 'Det var ikke muligt at trække kaldet fra køen, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'failedToMoveCallFromQueueToEmployee': 'Det var ikke muligt at flytte kaldet fra køen til den valgte medarbejder, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'failedToMoveCallFromQueueToQueue': 'Det var ikke muligt at flytte kaldet til den anden kø, enten fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'blindTransferError': 'Det var ikke muligt at %%message%%, fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'attendedTransferError': 'Det var ikke muligt at omstille kaldet, fordi det allerede er afsluttet eller på grund af manglende rettigheder.',
			'queueNotDynamic': 'Denne kø tillader ikke indmeldelse af nye medlemmer.',
			'employeeNotValidForQueue': 'Kollegaen kan ikke meldes ind i denne kø.',
			'queueJoinOtherDenied': 'Du har ikke tilladelse til at melde kollegaen ind i denne kø.',
			'queueNotFound': 'Denne kø findes ikke længere',
			'employeeNotFound': 'Kollegaen findes ikke længere',
			'employeeAlreadyMember': 'Kollegaen er allerede medlem af køen',
			'unexpectedError': 'Der opstod en uventet fejl - kode',
			'noPermissionToAddExternalToQueue': 'Du har ikke tilladelse til at melde det eksterne nummer ind i denne kø.',
			'externalAlreadyMember': 'Det eksterne nummer er allerede medlem af køen',
			'addNewMemberHeader': 'Tilføj nyt kø medlem',
			'blindTransferLegNotFound': 'Det var ikke muligt at finde kaldet der skal omstilles.'
		},
		'dashboards': {
			'coworkerPanelWithoutName': 'Et kollega panel i kolonne %%column%% mangler et panel navn.',
			'coworkerPanelHasNoContent': 'Et kollega panel i kolonne %%column%% har ingen kollegaer.',
			'queuePanelWithQueue': 'Et kø panel i kolonne %%column%% mangler en kø.',
			'webPanelHasInvalidWidth': 'Et web panel i kolonne %%column%% har en ugyldig bredde.',
			'webPanelHasInvalidHeight': 'Et web panel i kolonne %%column%% har en ugyldig højde.',
			'webPanelWithoutName': 'Et web panel i kolonne %%column%% mangler et panel navn.',
			'webPanelWithoutUrl': 'Et web panel i kolonne %%column%% mangler en URL.',
			'shortcutsPanelWithoutName': 'Et genvejs panel i kolonne %%column%% mangler et panel navn.',
			'shortcutsPanelInvalidShortcuts': 'Et genvejs panel i kolonne %%column%% har en eller flere genveje som mangler navn/nummer.',
			'switchvarPanelWithoutName': 'Et variabel panel i kolonne %%column%% mangler et panel navn.',
			'switchvarPanelInvalidSwitches': 'Et variabel panel i kolonne %%column%% har en eller flere variabler som mangler navn/label.',
			'panelHasError': 'Fejl i panel:',
			'unexpectedErrorHeader': 'Opret nyt layout',
			'unexpectedErrorMessage': 'Der opstod en uventet fejl under oprettelse af det nye layout. Serveren siger:',
			'unexpectedUpdateErrorHeader': 'Opdatering af layout',
			'unexpectedUpdateErrorMessage': 'Der opstod en uventet fejl under opdateringen af dette layout. Serveren siger:',
			'deletedAndOtherSelected': ', og %%name%% er valgt i stedet.',
			'deletedHeader': 'Layout slettet',
			'deletedMessage': 'er slettet',
			'deleteFailedHeader': 'Sletning af layout',
			'deleteFailedMessage': 'Der opstod en uventet fejl under sletningen af dette layout. Serveren siger:',
			'newExtensionsTitle': 'Ny liste',
			'newWebTitle': 'Web',
			'newSwitchvarTitle': 'Kontakter',
			'newShortcutsTitle': 'Genveje',
			'sharedEditAccessDeniedHeader': 'Redigering ikke tilladt',
			'sharedEditAccessDeniedMessage': 'Dette layout er et delt layout, og du har ikke tilladelse til at redigere det.',
			'sharedDeleteAccessDeniedHeader': 'Sletning ikke tilladt',
			'sharedDeleteAccessDeniedMessage': 'Dette layout er et delt layout, og du har ikke tilladelse til at slette det.'
		},
		'employees': {
			'errorHeader': 'Medarbejdere',
			'errorLoadingNewEmployeeMessage': 'Der opstod en uventet fejl under indlæsning af en ny-oprettet medarbejder. Serveren siger:',
			'errorLoadingUpdatedEmployeeMessage': 'Der opstod en uventet fejl under indlæsning af en opdateret medarbejder. Serveren siger:',
			'voicemail': 'Telefonsvarer',
			'dnd': 'Forstyr ikke',
			'setSwitch': 'Sæt kontakt',
			'mainMenu': 'Hovedmenu',
			'transfer': 'Omstilling',
			'greeting': 'Velkomst hilsen',
			'recordings': 'Optagelser',
			'queueMenu': 'Kø menu',
			'queueJoin': 'Melder ind i kø',
			'queueIsMember': 'Medlemskab af kø',
			'queueMemberList': 'Oplæsning af kø medlemskab',
			'queueLeaveAll': 'Melder ud af alle køer',
			'queueLeave': 'Melder ud af kø',
			'blackWiteList': 'Sort/hvid liste',
			'pickupMenu': 'Træk menu',
			'pickupRinging': 'Træk ringende telefon',
			'pickupRingingFrom': 'Træk ringende fra',
			'stealCallFrom': 'Træk kald fra',
			'spyOn': 'Lyt med på',
			'setPresence': 'Sæt status',
			'hotdesk': 'Hotdesk',
			'moveCall': 'Flyt kald'
		},
		'integration': {
			'errorHeader': 'Integration',
			'errorClipboardMessage': 'Udklipsholder integration mislykkedes, kunne ikke udføre kommando.',
			'errorRunMessage': 'Kør program integration mislykkedes, kunne ikke udføre kommando.',
			'errorSendkeysMessage': 'Send tastetryk integration mislykkedes, kunne ikke udføre kommando.',
			'errorBrowserMessage': 'Browser integration mislykkedes, kunne ikke udføre kommando.',
			'myCallsHeader': 'Mine kald',
			'myCallsFailedToAnswer': 'Besvarelse af kaldet mislykkedes.',
			'myCallsFailedHangup': 'Pålægning af kaldet mislykkedes.',
			'myCallsFailedReject': 'Pålægning af kaldet mislykkedes.'
		},
		'mediaDevices': {
			'builtin': 'Indbygget',
			'communication': 'Kommunikation',
			'virtual': 'Virtuel',
			'default': 'Standard'
		},
		'queues': {
			'errorHeader': 'Køer',
			'errorLoadingNewlyCreatedQueueMessage': 'Der opstod en fejl under indlæsning af en ny-oprettet kø med id %%id%%. Serveren siger: %%response%%',
			'errorLoadingUpdatedQueueMessage': 'Der opstod en fejl under indlæsning af opdateringen af en kø med id %%id%%. Serveren siger: %%response%%'
		},
		'sipClient': {
			'ringtones': {
				'old': 'Gammeldags',
				'digital': 'Digital',
				'digital2': 'Digital 2',
				'marimba': 'Marimba',
				'iphoneremix': 'iPhone remix',
				'behappy': 'Be happy',
				'marimba2': 'Marimba 2',
				'guitar': 'Guitar',
				'nokia': 'Nokia',
				'christmastime': 'Juletid'
			},
			'audioDeviceUnavailableInsteadUsing': '%%old%% er ikke længere tilgængelig, benytter i stedet %%new%%.',
			'audioDeviceChangedHeader': 'Lydenhed ændret',
			'audioDeviceNowUsing': '%%new%% benyttes nu.',
			'videoDeviceUnavailableInsteadUsing': '%%old%% er ikke længere tilgængelig, benytter i stedet %%new%%.',
			'videoDeviceChangedHeader': 'Video enhed ændret',
			'videoDeviceNowUsing': '%%new%% benyttes nu.',
			'errorHeader': 'Telefon',
			'errorWebRtcInitFailedMessage': 'Det lykkedes ikke at initialisere den indbyggede telefon - serveren afviste at initalisere den:',
			'errorBrowserHasNoWebRtc': 'Telefonen kunne ikke aktiveres da din browser ikke understøtter de nødvendige protokoller.',
			'errorFailedToConnectToServer': 'Telefonen kunne ikke startes, da der kunne ikke skabes forbindelse til serveren',
			'errorFailedToConnectToServerGeneric': 'Telefonen virker ikke, da det ikke lykkedes at starte den:',
			'errorFailedToAttachToSip': 'Telefonen kunne ikke tilkoble sig til SIP delen på serveren:',
			'errorUnexpectedSipError': 'Der opstod en uventet fejl: %%message%% - prøver at genetablere forbindelsen.',
			'errorFailedToAuthenticate': 'Kunne ikke tilslutte til serveren: %%code%% %%reason%% - prøver at genetablere forbindelsen.',
			'errorFailedToAuthenticateFatal': 'Kunne ikke tilslutte til serveren.',
			'errorRetry': 'Prøv igen',
			'errorTerminate': 'Afslut',
			'errorSipMessageReceived': 'Besked modtaget fra %%from%%: %%message%%',
			'errorSipInfoReceived': 'Info modtaget fra %%from%%: %%message%%',
			'errorSipNotifyReceived': '%%notify%% modtaget: %%message%%',
			'errorFailedToAnswer': 'Besvarelse af kald mislykkedes:',
			'errorFailedToCall': 'Der opstod en uventet fejl - kaldet kunne ikke etableres: %%error%%',
			'errorFailedToInitSipChannel': 'Initialisering af SIP kanal mislykkedes:'
		},
		'jabra': {
			'errorHeader': 'Jabra integration',
			'errorFailedToInit': 'Jabra integration kunne ikke aktiveres:',
			'errorNoAccessToDevices': 'der er ikke adgang til Jabra enheder',
			'errorInitFailedDeviceNotFound': 'Initalisering af %%name%% mislykkedes. Enheden blev ikke fundet.',
			'webhidHeader': 'Jabra',
			'webhidMessage': 'Du har valgt en Jabra enhed, men har ikke installeret Jabra plugin. For at kunne styre enhedenen skal du give adgang. Klik nedenfor på "Vis dialog", vælg enheden på listen, og klik "Opret forbindelse".',
			'showDialog': 'Vis dialog',
			'deviceDoesNotSupportCallControl': 'Den valgte Jabra enhed kunne ikke styres. Sørg for at den er tændt og tilsluttet.'
		},
		'sennheiser': {
			'errorHeader': 'Sennheiser EPOS integration',
			'errorFailedToInit': 'Sennheiser EPOS integration kunne ikke aktiveres:',
			'errorNoAccessToDevices': 'der er ikke adgang til Sennheiser enheder',
			'errorInitFailedDeviceNotFound': 'Initalisering af %%name%% mislykkedes. Enheden blev ikke fundet.',
			'webhidHeader': 'Sennheiser EPOS',
			'webhidMessage': 'Du har valgt en Sennheiser enhed, men har ikke installeret Sennheiser Epos Connect app. For at kunne styre enhedenen skal du give adgang. Klik nedenfor på "Vis dialog", vælg enheden på listen, og klik "Opret forbindelse".',
			'showDialog': 'Vis dialog',
			'deviceDoesNotSupportCallControl': 'Den valgte Sennheiser enhed kunne ikke styres. Sørg for at den er tændt, tilsluttet og kompatibel.'
		},
		'mynotification': {
			'errorHeader': 'Notifikation',
			'errorMessage': 'Visning af notifikation i browser mislykedes'
		},
		'app': {
			'noServerConnection': 'INGEN FORBINDELSE TIL SERVEREN!',
			'ringtoneEnabled': 'Ringetone er nu aktiveret',
			'enabledAudioByClicking': 'Din browser har blokeret for afspilning af lyde. Klik OK for at ophæve blokeringen'
		}
	}
};

const i18n = new VueI18n({
	locale: navigator.language.split('-')[0],
	fallbackLocale: 'en',
	messages: messages
});
i18n.t2 = key => { return i18n.t(key).toString(); }; // NOSONAR

export default i18n;
