import EventBus from './EventBus';
import s from '../settings';
import i18n from '../utils/i18n';
import logger from './logger';

/**
 * Holds a list of all current audio input, audio output and video input devices, the preferred devices and the currently selected devices,
 * and announces when a device has been removed or added.
 * 
 * A device in this.devices contains:
 * 	deviceId: Unique id of the device,
 * 	kind: Type of device: audioinput, audiooutput, videoinput
 * 	label: Human friendly name of the device
 * 	groupId: Id of device group that the device belongs to (e.g. a mic and speaker is paired in a group)
 * 	productId: Id of the hw product/model
 */
class MediaDevices {
	/**
	 * Constructor
	 */
	constructor() {
		logger.debug('MediaDevices: Constructor');
		this.devices = [];
		this.hasAudioPermission = false;
		this.hasVideoPermission = false;
		this._ready = false;
		this.preferred = {
			audioInputs: [], // ordered list of which microphone is preferred, latest 5
			audioOutputs: [], // ordered list of which speaker is preferred, latest 5
			videoInputs: [], // ordered list of which camera is preferred, latest 5
			ringerOutputs: [], // ordered list of which devices is prefered to play ringtone on, latest 5
			ringerVolume: 1
		};
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'mediaDevices', value: this._ready });
			EventBus.$emit('StatusReport', { key: 'mediaDevicesList', value: this.devices });
		});

		logger.registerGlobal('mediaDevices', this);
	}

	/**
	 * Initialize
	 */
	async init() {
		logger.info('MediaDevices: Initializing');
		// Disabled until we need to support video
		// const permission1 = await this.requestMedia(true, true);
		// if (permission1) {
		// 	logger.info('MediaDevices: Got access to audio and video');
		// 	this.hasAudioPermission = true;
		// 	this.hasVideoPermission = true;
		// 	this.stopMedia(permission1);
		// } else {
		// 	const permission2 = await this.requestMedia(true, false);
		// 	if (permission2) {
		// 		logger.info('MediaDevices: Got access to audio only');
		// 		this.hasAudioPermission = true;
		// 		this.stopMedia(permission2);
		// 	} else {
		// 		logger.warning('MediaDevices: Failed to get access to audio and video');
		// 	}
		// }

		// While we only support audio
		const permission = await this.requestMedia(true, false);
		if (permission) {
			logger.info('MediaDevices: Got access to audio');
			this.hasAudioPermission = true;
			this.stopMedia(permission);
		} else {
			logger.warning('MediaDevices: Failed to get access to audio');
		}

		const preferred = localStorage.getItem('MediaDevices:Preferred');
		if (!preferred) {
			// Convert old format to new format
			const mic = localStorage.getItem('softphoneMicrophoneDeviceId');
			if (mic) { this.preferred.audioInputs.push(mic); }
			const speaker = localStorage.getItem('softphoneSpeakerDeviceId');
			if (speaker) { this.preferred.audioOutputs.push(mic); }
			const camera = localStorage.getItem('softphoneVideoDeviceId');
			if (camera) { this.preferred.videoInputs.push(camera); }
			const ringtone = localStorage.getItem('ringtoneDeviceId');
			if (ringtone) { this.preferred.ringerOutputs.push(ringtone); }
			const ringtoneVolume = localStorage.getItem('ringtoneVolume');
			if (ringtoneVolume) { this.preferred.ringerVolume = Number(ringtoneVolume); }
			localStorage.removeItem('softphoneMicrophoneDeviceId');
			localStorage.removeItem('softphoneSpeakerDeviceId');
			localStorage.removeItem('softphoneVideoDeviceId');
			localStorage.removeItem('ringtoneDeviceId');
			localStorage.removeItem('ringtoneVolume');
			localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		} else {
			this.preferred = JSON.parse(preferred);
		}

		logger.debug(`MediaDevices: Preferred devices: audioInputs: ${this.preferred.audioInputs[0] || 'n/a'} (${this.preferred.audioInputs.length})`);
		logger.debug(`MediaDevices: Preferred devices: audioOutputs: ${this.preferred.audioOutputs[0] || 'n/a'} (${this.preferred.audioOutputs.length})`);
		logger.debug(`MediaDevices: Preferred devices: ringerOutputs: ${this.preferred.ringerOutputs[0] || 'n/a'} (${this.preferred.ringerOutputs.length})`);

		navigator.mediaDevices.ondevicechange = this.buildDeviceList.bind(this);
		await this.buildDeviceList(true);
		this._ready = true;
	}

	/**
	 * Returns when all data has been loaded and the module is ready to be used
	 * @returns {Promise}
	 */
	async ready() {
		if (this._ready) { return Promise.resolve(); }
		await Helper.sleep(250);
		return this.ready();
	}

	/**
	 * Returns if media devices is initialized and ready to use
	 */
	get isReady() {
		return this._ready;
	}

	/**
	 * Request media streams and access
	 * @param {Boolean|String} audio True for any audio device, or the deviceId of a specific audio input device
	 * @param {Boolean|String} video true for any video device, or the deviceId of a specific video input device 
	 * @param {Number} [width] Desired video width
	 * @param {Number} [height] Desired video height
	 */
	async requestMedia(audio, video, width, height) {
		// Request permission to access audio/video devices (getUserMedia) by initializing them
		let config = { audio: audio, video: video };
		if (typeof audio === 'string') { config.audio = { deviceId: audio }; }
		if (typeof video === 'string') { config.video = { deviceId: video }; }
		if (width && height) {
			if (typeof config.video === 'boolean') {
				config.video = {};
			}
			config.video.width = { ideal: width };
			config.video.height = { ideal: height };
		}
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia(config);
			return mediaStream;
		}
		catch (err) {
			logger.error(`MediaDevices: RequestMedia failed: ${err.message}`);
			return null;
		}
	}

	/**
	 * Stop all tracks on a media stream
	 * @param {MediaStream} mediaStream Media stream
	 */
	stopMedia(mediaStream) {
		try {
			const tracks = mediaStream.getTracks();
			for (let track of tracks) {
				if (track) {
					track.stop();
				}
			}
		} catch (_) {
			// do nothing
		}
	}

	/**
	 * Build list of devices
	 * @param {Boolean} initial True on initial run, false when invoked because of device change
	 * @private
	 */
	async buildDeviceList(initial) {
		logger.info('MediaDevices: Building list of devices');
		try {
			// Build list of devices as it looks like now
			let devices = await navigator.mediaDevices.enumerateDevices(); // deviceId, kind, label, groupId
			devices = devices.filter(dev => { return dev.deviceId !== 'communications'; }); // Remove 'default' and 'communications' device types - so that headsets only appears once on windows
			devices = devices.map(dev => {
				let productId = null;
				const hasProductId = RegExp(/\([a-fA-F0-9]{4}:[a-fA-F0-9]{4}\)/).exec(dev.label);
				if (hasProductId) { productId = hasProductId[0].slice(1,-1); }
				return { deviceId: dev.deviceId, kind: dev.kind, label: dev.deviceId === 'default' ? 'Standard enhed' : Helper.cleanDeviceName(dev.label), groupId: dev.groupId, productId: productId, rawLabel: dev.label };
			});
			devices.forEach(device => {
				logger.debug(`MediaDevices: Found device: ID=${device.deviceId} KIND=${device.kind} LABEL=${device.label} RAW=${device.rawLabel}`);
			});

			// const defaultMicrophone = devices.find(o => o.deviceId == 'default' && o.kind == 'audioinput');
			// const defaultSpeaker = devices.find(o => o.deviceId == 'default' && o.kind == 'audiooutput');
			// if (defaultMicrophone && devices.find(o => o.deviceId != 'default' && o.productId == defaultMicrophone.productId)) {
			// 	devices = devices.filter(o => o != defaultMicrophone);
			// }
			// if (defaultSpeaker && devices.find(o => o.deviceId != 'default' && o.productId == defaultSpeaker.productId)) {
			// 	devices = devices.filter(o => o != defaultSpeaker);
			// }

			if (initial !== true) {
				// Device(s) has changed - find which one
				const removed = this.devices.filter(dev => devices.find(o => o.deviceId == dev.deviceId) == null);
				const added = devices.filter(dev => this.devices.find(o => o.deviceId == dev.deviceId) == null);
				this.devices = devices;
				removed.forEach(dev => {
					logger.debug(`MediaDevices: Device removed: ${dev.deviceId} - ${dev.label} - ${dev.kind} - ${dev.productId}`);
					EventBus.$emit('MediaDeviceChange', { event: 'removed', ...dev });
				});
				added.forEach(dev => {
					logger.debug(`MediaDevices: Device added: ${dev.deviceId} - ${dev.label} - ${dev.kind} - ${dev.productId}`);
					EventBus.$emit('MediaDeviceChange', { event: 'added', ...dev });
				});
			} else {
				this.devices = devices;
				EventBus.$emit('MediaDeviceSelected', { type: 'audioinput' });
			}
			logger.debug(`MediaDevices: Build list of devices with ${this.devices.length} devices`);
		}
		catch (err) {
			logger.warning(`MediaDevices: Build list failed: ${err.message}`);
			throw err;
		};
	}

	// #region Public

	/**
	 * Lookup a device
	 * @param {String} id Id of device
	 * @returns {Object<{deviceId, label, kind, productId, groupId, rawLabel }>}
	 */
	getDeviceById(id) {
		return this.devices.find(o => o.deviceId === id);
	}

	/**
	 * Get the name/label of a device
	 * @param {String} id Id of device
	 * @returns {string} Name of device, null if not found
	 */
	getDeviceName(id) {
		const dev = this.devices.find(o => o.deviceId === id);
		if (dev) { return dev.label; } else { return null; }
	}
	
	/**
	 * Returns list of audio input devices for settings
	 */
	get audioInDevices() {
		const selected = this.microphoneDeviceId;
		return this.devices.filter(o => o.kind == 'audioinput').map(o => { return { value: o.deviceId, text: o.label, group: o.groupId, selected: o.deviceId == selected }; });
	}

	/**
	 * Returns list of audio output devices for settings
	 */
	get audioOutDevices() {
		const selected = this.speakerDeviceId;
		return this.devices.filter(o => o.kind == 'audiooutput').map(o => { return { value: o.deviceId, text: o.label, group: o.groupId, selected: o.deviceId == selected }; });
	}

	/**
	 * Returns list of video input for settings
	 */
	get videoInDevices() {
		const selected = this.videoDeviceId;
		const list = this.devices.filter(o => o.kind == 'videoinput').map(o => { return { value: o.deviceId, text: o.label, group: o.groupId, selected: o.deviceId == selected }; });
		list.unshift({ value: null, text: 'Benyt ikke', groupId: null });
		return list;
	}

	get audioInputs() { return this.devices.filter(o => o.kind == 'audioinput'); }
	get audioOutputs() { return this.devices.filter(o => o.kind == 'audiooutput'); }
	get videoInputs() { return this.devices.filter(o => o.kind == 'videoinput'); }

	/**
	 * Returns list of device groups for settings
	 */
	get deviceGroups() {
		const list = {};
		this.devices.forEach(dev => {
			if (!list[dev.groupId]) { list[dev.groupId] = { groupId: dev.groupId, name: dev.label, devices: [] }; }
			list[dev.groupId].devices.push(dev);
		});
		return Object.values(list);
	}

	/**
	 * Check if a device is available and optionally of the right type
	 * @param {String} id Id of device
	 * @param {String} [type] Optional type of device: audioinput, audiooutput, videoinput
	 * @returns {Boolean} True if device exists
	 */
	isDeviceValid(id, type) {
		if (type) {
			return this.devices.find(o => o.deviceId == id && o.kind == type) != null;
		} else {
			return this.devices.find(o => o.deviceId == id) != null;
		}
	}

	/**
	 * Returns the id of the current microphone device - if the selected device is not available, will try previously selected device or fallback to default device
	 */
	get microphoneDeviceId() {
		let id = null;
		for (const device of this.preferred.audioInputs) {
			if (this.isDeviceValid(device)) {
				id = device;
				break;
			}
		}
		if (!id) {
			id = 'default';
		}
		return id;
	}

	/**
	 * Returns the current microphone device
	 */
	get microphoneDevice() {
		const id = this.microphoneDeviceId;
		return this.devices.find(o => o.deviceId == id && o.kind == 'audioinput');
	}

	/**
	 * Returns the id of the current speaker device - if the selected device is not available, will try previously selected device or fallback to default device
	 */
	get speakerDeviceId() {
		let id = null;
		for (const device of this.preferred.audioOutputs) {
			if (this.isDeviceValid(device)) {
				id = device;
				break;
			}
		}
		if (!id) { id = 'default'; }
		return id;
	}

	/**
	 * Returns the current speaker device
	 */
	get speakerDevice() {
		const id = this.speakerDeviceId;
		return this.devices.find(o => o.deviceId == id && o.kind == 'audiooutput');
	}

	/**
	 * Returns the id of the current video device - if the selected device is not available, will try previously selected device
 	*/
	get videoDeviceId() {
		let id = null;
		for (const device of this.preferred.videoInputs) {
			if (this.isDeviceValid(device)) {
				id = device;
				break;
			}
		}
		return id;
	}

	/**
	 * Returhns the current video device
	 */
	get videoDevice() {
		const id = this.videoDeviceId;
		return this.devices.find(o => o.deviceId == id && o.kind == 'videoinput');
	}

	/**
	 * Returns the id of the current speaker device - if the selected device is not available, will try previously selected device or fallback to default device
	 */
	get ringerDeviceId() {
		let id = null;
		for (const device of this.preferred.ringerOutputs) {
			if (this.isDeviceValid(device)) {
				id = device;
				break;
			}
		}
		if (!id) { id = 'default'; }
		return id;
	}

	/**
	 * Returns the ringer volume
	 */
	get ringerVolume() {
		return this.preferred.ringerVolume;
	}
	
	set microphoneDeviceId(id) {
		logger.info(`MediaDevices: Selected microphone with device id ${id}`);
		if (id) { this.preferred.audioInputs.unshift(id); }
		if (this.preferred.audioInputs.length > 5) { this.preferred.audioInputs.pop(); }
		localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		EventBus.$emit('MediaDeviceSelected', { type: 'audioinput' });
	}

	set speakerDeviceId(id) {
		logger.info(`MediaDevices: Selected speaker with device id ${id}`);
		if (id) { this.preferred.audioOutputs.unshift(id); }
		if (this.preferred.audioOutputs.length > 5) { this.preferred.audioOutputs.pop(); }
		localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		EventBus.$emit('MediaDeviceSelected', { type: 'audiooutput' });
	}

	set videoDeviceId(id) {
		logger.info(`MediaDevices: Selected video with device id ${id}`);
		if (id) { this.preferred.videoInputs.unshift(id); } else { this.preferred.videoInputs = []; }
		if (this.preferred.videoInputs.length > 5) { this.preferred.videoInputs.pop(); }
		localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		EventBus.$emit('MediaDeviceSelected', { type: 'videoinput' });
	}

	set ringerDeviceId(id) {
		logger.info(`MediaDevices: Selected ringer with device id ${id}`);
		if (id) { this.preferred.ringerOutputs.unshift(id); }
		if (this.preferred.ringerOutputs.length > 5) { this.preferred.ringerOutputs.pop(); }
		localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		EventBus.$emit('MediaDeviceSelected', { type: 'ringeroutput' });
	}

	set ringerVolume(volume) {
		logger.info(`MediaDevices: Set ringer volume to ${volume}`);
		if (volume >=0 && volume <= 1) { this.preferred.ringerVolume = volume; }
		localStorage.setItem('MediaDevices:Preferred', JSON.stringify(this.preferred));
		EventBus.$emit('MediaDeviceSelected', { type: 'ringervolume' });
	}
	
	// #endregion
}

/**
 * Helper functions
 */
class Helper {
	static cleanDeviceName(a) {
		a = a.replace('Microphone', '')
			.replace('Mikrofonmatrice', '')
			.replace('Integrated', i18n.t('mediaDevices.builtin'))
			.replace('Camera', '')
			.replace('Speakers', '')
			.replace('Speaker', '')
			.replace('Headset Earphone', '')
			.replace('Headset Speaker', '')
			.replace('Headset', '')
			.replace('Communications', i18n.t('mediaDevices.communication'))
			.replace('Virtual', i18n.t('mediaDevices.virtual'))
			.replace('Internal', i18n.t('mediaDevices.builtin'))
			.replace('(Built-in)', '')
			.replace('Default', i18n.t('mediaDevices.default'))
		;

		if (!a.startsWith('Mikrofon i')) { a = a.replace('Mikrofon', ''); }

		const m1 = a.match(/ \([0-9a-fA-F]{4}:[0-9a-fA-F]{4}\)$/);
		if (m1) { a = a.substr(0, m1.index); }
		a = a.replace(/ {2}/g, ' ').trim();

		if (a.startsWith('(') && a.endsWith(')')) { a = a.slice(0, -1); }
		if (a.startsWith('(')) { a = a.substr(1); }
		if (a.match(/^\d- \w/)) { a = a.substr(3); }
		return a;
	}

	static sleep(ms) { return new Promise(r => { setTimeout(r, ms); }); }
}


// Singleton
export default new MediaDevices();
