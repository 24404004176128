
import u from '../utils/utils';
import s from '../settings';

class Employee {
	constructor(data, permissions) {
		u.extendObject(this, data);
		if (this.birthdate && !(this.birthdate instanceof Date)) {
			this.birthdate = new Date(this.birthdate);
		}

		this.extensions.forEach(ext => {
			if (!ext.calls) { ext.calls = []; }
			ext.permissions = permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
			ext.calls.forEach(call => { this.fixCallDates(call); });
		});

		if (this._id == s.auth.employee) {
			this.me = true;
		}

	}

	/** Returns the state of the extension(s) */
	get extensionsState() {
		let state = 'IDLE';
		this.extensions.forEach(ext => {
			if (ext.state == 'BUSY') { state = 'BUSY'; }
			else if (ext.state == 'RINGING' && state == 'IDLE') { ext.state == 'RINGING'; }
			else if (ext.state == 'CALLING' && state == 'IDLE') { ext.state == 'CALLING'; }
		});
		return state;
	}

	/** Returns all active calls */
	get calls() {
		let calls = [];
		const deviceId = this.me ? s.myRealPhoneId : null;

		this.extensions.forEach(ext => {
			if (ext.calls) {
				ext.calls.forEach(call => {
					if (!deviceId || call.device == deviceId) {
						calls.push(u.extendObject({}, call));
					}
				});
			}
		});
		calls.sort(u.dynamicSort('started'));
		return calls;
	}

	/** Returns the newest answered call */
	get newestAnsweredCall() {
		let calls = this.calls;
		if (calls.length == 0) { return null; }
		calls.sort(u.dynamicSort('-started'));
		return calls[0];
	}

	get showOtherParty() {
		// employee.currentCall&&employee.currentCallExtension.permissions.viewOtherParty&&employee.viewOtherPartyOverrideCheck
		return this.currentCall && this.currentCallExtension.permissions.viewOtherParty && !this.currentCall.hideOtherParty;
	}

	/** Returns the current call */
	get currentCall() {
		const calls = this.calls;

		// If no calls - null
		if (calls.length == 0) { return null; }

		// If we have an answered call - return the newest one
		calls.sort(u.dynamicSort('-started'));
		const newestActiveCall = calls.find(o => o.state == 'CALL_ANSWER');
		if (newestActiveCall) { return newestActiveCall; }

		// If we have a CALLING or RINGING call - return the oldest one
		calls.sort(u.dynamicSort('started'));
		const oldestCallingCall = calls.find(o => o.state == 'CALL_START' && o.leg == 'I');
		if (oldestCallingCall) { return oldestCallingCall; }
		const oldestRingingCall = calls.find(o => o.state == 'CALL_RING');
		if (oldestRingingCall) { return oldestRingingCall; }

		// If we have a HELD call - return the newest one
		calls.sort(u.dynamicSort('-started'));
		const newestHeldCall = calls.find(o => o.state == 'CALL_HOLD');
		if (newestHeldCall) { return newestHeldCall; }

		// Otherwise return the oldest call
		return calls[0];
	}

	/** Returns the extension for the current call */
	get currentCallExtension() {
		const cc = this.currentCall;
		let ccExt;
		this.extensions.forEach(ext => {
			if (ext.calls.find(o => o._id == cc._id)) { ccExt = ext; }
		});
		return ccExt;
	}

	/** Get current ringing call if any */
	get currentRingingCall() {
		let res;
		this.extensions.forEach(ext => {
			ext.calls.forEach(call => {
				if (call.state == 'CALL_RING' && !res) { res = call; }
			});
		});
		return res;
	}
	
	/** Returns the icon for the current call */
	get currentCallIcon() {
		const cc = this.currentCall;
		let icon = 'call-idle';
		if (cc) {
			if (cc.state == 'CALL_HOLD') { icon = '756-call-pause'; }
			else if (cc.leg == 'I') {
				if (cc.state == 'CALL_START') { icon = 'call-dialing'; }
				else if (cc.state == 'CALL_ANSWER') { icon = 'call-outgoing'; }
			} else {
				if (cc.state == 'CALL_START' || cc.state == 'CALL_RING') { icon = 'call-ringing'; }
				else if (cc.state == 'CALL_ANSWER') { icon = 'call-incoming'; }
			}
		}
		return icon;
	}

	/** Returns the icon color for the current call */
	get currentCallIconColor() {
		const cc = this.currentCall;
		if (!cc) { return 'var(--call-duration-color)'; }
		if (cc.state == 'CALL_RING' || (cc.leg == 'O' && cc.state == 'CALL_START')) {
			return 'var(--call-ringing)';
		}
		if (cc.leg == 'I') {
			if (cc.state == 'CALL_START') { return 'var(--call-dialing)'; }
			else if (cc.state == 'CALL_ANSWER') { return 'var(--call-answered)'; }
		}
		return 'var(--call-answered)';
	}

	/** Returns the icon for the type of the current call */
	get currentCallTypeIcon() {
		const cc = this.currentCall;
		if (cc) {
			if (cc.leg == 'O') {
				if (cc.caller.queueId) { return '44-user-group'; }
				if (cc.caller.number.length < 8 && cc.caller.number.length > 0) { return '4-user'; }
				if (!cc.caller.number && !cc.caller.name) { return null; }
				return '465-call';
			} else {
				return '465-call';
			}
		} else {
			return '';
		}
	}

	/** Returns the duration of the current call in seconds */
	get currentCallDuration() {
		const cc = this.currentCall;
		if (!cc) { return 0; }
		if (cc.leg == 'I') {
			const val = Math.round((new Date() - cc.started)/1000);
			if (!isNaN(val) && val >= 0) { return val; } else { return 0; }
		} else {
			if (!cc.answered) { return 0; }
			const val = Math.round((new Date() - cc.answered)/1000);
			if (!isNaN(val) && val >= 0) { return val; } else { return 0; }
		}
	}

	/** Returns the primary extension */
	get primaryExtension() {
		let ext = this.extensions.find(o => o.primary);
		if (!ext) { ext = this.extensions[0]; }
		return ext;
	}

	/** Get permissions for the employee based on extensions */
	get permissions() {
		let res = { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
		this.extensions.forEach(ext => {
			const perms = ext.permissions;
			if (perms) {
				Object.keys(perms).forEach(key => {
					if (perms[key]) { res[key] = true; }
				});
			}
		});
		return res;
	}

	/** Get the employees initials */
	get initials() {
		const p = this.name.split(/ +/);
		let out = '';
		if (p[0]) { out += p[0][0]; }
		if (p.length > 1 && p[p.length-1]) { out += p[p.length-1][0]; }
		return out.toUpperCase();
	}

	get presenceBackgroundColor() {
		if (this.presence && s.presenceBackground) {
			return 'var(--presence-' + this.presence.state.replace(/_/g, '-').toLowerCase() + '-background)';
		} else {
			return 'transparent';
		}
	}

	getCallfromSessionId(sessionId) {
		let foundCall;
		this.extensions.forEach(ext => {
			ext.calls.forEach(call => {
				if (call.sessionId == sessionId) { foundCall = call; }
			});
		});
		return foundCall;
	}

	/**
	 * Convert dates to Date objects
	 * @private
	 */
	fixCallDates(call) {
		['started','ringing','answered','ended','hold'].forEach(key => {
			if (typeof call[key] == 'string') { call[key] = new Date(call[key]); }
		});
	}

}

export default Employee;
