<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<div>
			<b-btn v-show="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" :editMode="dashboards.editMode">
				<glyph-icon icon="4-user" color="#fff" size="14" style="margin-top:-2px"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ data.title }}<span v-if="collapsed">&nbsp;({{ extensions.length }})</span></div>
				<div v-show="!dashboards.editMode">
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="toggleCollapse()">
						<glyph-icon :icon="collapsed?'221-chevron-down':'222-chevron-up'" color="var(--collapse-button-color-dashboard-header)" size="18"></glyph-icon>
					</b-btn>
				</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="dash-settings-box">
				<b-form-group :label="$t('extensions.panelName')">
					<b-form-input type="text" maxlength="64" size="sm" v-model="data.title" :required="true"></b-form-input>
				</b-form-group>

				<b-form-group :label="$t('extensions.selectedEmployees')">
					<b-list-group>
						<draggable :list="extensions" @change="extensionsDragChange" class="list-group" ghost-class="ghost">
							<b-list-group-item class="d-flex justify-content-between align-items-center employee-item" v-for="item in extensions" :key="item._id" style="cursor:move;background-color:var(--bg-color-primary)">
								<div style="display:flex;overflow:hidden">
									<b-btn size="sm" variant="danger" class="delete-employee-btn" @click="removeExtension(item)">
										<glyph-icon icon="17-bin" size="15" color="#fff" style="flex-shrink:0"></glyph-icon>
									</b-btn>
									<div style="display:flex">
										<div class="title">{{ item.name }}</div>
										<glyph-icon v-if="item.type==='EXTENSION'" icon="464-phone-old" size="13" color="var(--txt-color-primary)" style="margin-top:-5px;margin-left:2px"></glyph-icon>
									</div>
								</div>
								<b-badge variant="primary" pill class="employee-pill">{{ getPrimaryExtension(item).number }}</b-badge>
							</b-list-group-item>
						</draggable>
						<b-list-group-item disabled v-if="extensions.length==0" style="font-size:var(--font-size-secondary);text-align:center;background:#e0e0e0">{{ $t('common.noneSelected') }}</b-list-group-item>
					</b-list-group>
				</b-form-group>

				<b-form-group :label="$t('extensions.availableEmployees')" style="margin-bottom:2px">
					<glyph-icon icon="28-search" color="var(--pn-primary-1)" size="24" style="position:absolute;padding-left:3px;padding-top:2px"></glyph-icon>
					<b-input type="search" size="sm" v-model="filter" :placeholder="$t('common.filter')" style="padding-left:25px;font-size:var(--font-size-secondary);color:var(--pn-black-1)"></b-input>
					<b-list-group style="maxHeight:155px;overflow:scroll">
						<b-list-group-item @click="addExtension(item)" href="#" class="d-flex justify-content-between align-items-center" v-for="item in availableExtensionsFiltered" :key="item._id" style="background-color:var(--bg-color-primary)">
							<div style="display:flex">
								<div class="title">{{ item.name }}</div>
								<glyph-icon v-if="item.type==='EXTENSION'" icon="464-phone-old" size="13" color="var(--txt-color-primary)" style="margin-top:-5px;margin-left:2px"></glyph-icon>
							</div>
							<b-badge variant="primary" class="employee-pill" pill>{{ getPrimaryExtension(item).number }}</b-badge>
						</b-list-group-item>
						<b-list-group-item disabled v-if="availableExtensionsFiltered.length==0" style="font-size:var(--font-size-secondary);text-align:center;background:#e0e0e0">{{ $t('common.noneAvailable') }}</b-list-group-item>
					</b-list-group>
				</b-form-group>

			</div>

			<!-- VIEW MODE -->
			<div v-if="!dashboards.editMode" class="employee-container" :style="{'max-height':collapsed?'0px':extensions.length*50+'px'}">
				<employee :employee-id="item._id" v-for="(item,index) in extensions" :key="item._id" :last="extensions.length-1==index"></employee>
			</div>
		</div>
	</panel-draggable>
</template>

<script>
// import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import u from '../../utils/utils';
import Draggable from 'vuedraggable';
import PanelDraggable from './PanelDraggable.vue';
import Employee from './Employee.vue';
import employees from '../../data/employees';
import EventBus from '../../data/EventBus';
import s from '../../settings';
import i18n from '../../utils/i18n';
// import Vue from 'vue';

export default {
	components: {
		GlyphIcon,
		Draggable,
		PanelDraggable,
		Employee
	},
	props: {
		data: Object
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			extensions: [],
			availableExtensions: [],
			filter: '',
			isDraggable: false,
			isLoading: false,
			collapsed: s.getGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsed`) || false
		};
	},
	computed: {
		availableExtensionsFiltered: function() {
			if (!this.filter) { return this.availableExtensions; }
			return this.availableExtensions.filter(o => {
				const search = [o.name, o.title, o.department, o.emailAddress];
				if (o.address) { search.push(o.address.address, o.address.city, o.address.zip); }
				o.extensions.forEach(ext => { search.push(ext.number); search.push(ext.direct); search.push(...ext.mobile); });
				const filter = u.getRegExFromFilter(this.filter);
				return filter.test(search.filter(o=>o).map(o=>o.toLowerCase()).join(' '));
			});
		}
	},
	methods: {
		extensionsDragChange(e) {
			if (e.moved) {
				this.data.extensions.splice(e.moved.newIndex, 0, this.data.extensions.splice(e.moved.oldIndex, 1)[0]);
			}
		},
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		getPrimaryExtension(item) {
			const extension = item.extensions.find(o => o.primary);
			if (!extension && item.extensions.length > 0) { return item.extensions[0]; }
			return extension;
		},
		addExtension(item) {
			this.extensions.push(item);
			const index = this.availableExtensions.findIndex(o => o._id == item._id);
			this.availableExtensions.splice(index, 1);
			this.data.extensions.push(item._id);
		},
		removeExtension(item) {
			this.availableExtensions.push(item);
			this.availableExtensions.sort(u.dynamicSort('name'));
			const index = this.extensions.findIndex(o => o._id == item._id);
			this.extensions.splice(index, 1);
			this.data.extensions.splice(index, 1);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		loadEmployees() {
			if (this.isLoading) { return; }
			this.isLoading = true;
			this.extensions = [];
			// Load the list of available employess and populate the list of selected employees and if in edit mode also the available employees list
			return employees.getAll().then(list => {
				const emps = {};
				list.forEach(employee => { emps[employee._id] = employee; });
				this.data.extensions.forEach(employeeId => {
					const employee = emps[employeeId];
					if (employee) { this.extensions.push(employee); }
				});
				if (dashboards.editMode) {
					this.availableExtensions = list.filter(employee => !this.data.extensions.includes(employee._id)).sort(u.dynamicSort('name'));
					const a = 1;
				}
				this.isLoading = false;
			});
		},
		toggleCollapse() {
			this.collapsed = !this.collapsed;
			s.setGeneric(`${s.myDashboardId}-${this.data.type}-${this.data.column}-${this.data.position}-collapsed`, this.collapsed);
		}
	},
	mounted() {
		EventBus.$on('Employees:Loaded', () => {
			this.loadEmployees();
		});
		this.loadEmployees();
	},
	watch: {
		// When edit mode is enabled, load the list of available employees
		'dashboards.editMode': function(newValue) {
			if (newValue) {
				this.availableExtensions = employees.employees.filter(employee => !this.data.extensions.includes(employee._id)).sort(u.dynamicSort('name'));
			} else {
				this.availableExtensions = [];
				this.filter = '';
			}
		}
	}
	
};
</script>

<style scoped>
	.employee-container {
		margin: 0;
		/* padding-bottom: 2px;
		padding-top: 2px; */
		display: flex;
		flex-direction: column;
		overflow: hidden;
		transition: 400ms;
	}
	.delete-employee-btn {
		padding: 0 2px 0 2px;
		margin-right: 5px;
		box-shadow: none !important;
	}
	.title {
		font-size: var(--font-size-secondary);
		color: var(--txt-color-primary);
	}
	.ghost {
		opacity: 0.25;
	}
	.employee-pill {
		flex-shrink: 0;
		padding-top: 2px;
		padding-bottom: 2px;
		font-size: var(--font-size-small) !important;
		font-weight: 500;
		min-width: 40px;
	}
	.employee-item {
		padding: 2px 5px 2px 5px !important;
	}

</style>