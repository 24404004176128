/*
 * Queues data
 * 
 * - Holds the current set of queues
 * - Handles everything about queues from the dashboard
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2021-03-24
 */

import Vue from 'vue';
import EventBus from './EventBus';
import s from '../settings';
import rt from './realtime';
import u from '../utils/utils';
import i18n from '../utils/i18n';
import logger from './logger';

class Queues {
	// #region Init, load, setup

	constructor() {
		this.queues = [];
		this._ready = false;
		this.lastQueueRefreshCheck = new Date();

		EventBus.$on('Realtime:Config:PBX_QUEUE_CREATED', this.createdEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_QUEUE_UPDATED', this.updatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_QUEUE_DELETED', this.deletedEvent.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_ENTERED', this.callerEntered.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_UPDATE', this.callerUpdate.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_LEFT', this.callerLeft.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_MEMBER_JOIN', this.memberJoin.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_MEMBER_UPDATE', this.memberUpdate.bind(this));
		EventBus.$on('Realtime:Pbx:QUEUE_MEMBER_LEFT', this.memberLeft.bind(this));
		EventBus.$on('Realtime:Connected', this.load.bind(this));
		EventBus.$on('Auth:LoggedOut', () => {
			this.queues = [];
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'queues', value: this._ready });
		});

		if (rt.connected) { // else we do it on Realtime:Connected
			this.load();
		}

		EventBus.$on('OneMinute', () => { this.refresh(); });
		logger.registerGlobal('queues', this);
	}

	refresh() {
		// Only refresh at midnight
		if (this.lastQueueRefreshCheck.getDate() != new Date().getDate()) {
			this.lastQueueRefreshCheck = new Date();
			this.load();
			logger.info('Queues: Performing midnight reload');
		}
	}

	async load() {
		logger.info('Queues: Loading queues');
		this._ready = false;
		try {
			const res = await s.http.get('/pbx/app/queues?all=true');
			this.queues = res.data;
			this.queues.forEach(queue => {
				queue.members.forEach(member => {
					if (member.lastCallEnded) {
						member.lastCallEnded = new Date(member.lastCallEnded);
					}
				});
				queue.callers.forEach(caller => {
					if (caller.entered) {
						caller.entered = new Date(caller.entered);
					}
					if (caller.answered) {
						caller.answered = new Date(caller.answered);
					}
				});
			});
			this._ready = true;
			EventBus.$emit('Queues:Loaded');
			logger.info('Queues: ' + this.queues.length + ' queues loaded');
		} catch (_) { }
	}

	createdEvent(e) {
		s.http.get(`/pbx/app/queues?all=true&queue=${e.id}`)
			.then(res => {
				this.queues.push(res.data[0]);
				EventBus.$emit('Queues:Created', res.data[0]);
			})
			.catch(err => {
				if (err.status != 0 && err.code != 'network') {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('queues.errorHeader'), message: i18n.t('queues.errorLoadingNewlyCreatedQueueMessage').replace('%%id%%', e.id).replace('%%response%%', JSON.stringify(res.data)) });
				}
			});
	}

	updatedEvent(e) {
		s.http.get(`/pbx/app/queues?all=true&queue=${e.id}`)
			.then(res => {
				const index = this.queues.findIndex(o => o._id == e.id);
				if (index != -1) {
					Vue.set(this.queues, index, res.data[0]);
					EventBus.$emit('Queues:Updated', res.data[0]);
				}
			})
			.catch(err => {
				if (err.status != 0 && err.code != 'network') {
					console.log(`Queues: Failed to load queue ${e.id} from server: ${JSON.stringify(res.data)}`); // eslint-disable-line
					EventBus.$emit('CommonErrorModal', { header: i18n.t('queues.errorHeader'), message: i18n.t('queues.errorLoadingUpdatedQueueMessage').replace('%%id%%', e.id).replace('%%response%%', JSON.stringify(res.data)) });
				}
			});
	}

	deletedEvent(e) {
		const index = this.queues.findIndex(o => o._id == e.id);
		if (index != -1) {
			const queue = this.queues[index];
			this.queues.splice(index, 1);
			EventBus.$emit('Queues:Deleted', queue);
		}
	}

	callerEntered(data) {
		data.entered = new Date(data.entered);
		const queueId = data.queue;
		u.pruneObject(data, ['_id','state','number','name','privacy','entered','answered','answeredByExtension','answeredByNumber']);
		const index = this.queues.findIndex(o => o._id == queueId);
		if (index != -1) {
			if (data.number && data.number.toLowerCase() == 'anonymous') { data.privacy = true; }
			if (data.name && data.name.toLowerCase() == 'anonymous') { data.privacy = true; }
			this.queues[index].callers.push(data);
			logger.debug(`Queues: Caller ${data.number} ${data.name} entered queue ${this.queues[index].name}, now has ${this.queues[index].callers.length} callers`);
			EventBus.$emit('Queues:Entered', { queue: queueId, caller: data });
		}
	}

	callerUpdate(data) {
		data.entered = new Date(data.entered);
		if (data.answered) { data.answered = new Date(data.answered); }
		const queueId = data.queue;
		u.pruneObject(data, ['_id','state','number','name','privacy','entered','answered','answeredByExtension','answeredByNumber']);
		const index = this.queues.findIndex(o => o._id == queueId);
		if (index != -1) {
			const callerIndex = this.queues[index].callers.findIndex(o => o._id == data._id);
			if (callerIndex != -1) {
				Vue.set(this.queues[index].callers, callerIndex, data);
				logger.debug(`Queues: Caller ${data.number} ${data.name} updated in queue ${this.queues[index].name}, now has ${this.queues[index].callers.length} callers`);
				EventBus.$emit('Queues:Update', { queue: queueId, caller: data, index: callerIndex });
			}
		}
	}

	callerLeft(data) {
		data.entered = new Date(data.entered);
		if (data.answered) { data.answered = new Date(data.answered); }
		const queueId = data.queue;
		u.pruneObject(data, ['_id','state','number','name','privacy','entered','answered','answeredByExtension','answeredByNumber']);
		const index = this.queues.findIndex(o => o._id == queueId);
		if (index != -1) {
			const callerIndex = this.queues[index].callers.findIndex(o => o._id == data._id);
			if (callerIndex != -1) {
				this.queues[index].callers.splice(callerIndex, 1);
				logger.debug(`Queues: Caller ${data.number} - ${data.name} left queue ${this.queues[index].name}, now has ${this.queues[index].callers.length} callers`);
				EventBus.$emit('Queues:Left', { queue: queueId, caller: data, index: callerIndex });
			}
		}
	}

	memberJoin(data) {
		if (data.data.lastCallEnded) { data.data.lastCallEnded = new Date(data.data.lastCallEnded); }
		const index = this.queues.findIndex(o => o._id == data._id);
		if (index != -1) {
			this.queues[index].members.push(data.data);
			EventBus.$emit('Queues:MemberJoin', { queue: data._id, member: data.data });
			logger.debug(`Queues: Member ${data.data._id} ${data.data.number || ''} joined queue ${this.queues[index].name}`);
		}

	}

	memberUpdate(data) {
		if (data.data.lastCallEnded) { data.data.lastCallEnded = new Date(data.data.lastCallEnded); }
		const index = this.queues.findIndex(o => o._id == data._id);
		if (index != -1) {
			const memberIndex = this.queues[index].members.findIndex(o => o._id == data.data._id);
			if (memberIndex != -1) {
				Vue.set(this.queues[index].members, memberIndex, data.data);
				EventBus.$emit('Queues:MemberUpdate', { queue: data._id, member: data.data, index: memberIndex });
				logger.debug(`Queues: Member ${data.data._id} ${data.data.number || ''} updated in queue ${this.queues[index].name}`);
			}
		}
	}

	memberLeft(data) {
		if (data.data.lastCallEnded) { data.data.lastCallEnded = new Date(data.data.lastCallEnded); }
		const index = this.queues.findIndex(o => o._id == data._id);
		if (index != -1) {
			const memberIndex = this.queues[index].members.findIndex(o => o._id == data.data._id);
			if (memberIndex != -1) {
				this.queues[index].members.splice(memberIndex, 1);
				EventBus.$emit('Queues:MemberLeft', { queue: data._id, member: data.data, index: memberIndex });
				logger.debug(`Queues: Member ${data.data._id} ${data.data.number || ''} left queue ${this.queues[index].name}`);
			}
		}
	}

	ready() {
		if (this._ready) { return Promise.resolve(); }
		return s.sleep(250).then(() => this.ready());
	}

	// #region public APIs

	async getAll() {
		return this.ready().then(() => { return this.queues; });
	}

	async getList() {
		return this.ready().then(() => { return this.queues.map(o => { return { _id: o._id, name: o.name, text: o.name + (o.number ? ` (${o.number})`:'') }; }); });
	}
	
	async getQueue(id) {
		return this.ready().then(() => {
			 return this.queues.find(o => o._id == id);
		});
	}

	// #endregion

}

// Singleton
export default new Queues();
