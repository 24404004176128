<template>
	<div>
		<div class="cdr-container">
			<div class="cdr-listbox">
				<div class="dash-header">
					<glyph-icon icon="115-list" color="var(--icon-color-light)" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:8px">{{ $t('cdr.title') }}</div>
				</div>

				<div style="background-color:var(--bg-color-secondary);padding: 5px 20px 0 20px;border-bottom:var(--divider-line)">
					<b-row>
						<b-col>
							<b-form-group :label="$t('cdr.from')" style="margin-bottom:4px !important">
								<b-form-datepicker v-model="from" :value-as-date="true" class="mb-2" :min="minDate" :max="maxDate" :locale="$i18n.locale" size="sm" :placeholder="$t('cdr.from')" start-weekday="1" v-bind="labels" :hide-header="true" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-form-datepicker>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('cdr.to')" style="margin-bottom:0 !important">
								<b-form-datepicker v-model="to" :value-as-date="true" class="mb-2" :min="minDate" :max="maxDate" :locale="$i18n.locale" size="sm" :placeholder="$t('cdr.to')" start-weekday="1" v-bind="labels" :hide-header="true" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-form-datepicker>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('cdr.filter')" style="margin-bottom:0 !important">
								<b-form-input type="search" v-model="filter" size="sm" debounce="500" />
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('cdr.type')" style="margin-bottom:0 !important">
								<b-form-select v-model="type" :options="types" @change="loadList" size="sm" />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
				<div v-if="!isLoading&&list.length==0" class="none-found">{{ $t('cdr.noneFound') }}</div>
				<div class="loading" :class="{'active':isLoading}">
					<div>
						<b-spinner variant="light" style="width:30px;height:30px" />
						<br/>{{ $t('cdr.loading') }}
					</div>
				</div>
				<div class="cdr-list">
					<RecycleScroller class="my-scroller" :items="list" :item-size="39" key-field="_id" v-slot="{ item, index }">
						<list-item :item="item" :click="select" :index="index" :me="me" :contextMenu="showContextMenu" :from="from" :to="to" />
					</RecycleScroller>
				</div>
				<v-contextmenu ref="cdrmenu" id="cdrmenu">
					<v-contextmenu-item @click="call">{{ $t('cdr.call') }}</v-contextmenu-item>
					<v-contextmenu-item @click="filterOnThis">{{ $t('cdr.applyFilter') }}</v-contextmenu-item>
					<v-contextmenu-item v-if="selected&&selected.recording&&selected.recording.length==1&&!impersonated" @click="playRecording(0)">{{ $t('cdr.playRecording') }}</v-contextmenu-item>
					<template v-if="selected&&selected.recording&&selected.recording.length>1&&!impersonated">
						<v-contextmenu-item v-for="(rec,index) in item.recording" :key="rec._id" @click="playRecording(index)">{{ $t('cdr.playRecording') }} {{ index + 1 }}</v-contextmenu-item>
					</template>
				</v-contextmenu>
			</div>

		</div>
	</div>
</template>

<script>
import ListItem from './ListItem.vue';
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';
import callHistory from '../../data/callHistory';

export default {
	components: {
		ListItem,
		GlyphIcon
	},
	data() {
		return {
			s: s,
			$t: i18n.t,
			me: null,
			list: [],
			selected: null,
			filter: '',
			from: new Date(),
			to: new Date(),
			type: 'ALL',
			types: [
				{ value: 'ALL', text: this.$t('cdr.types.all') },
				{ value: 'IN', text: this.$t('cdr.types.in') },
				{ value: 'OUT', text: this.$t('cdr.types.out') },
				{ value: 'UNANSWERED', text: this.$t('cdr.types.unanswered') },
				{ value: 'FAILED', text: this.$t('cdr.types.failed') }
			],
			isLoading: false,
			loadAgain: false,
			minDate: null,
			maxDate: null,
			labels: i18n.messages[i18n.locale].datepicker
		};
	},
	methods: {
		showContextMenu(e, item) {
			this.selected = item;
			e.preventDefault();
			setTimeout(() => { this.$refs['cdrmenu'].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		call() {
			const direction = this.selected.caller.extension == this.me.primaryExtension._id ? 'OUT' : 'IN';
			const other = direction == 'IN' ? this.selected.caller : this.selected.callee;
			const target = other.extension ? other.extension : s.cutNationalPrefix(other.number);
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: this.$t('common.error.noPhoneHeader'), message: this.$t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(target);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: this.$t('common.error.callFailedHeader'), message: this.$t('common.error.callFailedMessage') });
				});
			}
		},
		filterOnThis() {
			const direction = this.selected.caller.extension == this.me.primaryExtension._id ? 'OUT' : 'IN';
			const other = direction == 'IN' ? this.selected.caller : this.selected.callee;
			this.filter = other.number;
		},
		playRecording(index) {
			if (index == null) { index = 0; }
			EventBus.$emit('AudioPlayerModal:Show', {
				header: `${this.$t('cdr.recordingHeader')} ${u.formatDate(this.selected.started,'d/M-yyyy hh:mm')}`,
				audioId: this.selected.recording[index]._id,
				length: this.selected.recording[index].length,
				filename: `${this.$t('cdr.recordingHeader')} ${u.formatDate(this.selected.started,'dd-MM-yyyy hh.mm')} - ${s.cutNationalPrefix(this.selected.caller.number)} - ${s.cutNationalPrefix(this.selected.callee.number)}`
			});
		},
		async select(item) {
			this.selected = item;
		},
		async loadList() {
			if (this.isLoading) { this.loadAgain = true; return; }
			this.isLoading = true;
			let query = { recording: true, offset: 0, limit: 2000 };

			if (this.filter) { query.filter = this.filter; }

			query.from = new Date(this.from);
			query.to = new Date(this.to);
			if (query.to < query.from) { query.to = new Date(query.from); query.to.setHours(23, 59, 59, 999); }
			query.from.setHours(0, 0, 0, 0);
			query.to.setHours(23, 59, 59, 999);

			const me = await employees.getMe();
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: this.$t('common.error.noUserProfileHeader'), message: this.$t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
			query.extension = me.primaryExtension._id;

			if (this.type == 'IN') { query.direction = 'IN'; }
			if (this.type == 'OUT') { query.direction = 'OUT'; }
			if (this.type == 'UNANSWERED') { query.direction = 'IN'; query.answeredByCallee = false; }
			if (this.type == 'FAILED') { query.terminatedReason = 'FAILED'; }

			s.http.get('/pbx/cdr', { params: query })
				.then(res => {
					this.isLoading = false;
					if (this.loadAgain) {
						this.loadAgain = false;
						this.loadList();
					}
					res.data.cdrs.forEach(item => {
						['started','answered','ended'].forEach(key => {
							if (item[key]) { item[key] = new Date(item[key]); }
						});
					});
					this.list = [];
					this.$nextTick(() => {
						this.list = res.data.cdrs.reverse();
					});
				})
				.catch(err => {
					this.isLoading = false;
					this.loadAgain = false;
					EventBus.$emit('CommonErrorModal', { header: this.$t('cdr.title'), message: this.$t('cdr.loadError') + '<br><br>' + err.message });
				});
		},
		pbxEventHandler(ev) {
			const now = new Date();
			const myFrom = new Date(this.from);
			const myTo = new Date(this.to);
			myFrom.setHours(0,0,0,0);
			myTo.setHours(23,59,59,999);
			if (this.me && ev.extension == this.me.primaryExtension._id && myFrom <= now && myTo >= now) {
				this.loadList();
			}
		}
	},
	computed: {
		impersonated: function() { return s.auth.impersonatedBy != null; },
	},
	created() {
		employees.getMe().then(me => {
			this.me = me;
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: this.$t('common.error.noUserProfileHeader'), message: this.$t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
		}).catch(() => {});
		EventBus.$on('Realtime:Pbx:CALL_END', this.pbxEventHandler.bind(this));
	},
	mounted() {
	},
	watch: {
		'filter': function() { this.loadList(); },
		'from': function() { this.loadList(); },
		'to': function() { this.loadList(); },
		's.selectedSection': function(value) {
			if (value == 'CALLS') {
				this.minDate = new Date();
				this.minDate.setDate(this.minDate.getDate() - 365);
				this.minDate.setHours(0,0,0,0);
				this.maxDate = new Date();
				this.maxDate.setHours(23,59,59,999);
				if (callHistory.missed > 0) {
					this.type = 'UNANSWERED';
					this.resetTypeOnExit = true;
				}
				this.loadList();
			} else {
				if (this.resetTypeOnExit) {
					this.resetTypeOnExit = false;
					this.type = 'ALL';
				}
			}
		}
	}
};
</script>

<style scoped>
	.cdr-container {
		margin: 10px 10px 10px 10px;
		height: calc(100vh - 95px);
		text-align: center;
	}
	@media (max-width: 769px) {
		.cdr-container {
			height: calc(100vh - 190px);
		}
	}
	.cdr-listbox {
		background: var(--bg-color-secondary);
		border-radius: 5px;
		height: 100%;
		max-width: 600px;
		margin: 0 auto;
	}
	.cdr-list {
		height: calc(100% - 92px); 
		/* overflow-y: scroll; */
	}
	.my-scroller {
		height: 100%;
	}
	.loading {
		position: absolute;
		transition: opacity 500ms, max-height 1ms 500ms, transform 500ms;
		z-index: 1000;
		overflow: hidden;
		max-height: 0;
		width: 600px;
		height: 110px;
		opacity: 0;
	}
	.loading > DIV {
		position: absolute;
		text-align: center;
		background: var(--pn-primary-2);
		color: var(--txt-color-primary-highlight);
		padding: 20px 10px;
		border-radius: 20px;
		font-weight: 300;
		font-size: var(--font-size-large);
		margin-left: 200px;
		margin-right: 200px;
		margin-top: 10px;
		margin-bottom: 10px;
		overflow: hidden;
		width: 200px;
		height: 95px;
	}
	.loading.active {
		transition: opacity 500ms 1s, max-height 1ms 1s, transform 500ms 1s;
		max-height: 200px;
		opacity: 1;
		transform: translateY(10px);
	}
	.none-found {
		text-align: center;
		margin: 10px;
		background: var(--pn-primary-2);
		color: var(--txt-color-primary-highlight);
		padding: 4px;
		border-radius: 10px;
		font-weight: 300;
		font-size: var(--font-size-large);
	}
</style>