<template>
	<div
		class="call-draggable"
		:class="{'is-dragging':isDragging}"
		:draggable="draggable"
		@dragstart="dragStart"
		@dragend="dragEnd"
		@dragenter="dragEnter"
		@dragleave="dragLeave"
		@dragover="dragOver"
		@drop="dragDrop"
	>
		<div>
			<div class="dropbox-overlay" :class="{'visible':isOver}" :style="{'height': myHeight+'px','width': myWidth+'px'}"></div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import callDrag from '../../data/callDrag';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import i18n from '../../utils/i18n';

export default {
	props: {
		draggable: { type: Boolean },
		target: { type: Boolean },
		data: { type: Object },
		type: { type: String },
		targetTypes: { type: Array }
	},
	data() {
		return {
			isDragging: false,
			isOver: false,
			leaveTimer: null,
			lastEvent: null,
			myHeight: 0,
			myWidth: 0,
			dynamicTarget: null,
			me: null
		};
	},
	computed: {
		trueTarget: function() {
			return this.dynamicTarget != null ? this.dynamicTarget : this.target;
		}
	},
	methods: {
		dragStart(ev) {
			if (this.draggable) {
				callDrag.dragItem = this.data;
				callDrag.dragItemType = this.type;
				ev.dataTransfer.effectAllowed = 'link';
				this.isDragging = true;
				this.lastEvent = 'dragStart';
				EventBus.$emit('CallDragStart', this);
			}
		},
		dragEnd() {
			if (this.draggable || this.isDragging) {
				this.isDragging = false;
				callDrag.dragItem = null;
				callDrag.dragItemType = null;
				callDrag.dragTarget = null;
				callDrag.dragTargetType = null;
				this.lastEvent = 'dragEnd';
				EventBus.$emit('CallDragEnd');
			}
		},
		dragEnter(ev) {
			if (!callDrag.dragItem || !this.trueTarget || callDrag.dragItem == this.data) {
				ev.dataTransfer.dropEffect = 'none';
				return;
			}
			callDrag.dragTarget = this.data;
			callDrag.dragTargetType = this.type;
			ev.dataTransfer.dropEffect = 'move';
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			if (callDrag.dragItem != this.data) {
				this.isOver = true;
				this.myHeight = this.$el.clientHeight;
				this.myWidth = this.$el.clientWidth;
			}
			this.lastEvent = 'dragEnter';
		},
		dragOver(ev) {
			ev.preventDefault();
			if (!callDrag.dragItem || !this.trueTarget || callDrag.dragItem == this.data) {
				ev.dataTransfer.dropEffect = 'none';
				return;
			}
			if (this.lastEvent == 'dragOver') { return; }
			callDrag.dragTarget = this.data;
			callDrag.dragTargetType = this.type;
			ev.dataTransfer.dropEffect = 'move';
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			if (callDrag.dragItem != this.data) {
				this.isOver = true;
				this.myHeight = this.$el.clientHeight;
				this.myWidth = this.$el.clientWidth;
			}
			this.lastEvent = 'dragOver';
		},
		dragLeave(ev) {
			if (!callDrag.dragItem || !this.trueTarget || callDrag.dragItem == this.data) { return; }
			callDrag.dragTarget = null;
			callDrag.dragTargetType = null;
			if (callDrag.dragItem != this.data) {
				this.leaveTimer = setTimeout(() => {
					this.isOver = false;
					this.leaveTimer = null;
				}, 100);
			}
			this.lastEvent = 'dragLeave';
		},
		dragDrop(ev) {
			if (!callDrag.dragItem || !this.trueTarget || callDrag.dragItem == this.data) { return; }
			ev.preventDefault();
			this.isOver = false;
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			this.lastEvent = 'dragDrop';
			callDrag.dragDropped();
		},
		handleDragStartEvent(source) {
			if (source != this) {
				// Special cases

				// If source is Employee, and target is Employee, ensure source has a ringing call and we have pickup permission OR source has an ongoing call and we have steal permission
				if (source.type == 'Employee' && (this.type == 'Employee' || this.type == 'MyCalls' || this.type == 'QueueCallers')) {
					const currentCall = source.data.currentCall;
					if (currentCall && ['CALL_START','CALL_RING'].includes(currentCall.state) && source.data.currentCallExtension.permissions.pickup) {
						this.dynamicTarget = true;
					} else if (currentCall && ['CALL_ANSWER','CALL_HOLD'].includes(currentCall.state) && source.data.currentCallExtension.permissions.steal) {
						this.dynamicTarget = true;
					} else {
						this.dynamicTarget = false;
					}

				// If source is Employee and target is QueueMembers, ensure user has permission to add employee to queues
				} else if (source.type == 'Employee' && this.type == 'QueueMembers') {
					this.dynamicTarget = source.data.permissions.queueMember && this.data.dynamic;

				// When adding a queue member to another queue, ensure it is another queue
				} else if (source.type == 'QueueMember' && this.type == 'QueueMembers') {
					this.dynamicTarget = this.me.permissions.queueMember && this.data.dynamic && this.data._id != source.data.queueId;

				// When moving a call from one queue to another, ensure it is moved to another queue
				} else if (source.type == 'QueueCall' && this.type == 'QueueCallers') {
					this.dynamicTarget = this.data._id != source.data.queueId;

				} else if (Array.isArray(this.targetTypes)) {
					// Types: MyCalls, MyCallsCall, Employee, QueueCallers, QueueMembers, QueueCall, QueueMember
					// MyCalls accepts: Employee, QueueCall
					// MyCallsCall accepts: none - never does
					// Employee accepts: MyCallsCall, Employee (for picking up ringing/active call), QueueCall
					// QueueCallers accepts: MyCallsCall,
					// QueueMembers accepts: Employee,
					// QueueCall accepts: none - never does
					// QueueMember accepts: none - never does

					this.dynamicTarget = this.targetTypes && this.targetTypes.includes(source.type);
				} else {
					this.dynamicTarget = null;
				}
			}
		},
		handleDragEndEvent() {
			this.dynamicTarget = null;
		}
	},
	created() {
		employees.getMe().then(me => {
			this.me = me;
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header:  i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
		}).catch(() => {});
		this.handleDragStartEventBound = this.handleDragStartEvent.bind(this);
		EventBus.$on('CallDragStart', this.handleDragStartEventBound);
		this.handleDragEndEventBound = this.handleDragEndEvent.bind(this);
		EventBus.$on('CallDragEnd', this.handleDragEndEventBound);
	},
	destroyed() {
		EventBus.$off('CallDragStart', this.handleDragStartEventBound);
		EventBus.$off('CallDragEnd', this.handleDragEndEventBound);
	}
};
</script>

<style scoped>
	.is-dragging {
		opacity: 0.2;
	}
	.call-draggable {
		background: var(--bg-color-secondary);
		border-radius: 5px;
	}
	.drop-target {
		transition: 500ms;
		border-radius: 5px 5px;
	}
	.drop-target-border {
		border: 1px dashed var(--border-color-dragdrop);
		background-color: var(--bg-color-dragdrop);
		margin-bottom: 10px;
	}
	.dropbox-overlay {
		opacity: 0;
		position: absolute;
		/* border: 3px solid var(--pn-telecom-1); */
		border-radius: 3px;
		color: var(--pn-telecom-1);
		display: block;
		z-index: 1000;
		background: var(--bg-color-dragdrop-dropbox);
		box-shadow: 0 0 5px 5px var(--pn-telecom-3);
		transition: opacity 600ms, max-height 1ms 601ms;
		max-height: 0;
	}
	.dropbox-overlay.visible {
		opacity: 1;
		transition: opacity 300ms, max-height 1ms;
		max-height: 1000px;
	}

</style>