/**
 * Module for communicating with Sennheiser headsets
 * - will use Sennheiser Epos Connect over websocket wss://127.0.0.1:41088/ or fallback to WebHID in Chrome
 */

import s from '../../settings';
import EventBus from '../../data/EventBus';
import SennHeiserEposApi from './SennheiserEposAPI';
import mediaDevices from '../../data/mediaDevices';
import logger from '../../data/logger';
import sipClient from '../../data/sipClient';

class Sennheiser {
	constructor() {
		logger.debug('Sennheiser: Constructor');
		this.use = false;
		this.useDeviceName = null;
		this.callId = 0;
		this.api = null;

		EventBus.$on('MediaDeviceChange', device => {
			if (device.kind == 'audioinput') {
				const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
				if (device?.label.toLowerCase().includes('sennheiser') || device?.label.toLowerCase().includes('epos')) {
					this.init();
				} else {
					this.use = false;
					this.useDeviceName = null;
					if (this.api) {
						this.api.terminate();
						this.api = null;
					}
					logger.info('Sennheiser: Selected device is not Sennheiser - terminating');
				}
			}
		});
		EventBus.$on('MediaDeviceSelected', data => {
			if (data.type == 'audioinput') {
				const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
				if (device?.label.toLowerCase().includes('sennheiser') || device?.label.toLowerCase().includes('epos')) {
					this.init();
				} else {
					this.use = false;
					this.useDeviceName = null;
					if (this.api) {
						this.api.terminate();
						this.api = null;
					}
					logger.info('Sennheiser: Selected device is not Sennheiser - terminating');
				}
			}
		});
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'sennheiser', value: this.use });
			if (this.use) {
				EventBus.$emit('StatusReport', { key: 'sennheiserDevice', value: this.useDeviceName });
			}
		});

		logger.registerGlobal('sennheiser', this);
	}

	// #region Init

	/**
	 * Setup what needs to be setup to initialize the headset device
	 */
	async init() {
		logger.info('Sennheiser: Initializing');
		this.use = false;
		this.useDeviceName = null;
		await mediaDevices.ready();
		if (mediaDevices.microphoneDeviceId == null || mediaDevices.microphoneDeviceId == 'default') {
			// No device specified
			logger.info('Sennheiser: No device specified - initialization cancelled');
			return;
		}			

		const device = mediaDevices.devices.find(o => o.deviceId == mediaDevices.microphoneDeviceId);
		if (device?.label.toLowerCase().includes('sennheiser') || device?.label.toLowerCase().includes('epos')) {
			return this.initBrowser(device);
		} else {
			logger.info('Sennheiser: Selected audio device is not from Sennheiser - Initialization cancelled');
		}
	}

	/**
	 * Initialize for browser and app based usage
	 * @param {Object} device Audio device we want to control
	 * @return {Promise}
	 */
	async initBrowser(device) {
		logger.info(`Sennheiser: InitBrowser: Initializing - looking for ${device.label} with ID ${device.productId}`);
		if (!this.api) {
			this.api = new SennHeiserEposApi('TelecomX', 'Communicator', s.version, true);
			window.senn = this.api;
			this.api.addEventListener('answer', () => {
				logger.info('Sennheiser: User pressed answer');
				sipClient.headsetAnswerEvent();
			});
			this.api.addEventListener('reject', () => {
				logger.info('Sennheiser: User pressed reject');
				sipClient.headsetRejectEvent();
			});
			this.api.addEventListener('hangup', () => {
				logger.info('Sennheiser: User pressed hangup');
				sipClient.headsetHangupEvent();
			});
			this.api.addEventListener('hold', () => {
				logger.info('Sennheiser: User put call on hold');
				sipClient.headsetActivateHoldEvent();
			});
			this.api.addEventListener('resume', () => {
				logger.info('Sennheiser: User resumed call');
				sipClient.headsetDeactivateHoldEvent();
			});
			this.api.addEventListener('status', data => {
				logger.info('Sennheiser: got event: ' + data.detail.state);
				switch (data.detail.state) {
					case 'CONNECTING':
						break;
					case 'RECONNECTING':
						this.use = false;
						break;
					case 'TERMINATED':
						this.use = false;
						break;
					case 'DISCONNECTED':
						this.use = false;
						break;
					case 'CONNECTED':
						this.use = true;
						this.api.selectDevice(device.label);
						break;
				}
			});
			this.api.addEventListener('headset', data => {
				if (data.detail.type === 'connected') {
					this.useDeviceName = data.detail.name;
					logger.info('Sennheiser: Event: Headset connected - ' + data.detail.name);
				}
				if (data.detail.type === 'disconnected') {
					logger.info('Sennheiser: Event: Headset disconnected - ' + data.detail.name);
				}
			});
		}
	}

	// #endregion

	// #region Actions - public

	inboundStart(callId) {
		logger.info('Sennheiser: Telling headset inbound call started');
		this.api.inboundStart(callId);
	}

	offHook(callId) {
		logger.info('Sennheiser: Telling headset call was answered');
		this.api.offHook(callId);
	}

	inboundReject(callId) {
		logger.info('Sennheiser: Telling headset inbound call was rejected');
		this.api.inboundReject(callId);
	}

	outboundStart(callId) {
		logger.info('Sennheiser: Telling headset outbound call has started');
		this.api.outboundStart(callId);
	}

	outboundReject(callId) {
		logger.info('Sennheiser: Telling headset outbound call was rejected');
		this.api.outboundReject(callId);
	}

	hangup(callId) {
		logger.info('Sennheiser: Telling headset to hangup');
		setTimeout(() => {
			this.api.hangup(callId);
		}, 500);
	}
	
	hold(callId) {
		logger.info('Sennheiser: Telling headset to go on hold');
		this.api.hold(callId);
	}

	unhold(callId) {
		logger.info('Sennheiser: Telling headset to go off hold');
		this.api.unhold(callId);
	}

	async reInit() {
		await this.api.terminate();
		await this.init();
	}

	// #endregion

}

// Singleton
export default new Sennheiser();