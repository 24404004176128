<template>
	<div class="cdr-list-item" @dblclick="call" :class="{'odd':index % 2 === 0}" @contextmenu="showContextMenu">
		<div>
			<glyph-icon v-if="item.recording&&item.recording.length>0" icon="664-equalizer-bars" color="var(--icon-color-dark)" size="18" style="position:absolute;margin-top:13px;margin-left:-2px" />
			<glyph-icon :icon="icon" :color="iconColor" size="32" style="margin-top:5px" />
		</div>
		<div v-if="direction=='IN'" style="display:flex;justify-content:center;flex-direction:column">
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="item.caller.privacy">{{ $t('cdr.unlistedNumber') }}</div>
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="!item.caller.privacy">{{ item.caller.name ? item.caller.name : item.caller.number | cutNationalPrefixAndFormat }}</div>
			<div style="font-size:var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-5px" v-if="!item.caller.privacy&&item.caller.name">{{ item.caller.number | cutNationalPrefixAndFormat }}</div>
		</div>
		<div v-if="direction=='OUT'" style="display:flex;justify-content:center;flex-direction:column">
			<div style="font-weight:400;font-size:var(--font-size-primary)">{{ item.callee.name ? item.callee.name : item.callee.number | cutNationalPrefixAndFormat }}</div>
			<div style="font-size:var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-5px" v-if="item.callee.name">{{ item.callee.number | cutNationalPrefixAndFormat }}</div>
		</div>
		<div style="display:flex;justify-content:center;flex-direction:column;text-align:right">
			<div v-if="multipleDays" style="font-size:var(--font-size-secondary)">{{ item.started | formatDate('w d. MMM yyyy') }} kl. {{ item.started | formatDate('HH:mm') }}</div>
			<div v-if="!multipleDays" style="font-size:var(--font-size-secondary)">kl. {{ item.started | formatDate('HH:mm') }}</div>
			<div v-if="item.answered" style="font-size:var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-3px">{{ duration | shortduration(false) }} {{ duration | shortduration(true) }}</div>
			<div v-if="!item.answered" :style="{'color':termColor}" style="font-size:var(--font-size-secondary);margin-top:-3px">{{ reasons[item.terminatedReason] }}</div>
		</div>
	</div>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import EventBus from '../../data/EventBus';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		GlyphIcon
	},
	props: {
		item: { type: Object, required: true },
		index: { type: Number},
		click: { type: Function },
		me: { type: Object },
		contextMenu: { type: Function },
		from: { type: Date },
		to: { type: Date }
	},
	data() {
		return {
			$t: i18n.t,
			reasons: i18n.messages[i18n.locale].cdr.callEndReason
		};
	},
	methods: {
		call() {
			const other = this.direction == 'IN' ? this.item.caller : this.item.callee;
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				const target = s.cutNationalPrefix(other.number);
				if (target) {
					sipClient.call(target);
				}
			} else {
				const target = other.extension ? other.extension : s.cutNationalPrefix(other.number);
				if (target) {
					s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(target)}`).catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });	
					});
				}
			}
		},
		showContextMenu(e) {
			this.contextMenu(e, this.item);
		}
	},
	computed: {
		multipleDays: function() {
			return this.from.toISOString().substr(0,10) != this.to.toISOString().substr(0,10);
		},
		direction: function() {
			if (this.item.caller.extension == this.me.primaryExtension._id) { return 'OUT'; }
			return 'IN';
		},
		icon: function() {
			return this.direction == 'IN' ? '757-call-incoming' : '758-call-outgoing';
		},
		iconColor: function() { // success, error, busy, cancelled
			switch(this.item.terminatedReason) {
				case 'CANCEL': return 'var(--cdr-cancelled)';
				case 'HANGUP': return 'var(--cdr-success)';
				case 'BUSY': return 'var(--cdr-busy)';
				case 'UNREACHABLE': return 'var(--cdr-error)';
				case 'ERROR': return 'var(--cdr-error)';
				case 'BLOCKED': return 'var(--cdr-error)';
				case 'NOT_FOUND': return 'var(--cdr-error)';
				default: return '';
			}
		},
		termColor: function() { // success, error, busy, cancelled
			switch(this.item.terminatedReason) {
				case 'CANCEL': return 'var(--cdr-cancelled)';
				case 'HANGUP': return 'var(--cdr-success)';
				case 'BUSY': return 'var(--cdr-busy)';
				case 'UNREACHABLE': return 'var(--cdr-error)';
				case 'ERROR': return 'var(--cdr-error)';
				case 'BLOCKED': return 'var(--cdr-error)';
				case 'NOT_FOUND': return 'var(--cdr-error)';
				default: return '';
			}
		},
		duration: function() {
			return Math.floor((this.item.ended - this.item.started) / 1000);
		}
	}
};
</script>

<style scoped>
	.cdr-list-item {
		display: grid;
		grid-template-columns: 45px 1fr auto;
		text-align: left;
		border-bottom: var(--divider-line);
		color: var(--txt-color-primary);
		padding-left: 10px;
		padding-right: 10px;
	}
	.cdr-list-item.odd {
		background-color: var(--bg-color-3);
	}
	/* .cdr-list-item:hover {
		background-color: var(--pn-primary-4);
		cursor: context-menu;
	} */
</style>