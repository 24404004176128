/**
 * This contains the runnning state and settings for the app
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2021-03-12
 */

import axios from 'axios';
import EventBus from './data/EventBus';
import utils from './utils/utils';
import WakeUpDetector from './utils/WakeUpDetector';
import i18n from './utils/i18n';
import logger from './data/logger';
const FRONTEND_VERSION = '3.2.10';


class Settings {
	constructor() {
		/** @type { employee: string, employeeName: string, customer: string, customerName: string, accessLevel: String, language: String, customerFeatures: Array, reseller: String. accountingSystemId: String, employeeFeatures: Array, recordingMode: String, skin: String, impersonatedBy: String } */
		this.auth = null;
		this.networkError = false;
		this.pbxSettings = { nationalPrefix: '' };
		this.checkForNetworkRunning = false;
		this.selectedSection = 'DASHBOARD'; // DASHBOARD, PHONEBOOK, RECORDINGS, VOICEMAIL, CALLS, CHAT, etc.
		this.version = FRONTEND_VERSION;
		this.globalShortcuts = {};
		this.globalShortcutsErrors = 0;
		this.localUrl = 'http://localhost:61337';
		this.ringtone = null;
		this.ringtoneLocal = null;
		this.wrapperVersion = '';
		this.showStatusPanel = false;
		this.autolaunch = false;
		this.autolaunchHidden = false;
		this.queueCriticalSound = false;
		this.skin = 'DEFAULT';
		this.launched = new Date();

		/** @type {String} Unique id for this browser - used for recognizing it on the server */
		this.instanceId = null;

		// #region Properties

		/** @type {Boolean} True if credentials shall be permanently stored */
		this.rememberMe = false;

		/** @type {String} Token for authentication, if no token, we are not logged in */
		this.token = null;

		/** @type {String} Logged on users email address */
		this.emailAddress = null;

		/** @type {String} Id of phone to control */
		this.myPhoneId = null;

		/** @type {String} Id of softphone device when used - updated by sipClient  */
		this.mySoftphoneId = null;

		/** @type {String} Id of dashboard to show */
		this.myDashboardId = null;

		/** @type {Boolean} True if we are in wallboard mode */
		this.windowWallboardMode = false;

		/** @type {Boolean} True if we are running embedded in the OS app and stuff like integration shall run */
		this.isEmbedded = false; // This is how we know if we are launched from the wrapper app

		/** @type {Boolean} True if presence shall also color the background of the employee */
		this.presenceBackground = false;

		/** @type {Boolean} True if we are in fullscreeen mode */
		this.fullscreen = false;

		/** @type {Number} Standard width of columns in the dashboard */
		this.dashboardColumnWidth = 370;

		/** @type {Boolean} True if we got stashed data that can be restored after impersonation has completed */
		this.gotStash = false;

		// #endregion

		logger.s = this;

		this.setupSkin();
		window.document.title = this.appName;

		// Handle query args when started from the wrapper app
		const query = utils.parseQuery(document.location.search);
		if (query.embedded) { this.isEmbedded = true; }
		if (query.port) { this.localUrl = 'http://localhost:' + query.port; }

		EventBus.$on('Auth:LoggedIn', () => {
			logger.debug('Settings: Detected LoggedIn - setting up skin, axios and loading PBX settings');
			this.setupSkin();
			this.initAxios();
			this.loadPbxSettings().catch(() => { });

			if (this.autolaunchHidden && (new Date() - this.launched) < 10000) {
				logger.debug('Settings: auto launch as hidden = true - telling app to hide');
				axios.get(`${this.localUrl}/hide`, { timeout: 5000 }).catch(() => { });
			}
		});

		EventBus.$on('Auth:LoggedOut', originator => {
			if (originator != 'SETTINGS') {
				logger.debug('Settings: Detected LoggedOut - now performing logout');
				this.logout();
			}
		});

		this.wakeup = new WakeUpDetector(this.wakeupEvent.bind(this));

		// Load settings from local storage
		this.rememberMe = utils.bool(localStorage.getItem('rememberMe'));
		this.token = this.rememberMe ? localStorage.getItem('token') : sessionStorage.getItem('token');
		this.emailAddress = localStorage.getItem('emailAddress');
		this.myPhoneId = localStorage.getItem('myPhoneId');
		this.myDashboardId = localStorage.getItem('myDashboardId');
		this.windowWallboardMode = utils.bool(localStorage.getItem('windowWallboardMode'));
		this.presenceBackground = utils.bool(localStorage.getItem('presenceBackground'));
		this.fullscreen = utils.bool(localStorage.getItem('fullscreen'));
		this.instanceId = localStorage.getItem('instanceId');
		if (!this.instanceId) { this.generateInstanceId(); }
		this.ringtone = localStorage.getItem('ringtone');
		this.ringtoneLocal = localStorage.getItem('ringtoneLocal');
		if (this.ringtone && !this.ringtoneLocal) {
			this.ringtoneLocal = this.ringtone;
			localStorage.setItem('ringtoneLocal', this.ringtoneLocal);
		}
		this.showStatusPanel = utils.bool(localStorage.getItem('showStatusPanel')) || false;
		this.autolaunch = utils.bool(localStorage.getItem('autolaunch')) || false;
		this.autolaunchHidden = utils.bool(localStorage.getItem('autolaunchHidden')) || false;
		this.queueCriticalSound = utils.bool(localStorage.getItem('queueCriticalSound')) || false;
		this.dashboardColumnWidth = Number(localStorage.getItem('dashboardColumnWidth')) || 370;
		this.skin = localStorage.getItem('skin') || 'DEFAULT';

		if (this.isEmbedded) {
			this.loadGlobalShortcuts();
			this.loadIntegration();
		}

		this.initAxios();
		this.initGlobalShortcuts();
		this.loadAuth(query.impersonate).catch(() => {}); // Triggers everything else to load
		
		// For updating times using a single timer instead of a million
		setInterval(() => { EventBus.$emit('OneSecond'); }, 1000);
		setInterval(() => { EventBus.$emit('OneMinute'); }, 1000*60);

		logger.registerGlobal('settings', this);
	}

	// #region global shortcuts and integration

	/**
	 * Load global shortcuts from disk, or generate defaults if none
	 */
	loadGlobalShortcuts() {
		try { this.globalShortcuts = JSON.parse(localStorage.getItem('globalShortcuts')); }
		catch(err) {}
		if (!this.globalShortcuts) {
			this.globalShortcuts = {};
			this.globalShortcuts.ANSWER = { key: 'B', commandOrControl: true, shift: true, alt: false, enabled: false };
			this.globalShortcuts.HANGUP = { key: 'A', commandOrControl: true, shift: true, alt: false, enabled: false };
			this.globalShortcuts.TOGGLE_WINDOW = { key: 'C', commandOrControl: true, shift: true, alt: false, enabled: false };
			this.globalShortcuts.CALL = { key: 'R', commandOrControl: true, shift: true, alt: false, enabled: false };
			this.globalShortcuts.SEARCH = { key: 'F', commandOrControl: true, shift: true, alt: false, enabled: false };
			localStorage.setItem('globalShortcuts', JSON.stringify(this.globalShortcuts));
		}
	}

	/**
	 * Save global shortcuts to disk
	 */
	saveGlobalShortcuts() {
		if (this.isEmbedded) {
			localStorage.setItem('globalShortcuts', JSON.stringify(this.globalShortcuts));
			this.initGlobalShortcuts();
		}
	}

	/**
	 * Instruct the wrapper app to enable/disable the global shortcuts
	 */
	initGlobalShortcuts() {
		if (this.isEmbedded) {
			let args = '';
			Object.keys(this.globalShortcuts).forEach(key => {
				if (this.globalShortcuts[key].enabled) {
					let gs = this.globalShortcuts[key].commandOrControl ? 'CommandOrControl+' : '';
					gs += this.globalShortcuts[key].alt ? 'Alt+' : '';
					gs += this.globalShortcuts[key].shift ? 'Shift+' : '';
					gs += this.globalShortcuts[key].key;
					args += '&' + key + '=' + encodeURIComponent(gs);
				}
			});
			args = args.substring(1);
			axios.get(`${this.localUrl}/globalshortcuts?${args}`, { timeout: 5000 })
				.then(res => {
					if (!res.data.success) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.globalShortcuts'), message: i18n.t('settings.globalShortcutsFailedMessage') });
					}
					this.globalShortcutsErrors = 0;
				})
				.catch(() => {
					this.globalShortcutsErrors += 1;
					if (this.globalShortcutsErrors > 20) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('settings.globalShortcuts'), message: i18n.t('settings.globalShortcutsFailedMessage') });
					} else {
						setTimeout(() => { 
							this.initGlobalShortcuts();
						}, 1000);
					}
				});
		}
	}

	/**
	 * Load integration settings from disk
	 */
	loadIntegration() {
		try { this.integration = JSON.parse(localStorage.getItem('integration')); }
		catch(err) {}
		if (!this.integration) {
			this.integration = {
				run: {
					when: 'NEVER',
					delay: 0,
					command: ''
				},
				sendkeys: {
					when: 'NEVER',
					delay: 0,
					focusProcess: '',
					maximizeProcess: false,
					useAutoit: false,
					keys: '',
					requireArgs: false
				},
				copyToClipboard: {
					when: 'NEVER',
					what: '',
					requireArgs: false
				},
				browser: {
					when: 'NEVER',
					website: '',
					spa: true,
					actions: [],
					requireArgs: false
				}
			};
			localStorage.setItem('integration', JSON.stringify(this.integration));
		}
	}

	/**
	 * Save integration settings to disk
	 */
	saveIntegration() {
		if (this.isEmbedded) {
			localStorage.setItem('integration', JSON.stringify(this.integration));
		}
	}

	// #endregion

	// #region Set Properties

	setRingtone(value) {
		this.ringtone = value;
		localStorage.setItem('ringtone', value);
	}

	setRingtoneLocal(value) {
		this.ringtoneLocal = value;
		localStorage.setItem('ringtoneLocal', value);
	}

	generateInstanceId() {
		this.instanceId = utils.randomString(32);
		localStorage.setItem('instanceId', this.instanceId);
	}

	setRememberMe(value) {
		this.rememberMe = value;
		if (value) { localStorage.setItem('rememberMe', value); } else { localStorage.removeItem('rememberMe'); }
	}

	setToken(value) {
		this.token = value;
		if (value) {
			if (this.rememberMe) { localStorage.setItem('token', value); } else { sessionStorage.setItem('token', value); }
		} else {
			localStorage.removeItem('token');
			sessionStorage.removeItem('token');
		}
	}

	setEmailAddress(value) {
		this.emailAddress = value;
		if (value) { localStorage.setItem('emailAddress', value); } else { localStorage.removeItem('emailAddress'); }
	}

	setMyPhoneId(value) {
		this.myPhoneId = value;
		if (value) { localStorage.setItem('myPhoneId', value); } else { localStorage.removeItem('myPhoneId'); }
	}
	
	setMyDashboardId(value) {
		this.myDashboardId = value;
		if (value) { localStorage.setItem('myDashboardId', value); } else { localStorage.removeItem('myDashboardId'); }
	}

	setSelectedSection(value) {
		// DASHBOARD, PHONEBOOK, RECORDINGS, VOICEMAIL, CALLS, CHAT, etc.
		const previous = this.selectedSection;
		this.selectedSection = value;
		EventBus.$emit('SectionChanged', { oldValue: previous, newValue: value });
	}

	setWindowWallboardMode(value) {
		this.windowWallboardMode = value;
		localStorage.setItem('windowWallboardMode', value);
	}

	setPresenceBackground(value) {
		this.presenceBackground = value;
		localStorage.setItem('presenceBackground', value);
	}

	setFullscreen(value) {
		this.fullscreen = value;
		localStorage.setItem('fullscreen', value);
	}

	setShowStatusPanel(value) {
		this.showStatusPanel = value;
		localStorage.setItem('showStatusPanel', value);
	}

	setAutolaunch(value) {
		this.autolaunch = value;
		localStorage.setItem('autolaunch', value);
	}

	setAutolaunchHidden(value) {
		this.autolaunchHidden = value;
		localStorage.setItem('autolaunchHidden', value);
	}

	setQueueCriticalSound(value) {
		this.queueCriticalSound = value;
		localStorage.setItem('queueCriticalSound', value);
	}

	setDashboardColumnWidth(value) {
		this.dashboardColumnWidth = value;
		localStorage.setItem('dashboardColumnWidth', value);
	}

	setSkin(value) {
		this.skin = value;
		localStorage.setItem('skin', value);
		this.setupSkin();
	}

	/**
	 * Save a value under a generic key
	 * @param {String} key Key to save the value under
	 * @param {*} value Value to save
	 */
	setGeneric(key, value) {
		localStorage.setItem('Generic-' + key, JSON.stringify(value));
	}

	/**
	 * Get a value from a generic key
	 * @param {String} key Name of key to lookup
	 * @returns {*} The value if any, else null
	 */
	getGeneric(key) {
		const value = localStorage.getItem('Generic-' + key);
		if (value) { return JSON.parse(value); } else { return null; }
	}

	// #endregion

	setupSkin() {
		// Remove the old if any
		const skinElement = document.getElementById('skin');
		if (skinElement) {
			document.getElementsByTagName('head')[0].removeChild(skinElement);
		}

		let skin = 'EARTH'; // Default
		if (this.auth?.skin) { skin = this.auth.skin; } // if logged in and server forces a skin - we will use it
		if (this.skin != 'DEFAULT') { skin = this.skin; } // If user has selected a specific skin to use - it will override the server choice
		if (skin == 'DEFAULT') { skin = 'EARTH'; } // Not logged in, ensure we have a skin

		// Load CSS
		if (skin != 'EARTH') { // Only override if default is not selected
			const link = document.createElement('link');
			link.href = `/css/${skin}.css`;
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.media = 'screen,print';
			link.id = 'skin';
			document.getElementsByTagName('head')[0].appendChild(link);
		}

		// Remove the old if any
		const icoElement = document.getElementById('ico');
		if (icoElement) {
			document.getElementsByTagName('head')[0].removeChild(icoElement);
		}

		// Load FavIcon
		//     <link rel="icon" href="favicon.ico">
		const link2 = document.createElement('link');
		link2.href = `favicon-${skin}.ico`;
		link2.rel = 'icon';
		link2.id = 'ico';
		document.getElementsByTagName('head')[0].appendChild(link2);
	}

	/** @type {String} Name of the application */
	get appName() {
		return 'Communicator';
	}

	/** @type {String} Path to logo */
	get appLogo() {
		return '/assets/images/login-logo.png';
	}

	/** @type {Boolean} True if user may edit dashboards */
	get isDashboardEditor() { return this.isAuthenticated && this.auth.employeeFeatures.includes('DASHBOARD_EDITOR'); }

	/** @type {Boolean} True if user has the COMMUNICATOR_DEBUG feature enabled */
	get isInDebugMode() { return this.isAuthenticated && this.auth.employeeFeatures.includes('COMMUNICATOR_DEBUG'); }

	/** @type {Boolean} Are we running on a development system */
	get isDevelopment() { return window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1'; }

	/** @type {String} Base URL for the API */
	get apiUrl() { return this.isDevelopment ? 'http://localhost:3000' : 'https://api.telecomx.dk'; }
	// get apiUrl() { return 'https://api.telecomx.dk'; }

	/** @type {String} Base URL for the APP */
	get appUrl() { return this.isDevelopment ? 'http://localhost:8080' : 'https://' + window.location.hostname; }

	/** @type {Boolean} True if user is authenticated */
	get isAuthenticated() { return this.auth?.employee != null; }

	/** @type {String} The real device id of the phone used regardless of if it is a hardphone or a softphone */
	get myRealPhoneId() {
		return this.myPhoneId == 'SOFTPHONE' ? this.mySoftphoneId : this.myPhoneId;
	}

	/**
	 * Initialize Axios as s.http, setup authorization and base url etc.
	 */
	initAxios() {
		logger.debug('Settings: Initialized Axios');
		this.http = axios.create();
		this.http.defaults.timeout = 10000;
		this.http.defaults.baseURL = this.apiUrl;

		this.http.interceptors.request.use(config => {
			if (this.token) {
				config.headers.Authorization = `Bearer ${this.token}`;
			}
			return config;
		}, null, { synchronous: true });

		this.http.interceptors.response.use(
			res => {
				this.networkError = false;
				return res;
			},
			res => {
				if (res.response) {
					this.networkError = false;
					if (res.response.data) {
						const data = res.response.data;
						if (data.error && data.error.status == 401) {
							this.auth = null;
							this.token = null;
							this.emailAddress = null;
							this.rememberMe = false;
							this.myPhoneId = null;
							this.myDashboardId = null;
						}
						throw res.response.data.error; // We just return the TCX error object
					} else {
						throw res;
					}
				} else {
					if (logger.destinations.remote) { // this section is for temporary debugging
						logger.error('Settings: Axios detected network error');
						console.dir(res); // eslint-disable-line
					}
					if (!this.checkForNetworkRunning) {
						this.checkForNetworkRunning = true;
						this.checkForNetwork(true);
					}
					const error = new Error(i18n.t('settings.networkError'));
					error.status = 0;
					error.code = 'network';
					error.path = 'network';
					throw error;
				}
			},
			{ synchronous: true }
		);
	}

	/**
	 * Invoked by API call that has failed to reach the server, by Axios interceptor
	 * - Will try to authenticate every 5 seconds until connection to the server has been established or we has been logged out (no token)
	 * @param {Boolean} requestHasFailed True if request has failed
	 */
	async checkForNetwork(requestHasFailed) {
		logger.warning('Checking for network: Starting');

		// Check if it was a single failure that we can recover from
		if (requestHasFailed) {
			try {
				await axios.get(this.apiUrl + '/auth/ping', { timeout: 3000 });
				logger.warning('Checking for network: Ping check succeeded');
				this.networkError = false; // Safety - should never be needed
				return;
			}
			catch(err) {
				this.networkError = true;
				logger.error('Checking for network: Failed - we are offline');
			}
		}

		await this.sleep(5000);

		if (this.networkError) {
			try {
				await this.loadAuth();
				logger.warning('Check for network: Network is back - re-init started');
				this.checkForNetworkRunning = false;
			}
			catch(err) {
				if (this.networkError) { this.checkForNetwork(); }
			}
		}
	}

	/**
	 * Load s.auth settings from server by validating that we are still logged in, throw if not
	 * - will through event bus trigger that all other datastores are refreshed upon success
	 * @param {String} [impersonate] If impersonating, this is the valid token for the user to impersonate
	 * @returns {Promise}
	 */
	async loadAuth(impersonate) {
		if (impersonate) {
			logger.info('Settings: Impersonating engaged');
			this.setToken(impersonate);
			this.setRememberMe(false);
		} else {
			this.gotStash = false;
		}

		if (!this.token) { throw new Error('No token'); }
		const res = await this.http.get('/auth/validateToken?extend=true&token=' + this.token);
		if (!res.data.success || !res.data.communicatorAccess) {
			this.logout();
		} else {
			if (impersonate) {
				this.setEmailAddress(res.data.username);
				res.data.employee = res.data._id;
				res.data.employeeName = res.data.name;
				delete res.data._id;
				delete res.data.name;
				delete res.data.token;
			}
			this.auth = res.data;
			i18n.locale = this.auth.language;
			logger.info('Settings: Token validated and core settings received from server - emitting Auth:LoggedIn');
			EventBus.$emit('Auth:LoggedIn');
		}
	}

	/**
	 * Load PBX settings from server
	 * @returns {Promise}
	 */
	async loadPbxSettings() {
		if (!this.token) { return Promise.reject(new Error('No token found')); }
		const res = await this.http.get('/pbx/settings');
		this.pbxSettings = res.data;
		logger.debug('Settings: PBX settings loaded');
	}

	/**
	 * Removes national prefix from phone number if present
	 * @param {String} number Phone number in E.164 format to work on
	 * @param {Boolean} format True to format number with spaces
	 * @returns {String}
	 */
	cutNationalPrefix(number, format) {
		if (!number || typeof number !== 'string') { return number; }
		if (this.pbxSettings && number && number.startsWith(this.pbxSettings.nationalPrefix) && number.length > this.pbxSettings.nationalPrefix.length) {
			const res = number.replace(this.pbxSettings.nationalPrefix, '');
			return format && !res.startsWith('+') ? res.match(/.{2}/g).join(' ') : res;
		} else {
			return number;
		}
	}

	/**
	 * Sleep for x time
	 * @param {Number} milliseconds Milliseconds to sleep
	 * @returns {Promise}
	 */
	async sleep(milliseconds) {
		return new Promise(resolve => {
			setTimeout(() => { resolve(); }, milliseconds);
		});
	}

	/**
	 * Returns when we are authenticated and ready to use the server API
	 * @returns {Promise}
	 */
	async ready() {
		if (this.isAuthenticated) { return Promise.resolve(); }
		await this.sleep(250);
		return this.ready();
	}

	/**
	 * Invoked when computer is woken up and we need to refresh all data
	 */
	wakeupEvent() {
		logger.debug('Settings: Wakeup occured');
		if (!this.isDevelopment) {
			EventBus.$emit('Wakeup');
			this.loadAuth().catch(() => {}); // Will trigger everyting else to also refresh
		} else {
			logger.info('Settings: Wakeup occured - pretending to refresh all');
		}
	}

	/**
	 * Invoked when user wants to logout
	 * - Will reset most settings
	 * @param {Boolean} True to stash settings, so that we may restore them after an impersonation session has ended
	 */
	logout(stash) {
		if (stash) {
			this.stashSettings();
			this.generateInstanceId(); // when impersonating we want to be someone else - thus we need a new instance id
		}
		logger.info('Settings: Performing logout');
		this.auth = null;
		this.networkError = false;
		this.selectedSection = 'DASHBOARD';
		this.pbxSettings = { nationalPrefix: '' };
		this.checkForNetworkRunning = false;
		this.setRememberMe(false);
		this.setToken(null);
		this.setEmailAddress(null);
		if (this.myPhoneId != 'SOFTPHONE' || stash) {
			this.setMyPhoneId(null);
		}
		this.setMyDashboardId(null);
		this.setWindowWallboardMode(false);
		this.setFullscreen(false);

		Object.keys(localStorage).forEach(key => {
			if (key.startsWith('Generic-')) {
				localStorage.removeItem(key);
			}
		});

		i18n.locale = navigator.language.split('-')[0];

		logger.info('Settings: Emitted Auth:LoggedOut'); // eslint-disable-line
		EventBus.$emit('Auth:LoggedOut', 'SETTINGS');
	}

	endImpersonation() {
		if (this.gotStash) {
			this.logout();
			setTimeout(() => {
				this.restoreSettings();
				this.loadAuth().catch(() => {});
			}, 500);
		}
	}

	stashSettings() {
		let stash = {
			rememberMe: this.rememberMe,
			token: this.token,
			emailAddress:  this.emailAddress,
			myPhoneId: this.myPhoneId,
			myDashboardId: this.myDashboardId,
			windowWallboardMode: this.windowWallboardMode,
			presenceBackground: this.presenceBackground,
			fullscreen: this.fullscreen,
			instanceId: this.instanceId,
			ringtone: this.ringtone,
			ringtoneLocal: this.ringtoneLocal,
			showStatusPanel: this.showStatusPanel,
			autolaunch: this.autolaunch,
			autolaunchHidden: this.autolaunchHidden,
			queueCriticalSound: this.queueCriticalSound,
			dashboardColumnWidth: this.dashboardColumnWidth,
			skin: this.skin
		};
		if (this.isEmbedded) {
			stash.globalShortcuts = this.globalShortcuts;
			stash.integration = this.integration;
		}

		stash.generic = {};
		Object.keys(localStorage).forEach(key => {
			if (key.startsWith('Generic-')) {
				stash.generic[key] = localStorage.getItem(key);
			}
		});

		sessionStorage.setItem('stash', JSON.stringify(stash));
		this.gotStash = true;
	}

	restoreSettings() {
		const stash = JSON.parse(sessionStorage.getItem('stash'));
		if (stash?.token) {
			this.setRememberMe(stash.rememberMe);
			this.setToken(stash.token);
			this.setEmailAddress(stash.emailAddress);
			this.setMyPhoneId(stash.myPhoneId);
			this.setMyDashboardId(stash.myDashboardId);
			this.setWindowWallboardMode(stash.windowWallboardMode);
			this.setPresenceBackground(stash.presenceBackground);
			this.setFullscreen(stash.fullscreen);
			this.instanceId = stash.instanceId;
			this.setRingtone(stash.ringtone);
			this.setRingtoneLocal(stash.ringtoneLocal);
			this.setShowStatusPanel(stash.showStatusPanel);
			this.setAutolaunch(stash.autolaunch);
			this.setAutolaunchHidden(stash.autolaunchHidden);
			this.setQueueCriticalSound(stash.queueCriticalSound);
			this.setDashboardColumnWidth(stash.dashboardColumnWidth);
			this.setSkin(stash.skin);

			Object.keys(stash.generic).forEach(key => {
				this.setGeneric(key.replace('Generic-',''), stash.generic[key]);
			});

			if (this.isEmbedded) {
				this.globalShortcuts = stash.globalShortcuts;
				this.integration = stash.integration;
				this.saveGlobalShortcuts();
				this.saveIntegration();
			}

			sessionStorage.setItem('stash', null);
			this.gotStash = false;
		}
	}

}

// Singleton
export default new Settings();
