<template>
	<div class="phonebook-list-item" @click="select">
		<glyph-icon :icon="item.type=='PERSON'?'4-user':'90-building'" color="var(--icon-color-dark)" size="32" />
		<div>
			<div class="dotdotdot" style="font-weight:300;font-size:var(--font-size-primary)">{{ item.name }}<span style="font-size:var(--font-size-secondary);font-style:italic" v-if="item.type=='PERSON'&&item.company"> ({{ item.company }})</span></div>
			<div class="phonebook-list-sub">
				<span v-if="item.type=='PERSON'&&item.title">{{ item.title }}</span>
				<span v-if="item.type=='PERSON'&&item.title&&item.department" style="padding-left:5px;padding-right:5px">•</span>
				<span v-if="item.type=='PERSON'&&item.department">{{ item.department }}</span>
				<span v-if="item.type=='COMPANY'&&item.addresses.length>0">{{ item.addresses[0].zip }} {{ item.addresses[0].city }}</span>
				<span v-if="item.type=='COMPANY'&&item.addresses.length>0&&item.phoneNumbers.length>0" style="padding-left:5px;padding-right:5px">•</span>
				<span v-if="item.type=='COMPANY'&&item.phoneNumbers.length>0">{{ getMostRelevantNumber(item) | cutNationalPrefixAndFormat }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';

export default {
	components: {
		GlyphIcon
	},
	props: {
		item: { type: Object, required: true },
		click: { type: Function }
	},
	methods: {
		getMostRelevantNumber(contact) {
			let found = null;
			['MAIN','WORK','MOBILE','OTHER','HOME'].forEach(key => {
				if (!found) {
					found = contact.phoneNumbers.find(o => o.type == key);
				}
			});
			return found ? found.number : null;
		},
		select() {
			this.click(this.item);
		}

	}
};
</script>