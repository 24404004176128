<template>
	<div v-if="employee" class="employee-box" @contextmenu="showContextMenu" @dblclick="callFirstExtension">
		<call-draggable :data="employee" type="Employee" :draggable="true" :target="true" :targetTypes="['MyCallsCall','Employee','QueueCall']" :style="{'background':employee.presenceBackgroundColor}">
			<div style="float:left">
				<div style="position:relative">
					<presence-icon v-if="employee.type==='EMPLOYEE'" :state="employee.presence.state" :size="19" class="presence-state" />
					<employee-picture :picture="employee.picture" :size="44" :spinner="true" :initials="employee.initials" />
					<img v-if="isBirthday" src="/assets/images/birthdayflag.png" class="birthday" alt="Fødselsdagsflag">
				</div>
			</div>
			<div class="duration-box" :class="{'visible':employee.currentCall}">
				<glyph-icon
					:icon="employee.currentCallIcon"
					size="20"
					:color="employee.currentCallIconColor"
					style="margin: 0 auto;flex-shrink:0"
					:class="{'ringing':employee.currentCall&&employee.currentCall.state=='CALL_RING','onhold':employee.currentCall&&employee.currentCall.state=='CALL_HOLD'}"
				></glyph-icon>
				<div v-if="employee.currentCall&&employee.currentCall.state!='CALL_RING'" class="duration-time">{{ currentCallDuration | duration(true) }}</div>
			</div>
			<div class="labels">
				<div class="name">
					{{ employee.name }}
					<div v-if="employee.primaryExtension.redirect" class="redirect">
						<div style="display:flex">
							<glyph-icon icon="435-forward-email" size="16" color="#fff" style="margin-top:-4px" />
							<div style="margin-top:-3px">{{ employee.primaryExtension.redirect | cutNationalPrefix }}</div>
						</div>
					</div>
				</div>
				<div class="message" v-if="employee.type==='EMPLOYEE' && inlineMessageAndLocation">
					<glyph-icon icon="238-chat-message" size="13" color="var(--label-message-color)" class="no-shrink" />
					<div class="label no-shrink">{{ employee.presence.message }}</div>
					<div class="no-shrink" style="width:10px"></div>
					<glyph-icon icon="591-map-marker" size="13" color="var(--label-location-color)" class="no-shrink" />
					<div class="label" style="color:var(--label-location-color)">{{ employee.presence.location }}</div>
				</div>
				<div class="message" v-if="employee.type==='EMPLOYEE' && !inlineMessageAndLocation && employee.presence.message">
					<glyph-icon icon="238-chat-message" size="13" color="var(--label-message-color)" class="no-shrink" />
					<div class="label">{{ employee.presence.message }}</div>
				</div>
				<div class="message" v-if="employee.type==='EMPLOYEE' && !inlineMessageAndLocation && employee.presence.location">
					<glyph-icon icon="591-map-marker" size="13" color="var(--label-location-color)" class="no-shrink" />
					<div class="label" style="color:var(--label-location-color)">{{ employee.presence.location }}</div>
				</div>
				<div class="expires" v-if="employee.type==='EMPLOYEE'" :class="{'visible':showExpires}">
					<glyph-icon icon="55-clock" size="12" color="var(--label-expires-color)" class="icon" />
					<div class="label">{{ expiresLabel }}</div>
				</div>
				<div class="call" :class="{'visible':employee.showOtherParty}">
					<glyph-icon :icon="employee.currentCallTypeIcon" size="13" color="var(--label-otherparty-color)" class="other-party-icon" />
					<div v-if="employee.currentCall&&employee.currentCall.leg=='I'" class="label">{{ employee.currentCall.callee.number | cutNationalPrefix }} {{ employee.currentCall.callee.name }}</div>
					<div v-if="employee.currentCall&&employee.currentCall.leg=='O'&&!employee.currentCall.caller.privacy" class="label">{{ employee.currentCall.caller.queueId ? employee.currentCall.caller.queueName + ': ' : '' }}{{ employee.currentCall.caller.number | cutNationalPrefix }} {{ employee.currentCall.caller.name }}</div>
					<div v-if="employee.currentCall&&employee.currentCall.leg=='O'&&employee.currentCall.caller.privacy" class="label">{{ employee.currentCall.caller.queueId ? employee.currentCall.caller.queueName + ': ' : '' }}{{ $t('employee.hiddenNumber') }}</div>
					<country-flag v-if="countryFlagName" :icon="countryFlagName" size="13" class="country-flag"></country-flag>
				</div>
			</div>
			<v-contextmenu :ref="tag" :id="tag">
				<template v-for="(item,index) in menuData">
					<v-contextmenu-item v-if="!item.type&&!item.submenu" v-bind:key="index" @click="item.click">{{ item.label }}</v-contextmenu-item>
					<v-contextmenu-item v-if="item.type=='separator'" v-bind:key="index" divider></v-contextmenu-item>
					<v-contextmenu-item v-if="item.type=='checkbox'" v-bind:key="index" @click="item.click" style="display:flex;padding-top:4px;padding-bottom:3px">
						<glyph-icon :icon="item.checked?'153-square-checkbox':'154-square-empty'" size="15" color="var(--icon-color-dark)"></glyph-icon>
						<div style="margin-left:5px;margin-top:2px">{{ item.label }}</div>
					</v-contextmenu-item>
					<v-contextmenu-submenu v-if="item.submenu" :title="item.label" v-bind:key="index">
						<v-contextmenu-item v-for="(subitem,subindex) in item.submenu" v-bind:key="subindex" @click="subitem.click">{{ subitem.label }}</v-contextmenu-item>
					</v-contextmenu-submenu>
				</template>
			</v-contextmenu>
		</call-draggable>
		<div v-if="!last" class="bottom-line"></div>
	</div>
</template>

<script>
import EmployeePicture from '../EmployeePicture.vue';
import PresenceIcon from '../PresenceIcon.vue';
import GlyphIcon from '../GlyphIcon.vue';
import CountryFlag from '../CountryFlag.vue';
import s from '../../settings';
import u from '../../utils/utils';
import employees from '../../data/employees';
import EventBus from '../../data/EventBus';
import MyNotification from '../../utils/MyNotification';
import CallDraggable from './CallDraggable';
import BindCache from '../../utils/BindCache';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';
import logger from '../../data/logger';

export default {
	props: {
		employeeId: String,
		last: Boolean
	},
	components: {
		EmployeePicture,
		PresenceIcon,
		GlyphIcon,
		CountryFlag,
		CallDraggable
		// Spinner		
	},
	data() {
		return {
			$t: i18n.t,
			token: null,
			employee: null,
			currentCallDuration: 0,
			notifyWhenAvailable: false,
			menuData: [],
			tag: null,
			bindCache: new BindCache(this)
		};
	},
	computed: {
		expiresLabel: function() {
			if (this.employee.type !== 'EMPLOYEE') { return ''; }
			if (this.employee && this.employee.presence && this.employee.presence.expire) {
				let lbl = i18n.t(`common.presence.${this.employee.presence.state}`);
				const expire = new Date(this.employee.presence.expire);

				const inAWeek = new Date();
				inAWeek.setDate(inAWeek.getDate() + 7);
				inAWeek.setHours(0, 0, 0, 0);
				
				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);

				const today = new Date();

				if (expire.getFullYear() == today.getFullYear() && expire.getMonth() == today.getMonth() && expire.getDate() == today.getDate()) {
					// kl. 18:00
					lbl += ` ${i18n.t('employee.until')} ${u.formatDate(expire, 'hh:mm')}`;
				} else if (expire.getFullYear() == tomorrow.getFullYear() && expire.getMonth() == tomorrow.getMonth() && expire.getDate() == tomorrow.getDate()) {
					// imorgen kl. 18:00
					if (u.formatDate(expire, 'hh:mm') == '00:00') {
						lbl += ' ' + i18n.t('employee.restOfDay');
					} else {
						lbl += ' ' + i18n.t('employee.untilTomorrow') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				} else if (expire < inAWeek) {
					lbl += ' ' + i18n.t('employee.until2') + ' ' + u.formatDate(expire, 'w');
					if (u.formatDate(expire, 'hh:mm') != '00:00') {
						lbl += ' ' + i18n.t('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				} else {
					lbl += ' ' + i18n.t('employee.until2') + ' ' + u.formatDate(expire, 'w');
					lbl += ' ' + i18n.t('employee.onThe') + ' ' + u.formatDate(expire, 'd/M');
					if (u.formatDate(expire, 'hh:mm') != '00:00') {
						lbl += ' ' + i18n.t('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				}

				return lbl;
			} else {
				return '';
			}
		},
		inlineMessageAndLocation: function() {
			if (!this.employee || this.employee.type !== 'EMPLOYEE') { return false; }
			const p = this.employee.presence;
			if (p.message && p.location && (p.expire || this.employee.currentCall)) { return true; }
			return false;
		},
		showExpires: function() {
			if (!this.employee || this.employee.type !== 'EMPLOYEE') { return false; }
			const p = this.employee.presence;
			if (!p.expire) { return false; }
			if (!this.employee.currentCall) { return true; }
			if (this.employee.currentCall && !p.message && !p.location) { return true; }
			return false;
		},
		countryFlagName: function() {
			if (!this.employee || !this.employee.currentCall) { return ''; }
			let number = (this.employee.currentCall.leg == 'I' ? this.employee.currentCall.callee.number : this.employee.currentCall.caller.number).substr(0, 6);
			let iso;
			while (!iso && number) {
				number = number.slice(0, -1);
				iso = u.ISO3166.prefix[number];
			}
			if (iso) { iso = iso.toLowerCase(); }
			if (number == s.pbxSettings.nationalPrefix) { iso = null; }
			return iso;
		},
		isBirthday: function() {
			if (this.employee && this.employee.birthdate) {
				const bd = this.employee.birthdate;
				const today = new Date();
				return bd.getMonth() == today.getMonth() && bd.getDate() == today.getDate();
			} else {
				return false;
			}
		}
	},
	methods: {
		callFirstExtension() {
			let ext = this.employee.extensions.find(o => o.primary == true);
			if (!ext) { ext = this.employee.extensions[0]; }
			if (ext) {
				logger.info(`Employee: User is calling employee ${this.employee.name}`);
				this.call(ext);
			}
		},
		async showContextMenu(e) {
			logger.info(`Employee: User is showing contextmenu for employee ${this.employee.name}`);
			const this2 = this;
			this.menuData = [];
			const perms = this.employee.permissions;
			let myCalls = await employees.getMyCalls();
			const myCallsSessionIds = myCalls.map(o => o.sessionId);
			const cc = this.employee.currentCall;
			const cr = this.employee.currentRingingCall;
			const ccIsWithMe = cc && myCallsSessionIds.includes(cc.sessionId);
			const crIsWithMe = cr && myCallsSessionIds.includes(cr.sessionId);
			if (ccIsWithMe) {
				myCalls = myCalls.filter(o => o.sessionId != cc.sessionId);
			}
			if (crIsWithMe) {
				myCalls = myCalls.filter(o => o.sessionId != cr.sessionId);
			}

			if (this.employee._id != s.auth.employee) {
				// Dial
				if (this.employee.extensions.length > 1) {
					let submenu = [];
					this.employee.extensions.forEach(ext => {
						if (ext.number) { submenu.push({ label: ext.number, click: () => { this2.call(ext); } }); }
						else if (ext.direct) { submenu.push({ label: ext.direct.replace(s.pbxSettings.nationalPrefix, ''), click: () => { this2.call(ext); } }); }
						else if (ext.mobile && ext.mobile.length > 0) { submenu.push({ label: ext.mobile[0].replace(s.pbxSettings.nationalPrefix, ''), click: () => { this2.call(ext); } }); }
					});
					this.menuData.push({ label: i18n.t('employee.call'), submenu: submenu });
				} else if (this.employee.extensions.length == 1) {
					const ext = this.employee.extensions[0];
					this.menuData.push({ label: i18n.t('employee.call'), click: () => { this2.call(ext); } });
				}

				// Transfer
				if (myCalls.length > 0 && this.employee.presence.state != 'BUSY') {
					if (myCalls.length > 1 && (!cc || ccIsWithMe)) {
						const submenu = [];
						myCalls.forEach(call => {
							if (!cc || call.sessionId != cc.sessionId) {
								const label = call.leg == 'I' ? call.callee.number.replace(s.pbxSettings.nationalPrefix, '') + ' ' + (call.callee.name || '') : call.caller.number.replace(s.pbxSettings.nationalPrefix, '') + ' ' + (call.caller.name || '');
								submenu.push({
									label: label,
									click: () => { this2.transfer(call, cc, ccIsWithMe); } // ccIsWithMe = attended transfer to cc ELSE blind transfer
								});
							}
						});
						this.menuData.push({ label: i18n.t('employee.transfer') + (cc && !ccIsWithMe ? ' ' + i18n.t('employee.toPersonalQueue') : ' ' + i18n.t('employee.toHere')), submenu: submenu });
					} else {
						this.menuData.push({ label: i18n.t('employee.transfer') + (cc && !ccIsWithMe ? ' ' + i18n.t('employee.toPersonalQueue') : ' ' + i18n.t('employee.toHere')), click: () => { this2.transfer(myCalls[0], cc, ccIsWithMe); } }); // ccIsWithMe = attended transfer to cc ELSE blind transfer
					}
				}

				// Add in
				if (myCalls.length > 0 && myCalls.some(o => o.state == 'CALL_ANSWER')) {
					this.menuData.push({ label: i18n.t('employee.addToCall'), click: () => { this2.addCall(myCalls.find(o => o.state == 'CALL_ANSWER')); }});
				}

				if (cc || (cr && perms.pickup) || (cc && perms.steal) || (cc && perms.spy)) {
					this.menuData.push({ type: 'separator' });
				}

				// Notify when available
				if (cc || (this2.employee.type === 'EMPLOYEE' && this2.employee.presence.state == 'BUSY')) {
					this.menuData.push({ label: i18n.t('employee.notifyWhenAvailable'), type: 'checkbox', checked: this.notifyWhenAvailable, click: () => { this2.notify(!this2.notifyWhenAvailable); } });
				}

				// Pickup ringing call
				if (cr && perms.pickup) { this.menuData.push({ label: i18n.t('employee.pickupRingingCall'), click: () => { this2.pickup(cr); } }); }
				if (cc && cc.state == 'CALL_ANSWER' && perms.steal) { this.menuData.push({ label: i18n.t('employee.pickupCurrentCall'), click: () => { this2.steal(cc); } }); }
				if (cc && cc.state == 'CALL_ANSWER' && perms.spy) { this.menuData.push({ label: i18n.t('employee.listenIn'), click: () => { this2.spy(cc); } }); }

				this.menuData.push({ type: 'separator' });

				const mobiles = [];
				this.employee.extensions.forEach(ext => {
					if (ext.mobile) { mobiles.push(...ext.mobile); }
				});

				// Send SMS
				if (mobiles.length == 1) {
					this.menuData.push({ label: i18n.t('employee.sendSms'), click: () => { this2.sendSms(mobiles[0]); } });
				} else if (mobiles.length > 1) {
					const submenu = [];
					mobiles.forEach(mobile => {
						submenu.push({ label: mobile.replace(s.pbxSettings.nationalPrefix, ''), click: () => { this2.sendSms(mobile); } });
					});
					this.menuData.push({ label: i18n.t('employee.sendSms'), submenu: submenu });
				}

				// Send email
				if (this.employee.emailAddress && this.employee.emailAddress.includes('@') && !this.employee.emailAddress.includes('@xtele.dk')) {
					this.menuData.push({ label: i18n.t('employee.sendEmail'), click: () => { this2.sendEmail(); } });
				}

				// Copy number
				if (this.employee.currentCall && this.employee.currentCall.leg == 'I') {
					const number = this.employee.currentCall.callee.number.replace(s.pbxSettings.nationalPrefix, '');
					this.menuData.push({ label: i18n.t('employee.copyNumberToClipboard'), click: () => { navigator.clipboard.writeText(number); } });
				} else if (this.employee.currentCall && this.employee.currentCall.leg == 'O' && !this.employee.currentCall.caller.privacy) {
					const number = this.employee.currentCall.caller.number.replace(s.pbxSettings.nationalPrefix, '');
					this.menuData.push({ label: i18n.t('employee.copyNumberToClipboard'), click: () => { navigator.clipboard.writeText(number); } });
				}

				// Set presence
				if (this.employee.type === 'EMPLOYEE' && perms.setPresence) {
					this.menuData.push({ label: i18n.t('employee.setPresence'), click: () => { this2.showSetPresence(); } } );
					this.menuData.push({ label: i18n.t('employee.quickSetPresence'),
						submenu: [
							{ label: i18n.t('common.presence.AVAILABLE'), click: () => { this2.setStatus('AVAILABLE'); } },
							{ label: i18n.t('common.presence.BUSY'), click: () => { this2.setStatus('BUSY'); } },
							{ label: i18n.t('common.presence.OUT_OF_OFFICE'), click: () => { this2.setStatus('OUT_OF_OFFICE'); } },
							{ label: i18n.t('common.presence.OFF_WORK'), click: () => { this2.setStatus('OFF_WORK'); } },
							{ label: i18n.t('common.presence.HOLIDAY'), click: () => { this2.setStatus('HOLIDAY'); } }
						]
					});
					this.menuData.push({ label: i18n.t('employee.redirect'), click: () => { this2.showRedirect(); } } );
				}
			}

			if (this.employee.type === 'EMPLOYEE') {
				this.menuData.push({ label: i18n.t('employee.showInfo'), click: () => { this2.showInfo(); } });
			}

			e.preventDefault();
			setTimeout(() => { this.$refs[this.tag].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		showSetPresence() {
			EventBus.$emit('CommonSetPresenceModal', this.employeeId);
		},
		showRedirect() {
			EventBus.$emit('CommonSetRedirectModal', this.employee);
		},
		refreshDurationAndCheckNotify() {
			this.currentCallDuration = this.employee && this.employee.currentCall ? this.employee.currentCallDuration : 0;
			if (this.notifyWhenAvailable && !this.employee.currentCall && this.employee.presence.state != 'BUSY') {
				this.showAvailableNotification();
			}
		},
		showAvailableNotification() {
			logger.info(`Employee: ${this.employee.name} is now available - showing notification`);
			const this2 = this;
			this.notifyWhenAvailable = false;
			const icon = this.employee.picture ? `https://image.telecomx.dk/${this.employee.picture}.png?width=256&height=256&token=${s.token}` : null;
			this.notifyWhenAvailableNotification = MyNotification.show(`${this.employee.name} ${i18n.t('employee.notifyAvailableHeader')}`, i18n.t('employee.notifyAvailableMessage'), icon, false, () => {
				const extension = this2.employee.primaryExtension;
				this2.call(extension);
			});
		},
		noPhoneSelected() {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') });
		},
		async call(extension) {
			// call this extension
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			if (s.myPhoneId == 'SOFTPHONE') {
				let number = extension.number;
				if (!number && extension.direct) { number = s.cutNationalPrefix(extension.direct); }
				if (!number && extension.mobile && extension.mobile.length > 0) { number = s.cutNationalPrefix(extension.mobile[0]); }
				if (!number) { number = extension._id; }
				logger.info(`Employee: User calling employee ${this.employee.name} at ${number}`);

				EventBus.$emit('Call:ActivateHold');
				await s.sleep(100);
				sipClient.call(number);
			} else {
				logger.info(`Employee: User calling employee ${this.employee.name}`);
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${extension._id}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		transfer(myCall, employeeCall, callIsWithMe) { // _id, customer, extension, state, started, answered, caller{number,name,secret}, callee{number,name}, sessionId, channel, calId, direction, leg, originator, device, pbx
			if (callIsWithMe && employeeCall.state == 'CALL_ANSWER') {
				// Attended transfer
				logger.info(`Employee: User performs attended transfer of ${(myCall.leg == 'I' ? myCall.callee.number : myCall.caller.number)} to ${this.employee.name}`);
				EventBus.$emit('AttendedTransferRequested', { source: myCall._id, destination: employeeCall._id });
				s.http.get(`/pbx/app/action/transfer?source=${myCall._id}&destination=${employeeCall._id}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.transferFailedHeader'), message: i18n.t('common.error.transferFailedMessage') });
				});
			} else if (callIsWithMe && employeeCall.state == 'CALL_RING') {
				// Hangup my call first
				s.http.get(`/pbx/app/action/hangup?target=${employeeCall._id}`)
					.then(() => {
						EventBus.$emit('BlindTransferRequested', { source: myCall._id, target: this.employee.primaryExtension._id });
						return s.http.get(`/pbx/app/action/transfer?source=${myCall._id}&target=${this.employee.primaryExtension._id}`);
					})
					.catch(() => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.transferFailedHeader'), message: i18n.t('common.error.transferFailedMessage') });
					});
			} else {
				// Blind transfer
				//console.log('Omstil blindt ' + (myCall.leg == 'I' ? myCall.callee.number : myCall.caller.number) + ' til ' + this.employee.name);
				EventBus.$emit('BlindTransferRequested', { source: myCall._id, target: this.employee.primaryExtension._id });
				s.http.get(`/pbx/app/action/transfer?source=${myCall._id}&target=${this.employee.primaryExtension._id}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.transferFailedHeader'), message: i18n.t('common.error.transferFailedMessage') });
				});
			}
		},
		addCall(call) {
			// add a new call to this extension on existing bridge where 'call' is connected
			logger.info(`Employee: User is adding ${this.employee.name} to the users existing call with ${(call.leg == 'I' ? call.callee.number : call.caller.number)}`);
			s.http.get(`/pbx/app/action/invite?source=${call._id}&target=${this.employee.primaryExtension._id}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.inviteFailedHeader'), message: i18n.t('common.error.inviteFailedMessage').replace('%%number%%', this.employee.name) });
			});
		},
		notify(enable) {
			logger.info(`Employee: User ${enable?'enabled':'disabled'} notification for when the employee ${this.employee.name} is available`);
			this.notifyWhenAvailable = enable;
		},
		async pickup(call) {
			// pickup this call
			logger.info(`Employee: User is picking up a ringing phone from ${this.employee.name} with caller ${(call.leg == 'I' ? call.callee.number : call.caller.number)}`);
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			EventBus.$emit('MyCallsAutoAnswerNextRinging', call.sessionId);
			s.http.get(`/pbx/app/action/pickup?device=${s.myRealPhoneId}&target=${call._id}`).catch(_ => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.pickFailedHeader'), message: i18n.t('common.error.pickupFailedMessage') });
			});
		},
		async steal(call) {
			// steal this call
			logger.info(`Employee: User is stealing an ongoing call from ${this.employee.name} who is talking to ${(call.leg == 'O' ? call.caller.number : call.callee.number)}`);
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			EventBus.$emit('MyCallsAutoAnswerNextRinging', call.sessionId);
			s.http.get(`/pbx/app/action/steal?device=${s.myRealPhoneId}&target=${call._id}`).catch(_ => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.stealFailedHeader'), message: i18n.t('common.error.stealFailedMessage') });
			});
		},
		async spy(call) {
			// spy on this call
			logger.info(`Employee: User is spying on ${this.employee.name} who is talking to ${(call.leg == 'O' ? call.caller.number : call.callee.number)}`);
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			EventBus.$emit('MyCallsAutoAnswerNextRinging', call.sessionId);
			s.http.get(`/pbx/app/action/spy?device=${s.myRealPhoneId}&target=${call._id}`).catch(_ => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.spyFailedHeader'), message: i18n.t('common.error.spyFailedMessage') });
			});
		},
		sendSms(number) {
			logger.info(`Employee: User is starting to write an SMS for ${this.employee.name} at ${number}`);
			EventBus.$emit('CommonSendSmsModal', { employee: this.employee, number: number });
		},
		sendEmail() {
			employees.getEmployee(s.auth.employee).then(me => {
				const call = me.currentCall;
				let message;
				if (call) {
					const caller = call.leg == 'O' ? call.caller : call.callee;
					if (caller.name) {
						message = i18n.t('employee.emailMessageWithName').replace('%%name%%', caller.name).replace('%%number%%', s.cutNationalPrefix(caller.number));
					} else {
						message = i18n.t('employee.emailMessage').replace('%%number%%', s.cutNationalPrefix(caller.number));
					}
				}
				logger.info(`Employee: User is writing an e-mail to ${this.employee.name} at ${this.employee.emailAddress}`);
				if (message) {
					window.location.href = `mailto:${this.employee.emailAddress}?subject=${encodeURIComponent(i18n.t('employee.emailSubject'))}&body=${encodeURIComponent(message)}`;
				} else {
					window.location.href = `mailto:${this.employee.emailAddress}`; // ?subject=telefonbesked&body=et kald fra....
				}
			});
		},
		setStatus(state) {
			logger.info(`Employee: User is setting status for ${this.employee.name} to ${state}`);
			const presence = { presence: { state: state } };//, expire: null, message: '', location: '' } };
			if (state == 'AVAILABLE') {
				presence.presence.expire = null;
				presence.presence.message = '';
				presence.presence.location = '';
			}
			s.http.post(`/employee/${this.employee._id}`, presence)
				.catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.setPresenceFailedHeader'), message: i18n.t('common.error.setPresenceFailedMessage').replace('%%name%%', this.employee.name) });
				});
		},
		showInfo() {
			//console.log('Show info for ' + this.employee.name);
			EventBus.$emit('CommonEmployeeDataModal', this.employee);
		}
	},
	created() {
		employees.getEmployee(this.employeeId).then(employee => { this.employee = employee; }).catch(() => {});
		EventBus.$on('OneSecond', this.bindCache.bind('refreshDurationAndCheckNotify', this.refreshDurationAndCheckNotify));
		this.tag = u.randomString(30);
		setTimeout(() => { this.refreshDurationAndCheckNotify(); }, 100);
		if (this.notifyWhenAvailable && !this.employee.currentCall) {
			this.notifyWhenAvailable = false;
		}
	},
	destroyed() {
		EventBus.$off('OneSecond', this.bindCache.unbind('refreshDurationAndCheckNotify'));
	}

};
</script>

<style scoped>
	.employee-box {
		/* cursor: context-menu; */
		/* transition: background 400ms; */
		padding: 2px 0 0 4px;
	}
	.presence-state {
		position: absolute;
		left: 33px;
		top: 23px;
		z-index: 1;
	}
	.labels {
		padding-left: 13px;
		height: 44px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 2px;
		margin-right: 2px;
		/* background: lightblue; */
	}
	.labels DIV {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.name {
		font-size: var(--font-size-primary);
		font-weight:400;
		line-height: var(--font-size-primary-lineheight);
		color: var(--txt-color-primary);
		letter-spacing: 0.01em;
		/* margin-top: -2px; */
	}
	.message {
		font-size: var(--font-size-small);
		color: var(--label-message-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		letter-spacing: 0.01em;
	}
	.expires {
		font-size: var(--font-size-small);
		font-style: italic;
		color: var(--label-expires-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		height: 0px;
		transition: 400ms;
		overflow: hidden;
	}
	.expires.visible {
		height: 15px;
	}
	.call {
		font-size: var(--font-size-small);
		font-style: italic;
		color: var(--label-otherparty-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		height: 0px;
		transition: 400ms;
	}
	.call.visible {
		height: 15px;
	}
	.label {
		padding-left: 2px;
	}
	.no-shrink {
		flex-shrink: 0;
	}
	.other-party-icon {
		margin-top: -2px;
	}
	.duration-box {
		height: 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		float: right;
		transition: 600ms;
		transition-delay: 300ms;
		max-width: 0px;
		min-width: 0px;
		overflow: hidden;
		margin-top: 4px;
	}
	.duration-box.visible {
		max-width: 65px;
		min-width: 40px;
	}
	.duration-time {
		color: var(--call-duration-color);
		font-family: RobotoMonoThin;
		font-size: var(--font-size-small);
		flex-shrink: 0;
		margin: 0 auto;
	}
	.ringing {
		animation: shake 1s; 
		animation-iteration-count: infinite; 
	}

	@keyframes shake {
		0% { transform: translate(2px, 0px); }
		10% { transform: translate(-2px, 1px); }
		20% { transform: translate(2px, -1px); }
		30% { transform: translate(-2px, 0px); }
		40% { transform: translate(2px, 0px); }
		50% { transform: translate(-2px, 0px); }
		60% { transform: translate(2px, 0px); }
		70% { transform: translate(-2px, 1px); }
		80% { transform: translate(2px, -1px); }
		90% { transform: translate(-2px, 0px); }
		100% { transform: translate(2px, 0px); }
	}
	.onhold {
		animation: holdanim 1s; 
		animation-iteration-count: infinite; 
	}
	@keyframes holdanim {
		0% { opacity: 1; }
		40% { opacity: 0.2; }
		70% { opacity: 1; }
	}
	.birthday {
		width: 24px;
		position: absolute;
		top: 30px;
	}
	.bottom-line {
		margin-left: 52px;
		border-bottom: var(--divider-line);
		margin-top: 2px;
	}
	.country-flag {
		margin-left: 3px;
		margin-top: -1px;
	}
	.redirect {
		font-size: var(--font-size-small);
		font-weight: 400;
		background: var(--bg-color-dashboard-header);
		padding: 1px 2px;
		display: inline-block;
		color: #fff;
		height: 13px;
		border-radius: 3px;
	}

</style>