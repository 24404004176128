<template>
	<div v-if="dashboards.dashboard" class="bar" :class="{'visible':dashboards.editMode}">
		<label v-if="dashboards.mode=='CREATE'">{{ $t('actionbar.newLayoutName') }}</label>
		<label v-if="dashboards.mode=='EDIT'">{{ $t('actionbar.layoutName') }}</label>
		<b-input id="DashboardName" size="sm" type="text" maxlength="64" style="max-width:250px;min-width:50px;margin-right: 10px" :autofocus="true" v-model="dashboards.dashboard.name"></b-input>

		<label style="margin-left:10px">{{ $t('actionbar.useLayoutInTopbar') }}</label>
		<b-form-select style="width:175px;margin-right:20px" v-model="dashboards.dashboard.topbar" :options="topbarPriorities" value-field="index" size="sm"></b-form-select>

		<label v-if="state.auth.accessLevel!='PERSONAL'&&state.auth.accessLevel!='VIEWER'" style="margin-left:10px">{{ $t('actionbar.availability') }}</label>
		<b-form-select v-if="state.auth.accessLevel!='PERSONAL'&&state.auth.accessLevel!='VIEWER'" style="width:125px;margin-right:20px" v-model="dashboards.dashboard.shared" :options="sharedOptions" value-field="value" size="sm" :disabled="dashboards.mode!='CREATE'"></b-form-select>

		<label v-if="state.auth.accessLevel!='PERSONAL'&&state.auth.accessLevel!='VIEWER'&&dashboards.dashboard.shared" style="margin-left:10px">{{ $t('actionbar.forMembersOf') }}</label>
		<b-form-select v-if="state.auth.accessLevel!='PERSONAL'&&state.auth.accessLevel!='VIEWER'&&dashboards.dashboard.shared" style="width:175px;margin-right:20px" v-model="dashboards.dashboard.group" :options="dashboards.pbxGroups" value-field="_id" size="sm"></b-form-select>

		<b-button style="height:26px" :disabled="dashboards.dashboard.name.length==0||dashboards.dashboard.items.length==0" size="sm" variant="success" @click="dashboards.save()">{{ dashboards.mode=='CREATE'? $t('common.create') : $t('common.save') }}</b-button>
		<b-button style="height:26px" size="sm" variant="danger" :disabled="!dashboards.dashboard" @click="remove()">{{ $t('common.delete') }}</b-button>
		<b-button style="height:26px" size="sm" @click="dashboards.cancel()">{{ $t('common.cancel') }}</b-button>
	</div>
</template>

<script>
import s from '../../settings';
import EventBus from '../../data/EventBus';
import dashboards from '../../data/dashboards';
import i18n from '../../utils/i18n';

export default {
	data() {
		return {
			dashboards: dashboards,
			state: s,
			deleteHeader: '',
			deleteQuestion: '',
			$t: i18n.t,
			topbarPriorities: [
				{ index: 0, text: i18n.t('common.no') },
				{ index: 1, text: i18n.t('actionbar.topbarPriority') + ' 1' },
				{ index: 2, text: i18n.t('actionbar.topbarPriority') + ' 2' },
				{ index: 3, text: i18n.t('actionbar.topbarPriority') + ' 3' },
				{ index: 4, text: i18n.t('actionbar.topbarPriority') + ' 4' },
				{ index: 5, text: i18n.t('actionbar.topbarPriority') + ' 5' }
			],
			sharedOptions: [
				{ index: 0, text: i18n.t('actionbar.personal'), value: false },
				{ index: 1, text: i18n.t('actionbar.shared'), value: true }
			]
		};
	},
	methods: {
		remove: function() {
			if (dashboards.dashboard) {
				EventBus.$emit('CommonQuestionModal', { header: i18n.t('actionbar.deleteHeader'), message: `${i18n.t('actionbar.deleteMessage')} ${dashboards.dashboard.name}?`, ok: i18n.t('common.delete'), emit: 'Dashboard:Delete', emitData: dashboards.dashboard._id});
			}
		}
	},
	watch: {
		'dashboards.editMode': function(newValue, oldValue) {
			if (newValue) {
				setTimeout(() => {
					document.getElementById('DashboardName').focus();
				}, 200);
			}
		}
	}
};
</script>

<style scoped>
	.bar {
		background: var(--bg-color-secondary);
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25);
		height: 40px;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 3px;
		display: flex;
		align-items: center;
		position: fixed;
		left: 0;
		top: 73px;
		right: 0;
		transition: 500ms;
		margin-top: -45px;
		z-index: 99;
	}
	.bar.macos {
		top: 90px;
	}
	.bar.visible {
		margin-top: 0px;
	}
	.bar LABEL {
		margin-bottom: 0;
		font-size: var(--font-size-secondary);
		font-weight: 300;
		padding-right: 5px;
	}
	.bar BUTTON {
		margin-top: 0;
		margin-bottom: 0;
		width: 100px;
		margin-right: 10px;
	}
</style>