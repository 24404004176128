<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex">
				<employee-picture :picture="emp.picture" :size="58" :spinner="true"></employee-picture>
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ $t('setPresence.header') }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ emp.name }}</div>
				</div>
			</div>
		</template>
		<div class="state-box" v-if="emp">
			<button class="state-box-item" :tabindex="index" :class="{ unselected: emp.presence.state != item.key }" v-for="(item, index) in states" :key="item.state" @click="selectState(item.key)">
				<presence-icon :state="item.key" :size="48" />
				{{ item.label }}
			</button>
		</div>

		<div style="margin-left:10px;margin-right:10px" v-if="emp">
			<b-form-group :label="$t('setPresence.message')">
				<b-form-input v-model="emp.presence.message" tabindex="20" type="text" size="sm" maxlength="512" :autofocus="true" @keydown="keyDownHandler"></b-form-input>
			</b-form-group>
			<b-form-group :label="$t('setPresence.location')">
				<b-form-input v-model="emp.presence.location" tabindex="30" type="text" size="sm" maxlength="512" @keydown="keyDownHandler"></b-form-input>
			</b-form-group>
			<b-form-group :label="$t('setPresence.until')">
				<div style="display:flex;margin-left:7px">
					<b-form-checkbox :disabled="emp.presence.state=='AVAILABLE'" size="lg" v-model="expire.enabled" tabindex="40"></b-form-checkbox>
					<b-form-datepicker :disabled="!expire.enabled" tabindex="50" style="width:490px" v-model="expire.date" :value-as-date="true" class="mb-2" :min="expire.min" :max="expire.max" locale="da" size="sm" :placeholder="$t('setPresence.selectDate')" start-weekday="1" v-bind="labels" :hide-header="true"></b-form-datepicker>
					<b-form-input :disabled="!expire.enabled" tabindex="60" size="sm" v-model="expire.time" maxlength="5" @keyup="timeKeyPress" :formatter="timeFormatter" :placeholder="$t('setPresence.time')" style="width:100px"></b-form-input>
				</div>
			</b-form-group>
		</div>

		<div class="space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" size="sm" @click="close()" tabindex="100">{{ $t('common.cancel') }}</b-button>
			<b-button variant="primary" style="height:30px" :disabled="!emp" size="sm" type="submit" @click="save()" tabindex="110">{{ $t('common.save') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import EmployeePicture from '../EmployeePicture.vue';
import PresenceIcon from '../PresenceIcon.vue';
import s from '../../settings';
import u from '../../utils/utils';
import i18n from '../../utils/i18n';

export default {
	props: {
		employee: { type: Object }
	},
	components: {
		PresenceIcon,
		EmployeePicture

	},
	data() {
		return {
			$t: i18n.t,
			emp: { presence: { state: 'BUSY' } },
			modalId: 'SetPresenceModal' + Math.random().toString(),
			expire: { enabled: false, date: '', time: '', min: '', max: '' },
			isSaving: false,
			states: [
				{ key: 'AVAILABLE', label: i18n.t('common.presence.AVAILABLE') },
				{ key: 'BUSY', label: i18n.t('common.presence.BUSY') },
				{ key: 'OUT_OF_OFFICE', label: i18n.t('common.presence.OUT_OF_OFFICE') },
				{ key: 'OFF_WORK', label: i18n.t('common.presence.OFF_WORK') },
				{ key: 'HOLIDAY', label: i18n.t('common.presence.HOLIDAY') }
			],
			labels: i18n.messages[i18n.locale].datepicker
		};
	},
	methods: {
		keyDownHandler(e) {
			if (e.key == 'Enter') {
				this.save();
			}
		},
		timeKeyPress(e) {
			if (['0','1','2','3','4','5','6','7','8','9',':'].includes(e.key) && this.expire.time.length == 2) {
				this.expire.time = this.expire.time + ':';
			}
		},
		timeFormatter(value) {
			value = value.replace(/[^0-9:]/g, '');
			while(value.split(':').length > 2) { value = value.slice(0, -1); }
			const parts = value.split(':');
			let changed = false;
			if (parts[0] && parts[0] > 23) { parts[0] = '23'; changed = true; }
			if (parts[1] && parts[1] > 59) { parts[1] = '59'; changed = true; }
			if (changed) { return parts.join(':'); }
			return value;
		},
		show(employee) {
			if (!employee) { employee = this.employee; }
			this.emp = u.extendObject({}, employee);
			if (this.emp.presence.expire) {
				const ex = new Date(this.emp.presence.expire);
				this.expire.enabled = true;
				this.expire.date = ex;
				this.expire.time = u.formatDate(ex, 'hh:mm');
			}
			this.expire.min = new Date();
			const max = new Date();
			max.setMonth(max.getMonth() + 12);
			this.expire.max = max;
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		selectState(state) {
			this.emp.presence.state = state;
			if (state == 'AVAILABLE') {
				this.expire.enabled = false;
				this.expire.date = null;
				this.expire.time = '';
			}
		},
		save() {
			this.isSaving = true;
			if (this.expire.enabled && this.expire.date && this.expire.time) {
				const ex = this.expire.date;
				if (!isNaN(ex)) {
					ex.setHours(this.expire.time.substr(0,2), this.expire.time.substr(3,2), 0, 0);
					this.emp.presence.expire = ex;
				} else {
					this.emp.presence.expire = null;
				}
			} else {
				this.emp.presence.expire = null;
			}
			const presence = { presence: this.emp.presence };
			s.http.post(`/employee/${this.employee._id}`, presence)
				.then(() => { this.close(); })
				.catch(err => {
					this.isSaving = false;
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					} else {
						if (err.status >= 400) {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('setPresence.errorHeader'), message: i18n.t('setPresence.errorUnknown') + ' ' + err.status });
						} else {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('setPresence.errorHeader'), message: i18n.t('setPresence.errorUnknown') + ` ${err.status} ${err.path}\n${err.message}` });
						}
					}
				});
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.state-box {
		display: flex;
		justify-content: space-between;
		/* margin-left: 10px;
		margin-right: 10px;
		margin-top: 10px; */
		margin-bottom: 15px;
		margin-top: 15px;
	}
	.state-box-item {
		flex-grow: 0;
		flex-shrink: 0;
		width: 90px;
		border: 0;
		font-size: 12px;
		color: var(--txt-color-primary);
		cursor: pointer;
		outline: 0;
		white-space: nowrap;
		background: var(--bg-color-primary);
		border-radius: 5px;
		padding-top:5px;
		padding-bottom: 5px;
		border: 1px solid var(--bg-color-3);
		box-shadow: 0px 0px 10px 1px #ddd;
	}
	.state-box-item:focus {
		/* border: 0; */
		box-shadow: 0 0 5px 2px rgba(0, 123, 255, 0.5) !important;
		-webkit-box-shadow: 0 0 5px 2px rgba(0, 123, 255, 0.5) !important;
	}
	.unselected {
		opacity: 0.4;
		border: 1px solid #fff;
		box-shadow: none;
	}
	.unselected:hover {
		opacity: 1;
	}
	.space-to-bottom {
		height: 28px;
	}
	.overlay {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #ffffff80;
	}
	.overlay.visible {
		display: block;
	}
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
</style>
