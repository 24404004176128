/**
 * This module will watch for CALL_START or CALL_ANSWER on our phone, and trigger the enabled integrations.
 * This will also listen to commands from the local API and make sure they are carried out
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2021-04-30
*/

import s from '../settings';
import employees from './employees';
import EventBus from './EventBus';
import realtime from './realtime';
import axios from 'axios';
import u from '../utils/utils';
import sipClient from '../data/sipClient';
import i18n from '../utils/i18n';
import logger from './logger';

class Integration {
	constructor() {
		this.localApiConnected = false;
		if (s.isEmbedded) { // Only initialize if running in embedded mode
			EventBus.$on('Realtime:Pbx:CALL_RING', this.callEvent.bind(this));
			EventBus.$on('Realtime:Pbx:CALL_ANSWER', this.callEvent.bind(this));
			EventBus.$on('Realtime:Connected', this.init.bind(this));
			EventBus.$on('StatusRequest', () => {
				EventBus.$emit('StatusReport', { key: 'integration', value: this.localApiConnected });
			});
			if (realtime.connected) {
				this.init();
			}

			logger.registerGlobal('integration', this);
		}
	}

	async init() {
		this.me = await employees.getMe();
		if (!this.me) {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
			s.logout();
			return;
		}
		this.pollLocalApi();
	}

	async callEvent(e) {
		if (!this.me) {
			this.me = await employees.getMe();
		}

		// If a call is for my extension and I am receiving it and it is in state ringing or answered - then I want to know more...
		if (e.extension == this.me.primaryExtension._id && e.device == s.myRealPhoneId && e.leg == 'O' && (e.event == 'CALL_RING' || e.event == 'CALL_ANSWER')) {
			setTimeout(() => { // to allow employees to be updated first
				// Find calls that concerns my device only
				const myCalls = this.me.primaryExtension.calls.filter(o => o.device == s.myRealPhoneId);

				// If I have exactly 1 incoming call in the right state on the phone that I am using - then we have a call that we need to care about
				if (myCalls.length == 1) {
					const call = myCalls[0];
					//console.log(`We got an incoming call with state ${call.state} from ${call.caller.number} - ${call.caller.name} - and it is the only call`);

					// Check each automation, if any is to be triggered then do it

					// Sendkeys
					if ((s.integration.sendkeys.when == 'RINGING' && call.state == 'CALL_RING') || (s.integration.sendkeys.when == 'ANSWERED' && call.state == 'CALL_ANSWER')) {
						this.sendkeys(call).catch(() => {});
					}

					// Browser
					if ((s.integration.browser.when == 'RINGING' && call.state == 'CALL_RING') || (s.integration.browser.when == 'ANSWERED' && call.state == 'CALL_ANSWER')) {
						this.browser(call).catch(() => {});
					}

					// Run
					if ((s.integration.run.when == 'RINGING' && call.state == 'CALL_RING') || (s.integration.run.when == 'ANSWERED' && call.state == 'CALL_ANSWER')) {
						this.run(call).catch(() => {});
					}

					// Copy to clipboad
					if ((s.integration.copyToClipboard.when == 'RINGING' && call.state == 'CALL_RING') || (s.integration.copyToClipboard.when == 'ANSWERED' && call.state == 'CALL_ANSWER')) {
						this.clipboard(call);
					}

				}
			}, 1);
		}
	}

	/**
	 * Replaces number, name and variables
	 * @param {String} str String to perform replacing on
	 * @param {Object} call Call object
	 * @param {Boolean} requireArgs True if all variables must be present, otherwise return null
	 * @param {Boolean} testMode True if we are in test mode and should make up test data
	 * @returns {Promise<String>} Replaced string
	 */
	async replace(str, call, requireArgs, testMode) {
		const number = testMode ? '+4512345678' : (call.caller.privacy ? '' : call.caller.number);
		const name = testMode ? 'Test Testesen' : (call.caller.privacy ? '' : call.caller.name);
		let failed = false;
		if (str.includes('{NUMBER}') && !number) { failed = true; }
		if (str.includes('{NUMBER-NO-PREFIX}') && !number) { failed = true; }
		str = str.replace(/\{NUMBER\}/g, number).replace(/\{NAME\}/g, name).replace(/\{NUMBER-NO-PREFIX\}/g, s.cutNationalPrefix(number));
		const vars = str.match(/\{VAR:[^}]+\}/g);
		if (!vars) {
			if (requireArgs && failed) { return null; }
			return str;
		}
		while (vars.length > 0) {
			const variable = vars.shift();
			if (testMode) {
				str = str.replace(variable, `${variable.substr(5).slice(0, -1)}:TEST`);
			} else {
				const value = await this.getVariableValue(call._id, call.callerChannelId, variable.substr(5).slice(0, -1));
				if (value == '') { failed = true; }
				str = str.replace(variable, value);
			}
		}
		if (requireArgs && failed) { return null; }
		return str;
	}

	async getVariableValue(callId, callerChannelId, variable) {
		return s.http.get(`/pbx/callvariable/${callId.split(':')[0]}:${callerChannelId}/${variable}`)
			.then(res => { return res.data.value; })
			.catch(res => { return ''; });
	}

	/**
	 * Run Copy-to-clipboard integration
	 * @param {Object} call Call object
	 * @param {Object} settings Test settings, used when testing integration
	 */
	async clipboard(call, settings) {
		const data = settings ? settings : s.integration.copyToClipboard;
		let what = await this.replace(data.what, call, data.requireArgs, settings != null); // replaces {NUMBER}, {NUMBER-NO-PREFIX}, {NAME}, {VAR:cpr} in the command
		if (what != null) {
			axios.post(`${s.localUrl}/integration/clipboard`, { content: what }, { timeout: 5000 }).catch(() => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.errorHeader'), message: i18n.t('integration.errorClipboardMessage') });
			});
		}
	}

	/**
	 * Run Run app integration
	 * @param {Object} call Call object
	 * @param {Object} settings Test settings, used when testing integration
	 */
	 async run(call, settings) {
		const data = settings ? settings : s.integration.run;
		await this.delay(data.delay);
		let command = await this.replace(data.command, call, data.requireArgs, settings != null); // replaces {NUMBER}, {NUMBER-NO-PREFIX}, {NAME}, {VAR:cpr} in the command
		if (command != null) {
			axios.post(`${s.localUrl}/integration/run`, { command: command }, { timeout: 5000 }).catch(() => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.errorHeader'), message: i18n.t('integration.errorRunMessage') });
			});
		}
	}

	/**
	 * Run Send keys integration
	 * @param {Object} call Call object
	 * @param {Object} settings Test settings, used when testing integration
	 */
	 async sendkeys(call, settings) {
		const data = settings ? settings : s.integration.sendkeys;
		await this.delay(data.delay);
		let keys = await this.replace(data.keys, call, data.requireArgs, settings != null); // replaces {NUMBER}, {NUMBER-NO-PREFIX}, {NAME}, {VAR:cpr} in the keys
		if (keys != null) {
			axios.post(`${s.localUrl}/integration/sendkeys`, { focusProcess: data.focusProcess, maximizeProcess: data.maximizeProcess, useAutoit: data.useAutoit, keys: keys }, { timeout: 5000 }).catch(() => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.errorHeader'), message: i18n.t('integration.errorSendkeysMessage') });
			});
		}
	}

	/**
	 * Run Browser integration
	 * @param {Object} call Call object
	 * @param {Object} settings Test settings, used when testing integration
	 */
	 async browser(call, settings) {
		const data = u.cloneObject(settings || s.integration.browser);
		let failed = false;
		for (const element of data.actions) {
			if (element.action == 'insert') {
				const value = await this.replace(element.value, call, data.requireArgs, settings != null);  // replaces {NUMBER}, {NUMBER-NO-PREFIX}, {NAME}, {VAR:cpr} in the keys
				if (value != null) {
					element.value = value;
				} else {
					failed = true;
				}
			}
		}
		if (!failed) {
			axios.post(`${s.localUrl}/integration/browser`, data, { timeout: 5000 }).catch(() => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.errorHeader'), message: i18n.t('integration.errorBrowserMessage') });
			});
		}
	}

	delay(ms) {
		return new Promise(resolve => {
			setTimeout(() => { resolve(); }, ms);
		});
	}

	pollLocalApi() {
		if (!this.localApiConnected) {
			logger.debug('Integration: Trying to connect to local API (app backend) at ' + s.localUrl);
		}
		const timeout = this.localApiConnected ? 10000 : 500;
		if (this.pollLocalApiToken) {
			this.pollLocalApiToken.cancel();
		}
		this.pollLocalApiToken = axios.CancelToken.source();

		axios.get(`${s.localUrl}/get?client=MAIN&timeout=${timeout}`, {
			timeout: this.localApiConnected ? 12000 : 2500,
			cancelToken: this.pollLocalApiToken.token
		})
			.then(res => {
				delete this.pollLocalApiToken;
				if (!this.localApiConnected) {
					this.localApiConnected = true;
					logger.debug('Integration: Local API connected (app backend)');
				}
				if (res.data.length > 0) {
					this.handleApiRequest(res.data);
				}
				this.pollLocalApi();
			})
			.catch(err => {
				if (!err || (!err.message && !err.stack)) { logger.debug('Integration: Polling was cancelled'); return; } // Request was cancelled
				logger.debug(`Integration: Local API failed: ${err.message} - not connected anymore`);
				delete this.pollLocalApiToken;
				this.localApiConnected = false;
				setTimeout(() => {
					this.pollLocalApi(); 
				}, 1000);
			});
	}

	handleApiRequest(commands) {
		commands.forEach(command => {
			switch (command.command) {
				case 'ANSWER': {
					const cr = this.me.currentRingingCall;
					if (cr && s.myPhoneId) {
						if (s.myPhoneId == 'SOFTPHONE') {
							const session = sipClient.getSession(cr.callId, cr.caller.number, cr.state);
							if (session) {
								sipClient.answer(session);
							}
						} else {
							s.http.get(`/pbx/app/action/answer?device=${s.myPhoneId}&target=${cr._id}`).catch(err => {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.myCallsHeader'), message: i18n.t('integration.myCallsFailedToAnswer') });
							});
						}
					}
					break;
				}

				case 'HANGUP': {
					const cc = this.me.currentCall;
					if (cc && s.myPhoneId) {
						if (s.myPhoneId == 'SOFTPHONE') {
							const session = sipClient.getSession(cc.callId, cc.caller.number, cc.state);
							if (session) {
								sipClient.hangup(session);
							}
						} else {
							s.http.get(`/pbx/app/action/hangup?target=${cc._id}`).catch(err => {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.myCallsHeader'), message: i18n.t('integration.myCallsFailedHangup') });
							});
						}
					}
					break;
				}

				case 'REJECT': {
					const cr = this.me.currentRingingCall;
					if (cr && s.myPhoneId) {
						if (s.myPhoneId == 'SOFTPHONE') {
							const session = sipClient.getSession(cr.callId, cr.caller.number, cr.state);
							if (session) {
								EventBus.$emit('CallRejected', { source: cr._id });
								sipClient.decline(session);
							}
						} else {
							s.http.get(`/pbx/app/action/hangup?target=${cr._id}`).catch(err => {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('integration.myCallsHeader'), message: i18n.t('integration.myCallsFailedReject') });
							});
						}
					}
					break;
				}

				case 'SEARCH': {
					const el = document.getElementById('MainSearchInput');
					el.value = '';
					el.focus();
					break;
				}

				case 'CALL': {
					let number = command.number;
					if (number) {
						if (number.startsWith('00')) { number = '+' + number.substr(2); }
						if (!number.startsWith('+') && number.length > 7) { number = s.pbxSettings.nationalPrefix + number; }
						if (!s.myPhoneId) { EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); return; }
						if (s.myPhoneId == 'SOFTPHONE') {
							sipClient.call(number);
						} else {
							s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(number)}`).catch(() => {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
							});
						}
					}
					break;
				}

				case 'ERROR':
					EventBus.$emit('CommonErrorModal', { header: command.header, message: command.message });
					break;

				case 'LOGGER':
					switch(command.severity) {
						case 'INFO': logger.info(command.message); break;
						case 'WARNING': logger.warning(command.message); break;
						case 'ERROR': logger.error(command.message); break;
						case 'DEBUG': logger.debug(command.message); break;
					}
					break;
			}
		});
	}
}

// Singleton
export default new Integration();
