<template>
	<div>
		<div class="voicemail-container">
			<div class="voicemail-listbox">
				<div class="dash-header">
					<glyph-icon icon="662-voicemail" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:4px">{{ $t('voicemail.header') }}</div>
					<div style="color:var(--txt-color-secondary-highlight);font-size:var(--font-size-small);margin-top:1px;">
						<span v-if="newMessages>0">{{ newMessages }} {{ newMessages == 1 ? $t('voicemail.newMessage') : $t('voicemail.newMessages') }}</span>
						<span v-if="oldMessages>0&&newMessages>0"> {{ $t('voicemail.and') }} </span>
						<span v-if="oldMessages>0">{{ oldMessages }} {{ oldMessages == 1 ? $t('voicemail.oldMessage') : $t('voicemail.oldMessages') }}</span>
					</div>
				</div>

				<div style="background-color:var(--bg-color-secondary);padding: 5px 20px 0 20px;border-bottom:var(--divider-line)">
					<b-row>
						<b-col cols="6">
							<b-form-group :label="$t('common.filter')">
								<b-form-input type="search" v-model="filter" size="sm" debounce="500" />
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group :label="$t('voicemail.type')">
								<b-form-select v-model="type" :options="types" @change="loadList" size="sm" />
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-button style="margin-top:20px" block variant="danger" size="sm" :disabled="list.length==0" @click="removeAll()">{{ $t('voicemail.deleteAll') }}</b-button>
						</b-col>
					</b-row>
				</div>
				<div v-if="!isLoading&&list.length==0" style="text-align:center;margin:10px;background:var(--pn-primary-2);color:#fff;padding:4px;border-radius:10px;font-weight:300;font-size:16px">{{ $t('common.noneFound') }}</div>
				<div class="loading" :class="{'active':isLoading}">
					<div>
						<b-spinner variant="light" style="width:30px;height:30px" />
						<br/>{{ $t('voicemail.loading') }}
					</div>
				</div>
				<div class="voicemail-list">
					<RecycleScroller class="my-scroller" :items="list" :item-size="39" key-field="_id" v-slot="{ item, index }">
						<list-item :item="item" :click="select" :index="index" :me="me" :contextMenu="showContextMenu" :playVoicemail="playVoicemail" />
					</RecycleScroller>
				</div>
				<v-contextmenu ref="voicemailmenu" id="voicemailmenu">
					<v-contextmenu-item @click="call">{{ $t('voicemail.actions.call') }}</v-contextmenu-item>
					<v-contextmenu-item @click="filterOnThis">{{ $t('voicemail.actions.filterOnThis') }}</v-contextmenu-item>
					<v-contextmenu-item @click="playVoicemail()">{{ $t('voicemail.actions.playMessage') }}</v-contextmenu-item>
					<v-contextmenu-item divider />
					<v-contextmenu-item @click="remove">{{ $t('common.delete') }}</v-contextmenu-item>
				</v-contextmenu>
			</div>

		</div>
	</div>
</template>

<script>
import ListItem from './ListItem.vue';
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import realtime from '../../data/realtime';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		ListItem,
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			me: null,
			list: [],
			selected: null,
			filter: '',
			type: 'ALL',
			types: [
				{ value: 'ALL', text: i18n.t('voicemail.types.all') },
				{ value: 'NEW', text: i18n.t('voicemail.types.new') }
			],
			isLoading: false,
			loadAgain: false,
			labels: i18n.messages[i18n.locale].datepicker,
			newMessages: 0,
			oldMessages: 0
		};
	},
	methods: {
		showContextMenu(e, item) {
			this.selected = item;
			e.preventDefault();
			setTimeout(() => { this.$refs['voicemailmenu'].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		call() {
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(this.selected.aNumber);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(s.cutNationalPrefix(this.selected.aNumber))}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		filterOnThis() {
			this.filter = this.selected.aNumber;
		},
		playVoicemail(item) {
			if (item) { this.selected = item; }
			EventBus.$emit('AudioPlayerModal:Show', {
				header: i18n.t('voicemail.playInfo').replace('%%date%%', u.formatDate(this.selected.date,'d/M-yyyy hh:mm')).replace('%%from%%', this.selected.aName || s.cutNationalPrefix(this.selected.aNumber, true)),
				audioId: this.selected._id,
				length: this.selected.length,
				markAsPlayed: !this.selected.old,
				filename: `${i18n.t('voicemail.header')} ${u.formatDate(this.selected.date,'dd-MM-yyyy hh.mm')} - ${s.cutNationalPrefix(this.selected.aName || this.selected.aNumber)}`,
				isVoicemail: true
			});
		},
		remove() {
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('voicemail.deleteHeader'), message: i18n.t('voicemail.deleteMessage'), ok: i18n.t('common.delete'), emit: 'Voicemail:Delete', emitData: this.selected._id});
		},
		removeDo(id) {
			s.http.delete(`/pbx/voicemail/${id}`)
				.then(() => {
					this.loadList();
				})
				.catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('voicemail.deleteHeader'), message: i18n.t('voicemail.deleteFailed') });
				});
		},
		removeAll() {
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('voicemail.deleteAllHeader'), message: i18n.t('voicemail.deleteAllMessage'), ok: i18n.t('common.delete'), emit: 'Voicemail:DeleteAll' });
		},
		removeDoAll() {
			s.http.delete('/pbx/voicemail')
				.then(() => {
					this.loadList();
				})
				.catch(() => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('voicemail.deleteAllHeader'), message: i18n.t('voicemail.deleteAllFailed') });
				});
		},
		async select(item) {
			this.selected = item;
		},
		async loadList() {
			if (this.isLoading) { this.loadAgain = true; return; }
			this.isLoading = true;
			let query = { offset: 0, limit: 1000 };

			if (this.filter) { query.filter = this.filter; }
			if (this.type == 'NEW') { query.new = true; }

			s.http.get('/pbx/voicemail', { params: query })
				.then(res => {
					this.isLoading = false;
					if (this.loadAgain) {
						this.loadAgain = false;
						this.loadList();
					}
					res.data.voicemails.forEach(item => {
						item.date = new Date(item.date);
					});
					res.data.voicemails.sort(u.dynamicSort('-date'));
					this.list = [];
					this.$nextTick(() => {
						this.list = res.data.voicemails;
					});
				})
				.catch(err => {
					this.isLoading = false;
					this.loadAgain = false;
					EventBus.$emit('CommonErrorModal', { header: i18n.t('voicemail.header'), message: i18n.t('voicemail.loadingError') + '<br><br>' + err.message });
				});
		},
		pbxEventHandler(ev) {
			if (ev._id == this.me._id) {
				this.newMessages = ev.newMessages;
				this.oldMessages = ev.oldMessages;
				EventBus.$emit('Voicemail:Count', { newMessages: this.newMessages, oldMessages: this.oldMessages });
				this.loadList();
			}
		},
		loadCount() {
			s.http.get('/pbx/voicemail/count').then(res => {
				this.newMessages = res.data.newMessages;
				this.oldMessages = res.data.oldMessages;
				EventBus.$emit('Voicemail:Count', { newMessages: this.newMessages, oldMessages: this.oldMessages });
			}).catch(() => {});
		}
	},
	created() {
		employees.getMe().then(me => {
			this.me = me;
			if (!me) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
				s.logout();
				return;
			}
		}).catch(() => {});
		EventBus.$on('Voicemail:Delete', this.removeDo.bind(this));
		EventBus.$on('Voicemail:DeleteAll', this.removeDoAll.bind(this));
		EventBus.$on('Voicemail:Read', this.loadList.bind(this));
		EventBus.$on('Realtime:Pbx:VOICEMAIL_NEW', this.pbxEventHandler.bind(this));
		EventBus.$on('Realtime:Pbx:VOICEMAIL_OLD', this.pbxEventHandler.bind(this));
		EventBus.$on('Realtime:Pbx:VOICEMAIL_DELETED', this.pbxEventHandler.bind(this));
		EventBus.$on('Realtime:Connected', this.loadCount.bind(this));
		if (realtime.connected) { this.loadCount(); }
	},
	mounted() {
	},
	watch: {
		'filter': function() { this.loadList(); },
		'from': function() { this.loadList(); },
		'to': function() { this.loadList(); },
		's.selectedSection': function(value) {
			if (value == 'VOICEMAIL') {
				this.loadList();
			}
		}
	}
};
</script>

<style scoped>
	.voicemail-container {
		margin: 10px 10px 10px 10px;
		height: calc(100vh - 95px);
		text-align: center;
	}
	@media (max-width: 769px) {
		.voicemail-container {
			height: calc(100vh - 190px);
		}
	}
	.voicemail-listbox {
		background: var(--bg-color-secondary);
		border-radius: 5px;
		height: 100%;
		max-width: 500px;
		margin: 0 auto;
	}
	.voicemail-list {
		height: calc(100% - 92px); 
		/* overflow-y: scroll; */
	}
	.my-scroller {
		height: 100%;
	}
	.loading {
		position: absolute;
		transition: opacity 500ms, max-height 1ms 500ms, transform 500ms;
		z-index: 1000;
		overflow: hidden;
		max-height: 0;
		width: 600px;
		height: 110px;
		opacity: 0;
	}
	.loading > DIV {
		position: absolute;
		text-align: center;
		background: var(--pn-primary-2);
		color: var(--txt-color-primary-highlight);
		padding: 20px 10px;
		border-radius: 20px;
		font-weight: 300;
		font-size: 18px;
		margin-left: 200px;
		margin-right: 200px;
		margin-top: 10px;
		margin-bottom: 10px;
		overflow: hidden;
		width: 200px;
		height: 95px;
	}
	.loading.active {
		transition: opacity 500ms 1s, max-height 1ms 1s, transform 500ms 1s;
		max-height: 200px;
		opacity: 1;
		transform: translateY(10px);
	}
</style>