

class BrowserDetect {
	constructor() {
		this.isChromium = window.chrome;
		this.winNav = window.navigator;
		this.vendorName = this.winNav.vendor;
		this.isOpera = typeof window.opr !== 'undefined';
		this.isIEedge = this.winNav.userAgent.indexOf('Edge') > -1;
		this.isIOSChrome = this.winNav.userAgent.match('CriOS');
		/** @type {Boolean} Is this a Safari browser */
		this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		this.isFirefox = navigator.userAgent.includes('Firefox');

		/** @type {Boolean} Is this a Chrome browser */
		this.isChrome = false;

		if (this.isIOSChrome) {
			this.isChrome = true;
		} else if(this.isChromium != null && this.vendorName === 'Google Inc.' && this.isOpera === false && this.isIEedge === false) {
			this.isChrome = true;
		} else { 
			this.isChrome = false;
		}

		/** @type {String} Platform/os */
		this.platform = navigator.userAgentData && navigator.userAgentData.platform ? navigator.userAgentData.platform : navigator.platform;

		/** @type {String} Browser name */
		this.browser = 'n/a';
		if (this.isOpera) { this.browser = 'Opera'; }
		if (this.isIEedge) { this.browser = 'Edge'; }
		if (this.isChrome) { this.browser = 'Chrome'; }
		if (this.isIOSChrome) { this.browser = 'Chrome IOS'; }
		if (this.isFirefox) { this.browser = 'Firefox'; }
		if (this.isSafari) { this.browser = 'Safari'; }

		/** @type {Boolean} Is this a MAC */
		this.isMac = this.platform.toUpperCase().includes('MAC');

		if (window.communicator) {
			window.communicator.browserDetect = this; // making it available globally for debugging purposes
		}
	}
}

export default new BrowserDetect();
