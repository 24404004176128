<template>
	<b-modal id="AudioPlayerModal" centered hide-header-close hide-footer>
		<template #modal-header>
			{{ header }}
		</template>
		
		<div style="height:5px"></div>

		<b-progress :value="progress" :max="length" />
		<audio id="AudioPlayerModalAudio" />
		<div style="display:flex;justify-content:space-between">
			<div style="text-align:right;font-size:var(--font-size-small);color:var(--txt-color-secondary)">{{ progress | shortduration(false) }} {{ progress | shortduration(true) }}</div>
			<div style="text-align:left;font-size:var(--font-size-small);color:var(--txt-color-secondary)">{{ length | shortduration(false) }} {{ length | shortduration(true) }}</div>
		</div>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<!-- Play button -->
			<b-button v-if="!isPlaying&&!isLoading" variant="primary" size="sm" @click="play" tabindex="110" style="width:35px;min-width:0" :disabled="isDownloading">
				<div style="height:14px"><glyph-icon icon="175-play" size="15" color="#fff" style="margin-top:-2px" /></div>
			</b-button>
			<!-- Is loading audio - play button -->
			<b-button v-if="!isPlaying&&isLoading" variant="primary" size="sm" @click="stop" tabindex="110" style="width:35px;min-width:0" :disabled="isDownloading">
				<div style="height:14px"><glyph-icon icon="546-cloud-download" size="15" color="#fff"  style="margin-top:-2px"/></div>
			</b-button>
			<!-- Stop button -->
			<b-button v-if="isPlaying&&!isLoading" variant="primary" size="sm" @click="stop" tabindex="120" style="width:35px;min-width:0" :disabled="isDownloading">
				<div style="height:14px"><glyph-icon icon="176-stop" size="15" color="#fff"  style="margin-top:-2px"/></div>
			</b-button>
			<!-- Start over button -->
			<b-button variant="primary" :disabled="!hasBeenLoaded||isDownloading" size="sm" @click="startOver" tabindex="130" style="width:35px;min-width:0">
				<div style="height:14px"><glyph-icon icon="172-skip-to-start" size="15" color="#fff"  style="margin-top:-2px"/></div>
			</b-button>
			<!-- Download button -->
			<b-button variant="primary" size="sm" @click="download" tabindex="135" :disabled="isDownloading">{{ $t('common.download') }}</b-button>
			<!-- Delete button -->
			<b-button v-if="isVoicemail" variant="danger" size="sm" @click="remove" tabindex="135" :disabled="isDownloading">{{ $t('common.delete') }}</b-button>
			<!-- Close button -->
			<b-button variant="secondary" size="sm" type="submit" @click="close" tabindex="140" :disabled="isDownloading">{{ $t('common.close') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import s from '../../settings';
import u from '../../utils/utils';
import GlyphIcon from '../GlyphIcon.vue';
import i18n from '../../utils/i18n';
import axios from 'axios';
import logger from '../../data/logger';
// import employees from '../../data/employees';

export default {
	components: {
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			header: '',
			audioId: '',
			length: 0,
			isPlaying: false,
			isLoading: false,
			hasBeenLoaded: false,
			progress: 0,
			markAsPlayed: false,
			filename: '',
			isDownloading: false,
			isVoicemail: false,
			ae: null
		};
	},
	computed: {
	},
	methods: {
		show(settings) {
			this.header = settings.header;
			this.audioId = settings.audioId;
			this.length = settings.length;
			this.filename = settings.filename;
			this.markAsPlayed = settings.markAsPlayed || false;
			this.isVoicemail = settings.isVoicemail || false;
			this.progress = 0;
			this.hasBeenLoaded = false;
			this.isPlaying = false;
			this.isLoading = false;
			this.$bvModal.show('AudioPlayerModal');
		},
		initAe() {
			if (!this.ae) { this.ae = document.getElementById('AudioPlayerModalAudio'); }
			if (!this.ae.onloadstart) {
				this.ae.onloadstart = () => {
					this.isLoading = true;
				};
				this.ae.onended = () => {
					this.isPlaying = false;
					if (this.markAsPlayed) {
						this.doMarkVoicemailAsPlayed();
					}
				};
				this.ae.onpause = () => {
					this.isPlaying = false;
				};
				this.ae.onplay = () => {
					this.isPlaying = true;
					this.isLoading = false;
					this.hasBeenLoaded = true;
				};
				this.ae.onplaying = () => {
					this.isPlaying = true;
					this.isLoading = false;
					this.hasBeenLoaded = true;
				};
			}
		},
		download() {
			if (s.isEmbedded && this.isDownloading) { return; }
			logger.info(`AudioPlayer: Downloading audio file ${this.audioId}`);
			const url = `https://audio.telecomx.dk/${this.audioId}/${this.filename}.mp3?token=${encodeURIComponent(s.token)}`;
			if (s.isEmbedded) {
				this.isDownloading = true;
				axios.get(`${s.localUrl}/download?url=${encodeURIComponent(url)}`, { timeout: 30000 })
					.then(res => {
						this.isDownloading = false;
						if (!res.data.success) {
							logger.warning('AudioPlayer: Failed to download audio file');
						}
					})
					.catch(err => {
						this.isDownloading = false;
						logger.debug(`AudioPlayer: Failed to download: ${err.message}`);
					});
			} else {
				window.location.href = url;
			}
		},
		remove() {
			if (this.isDownloading || !this.isVoicemail) { return; }
			this.close();
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('voicemail.deleteHeader'), message: i18n.t('voicemail.deleteMessage'), ok: i18n.t('common.delete'), emit: 'Voicemail:Delete', emitData: this.audioId });
		},
		close() {
			this.stop();
			this.$bvModal.hide('AudioPlayerModal');
		},
		startOver() {
			if (this.hasBeenLoaded) {
				this.ae.currentTime = 0;
				this.ae.play();
				this.startTimer();
			}
		},
		play() {
			this.initAe();
			const url = `https://audio.telecomx.dk/${this.audioId}.mp3?token=${encodeURIComponent(s.token)}`;
			if (this.ae.src == url) {
				this.ae.play();
				this.timer = setInterval(() => {
					this.progress = this.ae.currentTime;
				}, 100);
			} else {
				this.ae.src = url;
				this.ae.currentTime = 0;
				this.ae.volume = 1;
				this.ae.play();
				this.startTimer();
			}
		},
		stop() {
			if (this.ae && this.isPlaying) {
				this.ae.pause();
			}
			this.stopTimer();
		},
		startTimer() {
			this.progress = this.ae.currentTime;
			this.timer = setInterval(() => {
				this.progress = this.ae.currentTime;
				if (this.progress > 5 && this.markAsPlayed) {
					this.doMarkVoicemailAsPlayed();
				}
			}, 100);
		},
		stopTimer() {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
		},
		doMarkVoicemailAsPlayed() {
			this.markAsPlayed = false;
			s.http.get(`/pbx/voicemail/${this.audioId}/read`)
				.then(() => {
					EventBus.$emit('Voicemail:Read', this.audioId);
				})
				.catch(() => {});
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
		this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
			if (modalId == 'AudioPlayerModal') {
				this.stop();
			}
    	});
	},
	created() {

	}
};
</script>

<style scoped>
</style>
