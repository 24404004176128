<template>
	<div style="position:relative" :style="{ width: size + 'px', height: size + 'px' }">
		<img v-if="url" class="image" :width="size" :height="size" :src="url" @load="imageLoaded" alt="Medarbejder billede" />
		<div v-if="url" class="spinbox" :style="{'top':spinnerOffset+'px','left':spinnerOffset+'px'}">
			<spinner color="#135" :run="isLoading" :size="spinnerSize" shadowColor="#ffffff"></spinner>
		</div>
		<div :id="myId" v-if="!url&&initials" class="name" :style="{ width: size + 'px', height: size + 'px', fontSize: size/2.5 + 'px' }" style="display:flex;justify-content:center;align-items:center">
			<div>{{ initials }}</div>
		</div>
	</div>
</template>

<script>
import Spinner from './Spinner.vue';
import s from '../settings';

export default {
	props: {
		picture: { type: String, default: null },
		size: { type: Number, required: true },
		spinner: { type: Boolean, default: false },
		initials: { type: String, required: false }
	},
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: true,
			myId: 'employeepicture' + Math.random().toString()
		};
	},
	methods: {
		imageLoaded: function() {
			this.isLoading = false;
		}
	},
	computed: {
		url: function() {
			if (this.picture && s.token) {
				return `https://image.telecomx.dk/${this.picture}.jpg?width=300&height=300&token=${s.token}`;
			} else if (this.initials) {
				return ''; // Dummy person picture
			} else {
				return 'https://image.telecomx.dk/5cb5ce081abcc110ca68f034.png'; // Dummy person picture
			}
		},
		spinnerSize: function() {
			return (this.size / 100) * 80;
		},
		spinnerOffset: function() {
			return (this.size - ((this.size / 100) * 80)) / 2;
		}
	}
};
</script>

<style scoped>
	.image {
		background-color: var(--employee-picture-background);
		border: var(--employee-picture-border);
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.spinbox {
		position: absolute;
	}
	.name {
		background-color: var(--employee-picture-background);
		color: var(--employee-picture-color);
		border: var(--employee-picture-border);
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
		text-transform: uppercase;
		font-weight: 200;
		text-align: center;
		padding-top: 6px;
	}
	.name > DIV {
		margin-top: -6px;
	}
</style>