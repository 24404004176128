/**
 * This is a simple app-wide event bus (publish/subscribe)
 * 
 * To:
 *   use........: import { EventBus } from './data/EventBus.js';
 *   publish....: EventBus.$emit('event-name', data);
 *   subscribe..: EventBus.$on('event-name', data => { handlercode(); });
 *   unsubscribe: EventBus.$off('event-name', handler);
 */
import Vue from 'vue';
export default new Vue();
