/*
 * Holds the the logged on users employee, extension and callerId data
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2022-08-03
 */

import EventBus from './EventBus';
import s from '../settings';
import employees from './employees';
import logger from './logger';

class Me {

	constructor() {
		this.isLoading = false;
		this.employee = null;
		this.extension = null;
		this.callerIdNumbers = [];
		EventBus.$on('Employees:Loaded', this.load.bind(this));
		EventBus.$on('Realtime:Config:EMPLOYEE_UPDATED', this.employeeUpdatedEvent.bind(this));
		EventBus.$on('Realtime:Config:EMPLOYEE_DELETED', this.employeeDeletedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_EXTENSION_UPDATED', this.extensionUpdatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_EXTENSION_DELETED', this.extensionDeletedEvent.bind(this));
		logger.registerGlobal('me', this);
		this.init();
	}

	async init() {
		await s.ready();
		this.load();
	}

	async load() {
		if (this.isLoading) { return; }
		this.isLoading = true;
		logger.info('Me: Loading users data');
		try {
			await s.ready();
			this.employee = await employees.getMe();
			if (this.employee) {
				const res2 = await s.http.get('/pbx/extension/calleridnumbers');
				this.callerIdNumbers = res2.data;

				const res = await s.http.get('/pbx/extension/' + this.employee.primaryExtension._id);
				this.extension = res.data;

				if (this.employee.primaryExtension.mobile.length > 0) {
					this.employee.primaryExtension.mobile.forEach(number => { this.callerIdNumbers.unshift(number); });
				}
				if (this.employee.primaryExtension.direct) { this.callerIdNumbers.unshift(this.employee.primaryExtension.direct); }
			}
			this.isLoading = false;
			logger.info(`Me: Completed loading users data for: ${this.employee.name}`);
		}
		catch(_) { }
	}

	clear() {
		this.employee = null;
		this.extension = null;
		this.callerIdNumbers = [];
	}

	employeeUpdatedEvent(data) {
		if (data.id === s.auth.employee) {
			this.load();
		}
	}

	employeeDeletedEvent(data) {
		if (data.id === s.auth.employee) {
			this.clear();
		}
	}

	extensionUpdatedEvent(data) {
		if (data.id === this.employee.primaryExtension._id) {
			this.load();
		}
	}

	extensionDeletedEvent(data) {
		if (data.id === this.employee.primaryExtension._id) {
			this.clear();
		}
	}

}

// Singleton
export default new Me();
