/**
 * Module for show desktop notifications
 * - will invoke web notifications if running from a browser or wrapper via localhost:61337
 */

import s from '../settings';
import axios from 'axios';
import EventBus from '../data/EventBus';
import i18n from './i18n';
import logger from '../data/logger';
import browserdetect from './browserdetect';

/**
 * Notification handler
 */
class MyNotification {
	/**
	 * Show a notification
	 * @param {String} title Title
	 * @param {String} message Optionel message
	 * @param {String} icon Optional url of image to show
	 * @param {Boolean} requireInteraction True to require user to click it
	 * @param {Function} onClick Optional callback when notification is clicked
	 * @param {Function} onClose Optional callback when notification is closed
	 */
	static show(title, message, icon, requireInteraction, onClick, onClose) {
		const instance = new MyNotification(title, message, icon, requireInteraction, onClick, onClose);
		return instance;
	}

	/**
	 * Show a notification
	 * @param {String} title Title
	 * @param {String} message Optionel message
	 * @param {String} icon Optional url of image to show
	 * @param {Boolean} requireInteraction True to require user to click it
	 * @param {Function} onClick Optional callback when notification is clicked
	 * @param {Function} onClose Optional callback when notification is closed
	 */
	 constructor(title, message, icon, requireInteraction, onClick, onClose) {
		this.title = title;
		this.onClick = onClick;
		this.onClose = onClose;
		this.options = {};
		if (requireInteraction) {
			this.options.silent = true; // phone is ringing - does not need bell
		}
		if (message) { this.options.body = message; }
		if (icon) { this.options.icon = icon; }
		if (requireInteraction) { this.options.requireInteraction = requireInteraction; }
		this.options.tag = 'Notify' + Math.random().toString().substring(2);
		//console.log('Using options:', this.options);

		if (s.isEmbedded) { this.showSystemNotification(); } else { this.showWebNotification(); }
	}

	/**
	 * @private
	 */
	showWebNotification() {
		logger.debug(`MyNotification: Show web notification: ${this.title} - ${this.options.body}`);
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		let p;
		if (isSafari) {
			p = new Promise(resolve => {
				const permission = Notification.requestPermission();
				resolve(permission);
			});
		} else {
			p = Notification.requestPermission();
		}
		p
			.then(permission => {
				if (permission != 'granted') { return; }

				const notification = new Notification(this.title, this.options);
				this.notification = notification;
				const this2 = this;
				if (this.onClick) {
					notification.onclick = ev => {
						this2.notification.close();
						this2.onClose = null;
						this2.onClick();
					};
				}
				if (this.onClose) {
					notification.onclose = ev => {
						this2.onClose();
					};
				}
			})
			.catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('mynotification.errorHeader'), message: i18n.t('mynotification.errorMessage') + '<br><br>' + err.message });
			});
	}

	/**
	 * @private
	 */
	showSystemNotification() {
		if (s.isEmbedded && browserdetect.isMac) {
			this.showWebNotification();
			return;
		}
		logger.debug(`MyNotification: Show native notification: ${this.title} - ${this.options.body}`);
		let args = 'title=' + encodeURIComponent(this.title) + '&tag=' + encodeURIComponent(this.options.tag);
		if (this.options.body) { args += '&body=' + encodeURIComponent(this.options.body); }
		if (this.options.requireInteraction) { args += '&requireInteraction=true'; } else { args += '&requireInteraction=false'; }
		if (this.options.icon) { args += '&icon=' + encodeURIComponent(this.options.icon); }
		axios.get(`${s.localUrl}/notification/show?${args}`, { timeout: 60000 })
			.then(res => {
				if (this.onClick && res.data.onClick == true) {
					this.onClick();
				} else if (this.onClose && res.data.onClose == true) {
					this.onClose();
				}
			})
			.catch(err => {
				// if (s.emailAddress.includes('@powernet.dk') || s.emailAddress.includes('@telecomx.dk')) {
				// 	EventBus.$emit('CommonErrorModal', { header: 'Notifikation', message: 'Visning af system notifikation mislykedes<br><br>' + (err.message ? err.message : JSON.stringify(err.data)) });
				// }
			});
	}

	/**
	 * Close the notification - if it is still showing
	 */
	close() {
		if (s.isEmbedded) {
			if (browserdetect.isMac) {
				this.closeWebNotification();
			} else {
				this.closeSystemNotification();
			}
		} else {
			this.closeWebNotification();
		}
	}

	/**
	 * @private
	 */
	closeSystemNotification() {
		axios.get(`${s.localUrl}/notification/close?tag=${encodeURIComponent(this.options.tag)}`, { timeout: 5000 })
			.catch(() => { });
	}

	/**
	 * @private
	 */
	closeWebNotification() {
		if (this.notification) { this.notification.close(); }
		delete this.notification;
	}
}

export default MyNotification;