/*
 * Dashboard Employees (used by renderer)
 * 
 * - Holds the current set of employees, including settings, calls etc.
 * - Handles everything about employees from the dashboard
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2020-06-15
 */

import u from '../utils/utils';
import Vue from 'vue';
import Employee from './employee';
import EventBus from './EventBus';
import s from '../settings';
import rt from '../data/realtime';
import i18n from '../utils/i18n';
import logger from './logger';

class Employees {
	// #region Init, load, setup

	constructor() {
		this._ready = false;
		this.employees = [];
		this.permissions = {};
		this.lastMidnightCheck = new Date();
		this.setupListeners();

		if (rt.connected) { // otherwise it is invoked on Realtime:Connected
			this.init();
		}

		logger.registerGlobal('employees', this);
	}

	async init() {
		try {
			logger.info('Employees: Loading');
			this._ready = false;
			await s.ready();
			await this.loadEmployees();
			await this.loadPermissions();
			this._ready = true;
			EventBus.$emit('Employees:Loaded');
			logger.info(`Employees: Loaded ${this.employees.length} employees`);
		}
		catch(err) {
			// Dont care because realtime will ensure we see an error message if data cannot be fetched
			//EventBus.$emit('CommonErrorModal', { header: 'Indlæsning af medarbejdere', message: 'Der opstod en uventet fejl under indlæsning af medarbejdere. Serveren siger: ' + err.message });
		}
	}

	async setupListeners() {
		EventBus.$on('Realtime:Config:EMPLOYEE_CREATED', this.createdEvent.bind(this));
		EventBus.$on('Realtime:Config:EMPLOYEE_UPDATED', this.updatedEvent.bind(this));
		EventBus.$on('Realtime:Config:EMPLOYEE_DELETED', this.deletedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_EXTENSION_CREATED', this.extensionCreatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_EXTENSION_UPDATED', this.extensionUpdatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_EXTENSION_DELETED', this.extensionDeletedEvent.bind(this));
		EventBus.$on('Realtime:Config:PBX_GROUP_CREATED', this.loadPermissions.bind(this));
		EventBus.$on('Realtime:Config:PBX_GROUP_UPDATED', this.loadPermissions.bind(this));
		EventBus.$on('Realtime:Config:PBX_GROUP_DELETED', this.loadPermissions.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_START', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_RING', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_ANSWER', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_END', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_UPDATE', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_HOLD', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_UNHOLD', this.callEvent.bind(this));
		EventBus.$on('Realtime:Pbx:EMPLOYEE_PRESENCE_CHANGED', this.updatedEvent.bind(this));
		EventBus.$on('Realtime:Config:PresenceUpdated', this.presenceUpdatedEvent.bind(this));
		EventBus.$on('Realtime:Connected', this.init.bind(this));
		EventBus.$on('Auth:LoggedOut', () => {
			this.employees = [];
			this.permissions = {};
			this._ready = false;
		});
		EventBus.$on('OneMinute', this.midnightCheck.bind(this));

		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'employees', value: this._ready });
		});
	}

	midnightCheck() {
		const now = new Date();
		if (now.getDate() != this.lastMidnightCheck.getDate()) {
			this.lastMidnightCheck = now;
			logger.info('Employees: Performing midnight refresh');
			this.init(); // will force all employees shown to reload their data and thus update things like expiring texts
		}
	}

	async loadPermissions() {
		const res = await s.http.get('/pbx/app/permissions');
		this.permissions = res.data;
		this.employees.forEach(employee => {
			employee.extensions.forEach(ext => {
				ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
			});
		});
	}

	async loadEmployees() {
		this.employees = [];
		const res = await s.http.get('/pbx/app/employees?calls=true&standalone=true');
		res.data.forEach(rawEmployee => {
			this.employees.push(new Employee(rawEmployee, this.permissions));
		});
	}

	// #endregion

	// #region Event handling

	createdEvent(d) {
		const { event, id, data } = d;
		s.http.get(`/pbx/app/employees?ids=${id}&calls=true`)
			.then(res => {
				if (res && res.data && res.data.length == 1) { // If not then employee/extension is not visible to Communicator apps
					const employee = this.employees.find(o => o._id == res.data[0]._id);
					if (employee) {
						const extensions = res.data[0].extensions;
						extensions.forEach(ext => {
							if (!ext.calls) { ext.calls = []; }
							ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
							this.fixCallsDates(ext.calls);
						});
						delete res.data[0].extensions;
						u.extendObject(employee, res.data[0]);
						extensions.forEach((ext, index) => {
							Vue.set(employee.extensions, index, ext);
						});
					} else {
						this.employees.push(new Employee(res.data[0], this.permissions));
					}
				}
			}).catch(err => {
				if (err.status != 0 && err.code != 'network') {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('employees.errorHeader'), message: i18n.t('employees.errorLoadingNewEmployeeMessage') + ' ' + err.message });
				}
			});
	}

	updatedEvent(d) {
		let { event, id, data, _id } = d;
		if (!id && _id) { id = _id; } // For EMPLOYEE_PRESENCE_CHANGED events
		s.http.get(`/pbx/app/employees?ids=${id}&calls=true`)
			.then(res => {
				if (!res || res.data.length == 0) {
					// Employee/extension no longer visible to communicator apps
					const index = this.employees.findIndex(o => o._id == id);
					if (index != -1) {
						this.employees.splice(index, 1);
					}
				} else {
					const employee = this.employees.find(o => o._id == res.data[0]._id);
					if (employee) {
						const extensions = res.data[0].extensions;
						extensions.forEach(ext => {
							if (!ext.calls) { ext.calls = []; }
							ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
							this.fixCallsDates(ext.calls);
						});
						delete res.data[0].extensions;
						u.extendObject(employee, res.data[0]);
						if (employee.birthdate && !(employee.birthdate instanceof Date)) {
							employee.birthdate = new Date(employee.birthdate);
						}
						extensions.forEach((ext, index) => {
							Vue.set(employee.extensions, index, ext);
						});
					} else {
						this.employees.push(new Employee(res.data[0], this.permissions));
					}
				}
			}).catch(err => {
				if (err.status != 0 && err.code != 'network') {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('employees.errorHeader'), message: i18n.t('employees.errorLoadingUpdatedEmployeeMessage') + ' ' + err.message });
				}
			});
	}

	presenceUpdatedEvent(d) {
		const { event, _, data } = d;
		const id = data._id;
		const employee = this.employees.find(o => o._id == id);
		if (employee) {
			Object.keys(data.data).forEach(key => {
				let value = data.data[key];
				if (key == 'expire' && value) { value = new Date(value); }
				Vue.set(employee.presence, key, value);
			});
		}
	}

	deletedEvent(d) {
		const { event, id, data } = d;
		const index = this.employees.findIndex(o => o._id == id);
		if (index != -1) {
			this.employees.splice(index, 1);
		}
	}

	async extensionCreatedEvent(d) {
		const { event, id, data } = d;
		try {
			const res = await s.http.get(`/pbx/app/employees?ids=${data.employeeId ? data.employeeId : id }&calls=true&standalone=true`);
			if (!res.data || res.data.length == 0) {
				// Employee/extension no longer visible to communicator apps - remove it
				const index = this.employees.findIndex(o => o._id == id);
				if (index != -1) {
					this.employees.splice(index, 1);
				}
			} else {
				const employee = this.employees.find(o => o._id == res.data[0]._id);
				if (employee && employee.type === 'EMPLOYEE') {
					// Extension created, attached to an employee
					res.data[0].extensions.forEach((ext, index) => {
						if (!ext.calls) { ext.calls = []; }
						ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
						this.fixCallsDates(ext.calls);
						Vue.set(employee.extensions, index, ext);
					});
					while (employee.extensions.length > res.data[0].extensions.length) {
						employee.extensions.pop();
					}
				} else if (employee && employee.type === 'EXTENSION') {
					// Extension created, but we already have it - so update (should never happen)
					const ext = res.data[0];
					if (!ext.calls) { ext.calls = []; }
					ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
					this.fixCallsDates(ext.calls);
					Vue.set(employee.extensions, 0, ext);
					employee.name = res.data[0].name;
				} else if (!employee) {
					// We dont have it - so regardless of it is an employee or an extension we can just create it
					this.employees.push(new Employee(res.data[0], this.permissions));
				}
			}
		}
		catch(err) {
			if (err.status != 0 && err.code != 'network') {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('employees.errorHeader'), message: i18n.t('employees.errorLoadingUpdatedEmployeeMessage') + ' ' + err.message });
			}
		}
	}

	async extensionUpdatedEvent(d) {
		const { event, id, data } = d;
		try {
			const res = await s.http.get(`/pbx/app/employees?ids=${data.employeeId ? data.employeeId : id }&calls=true&standalone=true`);
			if (!res.data || res.data.length == 0) {
				// Employee/extension no longer visible to communicator apps - remove it
				const index = this.employees.findIndex(o => o._id == id);
				if (index != -1) {
					this.employees.splice(index, 1);
				}
			} else {
				const employee = this.employees.find(o => o._id == res.data[0]._id);
				if (employee && employee.type === 'EMPLOYEE') {
					// Extension updated, update all extensions on employee
					res.data[0].extensions.forEach((ext, index) => {
						if (!ext.calls) { ext.calls = []; }
						ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
						this.fixCallsDates(ext.calls);
						Vue.set(employee.extensions, index, ext);
					});
					while (employee.extensions.length > res.data[0].extensions.length) {
						employee.extensions.pop();
					}
				} else if (employee && employee.type === 'EXTENSION') {
					// Extension updated
					const ext = res.data[0];
					if (!ext.calls) { ext.calls = []; }
					ext.permissions = this.permissions[ext._id] || { pickup: false, steal: false, spy: false, setPresence: false, queueMember: false, viewOtherParty: false };
					this.fixCallsDates(ext.calls);
					Vue.set(employee.extensions, 0, ext);
					employee.name = res.data[0].name;
				} else if (!employee) {
					// We dont have it - so regardless of it is an employee or an extension we can just create it
					this.employees.push(new Employee(res.data[0], this.permissions));
				}
			}
		}
		catch(err) {
			if (err.status != 0 && err.code != 'network') {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('employees.errorHeader'), message: i18n.t('employees.errorLoadingUpdatedEmployeeMessage') + ' ' + err.message });
			}
		}
	}

	async extensionDeletedEvent(d) {
		const { event, id, data } = d;
		const employee = this.employees.find(o => o._id == data.employeeId ? data.employeeId : id);
		if (employee && employee._id == id) {
			// It is a standalone extension, so we will remove the entire thing
			const index = this.employees.findIndex(o => o._id == data.employeeId ? data.employeeId : id);
			this.employees.splice(index, 1);
		} else if (employee) {
			const index = employee.extensions.findIndex(o => o._id == id);
			if (index != -1) {
				employee.extensions.splice(index, 1);
			}
		}
	}

	callEvent(e) {
		let call = this.parseCall(e);
		this.fixCallerCalleeNames(call);
		const employee = this.findEmployeeWithExtension(call.extension);
		if (employee) {
			const ext = employee.extensions.find(o => o._id == call.extension);
			if (ext) {
				if (!ext.calls) { ext.calls = []; }
				if (call.state == 'CALL_END') {
					const extIndex = ext.calls.findIndex(o => o._id == call._id);
					if (extIndex != -1) {
						ext.calls.splice(extIndex, 1);
					}
				} else if (call.state == 'CALL_UPDATE') {
					const extCall = ext.calls.find(o => o._id == call._id);
					if (extCall) {
						extCall.caller.number = call.caller.number;
						extCall.caller.name = call.caller.name;
						extCall.caller.privacy = call.caller.privacy;
						extCall.callee.number = call.callee.number;
						extCall.callee.name = call.callee.name;
					}
				} else { // CALL_START, CALL_RING, CALL_ANSWER, CALL_UPDATE, CALL_HOLD, CALL_UNHOLD
					// if (call.state == 'CALL_HOLD') {
					// 	console.log('Got a call hold');
					// }
					// if (call.state == 'CALL_UNHOLD') {
					// 	console.log('Got a call unhold');
					// }
					const extCallIndex = ext.calls.findIndex(o => o._id == call._id);
					if (extCallIndex != -1) {
						Object.keys(call).forEach(key => {
							ext.calls[extCallIndex][key] = call[key];
						});
						if (call.state == 'CALL_UNHOLD') {
							ext.calls[extCallIndex].hold = null;
							ext.calls[extCallIndex].state = 'CALL_ANSWER';
						}
						// Vue.set(ext.calls, extCallIndex, call);
					} else {
						ext.calls.push(call);
					}

					if (call.state == 'CALL_UNHOLD') {
						let otherLegsCall = this.findOtherLegsCall(call);
						if (otherLegsCall) {
							otherLegsCall.hold = null;
							otherLegsCall.state = 'CALL_ANSWER';
							otherLegsCall.holdOwner = false;
						}
					}
					if (call.state == 'CALL_HOLD') {
						let otherLegsCall = this.findOtherLegsCall(call);
						if (otherLegsCall) {
							otherLegsCall.hold = call.hold;
							otherLegsCall.state = call.state;
							otherLegsCall.holdOwner = true;
						}
					}
				}
				this.setExtensionState(ext);
			}
		} else {
			if (call.state == 'CALL_UNHOLD') {
				let otherLegsCall = this.findOtherLegsCall(call);
				if (otherLegsCall) {
					otherLegsCall.hold = null;
					otherLegsCall.state = 'CALL_ANSWER';
					otherLegsCall.holdOwner = false;
				}
			}
			if (call.state == 'CALL_HOLD') {
				let otherLegsCall = this.findOtherLegsCall(call);
				if (otherLegsCall) {
					otherLegsCall.hold = call.hold;
					otherLegsCall.state = call.state;
					otherLegsCall.holdOwner = true;
				}
			}

		}
	}

	parseCall(e) {
		const event = e.event;
		const id = e.eventId;
		const data = e;
		const call = {
			_id: id,
			customer: data.customer,
			extension: data.extension,
			state: event,
			caller: {
				number: data.caller,
				name: data.callerName,
				privacy: data.callerPrivacy || false
			},
			callee: {
				number: data.callee,
				name: data.calleeName
			},
			sessionId: data.sessionId,
			channel: data.channel,
			callId: data.callId,
			direction: data.direction,
			leg: data.leg,
			originator: data.originator,
			device: data.device,
			pbx: data.eventId.split(':')[0],
			callerChannelId: data.callerChannelId,
			hideOtherParty: data.hideOtherParty
		};
		if (data.queueId) {
			call.caller.queueId = data.queueId;
			call.caller.queueName = data.queueName;
		}
		if (event == 'CALL_START') { call.started = new Date(); }
		if (event == 'CALL_RING') { call.ringing = new Date(); }
		if (event == 'CALL_ANSWER') { call.answered = new Date(); }
		if (event == 'CALL_END') { call.ended = new Date(); }
		if (event == 'CALL_HOLD') { call.hold = new Date(); }
		return call;
	}

	fixCallsDates(calls) {
		calls.forEach(call => { this.fixCallDates(call); });
	}

	fixCallDates(call) {
		['started','ringing','answered','ended','hold'].forEach(key => {
			if (typeof call[key] == 'string') { call[key] = new Date(call[key]); }
		});
	}

	fixCallerCalleeNames(call) {
		if (call.callee.number.startsWith('*') || call.callee.number.startsWith('#')) {
			const data = this.lookupStarCode(call.callee.number);
			if (data) {
				call.callee.number = data.number;
				call.callee.name = data.name;
			}
		}
		if (!call.caller.name) {
			if (call.caller.number.startsWith('*') || call.caller.number.startsWith('#') || call.caller.number == 'VOICEMAIL' || call.caller.number == 'DND' || call.caller.number.startsWith('SWITCH-')) {
				const data = this.lookupStarCode(call.caller.number);
				if (data) {
					call.caller.number = data.number;
					call.caller.name = data.name;
				}
			}
			if (!call.caller.name) {
				const name = this.findNameFromNumber(call.caller.number);
				if (name) { call.caller.name = name; }
			}
		}
		if (!call.callee.name) {
			if (call.callee.number.startsWith('*') || call.callee.number.startsWith('#') || call.caller.number == 'VOICEMAIL' || call.caller.number == 'DND' || call.caller.number.startsWith('SWITCH-')) {
				const data = this.lookupStarCode(call.callee.number);
				if (data) {
					call.callee.number = data.number;
					call.callee.name = data.name;
				}
			}
			if (!call.callee.name) {
				const name = this.findNameFromNumber(call.callee.number);
				if (name) { call.callee.name = name; }
			}
		}
	}

	lookupStarCode(number) {
		if (number == 'VOICEMAIL') { return { number: '', name: i18n.t('employees.voicemail') }; }
		if (number == 'DND') { return { number: '', name: i18n.t('employees.dnd') }; }
		if (number.startsWith('SWITCH-')) { return { number: '', name: i18n.t('employees.setSwitch') + ' ' + number.substr(7) }; }
		if (number == '*') { return { number: '*', name: i18n.t('employees.mainMenu') }; }
		if (number.startsWith('*1')) { return { number: number, name: i18n.t('employees.voicemail') }; }
		if (number.startsWith('*2')) { return { number: number, name: i18n.t('employees.transfer') }; }
		if (number.startsWith('*3')) { return { number: number, name: i18n.t('employees.greeting') }; }
		if (number.startsWith('*4')) { return { number: number, name: i18n.t('employees.recordings') }; }
		if (number == '*5') { return { number: number, name: i18n.t('employees.queueMenu') }; }
		if (number.match(/^\*5\*\d+$/)) { return { number: number, name: i18n.t('employees.queueJoin') + ' ' + number.substr(3) }; }
		if (number.match(/^\*5\*\d+\*\d+$/)) { return { number: number, name: i18n.t('employees.queueJoin') + ' ' + number.substr(3) }; }
		if (number.match(/^\*51\*\d+$/)) { return { number: number, name: i18n.t('employees.queueIsMember') + ' ' + number.substr(4) }; }
		if (number == '*53') { return { number: number, name: i18n.t('employees.queueMemberList') }; }
		if (number == '#5' || number == '*55') { return { number: number, name: i18n.t('employees.queueLeaveAll') }; }
		if (number.startsWith('#5#')) { return { number: number, name: i18n.t('employees.queueLeave') + ' ' + number.substr(3) }; }
		if (number.startsWith('*6') || number.startsWith('#6')) { return { number: number, name: i18n.t('employees.blackWiteList') }; }
		if (number == '*7') { return { number: number, name: i18n.t('employees.pickupMenu') }; }
		if (number == '*71') { return { number: number, name: i18n.t('employees.pickupRinging') }; }
		if (number.startsWith('*71*')) { return { number: number, name: i18n.t('employees.pickupRingingFrom') + ' ' + number.substr(4) }; }
		if (number.startsWith('*72*')) { return { number: number, name: i18n.t('employees.stealCallFrom') + ' ' + number.substr(4) }; }
		if (number.startsWith('*73*')) { return { number: number, name: i18n.t('employees.spyOn') + ' ' + number.substr(4) }; }
		if (number.startsWith('*9') || number.startsWith('#9')) { return { number: number, name: i18n.t('employees.setPresence') }; }
		if (number.startsWith('*0') || number.startsWith('#0')) { return { number: number, name: i18n.t('employees.hotdesk') }; }
		if (number == '***') { return { number: number, name: i18n.t('employees.moveCall') }; }
		return null;
	}

	findNameFromNumber(number) {
		let name;
		const numberWithoutPrefix = s.cutNationalPrefix(number);
		this.employees.forEach(emp => {
			emp.extensions.forEach(ext => {
				if (ext.number == numberWithoutPrefix || ext.direct == number || (ext.mobile && ext.mobile.includes(number))) {
					name = emp.name;
				}
			});
		});
		return name;
	}

	findEmployeeWithExtension(id) {
		return this.employees.find(emp => {
			return emp.extensions.find(ext => ext._id == id);
		});
	}

	setExtensionState(ext) { // IDLE, CALLING, RINGING, BUSY
		if (ext.calls.length == 0) {
			ext.state = 'IDLE';
		} else if (ext.calls.length == 1) { // One ongoing call
			const call = ext.calls[0];
			switch (call.state) {
				case 'CALL_START': ext.state = call.leg == 'I' ? 'CALLING' : 'IDLE'; break; // outbound call or inbound call (want for ringing before changing state)
				case 'CALL_RING': ext.state = 'RINGING'; break; // inbound call, ringing (because outbound leg goes START->ANSWER->END)
				case 'CALL_ANSWER': ext.state = 'BUSY'; break;
			}
		} else { // Multiple ongoing calls
			if (ext.calls.find(call => call.state == 'CALL_ANSWER') != null) { // if any call is answered, then we are busy
				ext.state = 'BUSY';
			} else if (ext.calls.find(call => call.state == 'CALL_RING') != null) { // else if any call is ringing - then we re ringing
				ext.state = 'RINGING';
			} else { // otherwise its to early and we are still shown as IDLE
				ext.state = 'IDLE';
			}
		}
	}

	findOtherLegsCall(call) {
		let other;
		this.employees.forEach(employee => {
			const c = employee.getCallfromSessionId(call.sessionId);
			if (c && c._id != call._id) {
				other = c;
			}
		});
		return other;
	}


	// #endregion

	// #region Public function

	/**
	 * Returns when employees are loaded and we are ready to serve data
	 * @returns {Promise}
	 */
	async ready() {
		if (this._ready) {
			return Promise.resolve();
		}
		async function resolveIn250() {
			return new Promise(r => { setTimeout(r, 250); });
		}

		await resolveIn250();
		return await this.ready();
	}

	/**
	 * Get all employees
	 * @returns {Promise<Array[Object]>}
	 */
	async getAll() {
		await this.ready();
		return this.employees;
	}

	/**
	 * Get a single employee
	 * @param {String} id Id of employee to lookup
	 * @returns {Promise<Object>} Employee
	 */
	async getEmployee(id) {
		await this.ready();
		return this.employees.find(o => o._id == id);
	}

	/**
	 * Get employee for the user self
	 * @returns {Promise<Object>} Employee
	 */
	async getMe() {
		await this.ready();
		return this.employees.find(o => o._id == s.auth.employee);
	}

	/**
	 * Get a single employee who has a given extension number
	 * @param {String} extensionNumber Extension number to lookup
	 * @returns {Promise<Object>} Employee
	 */
	async getEmployeeByExtensionNumber(extensionNumber) {
		await this.ready();
		return this.employees.find(o => {
			return o.extensions.find(extension => extension.number == extensionNumber) != null;
		});
	}

	/**
	 * Get a single employee who has a given extension id
	 * @param {String} extension Extension id
	 * @returns {Promise<Object>} Employee
	 */
	async getEmployeeByExtensionId(extension) {
		await this.ready();
		return this.employees.find(o => {
			return o.extensions.find(ext => ext._id == extension) != null;
		});
	}

	/**
	 * Do you have permission to perform a given action on the specified employee
	 * @param {String} employeeId Id of employee to perform action on
	 * @param {String} type Type of action: pickup, steal, spy, setPresence, queueMember or viewOtherParty
	 * @returns {Promise<Boolean>}
	 */
	async hasPermission(employeeId, type) {
		await this.ready();
		if (this.permissions[employeeId]) { return this.permissions[employeeId][type] || false; }
		return false;
	}

	/**
	 * Returns the complete list of my ongoing calls
	 */
	async getMyCalls() {
		await this.ready();
		const calls = [];
		const me = this.employees.find(o => o._id == s.auth.employee);
		me.extensions.forEach(ext => {
			ext.calls.forEach(call => {
				calls.push(call);
			});
		});
		calls.sort(u.dynamicSort('started'));
		return calls;
	}

	// #endregion
}



// Singleton
export default new Employees();
