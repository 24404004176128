// Presence Icon
// - Properties:
//   - state: AVAILABLE/BUSY/OUT_OF_OFFICE/OFF_WORK/HOLIDAY
//   - size: Size of icon in pixels

<template>
	<div class="icon">
		<svg :class="iconStyle" style="border:1px solid #aaaaaae8;vertical-align:top" :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 38 38" v-html="content">
		</svg>
	</div>
</template>

<script>
export default {
	props: {
		state: {
			type: String,
			default: 'AVAILABLE',
			required: true
		},
		size: {
			type: Number,
			default: 32,
			required: true
		}
	},
	computed: {
		content: function() {
			switch (this.state) {
				case 'AVAILABLE':
					return '<path id="check" d="M27.37592,9.70459l-14.151,15.97693a.99985.99985,0,0,1-1.47558.02356L4.59711,18.1322a.99992.99992,0,0,1-.05384-1.31128L5.495,15.63123a.99994.99994,0,0,1,1.22808-.26966L12,18,24.79724,7.09863a.99991.99991,0,0,1,1.35553.0542l1.1817,1.18164A1,1,0,0,1,27.37592,9.70459Z"/>';
				case 'BUSY':
					return '<path id="minus" d="M26,14v4a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V14a1,1,0,0,1,1-1H25A1,1,0,0,1,26,14Z"/>';
				case 'OUT_OF_OFFICE':
					return '<path id="car" d="M27.16541,14.38342,25.67456,7.9231A3.96238,3.96238,0,0,0,22,5H10A3.96238,3.96238,0,0,0,6.32544,7.9231L4.83459,14.38342A1.99637,1.99637,0,0,0,4,16V27a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V24H24v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V16A1.99637,1.99637,0,0,0,27.16541,14.38342ZM8.27393,8.373A1.96715,1.96715,0,0,1,10,7H22a1.96715,1.96715,0,0,1,1.72607,1.373L24.79358,13H7.20642ZM10,17.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm16,0a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Z"/>';
				case 'OFF_WORK':
					return 'Off work<path id="glass" d="M17,17v8l4.64368,1.39307a.5.5,0,0,1,.35632.479V27.5a.5.5,0,0,1-.5.5H9.5a.5.5,0,0,1-.5-.5v-.62793a.5.5,0,0,1,.35632-.479L14,25V17L4.61914,4.80493A.5.5,0,0,1,5.01544,4H25.98456a.5.5,0,0,1,.3963.80493Z"/>';
				case 'HOLIDAY':
					return '<path id="beach-umbrella" d="M15,18h2v9a1,1,0,0,1-2,0Zm13-1a4.81241,4.81241,0,0,0-3-1,4.9816,4.9816,0,0,0-4.03375,2.06836,6.99623,6.99623,0,0,0-9.93244,0A4.98039,4.98039,0,0,0,4,17,11.99443,11.99443,0,0,1,15,5.05054V4a1,1,0,0,1,2,0V5.05054A11.99443,11.99443,0,0,1,28,17ZM15.32629,7.02783a10.02018,10.02018,0,0,0-8.87365,6.99365Q6.7251,14.00024,7,14a6.95441,6.95441,0,0,1,3.21143.79346A11.9987,11.9987,0,0,1,15.32629,7.02783ZM25,14q.27466,0,.54736.02148a10.02018,10.02018,0,0,0-8.87365-6.99365,11.9987,11.9987,0,0,1,5.11486,7.76563A6.95441,6.95441,0,0,1,25,14Z"/>';
				default:
					return '';
			}
		},
		iconStyle: function() {
			return this.state.toLowerCase();
		}
	}
};
</script>

<style scoped>
	.icon          { border-radius: 50%; }
	.available     { background-color: var(--presence-available); fill: var(--presence-color); border-radius: 50%; }
	.busy          { background-color: var(--presence-busy); fill: var(--presence-color); border-radius: 50%; }
	.out_of_office { background-color: var(--presence-out-of-office); fill: var(--presence-color); border-radius: 50%; }
	.off_work      { background-color: var(--presence-off-work); fill: var(--presence-color); border-radius: 50%; }
	.holiday       { background-color: var(--presence-holiday); fill: var(--presence-color); border-radius: 50%; }
</style>