<template>
	<div>
		<div class="phonebook-container">
			<div class="phonebook-listbox">
				<div class="dash-header">
					<glyph-icon icon="89-book-address" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div style="margin-left:8px">{{ $t('phonebook.header') }} ({{ list.length }}<span v-if="filter"> {{ $t('phonebook.of') }} {{ phonebook.contacts.length }}</span>)</div>
					<b-btn size="sm" variant="none" class="dash-collapse-btn" @click="add()" style="margin-right:-4px;margin-top:-1px">
						<glyph-icon icon="191-circle-empty-plus" color="#fff" size="18"></glyph-icon>
					</b-btn>
				</div>

				<div style="display:flex">
					<b-form-input type="search" v-model="filter" :placeholder="$t('common.filter')" size="sm" style="border-radius:0;border-left:none;border-right:none;border-top:none;" debounce="100" />
					<b-form-select v-model="type" style="width:110px;border-radius:0" :options="contactTypes" @change="loadList" size="sm" />
				</div>
				<div v-if="filter&&list.length==0" style="text-align:center;margin:10px;background:var(--pn-primary-1);color:#fff;padding:4px;border-radius:10px;font-weight:300;font-size:16px">{{ $t('common.noneFound') }}</div>
				<div class="phonebook-list">
					<RecycleScroller class="my-scroller" :items="list" :item-size="44" key-field="_id" v-slot="{ item }">
						<list-item :item="item" :click="select" />
					</RecycleScroller>
				</div>
			</div>

			<div class="phonebook-editbox">
				<div class="dash-header" v-if="selected">
					<glyph-icon icon="89-book-address" color="#fff" size="18" style="margin-top:-2px"></glyph-icon>
					<div class="dotdotdot" style="margin-left:8px">{{ selected._id ? selected.name : $t('phonebook.newContact') }}</div>
				</div>

				<edit-item :item="selected" v-if="selected"	/>

			</div>
		</div>
	</div>
</template>

<script>
import ListItem from './ListItem.vue';
import EditItem from './EditItem.vue';
import GlyphIcon from '../GlyphIcon.vue';
import phonebook from '../../data/phonebook';
import s from '../../settings';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import i18n from '../../utils/i18n';

export default {
	components: {
		ListItem,
		EditItem,
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			list: [],
			selected: null,
			filter: '',
			type: 'ALL',
			phonebook: phonebook,
			contactTypes: [
				{ value: 'ALL', text: i18n.t('phonebook.types.ALL') },
				{ value: 'SHARED', text: i18n.t('phonebook.types.SHARED') },
				{ value: 'PERSONAL', text: i18n.t('phonebook.types.PERSONAL') }
			]
		};
	},
	methods: {
		async init() {
			await phonebook.ready();
			this.list = phonebook.contacts;
		},
		async select(item) {
			if (this.selected) {
				this.selected = null;
				await s.sleep(1);
				this.selected = u.extendObject({}, item);
			} else {
				this.selected = u.extendObject({}, item);
			}
			
		},
		async add() {
			if (this.selected) {
				this.selected = null;
				await s.sleep(10);
			}
			this.selected = {
				employee: null,
				type: 'COMPANY',
				givenName: '',
				familyName: '',
				company: '',
				title: '',
				department: '',
				addresses: [],
				phoneNumbers: [],
				emailAddresses: [],
				notes: '',
				picture: null,
				externalId: ''
			};
		},
		loadList() {
			this.list = phonebook.search(this.filter, this.type);
		}
	},
	watch: {
		'filter': function() { this.loadList(); }
	},
	created() {
		this.init();
		EventBus.$on('Phonebook:Delete', id => {
			phonebook.remove(id).then(() => {
				this.selected = null;
			});
		});
		EventBus.$on('Phonebook:Created', id => { this.loadList(); });
		EventBus.$on('Phonebook:Updated', id => { this.loadList(); });
		EventBus.$on('Phonebook:Deleted', id => { this.loadList(); });
		EventBus.$on('Phonebook:Reloaded', () => { this.loadList(); });
	},
	mounted() {
	}
};
</script>

<style scoped>
	.phonebook-container {
		display: grid;
		grid-template-columns: 400px 1fr;
		margin: 10px 10px 10px 10px;
		height: calc(100vh - 95px);
	}
	@media (max-width: 769px) {
		.phonebook-container {
			height: calc(100vh - 190px);
		}
	}
	.phonebook-listbox {
		background: var(--bg-color-secondary);
		border-radius: 5px;
		height: 100%;
		overflow: hidden;
	}
	.phonebook-list {
		height: calc(100% - 55px); 
		/* overflow-y: scroll; */
	}
	.phonebook-editbox {
		margin-left: 10px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		overflow: hidden;
		max-width: 1200px;
		min-width: 600px;
	}
	.my-scroller {
		height: 100%;
	}

</style>