import { render, staticRenderFns } from "./CallDraggable.vue?vue&type=template&id=677de906&scoped=true&"
import script from "./CallDraggable.vue?vue&type=script&lang=js&"
export * from "./CallDraggable.vue?vue&type=script&lang=js&"
import style0 from "./CallDraggable.vue?vue&type=style&index=0&id=677de906&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "677de906",
  null
  
)

export default component.exports