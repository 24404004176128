<template>
	<div class="voicemail-list-item" @click="playVoicemail(item)" :class="{'odd':index % 2 === 0}" @contextmenu="showContextMenu">
		<div>
			<glyph-icon :icon="icon" :color="iconColor" size="32" style="margin-top:5px" />
		</div>
		<div style="display:flex;justify-content:center;flex-direction:column">
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="!item.aNumber">{{ $t('voicemail.hiddenNumber') }}</div>
			<div style="font-weight:400;font-size:var(--font-size-primary)" v-if="item.aNumber">{{ item.aName ? item.aName : item.aNumber | cutNationalPrefixAndFormat }}</div>
			<div style="font-size: var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-5px" v-if="item.aNumber&&item.aName">{{ item.aNumber | cutNationalPrefixAndFormat }}</div>
		</div>
		<div style="display:flex;justify-content:center;flex-direction:column;text-align:right">
			<div style="font-size:var(--font-size-secondary)">{{ item.date | formatDate('w d. MMM yyyy') }} {{ $t('common.at') }} {{ item.date | formatDate('HH:mm') }}</div>
			<div style="font-size: var(--font-size-secondary);color:var(--label-otherparty-color);margin-top:-3px">{{ item.length | shortduration(false) }} {{ item.length | shortduration(true) }}</div>
		</div>
	</div>
</template>

<script>
import GlyphIcon from '../GlyphIcon.vue';
import s from '../../settings';
import EventBus from '../../data/EventBus';
import sipClient from '../../data/sipClient';
import i18n from '../../utils/i18n';

export default {
	components: {
		GlyphIcon
	},
	props: {
		item: { type: Object, required: true },
		index: { type: Number},
		click: { type: Function },
		me: { type: Object },
		contextMenu: { type: Function },
		playVoicemail: { type: Function }
	},
	data() {
		return {
			$t: i18n.t
		};
	},
	methods: {
		call() {
			if (!s.myPhoneId) { return EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') }); }
			if (s.myPhoneId == 'SOFTPHONE') {
				s.setSelectedSection('DASHBOARD');
				sipClient.call(this.item.aNumber);
			} else {
				s.http.get(`/pbx/app/action/call?device=${s.myPhoneId}&target=${encodeURIComponent(s.cutNationalPrefix(this.item.aNumber))}`).catch(err => {
					EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.callFailedHeader'), message: i18n.t('common.error.callFailedMessage') });
				});
			}
		},
		showContextMenu(e) {
			this.contextMenu(e, this.item);
		}
	},
	computed: {
		icon: function() {
			return this.item.old ? '739-check' : '49-star';
		},
		iconColor: function() {
			return this.item.old ? 'var(--txt-color-primary)' : 'var(--cdr-success)';
		}
	}
};
</script>

<style scoped>
	.voicemail-list-item {
		display: grid;
		grid-template-columns: 45px 1fr auto;
		text-align: left;
		border-bottom: var(--divider-line);
		color: var(--txt-color-primary);
		padding-left: 10px;
		padding-right: 10px;
		cursor: pointer;
	}
	.voicemail-list-item.odd {
		background-color: var(--bg-color-3);
	}
	/* .voicemail-list-item:hover {
		background-color: var(--pn-primary-4);
		cursor: context-menu;
	} */
</style>