/**
 * This modules handles dragging of calls and queue members
 * 
 * Created by Per Moeller
 */
import u from '../utils/utils';
import EventBus from './EventBus';
import s from '../settings';
import employees from '../data/employees';
import sipClient from '../data/sipClient';
import i18n from '../utils/i18n';
import logger from '../data/logger';

class CallDrag {
	constructor() {
		this.dragItem = null;
		this.dragItemType = null;
		this.dragTarget = null;
		this.dragTargetType = null;
	}

	dragDropped() {
		//console.log(`Call of type ${this.dragItemType} with id ${this.dragItem._id} was dropped on type ${this.dragTargetType} with id ${this.dragTarget._id}`);

		// Types: MyCalls, MyCallsCall, Employee, QueueCallers, QueueMembers, QueueCall, QueueMember
		// MyCalls accepts: Employee, QueueCall
		// MyCallsCall accepts: none - never does
		// Employee accepts: MyCallsCall, Employee (for picking up ringing/active call), QueueCall
		// QueueCallers accepts: MyCallsCall,
		// QueueMembers accepts: Employee,
		// QueueCall accepts: none - never does
		// QueueMember accepts: none - never does

		switch (this.dragItemType) {
			case 'MyCallsCall': {
				switch (this.dragTargetType) {
					case 'Employee': this.myCall2Employee(); break;
					case 'QueueCallers': this.myCall2Queue(); break;
				}
				break;
			}

			case 'Employee': {
				switch (this.dragTargetType) {
					case 'MyCalls': this.employee2myCalls(); break;
					case 'Employee': this.employee2employee(); break;
					case 'QueueMembers': this.employee2queueMember(); break;
					case 'QueueCallers': this.employee2queue(); break;
				}
				break;
			}

			case 'QueueCall': {
				switch (this.dragTargetType) {
					case 'MyCalls': this.queueCall2myCalls(); break;
					case 'Employee': this.queueCall2employee(); break;
					case 'QueueCallers': this.queueCall2queue(); break;
				}
				break;
			}

			case 'QueueMember': {
				switch (this.dragTargetType) {
					case 'QueueMembers': this.queueMember2queueMember(); break;
				}
				break;
			}

			default:
				//console.log('Something went wrong');
				break;
		}
	}

	// #region Middlemen

	async myCall2Employee() {
		// IN: dragItem=call, dragTarget=employee

		// If I have a ringing call, blind transfer it to the employee
		if (['CALL_START','CALL_RING'].includes(this.dragItem.state)) {
			logger.info(`CallDrag: User performing blind transfer of own ringing call to employee with extension id ${this.dragTarget.primaryExtension._id}`);
			this.blindTransfer(this.dragItem._id, this.dragTarget.primaryExtension._id, i18n.t('callDrag.transferToCalledEmployee'));
		} else {
			// Do I have an ongoing call with this Employee, if yes than we do an attendedTransfer, otherwise we do a blindTransfer
			let myCalls = await employees.getMyCalls();
			const myCallsSessionIds = myCalls.map(o => o.sessionId);
			const cc = this.dragTarget.currentCall;
			if (cc && myCallsSessionIds.includes(cc.sessionId)) {
				logger.info('CallDrag: User performing attended transfer of own active call to employee which she is talking to');
				this.attendedTransfer(this.dragItem._id, cc._id);
			} else {
				logger.info('CallDrag: User performing blind transfer of own active call to employee with extension id: ' + this.dragTarget.primaryExtension._id);
				this.blindTransfer(this.dragItem._id, this.dragTarget.primaryExtension._id, i18n.t('callDrag.transferToCalledEmployee'));
			}
		}
	}

	myCall2Queue() {
		// IN: dragItem=call, dragTarget=queue
		// if (this.dragItem.leg === 'I') {
		// 	const otherCall = employees.findOtherLegsCall(this.dragItem);
		// 	if (otherCall) {
		// 		this.blindTransfer(otherCall._id, this.dragTarget._id, i18n.t('callDrag.transferCallToTheQueue'));
		// 	} else {
		// 		EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.blindTransferLegNotFound') });
		// 	}
		// } else {
		//console.log(JSON.stringify(this.dragItem, null, 4));
		logger.info(`CallDrag: User is performing blind transfer of own call to the queue ${this.dragTarget._id}`);
		this.blindTransfer(this.dragItem._id, this.dragTarget._id, i18n.t('callDrag.transferCallToTheQueue'));
		// }
	}

	async employee2myCalls() {
		// IN: dragItem=employee, dragTarget=employee(me)
		await this.hangupMyRingingCalls(true);
		const cc = this.dragItem.currentCall;
		const cr = this.dragItem.currentRingingCall;
		if (cr) {
			logger.info('CallDrag: User is picking up a ringing call from an employee');
			EventBus.$emit('MyCallsAutoAnswerNextRinging', cr.sessionId);
			s.http.get(`/pbx/app/action/pickup?device=${s.myRealPhoneId}&target=${cr._id}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.failedToPickupCall') });
			});
		} else if (cc) {
			logger.info('CallDrag: User is picking up a call from an employee');
			EventBus.$emit('MyCallsAutoAnswerNextRinging', cc.sessionId);
			s.http.get(`/pbx/app/action/steal?device=${s.myRealPhoneId}&target=${cc._id}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.failedToPickupCall') });
			});
		}
	}

	async hangupMyRingingCalls(alsoHoldAnsweredCalls) {
		// When dragging a call to me, ensure I do not have another ringing call on me, and if I do - hang it up first
		let myCalls = await employees.getMyCalls();
		if (myCalls.length > 0) {
			for(let call of myCalls) {
				if (call.state == 'CALL_RING') {
					if (s.myPhoneId == 'SOFTPHONE') {
						const session = sipClient.getSession(call.callId, call.caller.number, call.state);
						if (session) {
							EventBus.$emit('CallRejected', { source: call._id });
							sipClient.decline(session);
							await s.sleep(200);
						}
					} else {
						try { await s.http.get(`/pbx/app/action/hangup?target=${call._id}`); }
						catch(_) {
							// We do not care
						}
					}
				} else if (call.state == 'CALL_ANSWER' && alsoHoldAnsweredCalls) {
					if (s.myPhoneId == 'SOFTPHONE') {
						const session = sipClient.getSession(call.callId, call.caller.number, call.state);
						if (session) {
							sipClient.hold(session);
							await s.sleep(200);
						}
					} else {
						try { await s.http.get(`/pbx/app/action/hold?device=${s.myRealPhoneId}&target=${call._id}`); }
						catch(_) {
							// We do not care
						}
					}
				}
			}
		}
	}

	employee2employee() {
		// IN: dragItem=employee, dragTarget=employee
		const cc = this.dragItem.currentCall;
		if (cc) {
			logger.info('CallDrag: User is making a blind transfer from one employee to another employee');
			this.blindTransfer(cc._id, this.dragTarget.primaryExtension._id, i18n.t('callDrag.transferTheCallToTheEmployee'));
		}
	}

	employee2queueMember() {
		// IN: dragItem=employee, dragTarget=queue
		logger.info(`CallDrag: User is adding employee with id ${this.dragItem.primaryExtension._id} to the queue ${this.dragTarget}`);
		this.queueJoinExtension(this.dragItem.primaryExtension._id, this.dragTarget);
	}

	employee2queue() {
		// IN: dragItem=employee, dragTarget=queue
		const cc = this.dragItem.currentCall;
		if (cc) {
			logger.info(`CallDrag: User is blind transferring a call from employee ${cc._id} to queue ${this.dragTarget._id}`);
			this.blindTransfer(cc._id, this.dragTarget._id, i18n.t('callDrag.transferTheCallFromEmployeeToQueue'));
		}
	}

	queueCall2myCalls() {
		// IN: dragItem=queueCall, dragTarget=employee(me)
		this.hangupMyRingingCalls(true);
		logger.info(`CallDrag: User is answering call ${this.dragItem.call._id} from a queue`);
		EventBus.$emit('MyCallsAutoAnswerNextRinging', this.dragItem.call._id);
		s.http.get(`/pbx/app/action/queuepickup?device=${s.myRealPhoneId}&target=${this.dragItem.call._id}`).catch(err => {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.failedToPickupQueueCall') });
		});
	}

	queueCall2employee() {
		// IN: dragItem=queueCall, dragTarget=employee
		logger.info(`CallDrag: User is transferring queue caller ${this.dragItem.call._id} to an employee with id ${this.dragTarget.primaryExtension._id}`);
		s.http.get(`/pbx/app/action/queuepickup?extension=${this.dragTarget.primaryExtension._id}&target=${this.dragItem.call._id}`).catch(err => {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.failedToMoveCallFromQueueToEmployee')});
		});

	}

	queueCall2queue() {
		// IN: dragItem=queueCall, dragTarget=queue
		logger.info(`CallDrag: User is transferring queue caller ${this.dragItem.call._id} to another queue ${this.dragTarget._id}`);
		s.http.get(`/pbx/app/action/queuepickup?extension=${this.dragTarget._id}&target=${this.dragItem.call._id}`).catch(err => {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.failedToMoveCallFromQueueToQueue') });
		});
	}

	queueMember2queueMember() {
		// IN: dragItem=queueMember, dragTarget=queue
		if (this.dragItem.member.extension) {
			this.queueJoinExtension(this.dragItem.member._id, this.dragTarget);
		} else {
			this.queueJoinExternal(this.dragItem.member._id, this.dragTarget);
		}
	}

	// #endregion

	// #region actions

	blindTransfer(callId, extensionId, message) {
		//console.log(`Requested to blind transfer call: ${callId} to extension ${extensionId}`);
		EventBus.$emit('BlindTransferRequested', { source: callId, target: extensionId });
		s.http.get(`/pbx/app/action/transfer?source=${callId}&target=${extensionId}`).catch(err => {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.blindTransferError').replace('%%message%%', message) });
		});
	}

	attendedTransfer(SourceCallId, destinationCallId) {
		//console.log(`Requested to attended transfer call: ${SourceCallId} to ${destinationCallId}`);
		EventBus.$emit('AttendedTransferRequested', { source: SourceCallId, destination: destinationCallId });
		s.http.get(`/pbx/app/action/transfer?source=${SourceCallId}&destination=${destinationCallId}`).catch(err => {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('callDrag.transferFailedHeader'), message: i18n.t('callDrag.attendedTransferError') });
		});
	}

	queueJoinExtension(extensionId, queue) {
		//console.log(`Requested to add extension ${extensionId} to queue ${queue._id}`);
		s.http.post(`/pbx/queue/${queue._id}/member`, { extension: extensionId, priority: 1 })
			.catch(err => {
				switch (err.status) {
					case 403:
						if (err.code == 'dynamic') { this.showError(i18n.t('callDrag.queueNotDynamic')); }
						else if (err.code == 'extension') { this.showError(i18n.t('callDrag.employeeNotValidForQueue')); }
						else { this.showError(i18n.t('callDrag.queueJoinOtherDenied')); }
						break;
					case 404:
						if (err.code == 'id') { this.showError(i18n.t('callDrag.queueNotFound')); }
						else if (err.code == 'extension') { this.showError(i18n.t('callDrag.employeeNotFound')); }
						break;
					case 409:
						if (err.code == 'member') { this.showError(i18n.t('callDrag.employeeAlreadyMember')); }
						break;
					default:
						this.showError(i18n.t('callDrag.unexpectedError') + ` ${err.status}: ${err.message}`);
						break;
				}
			});
	}

	queueJoinExternal(number, queue) {
		//console.log(`Requested to add external member ${number} to queue ${queue._id}`);
		s.http.post(`/pbx/queue/${queue._id}/member`, { number: number, priority: 1 })
			.catch(err => {
				switch (err.status) {
					case 403:
						if (err.code == 'dynamic') { this.showError(i18n.t('callDrag.queueNotDynamic')); }
						else { this.showError(i18n.t('callDrag.noPermissionToAddExternalToQueue')); }
						break;
					case 404:
						if (err.code == 'id') { this.showError(i18n.t('callDrag.queueNotFound')); }
						break;
					case 409:
						if (err.code == 'member') { this.showError(i18n.t('callDrag.externalAlreadyMember')); }
						else if (err.code == 'extension') { this.showError(i18n.t('callDrag.noPermissionToAddExternalToQueue')); }
						break;
					default:
						this.showError(i18n.t('callDrag.unexpectedError') + ` ${err.status}: ${err.message}`);
						break;
				}
			});
	}

	showError(message, header) {
		EventBus.$emit('CommonErrorModal', { header: header || i18n.t('callDrag.addNewMemberHeader'), message: message });
	}

	// #endregion

}

export default new CallDrag();