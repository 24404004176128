<template>
	<div v-html="svg" class="mysvg" :style="{ width: size+'px', height: size+'px', background: background ? background : '' }">
	</div>
</template>

<script>
import axios from 'axios';
import cache from './GlyphIconCache';

export default {
	props: {
		icon: { type: String },
		size: { type: String, required: true },
		color: { type: String, required: false },
		background: { type: String, required: false }
	},
	data() {
		return {
			svg: null
		};
	},
	methods: {
		load() {
			if (this.icon) {
				cache.getIcon(this.icon)
					.then(svg => {
						if (this.color) {
							this.svg = svg.replace('<svg', `<svg style="fill:${this.color}"`);
						} else {
							this.svg = svg;
						}
					})
					.catch(() => {
						cache.setIsLoading(this.icon);
						axios.get('/assets/glyphicons/glyphicons-basic-' + this.icon + '.svg')
							.then(res => {
								cache.setIcon(this.icon, res.data);
								if (this.color) {
									this.svg = res.data.replace('<svg', `<svg style="fill:${this.color}"`);
								} else {
									this.svg = res.data;
								}
							})
							.catch(() => {});
					});
				
			}
		}
	},
	watch: {
		icon() { this.load(); },
		size() { this.load(); },
		color() { this.load(); }
	},
	created() {
		this.load();
	},
	mounted() {
		this.$watch('color', () => { this.load(); });
		this.$watch('size', () => { this.load(); });
		this.$watch('icon', () => { this.load(); });
	}
};
</script>
