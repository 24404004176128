<template>
	<div v-if="me.employee" class="box" @contextmenu="showContextMenu" @click="showContextMenu">
		<file-uploader :targetUrl="uploadUrl" property="file" fileType="image/*" :onEvent="uploadEvent" ref="EmployeePictureUploader">
			<employee-picture :picture="me.employee.picture" :size="64" />
		</file-uploader>
		<presence-icon :state="me.employee.presence.state" :size="24" class="presence-state" />
		<spinner color="#135" :run="isLoadingPicture" :size="40" shadowColor="#ffffff" class="picture-loading"></spinner>
		<div class="labels default-font" :style="{'margin-top':labelsTopMargin}">
			<div class="name">
				{{ me.employee.name }}
				<div v-if="me.employee&&me.employee.primaryExtension&&me.employee.primaryExtension.redirect" class="redirect">
					<div style="display:flex">
						<glyph-icon icon="435-forward-email" size="14" color="#fff" style="margin-top:-4px" />
						<div style="margin-top:-3px">{{ me.employee.primaryExtension.redirect | cutNationalPrefixAndFormat }}</div>
					</div>
				</div>
			</div>
			<div class="message">
				<glyph-icon icon="465-call" size="14" color="var(--label-location-color)" class="icon" />
				<div class="label">{{ numberPresentation | cutNationalPrefixAndFormat }}</div>
			</div>
			<div class="message" v-if="me.employee.presence.message">
				<glyph-icon icon="238-chat-message" size="16" color="var(--label-message-color)" class="icon" />
				<div class="label">{{ me.employee.presence.message }}<span v-if="me.employee.presence.expire!=null&&me.employee.presence.location"> | {{ me.employee.presence.location }}</span></div>
			</div>
			<div class="location" v-if="me.employee.presence.location&&(!me.employee.presence.expire||!me.employee.presence.message)">
				<glyph-icon icon="591-map-marker" size="16" color="var(--label-location-color)" class="icon" />
				<div class="label">{{ me.employee.presence.location }}</div>
			</div>
			<div class="expires" v-if="me.employee.presence.expire!=null">
				<glyph-icon icon="55-clock" size="16" color="var(--label-expires-color)" class="icon" />
				<div class="label">{{ expiresLabel }}</div>
			</div>
		</div>
		<v-contextmenu ref="mypresencecontextmenu">
			<v-contextmenu-item @click="showSendSms">{{ $t('mypresence.action.sendSms') }}</v-contextmenu-item>
			<v-contextmenu-item @click="showSendGroupSms">{{ $t('mypresence.action.sendGroupSms') }}</v-contextmenu-item>
				<v-contextmenu-item divider></v-contextmenu-item>
			<v-contextmenu-item @click="showSetPresenceModal">{{ $t('mypresence.action.setPresence') }}</v-contextmenu-item>
			<v-contextmenu-submenu :title="$t('mypresence.action.quickSetPresence')">
				<v-contextmenu-item @click="setPresenceState('AVAILABLE')">{{ $t('common.presence.AVAILABLE') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('BUSY')">{{ $t('common.presence.BUSY') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('OUT_OF_OFFICE')">{{ $t('common.presence.OUT_OF_OFFICE') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('OFF_WORK')">{{ $t('common.presence.OFF_WORK') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('HOLIDAY')">{{ $t('common.presence.HOLIDAY') }}</v-contextmenu-item>
				<v-contextmenu-item divider></v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('BUSY', '1_HOUR')">{{ $t('mypresence.action.busy1Hour') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('BUSY', '2_HOURS')">{{ $t('mypresence.action.busy2Hours') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('BUSY', 'REST_OF_DAY')">{{ $t('mypresence.action.busyRestOfDay') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('OFF_WORK', 'REST_OF_DAY')">{{ $t('mypresence.action.offWorkRestOfDay') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('OFF_WORK', 'REST_OF_WEEK')">{{ $t('mypresence.action.offWorkRestOfWeek') }}</v-contextmenu-item>
				<v-contextmenu-item @click="setPresenceState('OFF_WORK', 'REST_OF_MONTH')">{{ $t('mypresence.action.offWorkRestOfMonth') }}</v-contextmenu-item>
			</v-contextmenu-submenu>
			<v-contextmenu-submenu :title="$t('mypresence.action.numberPresentation')" v-if="me.extension">
				<v-contextmenu-submenu :title="$t('mypresence.action.numberPresentationWorking')">
					<v-contextmenu-item v-for="number in me.callerIdNumbers" :key="number" style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnPresenceWork(number)">
						<glyph-icon :icon="number==me.extension.outbound.callerId.onPresenceWork?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
						<div style="margin-left:5px;margin-top:1px">{{ number | cutNationalPrefixAndFormat }}</div>
					</v-contextmenu-item>
					<v-contextmenu-item style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnPresenceWork('SECRET')">
							<glyph-icon :icon="'SECRET'==me.extension.outbound.callerId.onPresenceWork?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
							<div style="margin-left:5px;margin-top:1px">{{ $t('mypresence.action.numberPresentationSecret') }}</div>
					</v-contextmenu-item>
				</v-contextmenu-submenu>
				<v-contextmenu-submenu :title="$t('mypresence.action.numberPresentationOffWork')">
					<v-contextmenu-item v-for="number in me.callerIdNumbers" :key="number" style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnPresenceOffWork(number)">
						<glyph-icon :icon="number==me.extension.outbound.callerId.onPresenceOffWork?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
						<div style="margin-left:5px;margin-top:1px">{{ number | cutNationalPrefixAndFormat }}</div>
					</v-contextmenu-item>
					<v-contextmenu-item style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnPresenceOffWork('SECRET')">
							<glyph-icon :icon="'SECRET'==me.extension.outbound.callerId.onPresenceOffWork?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
							<div style="margin-left:5px;margin-top:1px">{{ $t('mypresence.action.numberPresentationSecret') }}</div>
					</v-contextmenu-item>
				</v-contextmenu-submenu>
				<v-contextmenu-item divider></v-contextmenu-item>
				<v-contextmenu-submenu :title="$t('mypresence.action.numberPresentationNextCall')" :class="{'menu-active':me.extension.outbound.callerId.onNextCall!=null}">
					<v-contextmenu-item v-for="number in me.callerIdNumbers" :key="number" style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnNextCall(number)">
						<glyph-icon :icon="number==me.extension.outbound.callerId.onNextCall?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
						<div style="margin-left:5px;margin-top:1px">{{ number | cutNationalPrefixAndFormat }}</div>
					</v-contextmenu-item>
					<v-contextmenu-item style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnNextCall('SECRET')">
							<glyph-icon :icon="'SECRET'==me.extension.outbound.callerId.onNextCall?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
							<div style="margin-left:5px;margin-top:1px">{{ $t('mypresence.action.numberPresentationSecret') }}</div>
					</v-contextmenu-item>
					<v-contextmenu-item style="display:flex;padding-top:4px;padding-bottom:3px" @click="setCallerIdOnNextCall(null)">
							<glyph-icon :icon="!me.extension.outbound.callerId.onNextCall?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
							<div style="margin-left:5px;margin-top:1px">{{ $t('mypresence.action.numberPresentationNone') }}</div>
					</v-contextmenu-item>
				</v-contextmenu-submenu>
			</v-contextmenu-submenu>
			<v-contextmenu-item @click="showRedirectModal">{{ $t('mypresence.action.unconditionalCallForward') }}</v-contextmenu-item>
			<v-contextmenu-item @click="toggleFullScreen"><span v-if="isFullScreen">{{ $t('mypresence.action.endFullScreen') }}</span><span v-if="!isFullScreen">{{ $t('mypresence.action.startFullScreen') }}</span></v-contextmenu-item>
			<v-contextmenu-item divider></v-contextmenu-item>
			<v-contextmenu-item @click="changePicture">{{ $t('mypresence.action.changeProfilePicture') }}</v-contextmenu-item>
			<v-contextmenu-submenu :title="$t('mypresence.action.layout')">
				<v-contextmenu-item v-for="item in dashboards.dashboards" @click="selectDashboard(item)" v-bind:key="item._id" style="display:flex;padding-top:4px;padding-bottom:3px">
					<glyph-icon :icon="item._id==s.myDashboardId?'739-check':'blank'" size="15" color="var(--txt-color-primary)"></glyph-icon>
					<div style="margin-left:5px;margin-top:1px">{{ item.name }}</div>
				</v-contextmenu-item>
				<v-contextmenu-item divider></v-contextmenu-item>
				<v-contextmenu-item :disabled="!s.isDashboardEditor" @click="createDashboard()">{{ $t('mypresence.action.layoutNew') }}</v-contextmenu-item>
				<v-contextmenu-item :disabled="!s.isDashboardEditor" v-if="s.myDashboardId!=null" @click="editDashboard()">{{ $t('mypresence.action.layoutEdit') }}</v-contextmenu-item>
				<v-contextmenu-item :disabled="!s.isDashboardEditor" v-if="s.myDashboardId!=null" @click="deleteDashboard()">{{ $t('mypresence.action.layoutDelete') }}</v-contextmenu-item>
			</v-contextmenu-submenu>
			<v-contextmenu-item @click="showSettings">{{ $t('mypresence.action.settings') }}</v-contextmenu-item>
			<v-contextmenu-item divider></v-contextmenu-item>
			<v-contextmenu-item @click="logout">{{ $t('mypresence.action.logout') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="s.gotStash" @click="endImpersonation">{{ $t('mypresence.endImpersonation') }}</v-contextmenu-item>

		</v-contextmenu>
		<set-presence-modal ref="mySetPresenceModal" :employee="me.employee"></set-presence-modal>
	</div>
</template>

<script>
import EmployeePicture from '../EmployeePicture.vue';
import PresenceIcon from '../PresenceIcon.vue';
import GlyphIcon from '../GlyphIcon.vue';
import u from '../../utils/utils';
import Spinner from '../Spinner.vue';
import EventBus from '../../data/EventBus';
import FileUploader from '../FileUploader.vue';
import fileDialog from 'file-dialog';
import s from '../../settings';
import SetPresenceModal from '../modals/SetPresenceModal.vue';
import dashboards from '../../data/dashboards';
import kuando from '../../utils/headsets/kuando';
import i18n from '../../utils/i18n';
import me from '../../data/me';
import logger from '../../data/logger';

export default {
	components: {
		EmployeePicture,
		PresenceIcon,
		GlyphIcon,
		Spinner,
		FileUploader,
		SetPresenceModal
	},
	data() {
		return {
			$t: i18n.t,
			me: me,
			isLoadingPicture: false,
			s: s,
			dashboards: dashboards,
			isFullScreen: false
		};
	},
	computed: {
		expiresLabel() {
			if (this.me.employee && this.me.employee.presence && this.me.employee.presence.expire) {
				let lbl = i18n.t('common.presence.' + this.me.employee.presence.state);
				const expire = new Date(this.me.employee.presence.expire);

				const inAWeek = new Date();
				inAWeek.setDate(inAWeek.getDate() + 7);
				inAWeek.setHours(0, 0, 0, 0);
				
				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);

				const today = new Date();

				if (expire.getFullYear() == today.getFullYear() && expire.getMonth() == today.getMonth() && expire.getDate() == today.getDate()) {
					// kl. 18:00
					lbl += ` ${i18n.t('employee.until')} ${u.formatDate(expire, 'hh:mm')}`;
				} else if (expire.getFullYear() == tomorrow.getFullYear() && expire.getMonth() == tomorrow.getMonth() && expire.getDate() == tomorrow.getDate()) {
					// imorgen kl. 18:00
					if (u.formatDate(expire, 'hh:mm') == '00:00') {
						lbl += ' ' + i18n.t('employee.restOfDay');
					} else {
						lbl += ' ' + i18n.t('employee.untilTomorrow') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				} else if (expire < inAWeek) {
					lbl += ' ' + i18n.t('employee.until2') + ' ' + u.formatDate(expire, 'w');
					if (u.formatDate(expire, 'hh:mm') != '00:00') {
						lbl += ' ' + i18n.t('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				} else {
					lbl += ' ' + i18n.t('employee.until2') + ' ' + u.formatDate(expire, 'w');
					lbl += ' ' + i18n.t('employee.onThe') + ' ' + u.formatDate(expire, 'd/M');
					if (u.formatDate(expire, 'hh:mm') != '00:00') {
						lbl += ' ' + i18n.t('common.at') + ' ' + u.formatDate(expire, 'hh:mm');
					}
				}

				return lbl;
			} else {
				return '';
			}
		},
		labelsTopMargin() {
			let lines = 1;
			if (this.me.employee && this.me.employee.presence) {
				if (this.me.employee.presence.message) { lines++; }
				if (this.me.employee.presence.location) { lines++; }
				if (this.me.employee.presence.expire) { lines++; }
			}
			return `${[0, 21, 15, 8, 0][lines]}px`;
		},
		uploadUrl() {
			return this.me.employee ? `/employee/${this.me.employee._id}/picture` : '';
		},
		numberPresentation() {
			if (!this.me.extension) { return ''; }
			const onNextCall = u.getDeepValue(this.me.extension, 'outbound.callerId.onNextCall');
			if (onNextCall) { return onNextCall; }
			// We do not handle if paired with mobile which has mobileUseOwnNumber=true
			if (this.me.employee.presence.state === 'OFF_WORK' || this.me.employee.presence.state === 'HOLIDAY') {
				return this.me.extension.outbound.callerId.onPresenceOffWork;
			} else {
				return this.me.extension.outbound.callerId.onPresenceWork;
			}
		}
	},
	methods: {
		async showContextMenu(e) {
			e.preventDefault();
			setTimeout(() => { this.$refs['mypresencecontextmenu'].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		toggleFullScreen() {
			if (!this.isFullScreen) {
				document.documentElement.requestFullscreen();
				s.setFullscreen(true);
				logger.info('MyPresence: Enabled fullscreen mode');
				
			} else {
				document.exitFullscreen();
				s.setFullscreen(false);
				logger.info('MyPresence: Disabled fullscreen mode');
			}
		},
		uploadEvent(args) {
			//console.log(`Upload event: ${args.state} - ${args.file} - ${JSON.stringify(args.response)}`);
			if (args.state == 'FAILED') {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.errorUploadHeader'), message: i18n.t('mypresence.errorUploadMessage') });
				logger.error(`MyPresence: Failed to upload picture: ${args.file} - ${JSON.stringify(args.response)}`);
			}
			if (args.state == 'COMPLETED') {
				logger.info('MyPresence: User uploaded new picture');
			}
		},
		showSetPresenceModal() {
			this.$refs.mySetPresenceModal.show();
		},
		setPresenceState(state, expires) {
			if (!expires) {
				const presence = { presence: { state: state, expire: null, message: '', location: '' } };
				s.http.post(`/employee/${s.auth.employee}`, presence)
					.then(() => {
						logger.info(`MyPresence: User changed presence to: ${state}`);
					})
					.catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.setPresenceErrorHeader'), message: i18n.t('mypresence.setPresenceErrorMessage') + '<br><br>' + err.message });
						logger.error(`MyPresence: User failed to set state: ${err.message}`);
					});
			} else {
				const date = new Date();
				switch (expires) {
					case '1_HOUR':
						date.setHours(date.getHours() + 1);
						break;
					case '2_HOURS':
						date.setHours(date.getHours() + 2);
						break;
					case 'REST_OF_DAY':
						date.setHours(0,0,0,0);
						date.setDate(date.getDate() + 1);
						break;
					case 'REST_OF_WEEK':
						date.setHours(0,0,0,0);
						const weekDay = date.getDay();
						if (weekDay == 0) {
							date.setDate(date.getDate() + 1);
						} else if (weekDay == 1) {
							date.setDate(date.getDate() + 7);
						} else if (weekDay > 1) {
							date.setDate(date.getDate() + (8 - weekDay));
						}
						break;
					case 'REST_OF_MONTH':
						date.setHours(0,0,0,0);
						date.setDate(1);
						date.setMonth(date.getMonth() + 1);
						break;
				}
				const presence = { presence: { state: state, expire: date, message: '', location: '' } };
				s.http.post(`/employee/${s.auth.employee}`, presence)
					.then(() => {
						logger.info(`MyPresence: User changed presence to: ${state} that expires: ${date.toLocaleString()}`);
					})
					.catch(err => {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.setPresenceErrorHeader'), message: i18n.t('mypresence.setPresenceErrorMessage') + '<br><br>' + err.message });
						logger.error(`MyPresence: User failed to set state: ${err.message}`);
					});
			}
		},
		changePicture() {
			let file;
			fileDialog({ accept: 'image/*', multiple: false })
				.then(files => {
					file = files[0].name;
					this.uploadEvent({ state: 'UPLOADING', file: file });
					const data = new FormData();
					data.append('file', files[0], files[0].name);
					return s.http.post(`/employee/${this.me.employee._id}/picture`, data, { headers: { 'content-type': 'multipart/form-data' }});
				})
				.then(res => {
					this.uploadEvent({ state: 'COMPLETED', file: file, response: res.data });
				})
				.catch(err => {
					this.uploadEvent({ state: 'FAILED', file: file, response: err.data });
				});
		},
		selectDashboard(item) {
			s.setMyDashboardId(item._id);
			EventBus.$emit('Dashboard:Select', item._id);
			logger.info(`MyPresence: User selected dashboard ${item._id}`);
		},
		createDashboard() {
			EventBus.$emit('Dashboard:Create');
			logger.info('MyPresence: User started create dashboard');
		},
		editDashboard() {
			EventBus.$emit('Dashboard:Edit');
			logger.info('MyPresence: User started edit dashboard');
		},
		deleteDashboard() {
			if (this.dashboards?.dashboard?.shared && (s.auth.accessLevel === 'PERSONAL' || s.auth.accessLevel === 'VIEWER')) {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('dashboards.sharedDeleteAccessDeniedHeader'), message: i18n.t('dashboards.sharedDeleteAccessDeniedMessage') });
				return;
			}
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('mypresence.layoutDeleteHeader'), message: i18n.t('mypresence.layoutDeleteMessage').replace('%%name%%',dashboards.dashboard.name), ok: i18n.t('common.delete'), emit: 'Dashboard:Delete', emitData: s.myDashboardId});
		},
		logout() {
			EventBus.$emit('CommonQuestionModal', { header: i18n.t('mypresence.logoutHeader'), message: i18n.t('mypresence.logoutMessage'), ok: i18n.t('common.yes'), cancel: i18n.t('common.no'), emit: 'Auth:InitiateLogout' });
		},
		logoutDo() {
			logger.info('User initiated logout');
			setTimeout(() => { s.logout(); }, 500);
		},
		showRedirectModal() {
			EventBus.$emit('CommonSetRedirectModal', this.me.employee);
		},
		showSettings() {
			EventBus.$emit('CommonSettings');
		},
		setCallerIdOnPresenceWork(number) {
			s.http.post(`/pbx/extension/${this.me.extension._id}`, { outbound: { callerId: { onPresenceWork: number }}})
				.then(() => { logger.info(`MyPresence: User set caller ID on presence work to ${number}`); })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.numberPresentationErrorHeader'), message: i18n.t('mypresence.numberPresentationErrorMessage1') }); });
		},
		setCallerIdOnPresenceOffWork(number) {
			s.http.post(`/pbx/extension/${this.me.extension._id}`, { outbound: { callerId: { onPresenceOffWork: number }}})
				.then(() => { logger.info(`MyPresence: User set caller ID on presence off work to ${number}`); })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.numberPresentationErrorHeader'), message: i18n.t('mypresence.numberPresentationErrorMessage2') }); });
		},
		setCallerIdOnNextCall(number) {
			s.http.post(`/pbx/extension/${this.me.extension._id}`, { outbound: { callerId: { onNextCall: number }}})
				.then(() => { logger.info(`MyPresence: User set caller ID on next call to ${number}`); })
				.catch(() => { EventBus.$emit('CommonErrorModal', { header: i18n.t('mypresence.numberPresentationErrorHeader'), message: i18n.t('mypresence.numberPresentationErrorMessage3') }); });
		},
		showSendSms() {
			EventBus.$emit('CommonSendSmsModal', { employee: null, number: null });
		},
		showSendGroupSms() {
			EventBus.$emit('CommonSendGroupSmsModal');
		},
		endImpersonation() {
			s.endImpersonation();
		}

	},
	mounted() {
		document.logger = logger; // So that devtools may access it
		EventBus.$on('Auth:InitiateLogout', this.logoutDo.bind(this));
		EventBus.$on('OneSecond', () => {
			//this.isFullScreen = window.outerHeight >= screen.availHeight && window.outerWidth >= screen.availWidth;
			this.isFullScreen = document.fullscreenElement != null;
		});
		if (s.fullscreen) {
			document.documentElement.requestFullscreen();
		}

		// Remove default title on file picker for employee picture
		const fileElements = document.querySelectorAll('input[type=\'file\'][accept=\'image/*\']');
		if (fileElements.length > 0) { fileElements[0].title = ''; }

	},
	watch: {
		'me.employee.presence.state': function(newValue, oldValue) {
			if (this.me.employee.calls.length == 0) {
				kuando.idle();
			}
		}
	}
};
</script>

<style scoped>
	.box {
		display: flex;
		cursor: context-menu;
		border-radius: 30px 5px 5px 30px;
		transition: background 400ms;
	}
	.box:hover {
		background:#00000010;
	}
	.name {
		font-size: 16px;
		font-weight: 400;
		line-height: 17px;
		/* letter-spacing: 0.05em; */
	}
	.message {
		font-size: var(--font-size-small);
		color: var(--label-message-color);
		line-height: var(--font-size-small);
		display: flex;
	}
	.location {
		font-size: var(--font-size-small);
		/* font-style: italic; */
		color: var(--label-location-color);
		line-height: var(--font-size-small);
		display: inline-flex;
	}
	.expires {
		font-size: var(--font-size-small);
		font-style: italic;
		color: var(--label-expires-color);
		line-height: var(--font-size-small);
		display: inline-flex;
	}
	.labels {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-left: -10px;
		width: 250px;
	}
	.labels DIV {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.presence-state {
		position: relative;
		left: -14px;
		top: 40px;
		flex-shrink: 0;
		height: 24px;
	}
	.fourLines { margin-top: -2px; }
	.threeLines { margin-top: 8px; }
	.twoLines { margin-top: 15px; }
	.oneLine { margin-top: 19px; }
	.icon {
		margin-top: -1px;
		flex: 0 0 auto;
	}
	.label {
		padding-left: 2px;
	}
	.picture-loading {
		position: relative;
		left: -74px;
		top: 13px;
	}
	.menu-active::before {
		content: "✔ ";
		opacity: 0.8;
	}
	.redirect {
		font-size: var(--font-size-small);
		font-weight: 400;
		background: var(--bg-color-dashboard-header);
		padding: 2px 2px;
		display: inline-block;
		color: var(--txt-color-dashboard-header);
		height: 15px;
		border-radius: 3px;
	}

</style>