
import EventBus from './EventBus';
import s from '../settings';
import rt from '../data/realtime';
import logger from '../data/logger';

class RecordingState {
	constructor() {
		logger.debug('RecordingState: Constructor');

		/** @type {Boolean} true when user is recording */
		this.isRecording = false;

		/** @type {Array} List if channel id for the channels that are being recorded */
		this.recordingIds = [];

		EventBus.$on('Realtime:Pbx:RECORDING_START', ev => {
			if (ev.employee == s.auth.employee) {
				this.recordingIds.push(ev.channel);
				this.isRecording = true;
				logger.info('RecordingState: Employee is recording the call with id: ' + ev.channel);
			}
		});
		
		EventBus.$on('Realtime:Pbx:RECORDING_END', ev => {
			if (ev.employee == s.auth.employee) {
				this.recordingIds = this.recordingIds.filter(o => o != ev.channel);
				if (this.recordingIds.length == 0) {
					this.isRecording = false;
				}
				logger.info('RecordingState: Employee stopped recording the call with id: ' + ev.channel);
			}
		});

		EventBus.$on('Realtime:Config:EMPLOYEE_UPDATED', ev => {
			let { event, id, data } = ev;
			if (id == s.auth.employee) {
				s.http.get('/auth/validateToken?extend=true&token=' + s.token)
					.then(res => {
						s.auth.recordingMode = res.data.recordingMode;
					})
					.catch(() => {});
			}
		});

		EventBus.$on('Realtime:Connected', () => { this.init(); });

		if (rt.connected) {
			this.init();
		}
	}

	init() {
		// Init recording status on startup and reconnect
		s.ready()
			.then(() => s.http.get('/pbx/recording/status'))
			.then(res => {
				this.recordingIds = [];
				res.data.forEach(item => {
					if (item.recording) {
						this.recordingIds.push(item.channel);
					}
				});
				this.isRecording = this.recordingIds.length > 0;
				logger.info('RecordingState: Initialized - currently we are ' + (this.isRecording ? 'recording ' + this.recordingIds.length + ' calls' : 'not recording'));
			}).catch(() => {});
	}
}

// Singleton
export default new RecordingState();
