<template>
	<div v-html="svg" :style="{ width: size+'px', height: size+'px', display: this.svg==''?'none':'block' }">
	</div>
</template>

<script>
import axios from 'axios';

export default {
	props: {
		icon: { type: String },
		size: { type: String, required: true }
	},
	data() {
		return { svg: null };
	},
	methods: {
		load() {
			if (this.icon) {
				axios.get('/assets/country/' + this.icon + '.svg')
					.then(res => {
						this.svg = res.data;
					})
					.catch(() => {});
			} else {
				this.svg = '';
			}
		}
	},
	created() { this.load(); },
	watch: {
		icon() { this.load(); }
	}
};
</script>