/**
 * Misc utilities
 *
 * Created by Per Moeller <pm@telecomx.dk> on 2014-03-25
 */

import i18n from './i18n';
const utils = {};

/**
 * Apply all properties from a secondary object onto the primary object, recursively
 * - use for taking a default/current object and apply new values, even if only a few is set
 * - can also be used for simply copying an object
 * Limitations: arrays will be copied, not merged!
 * @param {Object} primary The object to apply the secondary values to
 * @param {Object} secondary The object which values will be added/applied to the primary object
 * @returns {Object} The primary object
 */
utils.extendObject = function(primary, secondary) {
	for (let key in secondary) {
		if (secondary.hasOwnProperty(key)) {

			// If it's an object
			if (Object.prototype.toString.call(secondary[key]) === '[object Object]') {
				if (!primary[key]) { primary[key] = {}; }
				utils.extendObject(primary[key], secondary[key]);
			}

			// If it's an array - an array will always overwrite, not append!
			else if (Object.prototype.toString.call(secondary[key]) == '[object Array]') {
				primary[key] = [];
				secondary[key].forEach(function(item) {
					if (typeof item === 'object') {
						// Do object copying
						const obj = {};
						primary[key].push(obj);
						utils.extendObject(obj, item);

					} else {
						// Do primitive copying
						primary[key].push(item);
					}
				});

			} else {
				// It's a primitive
				primary[key] = secondary[key];
			}
		}
	}
	return primary;
};

/**
 * Copy an object
 * @param {Object} source Object to copy
 * @return {Object} The copy
 */
utils.copyObject = function(source) {
	return utils.extendObject({}, source);
};

/**
 * Traverses an object and removes properties than are not in the array of permitted properties.
 * Limitations: only works on level 0
 * @param {Object} obj The object to check
 * @param {Array} properties Array of valid property names
 * @param {Boolean} [reverse] If true the properties list instead contains invalid property names
 * @returns {*} The pruned object
 */
utils.pruneObject = function(obj, properties, reverse) {
	for (let key in obj) {
		if (reverse) {
			if (obj.hasOwnProperty(key) && properties.indexOf(key) != -1) {
				delete obj[key];
			}
		} else {
			if (obj.hasOwnProperty(key) && properties.indexOf(key) == -1) {
				delete obj[key];
			}
		}
	}
	return obj;
};

/**
 * Convert an anything to a boolean
 * @param value Value to convert to boolean
 * @returns {Boolean} value
 */
utils.bool = function(value) {
	if (!value) { return false; }
	if (typeof value === 'string') {
		return ['true', 'yes', '1', 'y'].indexOf(value.toLowerCase()) != -1;
	} else if (typeof value === 'boolean') {
		return value;
	} else if (typeof value === 'number') {
		return value > 0;
	} else {
		return false; // not a string and not a boolean
	}
};

/**
 * Simple object cloning, only properties will be passed over
 * @param {Object} obj Object to clone
 * @returns {*} A clone of the object
 */
utils.cloneObject = function(obj) {
	return JSON.parse(JSON.stringify(obj));
};

/**
 * Returns unique values of an array
 * @param {Array} array The array to work on
 * @returns {Array} Array of distinct values
 */
utils.getUnique = function(array) {
	return array.filter(function(value, index, self) {
		return self.indexOf(value) === index;
	});
};

/**
 * Takes an array of phone numbers and turns them into a short version, so that consecutive numbers are joined, e.g. 1,2,3,4,5 -> 1-5
 * @param {Array} numbers Array of numbers
 * @param {Boolean} asString True to return as a comma separated list of numbers, otherwise an array will be returned
 * @param {Boolean} stripPlus45 True to remove +45 from numbers
 * @returns {*} Array or string
 */
utils.minifyPhoneNumbers = function(numbers, asString, stripPlus45) {
	if (typeof numbers == 'undefined' || numbers == null || numbers.length == 0) {
		return asString ? '' : [];
	}

	let output = [], current, previous;

	if (numbers.length > 1) {
		numbers = numbers.sort();
		let firstOfSeries = parseInt(numbers[0].substr(1));

		for (let i = 1; i < numbers.length; i++) {
			current = parseInt(numbers[i].substr(1));
			previous = parseInt(numbers[i - 1].substr(1));

			if (current != previous + 1) {
				if (firstOfSeries != previous) {
					output.push('+' + firstOfSeries + '-+' + previous); // It's a range
				} else {
					output.push('+' + firstOfSeries); // It's a single
				}
				firstOfSeries = current;
			}
		}

		if (current != previous + 1) {
			output.push('+' + current); // It's a single
		} else {
			output.push('+' + firstOfSeries + '-+' + current);
		}
	} else {
		output.push(numbers[0]);
	}

	output = output.map(function(item) {
		if (item.indexOf('-') != -1) {
			const parts = item.split('-');
			const p1len = parts[0].length;
			const p2len = parts[1].length;
			if (p1len == p2len && parts[0].substr(0,p1len-2) == parts[1].substr(0,p1len-2)) {
				return parts[0] + '-' + parts[1].substr(p1len-2);
			} else {
				return item;
			}
		} else {
			return item;
		}
	});

	if (stripPlus45) {
		output = output.map(function(value) {
			return value.replace('+45', '').replace('+45', ''); });
	}

	return asString ? output.join(', ') : output;
};

/**
 * Tries to parse a string into an integer, if it fails the defaultValue is returned
 * @param {String} str Values to parse
 * @param {Number} [defaultValue] Number to use if parsing not possible, optional, default to 0
 * @param {Number} [minValue] Optional mimimum value to enforce
 * @param {Number} [maxValue] Optional maximum value to enforce
 * @returns {Number} Parsed value
 */
utils.tryParseInt = function(str, defaultValue, minValue, maxValue) {
	let retValue = defaultValue || 0;
	if (str != null) {
		if (str.length > 0) {
			//noinspection JSValidateTypes
			if (!isNaN(str)) {
				retValue = parseInt(str);
			}
		}
	}

	if (typeof minValue === 'number') {
		if (retValue < minValue) {
			retValue = minValue;
		}
	}

	if (typeof maxValue === 'number') {
		if (retValue > maxValue) {
			retValue = maxValue;
		}
	}

	return retValue;
};

/**
 * Returns a date as a formatted string
 * @param {Date} date The date to represent
 * @param {String} format Formatting, accepting: yyyy, MM, M, dd, d, hh, h, mm, m, ss, s
 * @returns {string} The formatted date
 */
utils.formatDate = function(date, format) {
	let parts = [], buffer = '';
	for (let i=0; i<format.length; i++) {
		buffer += format[i];
		if (format[i] != format[i+1]) {
			parts.push(buffer);
			buffer = '';
		}
	}
	return parts.map(p => {
		if (p == 'yyyy') { return date.getFullYear().toString(); }
		else if (p == 'MMM') { return i18n.t(`common.month-long.${date.getMonth()}`); }
		else if (p ==  'MM') { return (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1); }
		else if (p ==   'M') { return (date.getMonth() + 1).toString(); }
		else if (p ==  'dd') { return (date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate()); }
		else if (p ==   'd') { return date.getDate().toString(); }
		else if (p ==  'hh') { return (date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()); }
		else if (p ==  'HH') { return (date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours()); }
		else if (p ==   'h') { return date.getHours().toString(); }
		else if (p ==  'mm') { return (date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes()); }
		else if (p ==   'm') { return date.getMinutes().toString(); }
		else if (p ==  'ss') { return (date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds()); }
		else if (p ==   's') { return date.getSeconds().toString(); }
		else if (p ==   'w') { return i18n.t(`common.day-long.${date.getDay()}`); }
		else if (p ==   'v') { return i18n.t(`common.day-short.${date.getDay()}`); }
		else { return p; }
	}).join('');
};

/**
 * Pretty format money
 * @param value Amount to format
 * @return {string} The formatted amount
 */
utils.formatCurrency = function(value) {
	const numValue = typeof value != 'number' ? Number(value) : value;
	const strValue = numValue.toFixed(2).replace('.',',');
	let p = strValue.length - 4 - (2);
	if (p<1) return strValue;
	let res = strValue.substr(p);
	while(p >= 4) {
		p -= 3;
		res = strValue.substr(p, 3) + '.' + res;
	}
	res = strValue.substr(0, p) + '.' + res;
	return res;
};


/**
 * Check if a number is a phone number, if no + we assume +45, spaces and dashes are ignored (they are okay)
 * @param {String} number Number to validate
 * @param {Boolean} [empty] Is string considered valid if empty - optional
 * @returns {boolean} True if valid
 */
utils.isPhoneNumber = function(number, empty) {
	if (empty && (number == null || number.length == 0)) { return true; } // empty string is valid if empty flag is set

	if (!number) { return false; }

	number = number.replace(/[ -]/g, '');
	if (number.indexOf('00') == 0) { number = '+' + number.substr(1); }
	if (number.indexOf('+') != 0) { number = '+45' + number; }
	return utils.isE164(number, true);
};

/**
 * Convert a phone number with spaces, dashes and missing +<country code> to E.164 format
 * @param {string} number Phone number to convert
 * @return {string} Phone number in E.164 format
 */
utils.phoneNumberToE164 = function(number) {
	if (!number) { return ''; }
	number = number.replace(/[ -]/g, '');
	if (number.indexOf('00') == 0) { number = '+' + number.substr(1); }
	if (number.indexOf('+') != 0) { number = '+45' + number; }
	return number;
};

/**
 * Check if a string contains an IP address
 * @param {string} ip String to check
 * @returns {boolean} True if it contains a valid IP address
 */
utils.isIp = function(ip) {
	if (!ip) { return false; }
	return ip.match(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/) != null;
};

/**
 * Checks if a string containing one or more (comma separated) IP addresses is valid
 * @param {string} str String to check
 * @returns {boolean} True if string contains one or more valid IP addresses
 */
utils.hasValidIps = function(str) {
	if (!str) return false;
	return str.split(',').every(function (ip) { return utils.isIp(ip); }); // every is cool because it return on false or on the last being true
};

/**
 * Evaluates if a number is formatted according to E.164 (+CCABCDEFG...)
 * @param {string} number The number to check
 * @param {boolean} [strict] True if only regular numbers are allowed
 * @returns {boolean} True if valid
 */
utils.isE164 = function(number, strict) {
	if (arguments.length == 1)
		strict = false;

	if (number.indexOf('+45') == 0) {
		// DK
		if (number.match(/^\+45[2-9]\d{7}$/) != null) return true;
		if (number.match(/^\+45(112|114|118|18\d\d|16\d\d\d)$/) != null) return !strict;
		return false;

	} else if (number.indexOf('+46') == 0) {
		// SE
		return number.match(/^\+46\d{7,9}$/) != null;

	} else if (number.indexOf('+47') == 0) {
		// NO
		if (number.match(/^\+470[2-9]\d{3}$/) != null) return true;
		return number.match(/^\+47\d{8}$/) != null;

	} else {
		return number.match(/^\+\d{7,}$/) != null; // Rest of world
	}
};

/**
 * Validate an email address for correct formatting
 * @param {String} email Email address to validate
 * @param {Boolean} [empty] If no address is considered valid
 * @returns {Boolean} True if valid
 */
utils.isEmailAddress = function(email, empty) {
	if (empty && typeof empty === 'boolean' && empty && !email) { return true; }
	return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
};

/**
 * Parse a query string into a key/value object
 * @param {String} queryString 
 * @returns {Object} Key/Value of parsed query string
 */
utils.parseQuery = (queryString) => {
	const query = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=');
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}
	return query;
};


//noinspection JSUnusedGlobalSymbols
utils.ISO3166 = {
	/**
	 * Check if a string contains a valid ISO3166 2 letter country code
	 * @param {string} str 2 letter country code to lookup
	 * @returns {boolean} True if valid
	 */
	exists: function (str) {
		return utils.ISO3166.country[str] != null;
	},

	/**
	 * Converts the name of a country to it's ISO3166 code
	 * @param {string} countryName Country name to lookup
	 * @returns {*} ISO3166 2-char country code
	 */
	getISO: function (countryName) {
		for (let key in utils.ISO3166.country) {
			if (utils.ISO3166.country[key].toLowerCase() == countryName.toLowerCase()) {
				return key;
			}
		}
		return null;
	},

	/**
	 * Return all country codes
	 * @return {Array} Array of 2-char country codes
	 */
	getCodes: function() {
		return Object.keys(utils.ISO3166.country);
	},

	/** ISO3166 codes - the 2 letter code is the key, the country name is the value */
	country: {
		AF: 'Afghanistan', AL: 'Albania', DZ: 'Algeria', AS: 'American Samoa', AD: 'Andorra', AO: 'Angola', AI: 'Anguilla', AQ: 'Antarctica',
		AG: 'Antigua & Barbuda', AR: 'Argentina', AM: 'Armenia', AW: 'Aruba', AU: 'Australia', AT: 'Austria', AZ: 'Azerbaijan', BS: 'Bahamas',
		BH: 'Bahrain', BD: 'Bangladesh', BB: 'Barbados', BY: 'Belarus', BE: 'Belgium', BZ: 'Belize', BJ: 'Benin', BM: 'Bermuda', BT: 'Bhutan',
		BO: 'Bolivia', BA: 'Bosnia & Herzegovina', BW: 'Botswana', BV: 'Bouvet Island', BR: 'Brazil', IO: 'British Indian Ocean Territory',
		VG: 'British Virgin Islands', BN: 'Brunei', BG: 'Bulgaria', BF: 'Burkina Faso', BI: 'Burundi', KH: 'Cambodia', CM: 'Cameroon',
		CA: 'Canada', CV: 'Cape Verde', KY: 'Cayman Islands', CF: 'Central African Republic', TD: 'Chad', CL: 'Chile', CN: 'China', CX: 'Christmas Island',
		CC: 'Cocos (Keeling) Islands', CO: 'Colombia', KM: 'Comoros', CD: 'Congo/Zaire', CG: 'Congo', CK: 'Cook Islands', CR: 'Costa Rica',
		CI: 'Ivory Coast', CU: 'Cuba', CY: 'Cyprus', CZ: 'Czech Republic', DK: 'Denmark', DJ: 'Djibouti', DM: 'Dominica', DO: 'Dominican Republic',
		EC: 'Ecuador', EG: 'Egypt', SV: 'El Salvador', GQ: 'Equatorial Guinea', ER: 'Eritrea', EE: 'Estonia', ET: 'Ethiopia', FO: 'Faroe Islands',
		FK: 'Falkland Islands', FJ: 'Fiji', FI: 'Finland', FR: 'France', GF: 'French Guiana', PF: 'French Polynesia', TF: 'French Southern Territories',
		GA: 'Gabon', GM: 'Gambia', GE: 'Georgia', DE: 'Germany', GH: 'Ghana', GI: 'Gibraltar', GR: 'Greece', GL: 'Greenland', GD: 'Grenada',
		GP: 'Guadeloupe', GU: 'Guam', GT: 'Guatemala', GN: 'Guinea', GW: 'Guinea Bissau', GY: 'Guyana', HT: 'Haiti', HM: 'Heard & McDonald Islands',
		VA: 'Vatican City State', HN: 'Honduras', HK: 'Hong Kong', HR: 'Croatia', HU: 'Hungary', IS: 'Iceland', IN: 'India', ID: 'Indonesia', IR: 'Iran',
		IQ: 'Iraq', IE: 'Ireland', IL: 'Israel', IT: 'Italy', JM: 'Jamaica', JP: 'Japan', JO: 'Jordan', KZ: 'Kazakhstan', KE: 'Kenya', KI: 'Kiribati',
		KP: 'North Korea', KR: 'South Korea', KW: 'Kuwait', KG: 'Kyrgyz Republic', LA: 'Laos', LV: 'Latvia', LB: 'Lebanon', LS: 'Lesotho', LR: 'Liberia',
		LY: 'Libya', LI: 'Liechtenstein', LT: 'Lithuania', LU: 'Luxembourg', MF: 'Saint Martin', MO: 'Macau', MK: 'Macedonia', MG: 'Madagascar',
		MW: 'Malawi', MY: 'Malaysia', MV: 'Maldives', ML: 'Mali', MT: 'Malta', MH: 'Marshall Islands', MQ: 'Martinique', MR: 'Mauritania', MU: 'Mauritius',
		YT: 'Mayotte', MX: 'Mexico', FM: 'Micronesia', MD: 'Moldova', MC: 'Monaco', MN: 'Mongolia', MS: 'Montserrat', MA: 'Morocco', MZ: 'Mozambique',
		MM: 'Myanmar', NA: 'Namibia', NR: 'Nauru', NP: 'Nepal', AN: 'Netherlands Antilles', NL: 'Netherlands', NC: 'New Caledonia', NZ: 'New Zealand',
		NI: 'Nicaragua', NE: 'Niger', NG: 'Nigeria', NU: 'Niue', NF: 'Norfolk Island', MP: 'Northern Mariana Islands', NO: 'Norway', OM: 'Oman',
		PK: 'Pakistan', PW: 'Palau', PS: 'Palestine', PA: 'Panama', PG: 'Papua New Guinea', PY: 'Paraguay', PE: 'Peru', PH: 'Philippines',
		PN: 'Pitcairn Island', PL: 'Poland', PT: 'Portugal', PR: 'Puerto Rico', QA: 'Qatar', RE: 'Reunion', RO: 'Romania', RU: 'Russia', RW: 'Rwanda',
		RS: 'Serbia', SX: 'Sint Maarten', SH: 'St. Helena', KN: 'St. Kitts & Nevis', LC: 'St. Lucia', PM: 'St. Pierre & Miquelon', VC: 'St. Vincent & the Grenadines',
		WS: 'Samoa', SM: 'San Marino', ST: 'Sao Tome & Principe', SA: 'Saudi Arabia', SN: 'Senegal', ME: 'Montenegro', SC: 'Seychelles',
		SL: 'Sierra Leone', SG: 'Singapore', SK: 'Slovakia', SI: 'Slovenia', SB: 'Solomon Islands', SO: 'Somalia', ZA: 'South Africa', GS: 'South Georgia & the South Sandwich Islands',
		ES: 'Spain', LK: 'Sri Lanka', SD: 'Sudan', SR: 'Suriname', SJ: 'Svalbard & Jan Mayen Islands', SS: 'South Sudan', SZ: 'Swaziland', SE: 'Sweden',
		CH: 'Switzerland', SY: 'Syria', TW: 'Taiwan', TJ: 'Tajikistan', TZ: 'Tanzania', TH: 'Thailand', TL: 'East Timor', TG: 'Togo', TK: 'Tokelau',
		TO: 'Tonga', TT: 'Trinidad & Tobago', TN: 'Tunisia', TR: 'Turkey', TM: 'Turkmenistan', TC: 'Turks & Caicos Islands', TV: 'Tuvalu', VI: 'US Virgin Islands',
		UG: 'Uganda', UA: 'Ukraine', AE: 'United Arab Emirates', GB: 'United Kingdom', UM: 'United States Minor Outlying Islands', US: 'USA',
		UY: 'Uruguay', UZ: 'Uzbekistan', VU: 'Vanuatu', VE: 'Venezuela', VN: 'Vietnam', WF: 'Wallis & Futuna Islands', EH: 'Western Sahara',
		YE: 'Yemen', ZM: 'Zambia', ZW: 'Zimbabwe', XX: 'Satellite/special service', UN: 'United nations', 'FF': 'Free phone 800', 'XY': 'USA - Alaska',
		XK: 'Kosovo'
	},

	/** Prefix to ISO3166-2 country code */
	prefix: {
		'+1':'US','+1242':'BS','+1246':'BB','+1264':'AI','+1268':'AG','+1284':'VG','+1340':'VI','+1345':'KY','+1441':'BM','+1473':'GD','+1599':'MF','+1649':'TC','+1664':'MS','+1670':'MP','+1671':'GU','+1684':'AS','+1758':'LC','+1767':'DM',
		'+1784':'VC','+1809':'DO','+1868':'TT','+1869':'KN','+1876':'JM','+20':'EG','+212':'MA','+213':'DZ','+216':'TN','+218':'LY','+220':'GM','+221':'SN','+222':'MR','+223':'ML','+224':'GN','+225':'CI','+226':'BF','+227':'NE','+228':'TG',
		'+229':'BJ','+230':'MU','+231':'LR','+232':'SL','+233':'GH','+234':'NG','+235':'TD','+236':'CF','+237':'CM','+238':'CV','+239':'ST','+240':'GQ','+241':'GA','+242':'CG','+243':'CD','+244':'AO','+245':'GW','+248':'SC','+249':'SD',
		'+250':'RW','+251':'ET','+252':'SO','+253':'DJ','+254':'KE','+255':'TZ','+256':'UG','+257':'BI','+258':'MZ','+260':'ZM','+261':'MG','+262':'YT','+263':'ZW','+264':'NA','+265':'MW','+266':'LS','+267':'BW','+268':'SZ','+269':'KM',
		'+27':'ZA','+290':'SH','+291':'ER','+297':'AW','+298':'FO','+299':'GL','+30':'GR','+31':'NL','+32':'BE','+33':'FR','+34':'ES','+350':'GI','+351':'PT','+352':'LU','+353':'IE','+354':'IS','+355':'AL','+356':'MT','+357':'CY','+358':'FI',
		'+359':'BG','+36':'HU','+370':'LT','+371':'LV','+372':'EE','+373':'MD','+374':'AM','+375':'BY','+376':'AD','+377':'MC','+378':'SM','+380':'UA','+381':'RS','+382':'ME','+385':'HR','+386':'SI','+387':'BA','+389':'MK','+39':'IT',
		'+40':'RO','+405':'EH','+41':'CH','+420':'CZ','+421':'SK','+423':'LI','+43':'AT','+44':'GB','+45':'DK','+46':'SE','+47':'NO','+48':'PL','+49':'DE','+500':'FK','+501':'BZ','+502':'GT','+503':'SV','+504':'HN','+505':'NI',
		'+506':'CR','+507':'PA','+508':'PM','+509':'HT','+51':'PE','+52':'MX','+53':'CU','+54':'AR','+55':'BR','+56':'CL','+57':'CO','+58':'VE','+590':'GP','+591':'BO','+592':'GY','+593':'EC','+595':'PY','+597':'SR','+598':'UY','+599':'AN',
		'+60':'MY','+61':'AU','+62':'ID','+63':'PH','+64':'NZ','+65':'SG','+66':'TH','+670':'TL','+672':'NF','+673':'BN','+674':'NR','+675':'PG','+676':'TO','+677':'SB','+678':'VU','+679':'FJ','+680':'PW','+681':'WF',
		'+682':'CK','+683':'NU','+685':'WS','+686':'KI','+687':'NC','+688':'TV','+689':'PF','+690':'TK','+691':'FM','+692':'MH','+7':'RU','+77':'KZ','+81':'JP','+82':'KR','+84':'VN','+850':'KP','+852':'HK','+853':'MO','+855':'KH','+856':'LA',
		'+86':'CN','+870':'PN','+880':'BD','+886':'TW','+90':'TR','+91':'IN','+92':'PK','+93':'AF','+94':'LK','+95':'MM','+960':'MV','+961':'LB','+962':'JO','+963':'SY','+964':'IQ','+965':'KW','+966':'SA','+967':'YE','+968':'OM','+970':'PS','+971':'AE',
		'+972':'IL','+973':'BH','+974':'QA','+975':'BT','+976':'MN','+977':'NP','+98':'IR','+992':'TJ','+993':'TM','+994':'AZ','+995':'GE','+996':'KG','+998':'UZ',
		'+596':'MQ','+594':'GF','+247':'SH','+211':'SS','+246':'IO','+882':'XX','+881':'XX','+883120':'XX','+888':'UN','+800':'FF','+87810':'XX',
		'+1787':'PR','+1939':'PR',
		'+1250232':'CA','+1250233':'CA','+1250234':'CA','+1250235':'CA','+1250237':'CA','+1250239':'CA','+1250471':'CA','+1250500':'CA','+1250771':'CA',
		'+1250772':'CA','+1250773':'CA','+1250774':'CA','+1250775':'CA','+1250776':'CA','+1250779':'CA','+1867':'CA','+1204':'CA','+1226':'CA','+1236':'CA',
		'+1249':'CA','+1250':'CA','+1289':'CA','+1306':'CA','+1343':'CA','+1365':'CA','+1403':'CA','+1416':'CA','+1418':'CA','+1431':'CA','+1437':'CA',
		'+1438':'CA','+1450':'CA','+1506':'CA','+1514':'CA','+1519':'CA','+1579':'CA','+1581':'CA','+1587':'CA','+1600':'CA','+1604':'CA','+1613':'CA',
		'+1639':'CA','+1647':'CA','+1705':'CA','+1709':'CA','+1778':'CA','+1780':'CA','+1807':'CA','+1819':'CA','+1873':'CA','+1902':'CA','+1905':'CA',
		'+1721':'SX','+1907':'XY',
		'+383':'XK'
	}
};

/**
 * Regions with the countries that belongs to them
 */
utils.regions = {
	euNordic: ['AL','AD','AT','BE','BA','BG','HR','CY','CZ','EE','FO','FI','FR','DE','GR','GL','HU','IS','IE','IT','RS','LV','LI','LT','LU','MK','MT','ME','NL','NO','PL','PT','RO','SK','SI','ES','SE','CH','GB','GI','GF','GP','MC','MQ','SM','XK'],
	restOfEurope: ['BY','GE','MD','UA'],
	world1: ['BM','CA','PM','US','PR','VI','XY','TR','TH'],
	world2: ['DZ','AU','BH','BD','BW','CN','ET','GH','HK','IN','JM','AI','AW','BB','KY','DM','GD','HT','WS','KN','LC','VC','JP','MW','MY','MZ','NP','PK','PH','SG','ZA','KR','TW','UG','VN'],
	world3: ['AF','AO','AG','AM','AZ','BS','BZ','BJ','BO','BR','VG','BN','BF','BI','KH','CM','CV','CF','TD','CL','CO','CD','CR','CU','EC','EG','SV','GQ','FJ','GM','GT','GY','HT','ID','IR','IQ','IL','CI','JO','KZ','KE','KW','KG','LA','LB','LS','LR','MO','MG','MV','ML','MU','MX','MN','MA','NA','NZ','NI','NE','NG','OM','PS','PA','PG','PY','PE','QA','RU','SA','SN','SL','LK','SD','SZ','SY','TJ','TZ','TL','TG','TT','TN','TM','AE','UY','UZ','VE','YE','ZM','ZW']
};

utils.zipName = {
	555: 'Scanning',
	800: 'Høje Taastrup',
	877: 'København C',
	892: 'Sjælland USF P',
	893: 'Sjælland USF B',
	894: 'Udbetaling',
	897: 'eBrevsprækken',
	899: 'Kommuneservice',
	900: 'København C',
	910: 'København C',
	917: 'Københavns Pakkecenter',
	918: 'Københavns Pakke BRC',
	919: 'Returprint BRC',
	929: 'København C',
	960: 'Internationalt Postcenter',
	999: 'København C',
	1000: 'København K',
	1001: 'København K',
	1002: 'København K',
	1003: 'København K',
	1004: 'København K',
	1005: 'København K',
	1006: 'København K',
	1007: 'København K',
	1008: 'København K',
	1009: 'København K',
	1010: 'København K',
	1011: 'København K',
	1012: 'København K',
	1013: 'København K',
	1014: 'København K',
	1015: 'København K',
	1016: 'København K',
	1017: 'København K',
	1018: 'København K',
	1019: 'København K',
	1020: 'København K',
	1021: 'København K',
	1022: 'København K',
	1023: 'København K',
	1024: 'København K',
	1025: 'København K',
	1026: 'København K',
	1045: 'København K',
	1050: 'København K',
	1051: 'København K',
	1052: 'København K',
	1053: 'København K',
	1054: 'København K',
	1055: 'København K',
	1056: 'København K',
	1057: 'København K',
	1058: 'København K',
	1059: 'København K',
	1060: 'København K',
	1061: 'København K',
	1062: 'København K',
	1063: 'København K',
	1064: 'København K',
	1065: 'København K',
	1066: 'København K',
	1067: 'København K',
	1068: 'København K',
	1069: 'København K',
	1070: 'København K',
	1071: 'København K',
	1072: 'København K',
	1073: 'København K',
	1074: 'København K',
	1092: 'København K',
	1093: 'København K',
	1095: 'København K',
	1098: 'København K',
	1100: 'København K',
	1101: 'København K',
	1102: 'København K',
	1103: 'København K',
	1104: 'København K',
	1105: 'København K',
	1106: 'København K',
	1107: 'København K',
	1110: 'København K',
	1111: 'København K',
	1112: 'København K',
	1113: 'København K',
	1114: 'København K',
	1115: 'København K',
	1116: 'København K',
	1117: 'København K',
	1118: 'København K',
	1119: 'København K',
	1120: 'København K',
	1121: 'København K',
	1122: 'København K',
	1123: 'København K',
	1124: 'København K',
	1125: 'København K',
	1126: 'København K',
	1127: 'København K',
	1128: 'København K',
	1129: 'København K',
	1130: 'København K',
	1131: 'København K',
	1140: 'København K',
	1147: 'København K',
	1148: 'København K',
	1150: 'København K',
	1151: 'København K',
	1152: 'København K',
	1153: 'København K',
	1154: 'København K',
	1155: 'København K',
	1156: 'København K',
	1157: 'København K',
	1158: 'København K',
	1159: 'København K',
	1160: 'København K',
	1161: 'København K',
	1162: 'København K',
	1163: 'København K',
	1164: 'København K',
	1165: 'København K',
	1166: 'København K',
	1167: 'København K',
	1168: 'København K',
	1169: 'København K',
	1170: 'København K',
	1171: 'København K',
	1172: 'København K',
	1173: 'København K',
	1174: 'København K',
	1175: 'København K',
	1200: 'København K',
	1201: 'København K',
	1202: 'København K',
	1203: 'København K',
	1204: 'København K',
	1205: 'København K',
	1206: 'København K',
	1207: 'København K',
	1208: 'København K',
	1209: 'København K',
	1210: 'København K',
	1211: 'København K',
	1212: 'København K',
	1213: 'København K',
	1214: 'København K',
	1215: 'København K',
	1216: 'København K',
	1217: 'København K',
	1218: 'København K',
	1219: 'København K',
	1220: 'København K',
	1221: 'København K',
	1240: 'København K',
	1250: 'København K',
	1251: 'København K',
	1252: 'København K',
	1253: 'København K',
	1254: 'København K',
	1255: 'København K',
	1256: 'København K',
	1257: 'København K',
	1258: 'København K',
	1259: 'København K',
	1260: 'København K',
	1261: 'København K',
	1263: 'København K',
	1264: 'København K',
	1265: 'København K',
	1266: 'København K',
	1267: 'København K',
	1268: 'København K',
	1270: 'København K',
	1271: 'København K',
	1291: 'København K',
	1300: 'København K',
	1301: 'København K',
	1302: 'København K',
	1303: 'København K',
	1304: 'København K',
	1306: 'København K',
	1307: 'København K',
	1308: 'København K',
	1309: 'København K',
	1310: 'København K',
	1311: 'København K',
	1312: 'København K',
	1313: 'København K',
	1314: 'København K',
	1315: 'København K',
	1316: 'København K',
	1317: 'København K',
	1318: 'København K',
	1319: 'København K',
	1320: 'København K',
	1321: 'København K',
	1322: 'København K',
	1323: 'København K',
	1324: 'København K',
	1325: 'København K',
	1326: 'København K',
	1327: 'København K',
	1328: 'København K',
	1329: 'København K',
	1349: 'København K',
	1350: 'København K',
	1352: 'København K',
	1353: 'København K',
	1354: 'København K',
	1355: 'København K',
	1356: 'København K',
	1357: 'København K',
	1358: 'København K',
	1359: 'København K',
	1360: 'København K',
	1361: 'København K',
	1362: 'København K',
	1363: 'København K',
	1364: 'København K',
	1365: 'København K',
	1366: 'København K',
	1367: 'København K',
	1368: 'København K',
	1369: 'København K',
	1370: 'København K',
	1371: 'København K',
	1400: 'København K',
	1401: 'København K',
	1402: 'København K',
	1403: 'København K',
	1404: 'København K',
	1406: 'København K',
	1407: 'København K',
	1408: 'København K',
	1409: 'København K',
	1410: 'København K',
	1411: 'København K',
	1412: 'København K',
	1413: 'København K',
	1414: 'København K',
	1415: 'København K',
	1416: 'København K',
	1417: 'København K',
	1418: 'København K',
	1419: 'København K',
	1420: 'København K',
	1421: 'København K',
	1422: 'København K',
	1423: 'København K',
	1424: 'København K',
	1425: 'København K',
	1426: 'København K',
	1427: 'København K',
	1428: 'København K',
	1429: 'København K',
	1430: 'København K',
	1431: 'København K',
	1432: 'København K',
	1433: 'København K',
	1434: 'København K',
	1435: 'København K',
	1436: 'København K',
	1437: 'København K',
	1438: 'København K',
	1439: 'København K',
	1440: 'København K',
	1441: 'København K',
	1448: 'København K',
	1450: 'København K',
	1451: 'København K',
	1452: 'København K',
	1453: 'København K',
	1454: 'København K',
	1455: 'København K',
	1456: 'København K',
	1457: 'København K',
	1458: 'København K',
	1459: 'København K',
	1460: 'København K',
	1461: 'København K',
	1462: 'København K',
	1463: 'København K',
	1464: 'København K',
	1465: 'København K',
	1466: 'København K',
	1467: 'København K',
	1468: 'København K',
	1470: 'København K',
	1471: 'København K',
	1472: 'København K',
	1473: 'København K',
	1500: 'København V',
	1501: 'København V',
	1502: 'København V',
	1503: 'København V',
	1504: 'København V',
	1505: 'København V',
	1506: 'København V',
	1507: 'København V',
	1508: 'København V',
	1509: 'København V',
	1510: 'København V',
	1512: 'Returpost',
	1513: 'Centraltastning',
	1532: 'København V',
	1533: 'København V',
	1550: 'København V',
	1551: 'København V',
	1552: 'København V',
	1553: 'København V',
	1554: 'København V',
	1555: 'København V',
	1556: 'København V',
	1557: 'København V',
	1558: 'København V',
	1559: 'København V',
	1560: 'København V',
	1561: 'København V',
	1562: 'København V',
	1563: 'København V',
	1564: 'København V',
	1566: 'København V',
	1567: 'København V',
	1568: 'København V',
	1569: 'København V',
	1570: 'København V',
	1571: 'København V',
	1572: 'København V',
	1573: 'København V',
	1574: 'København V',
	1575: 'København V',
	1576: 'København V',
	1577: 'København V',
	1592: 'København V',
	1599: 'København V',
	1600: 'København V',
	1601: 'København V',
	1602: 'København V',
	1603: 'København V',
	1604: 'København V',
	1605: 'København V',
	1606: 'København V',
	1607: 'København V',
	1608: 'København V',
	1609: 'København V',
	1610: 'København V',
	1611: 'København V',
	1612: 'København V',
	1613: 'København V',
	1614: 'København V',
	1615: 'København V',
	1616: 'København V',
	1617: 'København V',
	1618: 'København V',
	1619: 'København V',
	1620: 'København V',
	1621: 'København V',
	1622: 'København V',
	1623: 'København V',
	1624: 'København V',
	1630: 'København V',
	1631: 'København V',
	1632: 'København V',
	1633: 'København V',
	1634: 'København V',
	1635: 'København V',
	1640: 'København V',
	1650: 'København V',
	1651: 'København V',
	1652: 'København V',
	1653: 'København V',
	1654: 'København V',
	1655: 'København V',
	1656: 'København V',
	1657: 'København V',
	1658: 'København V',
	1659: 'København V',
	1660: 'København V',
	1661: 'København V',
	1662: 'København V',
	1663: 'København V',
	1664: 'København V',
	1665: 'København V',
	1666: 'København V',
	1667: 'København V',
	1668: 'København V',
	1669: 'København V',
	1670: 'København V',
	1671: 'København V',
	1672: 'København V',
	1673: 'København V',
	1674: 'København V',
	1675: 'København V',
	1676: 'København V',
	1677: 'København V',
	1699: 'København V',
	1700: 'København V',
	1701: 'København V',
	1702: 'København V',
	1703: 'København V',
	1704: 'København V',
	1705: 'København V',
	1706: 'København V',
	1707: 'København V',
	1708: 'København V',
	1709: 'København V',
	1710: 'København V',
	1711: 'København V',
	1712: 'København V',
	1713: 'København V',
	1714: 'København V',
	1715: 'København V',
	1716: 'København V',
	1717: 'København V',
	1718: 'København V',
	1719: 'København V',
	1720: 'København V',
	1721: 'København V',
	1722: 'København V',
	1723: 'København V',
	1724: 'København V',
	1725: 'København V',
	1726: 'København V',
	1727: 'København V',
	1728: 'København V',
	1729: 'København V',
	1730: 'København V',
	1731: 'København V',
	1732: 'København V',
	1733: 'København V',
	1734: 'København V',
	1735: 'København V',
	1736: 'København V',
	1737: 'København V',
	1738: 'København V',
	1739: 'København V',
	1749: 'København V',
	1750: 'København V',
	1751: 'København V',
	1752: 'København V',
	1753: 'København V',
	1754: 'København V',
	1755: 'København V',
	1756: 'København V',
	1757: 'København V',
	1758: 'København V',
	1759: 'København V',
	1760: 'København V',
	1761: 'København V',
	1762: 'København V',
	1763: 'København V',
	1764: 'København V',
	1765: 'København V',
	1766: 'København V',
	1770: 'København V',
	1771: 'København V',
	1772: 'København V',
	1773: 'København V',
	1774: 'København V',
	1775: 'København V',
	1777: 'København V',
	1780: 'København V',
	1782: 'København V',
	1785: 'København V',
	1786: 'København V',
	1787: 'København V',
	1790: 'København V',
	1799: 'København V',
	1800: 'Frederiksberg C',
	1801: 'Frederiksberg C',
	1802: 'Frederiksberg C',
	1803: 'Frederiksberg C',
	1804: 'Frederiksberg C',
	1805: 'Frederiksberg C',
	1806: 'Frederiksberg C',
	1807: 'Frederiksberg C',
	1808: 'Frederiksberg C',
	1809: 'Frederiksberg C',
	1810: 'Frederiksberg C',
	1811: 'Frederiksberg C',
	1812: 'Frederiksberg C',
	1813: 'Frederiksberg C',
	1814: 'Frederiksberg C',
	1815: 'Frederiksberg C',
	1816: 'Frederiksberg C',
	1817: 'Frederiksberg C',
	1818: 'Frederiksberg C',
	1819: 'Frederiksberg C',
	1820: 'Frederiksberg C',
	1822: 'Frederiksberg C',
	1823: 'Frederiksberg C',
	1824: 'Frederiksberg C',
	1825: 'Frederiksberg C',
	1826: 'Frederiksberg C',
	1827: 'Frederiksberg C',
	1828: 'Frederiksberg C',
	1829: 'Frederiksberg C',
	1835: 'Frederiksberg C',
	1850: 'Frederiksberg C',
	1851: 'Frederiksberg C',
	1852: 'Frederiksberg C',
	1853: 'Frederiksberg C',
	1854: 'Frederiksberg C',
	1855: 'Frederiksberg C',
	1856: 'Frederiksberg C',
	1857: 'Frederiksberg C',
	1860: 'Frederiksberg C',
	1861: 'Frederiksberg C',
	1862: 'Frederiksberg C',
	1863: 'Frederiksberg C',
	1864: 'Frederiksberg C',
	1865: 'Frederiksberg C',
	1866: 'Frederiksberg C',
	1867: 'Frederiksberg C',
	1868: 'Frederiksberg C',
	1870: 'Frederiksberg C',
	1871: 'Frederiksberg C',
	1872: 'Frederiksberg C',
	1873: 'Frederiksberg C',
	1874: 'Frederiksberg C',
	1875: 'Frederiksberg C',
	1876: 'Frederiksberg C',
	1877: 'Frederiksberg C',
	1878: 'Frederiksberg C',
	1879: 'Frederiksberg C',
	1900: 'Frederiksberg C',
	1901: 'Frederiksberg C',
	1902: 'Frederiksberg C',
	1903: 'Frederiksberg C',
	1904: 'Frederiksberg C',
	1905: 'Frederiksberg C',
	1906: 'Frederiksberg C',
	1908: 'Frederiksberg C',
	1909: 'Frederiksberg C',
	1910: 'Frederiksberg C',
	1911: 'Frederiksberg C',
	1912: 'Frederiksberg C',
	1913: 'Frederiksberg C',
	1914: 'Frederiksberg C',
	1915: 'Frederiksberg C',
	1916: 'Frederiksberg C',
	1917: 'Frederiksberg C',
	1920: 'Frederiksberg C',
	1921: 'Frederiksberg C',
	1922: 'Frederiksberg C',
	1923: 'Frederiksberg C',
	1924: 'Frederiksberg C',
	1925: 'Frederiksberg C',
	1926: 'Frederiksberg C',
	1927: 'Frederiksberg C',
	1928: 'Frederiksberg C',
	1931: 'Frederiksberg C',
	1950: 'Frederiksberg C',
	1951: 'Frederiksberg C',
	1952: 'Frederiksberg C',
	1953: 'Frederiksberg C',
	1954: 'Frederiksberg C',
	1955: 'Frederiksberg C',
	1956: 'Frederiksberg C',
	1957: 'Frederiksberg C',
	1958: 'Frederiksberg C',
	1959: 'Frederiksberg C',
	1960: 'Frederiksberg C',
	1961: 'Frederiksberg C',
	1962: 'Frederiksberg C',
	1963: 'Frederiksberg C',
	1964: 'Frederiksberg C',
	1965: 'Frederiksberg C',
	1966: 'Frederiksberg C',
	1967: 'Frederiksberg C',
	1970: 'Frederiksberg C',
	1971: 'Frederiksberg C',
	1972: 'Frederiksberg C',
	1973: 'Frederiksberg C',
	1974: 'Frederiksberg C',
	2000: 'Frederiksberg',
	2100: 'København Ø',
	2150: 'Nordhavn',
	2200: 'København N',
	2300: 'København S',
	2400: 'København NV',
	2450: 'København SV',
	2500: 'Valby',
	2600: 'Glostrup',
	2605: 'Brøndby',
	2610: 'Rødovre',
	2620: 'Albertslund',
	2625: 'Vallensbæk',
	2630: 'Taastrup',
	2635: 'Ishøj',
	2640: 'Hedehusene',
	2650: 'Hvidovre',
	2660: 'Brøndby Strand',
	2665: 'Vallensbæk Strand',
	2670: 'Greve',
	2680: 'Solrød Strand',
	2690: 'Karlslunde',
	2700: 'Brønshøj',
	2720: 'Vanløse',
	2730: 'Herlev',
	2740: 'Skovlunde',
	2750: 'Ballerup',
	2760: 'Måløv',
	2765: 'Smørum',
	2770: 'Kastrup',
	2791: 'Dragør',
	2800: 'Kongens Lyngby',
	2820: 'Gentofte',
	2830: 'Virum',
	2840: 'Holte',
	2850: 'Nærum',
	2860: 'Søborg',
	2870: 'Dyssegård',
	2880: 'Bagsværd',
	2900: 'Hellerup',
	2920: 'Charlottenlund',
	2930: 'Klampenborg',
	2942: 'Skodsborg',
	2950: 'Vedbæk',
	2960: 'Rungsted Kyst',
	2970: 'Hørsholm',
	2980: 'Kokkedal',
	2990: 'Nivå',
	3000: 'Helsingør',
	3050: 'Humlebæk',
	3060: 'Espergærde',
	3070: 'Snekkersten',
	3080: 'Tikøb',
	3100: 'Hornbæk',
	3120: 'Dronningmølle',
	3140: 'Ålsgårde',
	3150: 'Hellebæk',
	3200: 'Helsinge',
	3210: 'Vejby',
	3220: 'Tisvildeleje',
	3230: 'Græsted',
	3250: 'Gilleleje',
	3300: 'Frederiksværk',
	3310: 'Ølsted',
	3320: 'Skævinge',
	3330: 'Gørløse',
	3360: 'Liseleje',
	3370: 'Melby',
	3390: 'Hundested',
	3400: 'Hillerød',
	3450: 'Allerød',
	3460: 'Birkerød',
	3480: 'Fredensborg',
	3490: 'Kvistgård',
	3500: 'Værløse',
	3520: 'Farum',
	3540: 'Lynge',
	3550: 'Slangerup',
	3600: 'Frederikssund',
	3630: 'Jægerspris',
	3650: 'Ølstykke',
	3660: 'Stenløse',
	3670: 'Veksø Sjælland',
	3700: 'Rønne',
	3720: 'Aakirkeby',
	3730: 'Nexø',
	3740: 'Svaneke',
	3751: 'Østermarie',
	3760: 'Gudhjem',
	3770: 'Allinge',
	3782: 'Klemensker',
	3790: 'Hasle',
	4000: 'Roskilde',
	4030: 'Tune',
	4040: 'Jyllinge',
	4050: 'Skibby',
	4060: 'Kirke Såby',
	4070: 'Kirke Hyllinge',
	4100: 'Ringsted',
	4129: 'Ringsted',
	4130: 'Viby Sjælland',
	4140: 'Borup',
	4160: 'Herlufmagle',
	4171: 'Glumsø',
	4173: 'Fjenneslev',
	4174: 'Jystrup Midtsj',
	4180: 'Sorø',
	4190: 'Munke Bjergby',
	4200: 'Slagelse',
	4220: 'Korsør',
	4230: 'Skælskør',
	4241: 'Vemmelev',
	4242: 'Boeslunde',
	4243: 'Rude',
	4250: 'Fuglebjerg',
	4261: 'Dalmose',
	4262: 'Sandved',
	4270: 'Høng',
	4281: 'Gørlev',
	4291: 'Ruds Vedby',
	4293: 'Dianalund',
	4295: 'Stenlille',
	4296: 'Nyrup',
	4300: 'Holbæk',
	4320: 'Lejre',
	4330: 'Hvalsø',
	4340: 'Tølløse',
	4350: 'Ugerløse',
	4360: 'Kirke Eskilstrup',
	4370: 'Store Merløse',
	4390: 'Vipperød',
	4400: 'Kalundborg',
	4420: 'Regstrup',
	4440: 'Mørkøv',
	4450: 'Jyderup',
	4460: 'Snertinge',
	4470: 'Svebølle',
	4480: 'Store Fuglede',
	4490: 'Jerslev Sjælland',
	4500: 'Nykøbing Sj',
	4520: 'Svinninge',
	4532: 'Gislinge',
	4534: 'Hørve',
	4540: 'Fårevejle',
	4550: 'Asnæs',
	4560: 'Vig',
	4571: 'Grevinge',
	4572: 'Nørre Asmindrup',
	4573: 'Højby',
	4581: 'Rørvig',
	4583: 'Sjællands Odde',
	4591: 'Føllenslev',
	4592: 'Sejerø',
	4593: 'Eskebjerg',
	4600: 'Køge',
	4621: 'Gadstrup',
	4622: 'Havdrup',
	4623: 'Lille Skensved',
	4632: 'Bjæverskov',
	4640: 'Faxe',
	4652: 'Hårlev',
	4653: 'Karise',
	4654: 'Faxe Ladeplads',
	4660: 'Store Heddinge',
	4671: 'Strøby',
	4672: 'Klippinge',
	4673: 'Rødvig Stevns',
	4681: 'Herfølge',
	4682: 'Tureby',
	4683: 'Rønnede',
	4684: 'Holmegaard',
	4690: 'Haslev',
	4700: 'Næstved',
	4720: 'Præstø',
	4733: 'Tappernøje',
	4735: 'Mern',
	4736: 'Karrebæksminde',
	4750: 'Lundby',
	4760: 'Vordingborg',
	4771: 'Kalvehave',
	4772: 'Langebæk',
	4773: 'Stensved',
	4780: 'Stege',
	4791: 'Borre',
	4792: 'Askeby',
	4793: 'Bogø By',
	4800: 'Nykøbing F',
	4840: 'Nørre Alslev',
	4850: 'Stubbekøbing',
	4862: 'Guldborg',
	4863: 'Eskilstrup',
	4871: 'Horbelev',
	4872: 'Idestrup',
	4873: 'Væggerløse',
	4874: 'Gedser',
	4880: 'Nysted',
	4891: 'Toreby L',
	4892: 'Kettinge',
	4894: 'Øster Ulslev',
	4895: 'Errindlev',
	4900: 'Nakskov',
	4912: 'Harpelunde',
	4913: 'Horslunde',
	4920: 'Søllested',
	4930: 'Maribo',
	4941: 'Bandholm',
	4943: 'Torrig L',
	4944: 'Fejø',
	4951: 'Nørreballe',
	4952: 'Stokkemarke',
	4953: 'Vesterborg',
	4960: 'Holeby',
	4970: 'Rødby',
	4983: 'Dannemare',
	4990: 'Sakskøbing',
	4992: 'Midtsjælland USF P',
	5000: 'Odense C',
	5029: 'Odense C',
	5100: 'Odense C',
	5200: 'Odense V',
	5210: 'Odense NV',
	5220: 'Odense SØ',
	5230: 'Odense M',
	5240: 'Odense NØ',
	5250: 'Odense SV',
	5260: 'Odense S',
	5270: 'Odense N',
	5290: 'Marslev',
	5300: 'Kerteminde',
	5320: 'Agedrup',
	5330: 'Munkebo',
	5350: 'Rynkeby',
	5370: 'Mesinge',
	5380: 'Dalby',
	5390: 'Martofte',
	5400: 'Bogense',
	5450: 'Otterup',
	5462: 'Morud',
	5463: 'Harndrup',
	5464: 'Brenderup Fyn',
	5466: 'Asperup',
	5471: 'Søndersø',
	5474: 'Veflinge',
	5485: 'Skamby',
	5491: 'Blommenslyst',
	5492: 'Vissenbjerg',
	5500: 'Middelfart',
	5540: 'Ullerslev',
	5550: 'Langeskov',
	5560: 'Aarup',
	5580: 'Nørre Aaby',
	5591: 'Gelsted',
	5592: 'Ejby',
	5600: 'Faaborg',
	5610: 'Assens',
	5620: 'Glamsbjerg',
	5631: 'Ebberup',
	5642: 'Millinge',
	5672: 'Broby',
	5683: 'Haarby',
	5690: 'Tommerup',
	5700: 'Svendborg',
	5750: 'Ringe',
	5762: 'Vester Skerninge',
	5771: 'Stenstrup',
	5772: 'Kværndrup',
	5792: 'Årslev',
	5800: 'Nyborg',
	5853: 'Ørbæk',
	5854: 'Gislev',
	5856: 'Ryslinge',
	5863: 'Ferritslev Fyn',
	5871: 'Frørup',
	5874: 'Hesselager',
	5881: 'Skårup Fyn',
	5882: 'Vejstrup',
	5883: 'Oure',
	5884: 'Gudme',
	5892: 'Gudbjerg Sydfyn',
	5900: 'Rudkøbing',
	5932: 'Humble',
	5935: 'Bagenkop',
	5953: 'Tranekær',
	5960: 'Marstal',
	5970: 'Ærøskøbing',
	5985: 'Søby Ærø',
	6000: 'Kolding',
	6040: 'Egtved',
	6051: 'Almind',
	6052: 'Viuf',
	6064: 'Jordrup',
	6070: 'Christiansfeld',
	6091: 'Bjert',
	6092: 'Sønder Stenderup',
	6093: 'Sjølund',
	6094: 'Hejls',
	6100: 'Haderslev',
	6200: 'Aabenraa',
	6230: 'Rødekro',
	6240: 'Løgumkloster',
	6261: 'Bredebro',
	6270: 'Tønder',
	6280: 'Højer',
	6300: 'Gråsten',
	6310: 'Broager',
	6320: 'Egernsund',
	6330: 'Padborg',
	6340: 'Kruså',
	6360: 'Tinglev',
	6372: 'Bylderup-Bov',
	6392: 'Bolderslev',
	6400: 'Sønderborg',
	6430: 'Nordborg',
	6440: 'Augustenborg',
	6470: 'Sydals',
	6500: 'Vojens',
	6510: 'Gram',
	6520: 'Toftlund',
	6534: 'Agerskov',
	6535: 'Branderup J',
	6541: 'Bevtoft',
	6560: 'Sommersted',
	6580: 'Vamdrup',
	6600: 'Vejen',
	6621: 'Gesten',
	6622: 'Bække',
	6623: 'Vorbasse',
	6630: 'Rødding',
	6640: 'Lunderskov',
	6650: 'Brørup',
	6660: 'Lintrup',
	6670: 'Holsted',
	6682: 'Hovborg',
	6683: 'Føvling',
	6690: 'Gørding',
	6700: 'Esbjerg',
	6701: 'Esbjerg',
	6705: 'Esbjerg Ø',
	6710: 'Esbjerg V',
	6715: 'Esbjerg N',
	6720: 'Fanø',
	6731: 'Tjæreborg',
	6740: 'Bramming',
	6752: 'Glejbjerg',
	6753: 'Agerbæk',
	6760: 'Ribe',
	6771: 'Gredstedbro',
	6780: 'Skærbæk',
	6792: 'Rømø',
	6800: 'Varde',
	6818: 'Årre',
	6823: 'Ansager',
	6830: 'Nørre Nebel',
	6840: 'Oksbøl',
	6851: 'Janderup Vestj',
	6852: 'Billum',
	6853: 'Vejers Strand',
	6854: 'Henne',
	6855: 'Outrup',
	6857: 'Blåvand',
	6862: 'Tistrup',
	6870: 'Ølgod',
	6880: 'Tarm',
	6893: 'Hemmet',
	6900: 'Skjern',
	6920: 'Videbæk',
	6933: 'Kibæk',
	6940: 'Lem St',
	6950: 'Ringkøbing',
	6960: 'Hvide Sande',
	6971: 'Spjald',
	6973: 'Ørnhøj',
	6980: 'Tim',
	6990: 'Ulfborg',
	7000: 'Fredericia',
	7007: 'Fredericia',
	7017: 'Taulov Pakkecenter',
	7018: 'Pakker TLP',
	7029: 'Fredericia',
	7080: 'Børkop',
	7100: 'Vejle',
	7120: 'Vejle Øst',
	7130: 'Juelsminde',
	7140: 'Stouby',
	7150: 'Barrit',
	7160: 'Tørring',
	7171: 'Uldum',
	7173: 'Vonge',
	7182: 'Bredsten',
	7183: 'Randbøl',
	7184: 'Vandel',
	7190: 'Billund',
	7200: 'Grindsted',
	7250: 'Hejnsvig',
	7260: 'Sønder Omme',
	7270: 'Stakroge',
	7280: 'Sønder Felding',
	7300: 'Jelling',
	7321: 'Gadbjerg',
	7323: 'Give',
	7330: 'Brande',
	7361: 'Ejstrupholm',
	7362: 'Hampen',
	7400: 'Herning',
	7429: 'Herning',
	7430: 'Ikast',
	7441: 'Bording',
	7442: 'Engesvang',
	7451: 'Sunds',
	7470: 'Karup J',
	7480: 'Vildbjerg',
	7490: 'Aulum',
	7500: 'Holstebro',
	7540: 'Haderup',
	7550: 'Sørvad',
	7560: 'Hjerm',
	7570: 'Vemb',
	7600: 'Struer',
	7620: 'Lemvig',
	7650: 'Bøvlingbjerg',
	7660: 'Bækmarksbro',
	7673: 'Harboøre',
	7680: 'Thyborøn',
	7700: 'Thisted',
	7730: 'Hanstholm',
	7741: 'Frøstrup',
	7742: 'Vesløs',
	7752: 'Snedsted',
	7755: 'Bedsted Thy',
	7760: 'Hurup Thy',
	7770: 'Vestervig',
	7790: 'Thyholm',
	7800: 'Skive',
	7830: 'Vinderup',
	7840: 'Højslev',
	7850: 'Stoholm Jyll',
	7860: 'Spøttrup',
	7870: 'Roslev',
	7884: 'Fur',
	7900: 'Nykøbing M',
	7950: 'Erslev',
	7960: 'Karby',
	7970: 'Redsted M',
	7980: 'Vils',
	7990: 'Øster Assels',
	7992: 'Sydjylland/Fyn USF P',
	7993: 'Sydjylland/Fyn USF B',
	7996: 'Fakturaservice',
	7997: 'Fakturascanning',
	7998: 'Statsservice',
	7999: 'Kommunepost',
	8000: 'Aarhus C',
	8100: 'Aarhus C',
	8200: 'Aarhus N',
	8210: 'Aarhus V',
	8220: 'Brabrand',
	8229: 'Risskov Ø',
	8230: 'Åbyhøj',
	8240: 'Risskov',
	8245: 'Risskov Ø',
	8250: 'Egå',
	8260: 'Viby J',
	8270: 'Højbjerg',
	8300: 'Odder',
	8305: 'Samsø',
	8310: 'Tranbjerg J',
	8320: 'Mårslet',
	8330: 'Beder',
	8340: 'Malling',
	8350: 'Hundslund',
	8355: 'Solbjerg',
	8361: 'Hasselager',
	8362: 'Hørning',
	8370: 'Hadsten',
	8380: 'Trige',
	8381: 'Tilst',
	8382: 'Hinnerup',
	8400: 'Ebeltoft',
	8410: 'Rønde',
	8420: 'Knebel',
	8444: 'Balle',
	8450: 'Hammel',
	8462: 'Harlev J',
	8464: 'Galten',
	8471: 'Sabro',
	8472: 'Sporup',
	8500: 'Grenaa',
	8520: 'Lystrup',
	8530: 'Hjortshøj',
	8541: 'Skødstrup',
	8543: 'Hornslet',
	8544: 'Mørke',
	8550: 'Ryomgård',
	8560: 'Kolind',
	8570: 'Trustrup',
	8581: 'Nimtofte',
	8585: 'Glesborg',
	8586: 'Ørum Djurs',
	8592: 'Anholt',
	8600: 'Silkeborg',
	8620: 'Kjellerup',
	8632: 'Lemming',
	8641: 'Sorring',
	8643: 'Ans By',
	8653: 'Them',
	8654: 'Bryrup',
	8660: 'Skanderborg',
	8670: 'Låsby',
	8680: 'Ry',
	8700: 'Horsens',
	8721: 'Daugård',
	8722: 'Hedensted',
	8723: 'Løsning',
	8732: 'Hovedgård',
	8740: 'Brædstrup',
	8751: 'Gedved',
	8752: 'Østbirk',
	8762: 'Flemming',
	8763: 'Rask Mølle',
	8765: 'Klovborg',
	8766: 'Nørre Snede',
	8781: 'Stenderup',
	8783: 'Hornsyld',
	8800: 'Viborg',
	8830: 'Tjele',
	8831: 'Løgstrup',
	8832: 'Skals',
	8840: 'Rødkærsbro',
	8850: 'Bjerringbro',
	8860: 'Ulstrup',
	8870: 'Langå',
	8881: 'Thorsø',
	8882: 'Fårvang',
	8883: 'Gjern',
	8900: 'Randers C',
	8920: 'Randers NV',
	8930: 'Randers NØ',
	8940: 'Randers SV',
	8950: 'Ørsted',
	8960: 'Randers SØ',
	8961: 'Allingåbro',
	8963: 'Auning',
	8970: 'Havndal',
	8981: 'Spentrup',
	8983: 'Gjerlev J',
	8990: 'Fårup',
	9000: 'Aalborg',
	9029: 'Aalborg',
	9100: 'Aalborg',
	9200: 'Aalborg SV',
	9210: 'Aalborg SØ',
	9220: 'Aalborg Øst',
	9230: 'Svenstrup J',
	9240: 'Nibe',
	9260: 'Gistrup',
	9270: 'Klarup',
	9280: 'Storvorde',
	9293: 'Kongerslev',
	9300: 'Sæby',
	9310: 'Vodskov',
	9320: 'Hjallerup',
	9330: 'Dronninglund',
	9340: 'Asaa',
	9352: 'Dybvad',
	9362: 'Gandrup',
	9370: 'Hals',
	9380: 'Vestbjerg',
	9381: 'Sulsted',
	9382: 'Tylstrup',
	9400: 'Nørresundby',
	9430: 'Vadum',
	9440: 'Aabybro',
	9460: 'Brovst',
	9480: 'Løkken',
	9490: 'Pandrup',
	9492: 'Blokhus',
	9493: 'Saltum',
	9500: 'Hobro',
	9510: 'Arden',
	9520: 'Skørping',
	9530: 'Støvring',
	9541: 'Suldrup',
	9550: 'Mariager',
	9560: 'Hadsund',
	9574: 'Bælum',
	9575: 'Terndrup',
	9600: 'Aars',
	9610: 'Nørager',
	9620: 'Aalestrup',
	9631: 'Gedsted',
	9632: 'Møldrup',
	9640: 'Farsø',
	9670: 'Løgstør',
	9681: 'Ranum',
	9690: 'Fjerritslev',
	9700: 'Brønderslev',
	9740: 'Jerslev J',
	9750: 'Østervrå',
	9760: 'Vrå',
	9800: 'Hjørring',
	9830: 'Tårs',
	9850: 'Hirtshals',
	9870: 'Sindal',
	9881: 'Bindslev',
	9900: 'Frederikshavn',
	9940: 'Læsø',
	9970: 'Strandby',
	9981: 'Jerup',
	9982: 'Ålbæk',
	9990: 'Skagen',
	9992: 'Jylland USF P',
	9993: 'Jylland USF B',
	9996: 'Fakturaservice',
	9997: 'Fakturascanning',
	9998: 'Borgerservice'
};

/**
 * Array.sort function for arrays of objects. Can sort on any property in the object.
 * @example People.sort(DynamicSort('Name'));
 * @param {string} property The property to sort by, prefix with minus to reverse sort
 * @returns {Function} Sort order
 */
utils.dynamicSort = function(property) {
	let sortOrder = 1;
	if(property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a,b) {
		const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		return result * sortOrder;
	};
};

/**
 * Array.sort function for arrays of objects. Can sort on multiple properties in the object.
 * @example People.sort(DynamicSortMultiple('Name', '-Surname'));
 * @params arguments One or more properties to sort by, prefix with minus to reverse sort
 * @returns {Function} Sort order
 */
utils.dynamicSortMultiple = function() {
	const props = arguments;
	const ds = utils.dynamicSort;
	return function (obj1, obj2) {
		let i = 0, result = 0, numberOfProperties = props.length;
		while(result === 0 && i < numberOfProperties) {
			result = ds(props[i])(obj1, obj2);
			i++;
		}
		return result;
	};
};

/**
 * Generate a string of random upper/lowercase letters and numbers
 * @param {number} length Length of the desired string
 * @returns {string} String of random letters and numbers
 */
utils.randomString = function(length) {
	let buffer = '';
	const d = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	while(length) {
		buffer += d[Math.floor(Math.random() * d.length)];
		length--;
	}
	return buffer;
};

/**
 * Set a value in an object, no matter the depth that it is located at
 * @param {Object} obj The object to work on
 * @param {*} value The value to apply to the field
 * @param {String} path Path to the field, e.g. main.sub.child
 */
utils.setDeepValue = function(obj, value, path) {
	if (typeof path === 'string') {
		//noinspection JSValidateTypes
		path = path.split('.');
	}

	if(path.length > 1){
		//noinspection JSUnresolvedFunction
		const p = path.shift();
		if(obj[p] == null || typeof obj[p] !== 'object') {
			obj[p] = {};
		}
		utils.setDeepValue(obj[p], value, path);
	}else{
		obj[path[0]] = value;
	}
};

/**
 * Get a value from an object, no matter the depth that it is located at
 * @param {Object} obj The object to work on
 * @param {String} path Path to the field, e.g. main.sub.child
 * @returns {*} Value of the field
 */
utils.getDeepValue = function(obj, path) {
	if (typeof path === 'string') {
		//noinspection JSValidateTypes
		path = path.split('.');
	}

	if(path.length > 1){
		//noinspection JSUnresolvedFunction
		const p = path.shift();
		if(obj[p] == null || typeof obj[p] !== 'object') {
			return null;
		}
		return utils.getDeepValue(obj[p], path);
	}else{
		return obj[path[0]];
	}
};

utils.existsDeep = function(obj, path) {
	return utils.getDeepValue(obj, path) != null;
};

/**
 * Capitalize the first letter of the string
 * @param str String to work on
 * @return {string} Result
 */
utils.capitalize = function(str) {
	if (str && str.length > 1) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	} else {
		return str;
	}
};

/**
 * Extracts a fields from an array of objects into a new array, null values are ignored
 * @param {Array} array Array of objects to extract from
 * @param {String} name Fields name from which to extract the value
 * @param {Boolean} [unique] True if only unique values should be returned
 * @param {Boolean} [asString] True if values should be returned as strings (perform .toString() on all values)
 * @returns {Array} Array of the extracted values
 */
utils.getFieldFromArrayOfObjects = function(array, name, unique, asString) {

	if (array == null || array.length == 0) { return array; }
	let res = [];

	array.forEach(function(item) {
		const value = utils.getDeepValue(item, name);
		if (value != null) {
			// Add to list if we do not require unique or if we require unique and it is or if we require unique and its an ObjectID and it is unique
			if (!unique || (unique && res.indexOf(value) == -1)) {
				if (value != null) {
					if (asString) {
						res.push(value.toString());
					} else {
						res.push(value);
					}
				}
			}
		}
	});

	return res;
};

/**
 * Transform an array to a hash
 * @param {Array} data Array to transform
 * @param {String} keyName Name of the field to use as the key
 * @param {String} [valueName] Name of the field to use as the value - if null the whole array item is the value
 * @param {Boolean} [includeNullValues] True to also include array items which value will be null
 * @returns {Object}
 */
utils.arrayToHash = function(data, keyName, valueName, includeNullValues) {
	const res = {};
	if (Array.isArray(data)) {
		data.forEach(function(item) {
			const key = keyName.indexOf('.') == -1 ? item[keyName] : utils.getDeepValue(item, keyName);
			if (key) {
				if (valueName) {
					const value = valueName.indexOf('.') == -1 ? item[valueName] : utils.getDeepValue(item, valueName);
					if (value != null || includeNullValues) {
						res[key.toString()] = value || null;
					}
				} else {
					res[key.toString()] = item;
				}
			}
		});
		return res;
	} else {
		return {};
	}
};

/**
 * Get the index of an object in an array where a field holds a given value
 * @param array The array of objects to search
 * @param field The name of the field to look in
 * @param value The value to match
 * @return {number} Index of the object in the array
 */
utils.getIndexOfInArrayOfObjects = function(array, field, value) {
	for(let i=0; i<array.length; i++) {
		if (array[i][field] == value) { return i; }
	}
	return -1;
};

/**
 * Transform a string query filter to a regular expression that will match each word in the string
 * @param filter String filter
 * @return {RegExp} Regular expression
 */
utils.getRegExFromFilter = function(filter) {
	filter = '^' + filter.trim()
		.toLowerCase()
		.replace(/ {2}/g,' ')
		.replace(/\./g, '\\.')
		.replace(/[[\]|(),$^{}\t]/g, '')
		.split(' ')
		.map(function(t) { return '(?=.*?' + t + ')'; })
		.join('') + '.*$';
	return new RegExp(filter, 'i');
};

/**
 * Evaluates if a number is formatted according to E.164 (+CCABCDEFG...)
 * @param {string} number The number to check
 * @param {boolean} [strict] True if only regular numbers are allowed (3-5 digits numbers not allowed)
 * @returns {boolean} True if valid
 */
utils.isE164 = function(number, strict) {
	if (arguments.length == 1)
		strict = false;

	if (number.indexOf('+45') == 0) {
		// DK
		if (number.match(/^\+45[2-9]\d{7}$/) != null) { return true; }
		if (number.match(/^\+45(112|114|118|18\d\d|16\d\d\d)$/) != null) { return !strict; }
		return false;
	}

	else if (number.indexOf('+46') == 0) {
		// SE
		return number.match(/^\+46\d{7,9}$/) != null;
	}

	else if (number.indexOf('+47') == 0) {
		// NO
		if (number.match(/^\+470[2-9]\d{3}$/) != null) { return true; }
		return number.match(/^\+47\d{8}$/) != null;
	}

	else
	{
		// Rest of world
		return number.match(/^\+\d{7,}$/) != null;
	}
};

/**
 * Check if a number is a phone number, if no + we assume +45, spaces and dashes are ignored (they are okay)
 * @param {String} number Number to validate
 * @param {Boolean} [empty] Is string considered valid if empty - optional
 * @returns {boolean} True if valid
 */
utils.isPhoneNumber = function(number, empty) {
	if (empty && !number) { return true; } // empty string is valid if empty flag is set
	if (!number) { return false; }

	number = number.replace(/[ -]/g, '');
	if (number.indexOf('00') == 0) { number = '+' + number.substr(1); }
	if (number.indexOf('+') != 0) { number = '+45' + number; }
	return utils.isE164(number, true);
};

/**
 * Validate an email address for correct formatting
 * @param {String} email Email address to validate
 * @param {Boolean} [empty] If no address is considered valid
 * @returns {Boolean} True if valid
 */
utils.isEmailAddress = function(email, empty) {
	if (empty && typeof empty === 'boolean' && empty && !email) { return true; }
	return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
};

/**
 * Check if a string contains a valid date
 * @param {*} date Date string to check
 * @return {boolean} True if it is a date
 */
utils.isDateString = function(date) {
	const ts = Date.parse(date);
	return isNaN(ts) == false;
};

/**
 * Ensures that a date is a Date object or null
 * @param {string|null|Date} date Date to fix
 * @return {Date|null}
 */
utils.fixDate = function(date) {
	if (date == null) { return null; }
	if (date instanceof Date) { return date; }
	if (utils.isDateString(date)) {
		return new Date(date);
	} else {
		return null;
	}
};

/**
 * Fix multiple dates (see utils.fixDate)
 * @param {Object} obj Object to check
 * @param {Array} properties Array of property names to fix
 */
utils.fixDates = function(obj, properties) {
	properties.forEach(function(prop) {
		obj[prop] = utils.fixDate(obj[prop]);
	});
};

/**
 * Invoke a function with a rate limiter so that the function is never called more than once every limit time
 * @param {string} tag Unique id of the caller (so we can have multiple rate limiters)
 * @param {number} limit How often the function may be called in milliseconds
 * @param {function} func The function to call
 */
utils.rateLimit = function(tag, limit, func) {
	let now = new Date();
	now = now.getTime();
	if (utils.rateLimitCache[tag] && utils.rateLimitCache[tag].time > now) {
		// Delay invocation
		if (utils.rateLimitCache[tag].timeout) {
			clearTimeout(utils.rateLimitCache[tag].timeout);
		}
		utils.rateLimitCache[tag].timeout = setTimeout(function() {
			func();
			let now = new Date();
			now = now.getTime();
			utils.rateLimitCache[tag] = { time: now + limit, timeout: null };
		}, (utils.rateLimitCache[tag].time - now));
	} else {
		// Invoke now
		utils.rateLimitCache[tag] = { time: now + limit, timeout: null };
		func();
	}
};
utils.rateLimitCache = {};

/**
 * Parses a one line address into its component or as much as can be recognized
 * @param {string} addr Address to parse
 * @return {Object} road, number, floor, door, zip, city
 */
utils.parseAddress = function(addr) {
	const res = {};
	if (typeof addr !== 'string' || addr.length == 0) { return res; }

	// road
	const m1 = addr.match(/^([^0-9,]+)/);
	if (!m1) {
		res.road = addr.replace(/,/g,'').trim();
		return res;
	} else {
		res.road = m1[1].trim();
		addr = addr.substr(res.road.length).trim();
		if (addr.length == 0) { return res; }

		// number
		const m2 = addr.match(/^(\d+[a-zA-Z]?)/);
		if (!m2) { return res; }
		res.number = m2[1].trim().toUpperCase();
		addr = addr.substr(m2[1].length).trim();
		if (addr[0] == ',') { addr = addr.substr(1).trim(); }

		// floor
		const m3 = addr.match(/^([1-9]|[1-9][0-9]|kælder|kælderen|kld|kld\.kl|kl\.|stuen|st|st\.|k[2-9])(\W|$)/i);
		if (m3) {
			res.floor = m3[1].toLowerCase().replace('.','');
			if (['kælder','kælderen','kld','kl'].indexOf(res.floor) != -1) { res.floor = 'kl'; }
			else if (['stuen','st'].indexOf(res.floor) != -1) { res.floor = 'st'; }
			addr = addr.substr(m3[1].length).trim();
			if (addr[0] == ',' || addr[0] == '.') { addr = addr.substr(1).trim(); }
		}

		// zip/city
		const m4 = addr.match(/((\d{3,4}$)|(\d{3,4}) +([\wæøåÆØÅ ]+$))/);
		if (m4) {
			if (m4[3] && m4[4]) {
				res.zip = m4[3].trim();
				res.city = m4[4].trim();
			} else {
				res.zip = m4[2].trim();
			}
			addr = addr.slice(0, -m4[1].length).trim();
		}

		// door
		const m5 = addr.match(/(\W|^)(tv|th|mf|\d{1,4}|til venstre|venstre|til højre|højre|midt for|midt|midtfor|[a-zA-Z])(\W|$)/i);
		if (m5) {
			res.door = m5[2].toLowerCase().replace('.','');
			if (['tv','til venstre','venstre'].indexOf(res.door) != -1) { res.door = 'tv'; }
			else if (['th','til højre','højre'].indexOf(res.door) != -1) { res.door = 'th'; }
			else if (['mf','midt for','midt', 'midtfor'].indexOf(res.door) != -1) { res.door = 'mf'; }
			addr = addr.replace(m5[2],'').replace('.','').replace(',','').trim();
		}

		// If we have a remainder text, and it does not contain banned words, then we guess its a city
		if (!res.city &&
			addr.match(/^[\wæøåÆÅ ]+$/) &&
			['dør', 'værelse', 'sal', 'etage', 'lokale'].every(function(x) { return addr.toLowerCase().indexOf(x) == -1; })
		) {
			res.city = addr.trim();
		}

		return res;
	}
};

utils.imeiFix = function(imei) {
	if (imei.length == 15) { imei = imei.substr(0, 14); }

	let sum = 0;
	for (let i=imei.length - 1; i>-1; i--) {
		if (i%2) {
			const x = Number(imei[i]) * 2;
			sum += x > 9 ? Number(x.toString()[0]) + Number(x.toString()[1]) : x;
		} else {
			sum += Number(imei[i]);
		}
	}
	const checkDigit = 10 - sum % 10;
	return imei + checkDigit.toString();
};

/**
 * Compares 2 dates
 * @param {Date|null} a First date
 * @param {Date|null} b Second date
 * @return {Number} 0 if equal, negative if a is highest, positive if b is highest
 */
utils.dateCompare = function(a, b) {
	if (a == null && b == null) { return 0; }
	if (a == null && b != null) { return 1; }
	if (a != null && b == null) { return -1; }
	if (a instanceof Date && b instanceof Date) {
		if (a.getTime() > b.getTime()) { return -1; }
		if (a.getTime() < b.getTime()) { return 1; 	}
	}
	return 0;
};

/**
 * Return the difference between 2 dates
 * @param {Date} date1 From date
 * @param {Date} date2 To date
 * @param {string} interval Interval to return: YEARS, MONTHS, WEEKS, DAYS, HOURS, MINUTES, SECONDS
 * @return {*} Interval, returns NaN or undefined if it fails
 */
utils.dateDiff = function(date1, date2, interval) {
	let second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7;
	date1 = new Date(date1);
	date2 = new Date(date2);
	let timediff = date2 - date1;
	if (isNaN(timediff)) return NaN;
	switch (interval.toLowerCase()) {
		case 'years': return date2.getFullYear() - date1.getFullYear();
		case 'months': return (( date2.getFullYear() * 12 + date2.getMonth() ) - ( date1.getFullYear() * 12 + date1.getMonth() ));
		case 'weeks'  : return Math.round(timediff / week);
		case 'days'   : return Math.round(timediff / day);
		case 'hours'  : return Math.round(timediff / hour);
		case 'minutes': return Math.round(timediff / minute);
		case 'seconds': return Math.round(timediff / second);
		default: return undefined;
	}
};

/**
 * Check if an array contains a value
 * @param {Array} list Array to look at
 * @param {String|Number|Boolean} value Value to find
 * @return {boolean} True if found
 */
utils.contains = function(list, value) {
	if (!Array.isArray(list) || !value) { return false; }
	return list.indexOf(value) != -1;
};

/**
 * Get information about an IP address and subnet
 * - return object with: address|netmask|netaddress|netbcast+DotQuad|Integer|BinStr and netmaskBits
 * @param {String} addressDotQuad IP Address
 * @param {Number} netmaskBits Netmask bits
 * @constructor
 */
utils.IpInfo = function( addressDotQuad, netmaskBits ) {
	const split = addressDotQuad.split( '.', 4 );
	let byte1 = Math.max( 0, Math.min( 255, parseInt( split[0] ))); /* sanity check: valid values: = 0-255 */
	let byte2 = Math.max( 0, Math.min( 255, parseInt( split[1] )));
	let byte3 = Math.max( 0, Math.min( 255, parseInt( split[2] )));
	let byte4 = Math.max( 0, Math.min( 255, parseInt( split[3] )));
	if( isNaN( byte1 )) {	byte1 = 0;	}
	if( isNaN( byte2 )) {	byte2 = 0;	}
	if( isNaN( byte3 )) {	byte3 = 0;	}
	if( isNaN( byte4 )) {	byte4 = 0;	}
	addressDotQuad = ( byte1 +'.'+ byte2 +'.'+ byte3 +'.'+ byte4 );
	
	this.addressDotQuad = addressDotQuad.toString();
	this.netmaskBits = Math.max( 0, Math.min( 32, parseInt( netmaskBits ))); /* sanity check: valid values: = 0-32 */
	
	this.addressInteger = IPv4_dotquadA_to_intA( this.addressDotQuad );
	//	this.addressDotQuad  = IPv4_intA_to_dotquadA( this.addressInteger );
	this.addressBinStr  = IPv4_intA_to_binstrA( this.addressInteger );
	
	this.netmaskBinStr  = IPv4_bitsNM_to_binstrNM( this.netmaskBits );
	this.netmaskInteger = IPv4_binstrA_to_intA( this.netmaskBinStr );
	this.netmaskDotQuad  = IPv4_intA_to_dotquadA( this.netmaskInteger );
	
	this.netaddressBinStr = IPv4_Calc_netaddrBinStr( this.addressBinStr, this.netmaskBinStr );
	this.netaddressInteger = IPv4_binstrA_to_intA( this.netaddressBinStr );
	this.netaddressDotQuad  = IPv4_intA_to_dotquadA( this.netaddressInteger );
	
	this.netbcastBinStr = IPv4_Calc_netbcastBinStr( this.addressBinStr, this.netmaskBinStr );
	this.netbcastInteger = IPv4_binstrA_to_intA( this.netbcastBinStr );
	this.netbcastDotQuad  = IPv4_intA_to_dotquadA( this.netbcastInteger );
	
	/* In some versions of JavaScript subnet calculators they use bitwise operations to shift the values left. Unfortunately JavaScript converts to a 32-bit signed integer when you mess with bits, which leaves you with the sign + 31 bits. For the first byte this means converting back to an integer results in a negative value for values 128 and higher since the leftmost bit, the sign, becomes 1. Using the 64-bit float allows us to display the integer value to the user. */
	/* dotted-quad IP to integer */
	function IPv4_dotquadA_to_intA( strbits ) {
		const split = strbits.split( '.', 4 );
		const myInt = (
			parseFloat( split[0] * 16777216 )	/* 2^24 */
			+ parseFloat( split[1] * 65536 )		/* 2^16 */
			+ parseFloat( split[2] * 256 )		/* 2^8  */
			+ parseFloat( split[3] )
		);
		return myInt;
	}
	
	/* integer IP to dotted-quad */
	function IPv4_intA_to_dotquadA( strnum ) {
		const byte1 = ( strnum >>> 24 );
		const byte2 = ( strnum >>> 16 ) & 255;
		const byte3 = ( strnum >>>  8 ) & 255;
		const byte4 = strnum & 255;
		return ( byte1 + '.' + byte2 + '.' + byte3 + '.' + byte4 );
	}
	
	/* integer IP to binary string representation */
	function IPv4_intA_to_binstrA( strnum ) {
		let numStr = strnum.toString( 2 ); /* Initialize return value as string */
		const numZeros = 32 - numStr.length; /* Calculate no. of zeros */
		if (numZeros > 0) {	for (let i = 1; i <= numZeros; i++) { numStr = '0' + numStr; }	}
		return numStr;
	}
	
	/* binary string IP to integer representation */
	function IPv4_binstrA_to_intA( binstr ) {
		return parseInt( binstr, 2 );
	}
	
	/* convert # of bits to a string representation of the binary value */
	function IPv4_bitsNM_to_binstrNM( bitsNM ) {
		let bitString = '';
		let numberOfOnes = bitsNM;
		while( numberOfOnes-- ) bitString += '1'; /* fill in ones */
		let numberOfZeros = 32 - bitsNM;
		while( numberOfZeros-- ) bitString += '0'; /* pad remaining with zeros */
		return bitString;
	}
	
	/* The IPv4_Calc_* functions operate on string representations of the binary value because I don't trust JavaScript's sign + 31-bit bitwise functions. */
	/* logical AND between address & netmask */
	function IPv4_Calc_netaddrBinStr( addressBinStr, netmaskBinStr ) {
		let netaddressBinStr = '', aBit = 0, nmBit = 0;
		for( let pos = 0; pos < 32; pos ++ ) {
			aBit = addressBinStr.substr( pos, 1 );
			nmBit = netmaskBinStr.substr( pos, 1 );
			if( aBit == nmBit ) {	netaddressBinStr += aBit.toString();	}
			else{	netaddressBinStr += '0';	}
		}
		return netaddressBinStr;
	}
	
	/* logical OR between address & NOT netmask */
	function IPv4_Calc_netbcastBinStr( addressBinStr, netmaskBinStr ) {
		let netbcastBinStr = '', aBit = 0, nmBit = 0;
		for (let pos = 0; pos < 32; pos ++) {
			aBit = parseInt( addressBinStr.substr( pos, 1 ));
			nmBit = parseInt( netmaskBinStr.substr( pos, 1 ));
			
			if ( nmBit ) { nmBit = 0; }	/* flip netmask bits */
			else { nmBit = 1; }
			
			if ( aBit || nmBit ) {	netbcastBinStr += '1'; }
			else { netbcastBinStr += '0'; }
		}
		return netbcastBinStr;
	}
	
	/* included as an example alternative for converting 8-bit bytes to an integer in IPv4_dotquadA_to_intA */
	// function IPv4_BitShiftLeft( mask, bits ) {
	// 	return ( mask * Math.pow( 2, bits ) );
	// }
	
	/* used for display purposes */
	// function IPv4_BinaryDotQuad( binaryString ) {
	// 	return ( binaryString.substr( 0, 8 ) +'.'+ binaryString.substr( 8, 8 ) +'.'+ binaryString.substr( 16, 8 ) +'.'+ binaryString.substr( 24, 8 ) );
	// }
};

export default utils;