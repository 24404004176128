<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<div>
			<b-btn v-if="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" :editMode="dashboards.editMode">
				<glyph-icon icon="64-power" color="#fff" size="14" style="flex-shrink:0;margin-top:-2px;margin-right:2px;"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ data.title }}</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="settings-box">
				<b-form-group :label="$t('switchvar.panelName')">
					<b-form-input type="text" maxlength="64" size="sm" v-model="data.title" :required="true"></b-form-input>
				</b-form-group>

				<b-form-group :label="$t('switchvar.editHeader')">
					<b-list-group>
						<draggable :list="data.variables" class="list-group" ghost-class="ghost">
							<b-list-group-item class="d-flex justify-content-between align-items-center" style="margin-bottom: 5px" v-for="item in data.variables" :key="item._id">
								<b-container style="padding:0">
									<b-row>
										<b-col style="background:var(--pn-primary-2);margin-top:-5px;margin-left:10px;margin-right:10px;height:20px;cursor:move;border-radius:3px 3px 0 0;margin-bottom:5px">
											<glyph-icon icon="750-resize-vertical" size="20" color="#fff" style="float:left;margin-left:-15px;margin-top:-4px"></glyph-icon>
											<b-btn size="sm" variant="danger" class="delete-item-btn" style="margin-right:-15px;float:right;height:20px;border-bottom-right-radius:0 !important" @click="removeItem(item)">
												<glyph-icon icon="17-bin" size="16" color="#fff" style="margin-top:1px"></glyph-icon>
											</b-btn>
											<div class="edit-title dotdotdot">{{ item.label || $t('switchvar.newVariable') }}</div>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-form-group :label="$t('switchvar.label')">
												<b-form-input type="text" maxlength="64" size="sm" v-model="item.label"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group :label="$t('switchvar.variableName')">
												<b-form-input :id="item._id" type="text" maxlength="64" size="sm" v-model="item.name"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-form-group :label="$t('switchvar.type')">
												<b-form-select v-model="item.type" :options="types" size="sm"></b-form-select>
											</b-form-group>
										</b-col>
										<b-col>
											<b-form-group :label="$t('switchvar.owner')">
												<b-form-select v-model="item.owner" :options="ownerTypes" size="sm"></b-form-select>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="item.type=='VARIABLE'">
										<b-col>
											<b-form-group :label="$t('switchvar.values')" style="margin-bottom:0 !important">
												<b-list-group>
													<b-list-group-item class="d-flex justify-content-between align-items-center employee-item" v-for="(val,index) in item.values" :key="index">
														<div style="display:flex">
															<b-btn style="margin-right:5px" size="sm" variant="danger" class="delete-item-btn" @click="removeValue(item, index)">
																<glyph-icon icon="17-bin" size="15" color="#fff" style="margin-top:1px"></glyph-icon>
															</b-btn>
															<b-form-input style="width:300px" type="text" maxlength="64" size="sm" placeholder="Værdi" v-model="item.values[index]"></b-form-input>
														</div>
													</b-list-group-item>
													<b-list-group-item disabled v-if="item.values.length==0" style="text-align:center;background:#e0e0e0">{{ $t('switchvar.noValues') }}</b-list-group-item>
												</b-list-group>
											</b-form-group>
											<b-btn block variant="secondary" size="sm" @click="addValue(item)" style="margin-bottom:5px">{{ $t('switchvar.addValue') }}</b-btn>
										</b-col>
									</b-row>
								</b-container>
							</b-list-group-item>
						</draggable>
						<b-list-group-item disabled v-if="data.variables.length==0" style="text-align:center;background:#e0e0e0">{{ $t('switchvar.noVariables') }}</b-list-group-item>
					</b-list-group>
				</b-form-group>
				<b-btn block variant="primary" style="margin-top:-8px" size="sm" @click="addItem()">{{ $t('switchvar.add') }}</b-btn>
				<div style="height:4px"></div>


			</div>

			<!-- VIEW MODE -->
			<!-- type: SWITCH/VARIABLE, name, owner:SYSTEM/COMMON/PERSONAL, label, values[], _id -->
			<div v-if="!dashboards.editMode" class="view-box">
				<div class="item-box">
					<template v-for="item in data.variables">
						<div class="title dotdotdot" v-bind:key="item.label">{{ item.label }}</div>
						<div v-bind:key="item._id">
							<b-form-checkbox v-if="item.type=='SWITCH'" class="value" style="margin-top:-6px;text-align:right;margin-right:-10px" v-model="values[item.name]" size="lg" switch @change="changed($event, item)"></b-form-checkbox>
							<b-form-select v-if="item.type=='VARIABLE'" class="value" style="margin-top:-7px;" v-model="values[item.name]" size="sm" :options="item.values" @change="changed($event, item)"></b-form-select>
						</div>
					</template>
				</div>
			</div>
		</div>
	</panel-draggable>
</template>

<script>
import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import PanelDraggable from './PanelDraggable.vue';
import Draggable from 'vuedraggable';
import u from '../../utils/utils';
import Vue from 'vue';
import EventBus from '../../data/EventBus';
import i18n from '../../utils/i18n';

export default {
	props: {
		data: Object
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			isDraggable: false,
			types: [
				{ value: 'SWITCH', text: i18n.t('switchvar.switch') },
				{ value: 'VARIABLE', text: i18n.t('switchvar.variable') }
			],
			ownerTypes: [
				{ value: 'PERSONAL', text: i18n.t('switchvar.personal') },
				{ value: 'SHARED', text: i18n.t('switchvar.shared') },
				{ value: 'SYSTEM', text: i18n.t('switchvar.system') }
			],
			values: { }
		};
	},
	methods: {
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		removeItem(item) {
			const index = this.data.variables.findIndex(o => o._id == item._id);
			if (index) {
				this.data.variables.splice(index, 1);
			}
		},
		addItem() {
			const id = u.randomString(32);
			this.data.variables.push({ _id: id, type: 'SWITCH', name: '', owner: 'PERSONAL', label: '', values: [] });
			setTimeout(() => { document.getElementById(id).focus(); }, 100);
		},
		addValue(item) {
			item.values.push('');
		},
		removeValue(item, index) {
			item.values.splice(index, 1);
		},
		changed(newValue, item) {
			const previousValue = this.values[item.name];
			//console.log(`Item ${item.label} changed value to ${newValue} from ${previousValue}`);
			if (item.type == 'SWITCH') {
				s.http.post(`/pbx/switch/${item.name}`, { value: newValue })
					.catch(() => {
						 EventBus.$emit('CommonErrorModal', { header: i18n.t('switchvar.setErrorHeader'), message: i18n.t('switchvar.setSwitchErrorMessage') });
						 this.values[item.name] = previousValue;

					});
			} else if (item.type == 'VARIABLE') {
				s.http.post(`/pbx/variable/${item.name}`, { value: newValue })
					.catch(() => {
						 EventBus.$emit('CommonErrorModal', { header: i18n.t('switchvar.setErrorHeader'), message: i18n.t('switchvar.setVariableErrorMessage') });
						this.values[item.name] = previousValue;
					});
			}
		}
	},
	components: {
		GlyphIcon,
		PanelDraggable,
		Draggable
	},
	mounted() {
		this.data.variables.forEach(item => {
			item._id = u.randomString(32);
		});
		this.data.variables.forEach(item => {
			if (item.type == 'VARIABLE') {
				s.http.get(`/pbx/variable/${item.name}`)
					.then(res => { Vue.set(this.values, item.name, res.data.value); })
					.catch(() => { Vue.set(this.values, item.name, ''); });
			} else if (item.type == 'SWITCH') {
				s.http.get(`/pbx/switch/${item.name}`)
					.then(res => { Vue.set(this.values, item.name, res.data.value); })
					.catch(() => { Vue.set(this.values, item.name, false); });
			}
		});
	},
	created() {
		EventBus.$on('Realtime:Pbx:VARIABLE', data => {
			if (this.data.variables.find(o => o.name == data.name)) {
				Vue.set(this.values, data.name, data.value);
			}
		});
		EventBus.$on('Realtime:Pbx:SWITCH', data => {
			if (this.data.variables.find(o => o.name == data.name)) {
				Vue.set(this.values, data.name, data.value);
			}
		});
	}
	
};
</script>

<style scoped>
	.edit-title {
		color: var(--txt-color-primary-highlight);
		font-size: var(--font-size-secondary);
		font-weight: 400;
		align-self: center;
		width: 302px;
	}
	.title {
		color: var(--txt-color-primary);
		font-size: var(--font-size-secondary);
		font-weight: 400;
		align-self: center;
		margin-top: 1px;
		height: 25px;
	}
	.value {
		align-self: center;
		height: 25px;
	}
	.settings-box {
		margin: 5px 10px;
	}
	.view-box {
		margin: 4px 4px;
	}
	.item-box {
		display: grid;
		grid-template-columns: auto max-content;
		column-gap: 10px;
		width: 100%;
	}
	.ghost {
		opacity: 0.25;
	}
	.delete-item-btn {
		padding: 0 2px 2px 2px;
		margin-right: 5px;
	}
	.employee-item {
		padding: 2px 5px 2px 5px !important;
	}
</style>
