

class GlyphIconCache {
	constructor() {
		this.cache = {};
	}

	getIcon(icon) {
		return new Promise((resolve, reject) => {
			if (!this.cache[icon]) {
				this.setIsLoading(icon);
				return reject();
			}

			const c = this.cache[icon];
			if (c) {
				if (c.state == 'CACHED') {
					resolve(c.icon);
				} else {
					setTimeout(() => {
						if (this.cache[icon] && this.cache[icon].state == 'CACHED') {
							resolve(this.cache[icon].icon);
						} else {
							setTimeout(() => {
								if (this.cache[icon] && this.cache[icon].state == 'CACHED') {
									resolve(this.cache[icon].icon);
								} else {
									reject();
								}
							}, 250);
						}
					}, 250);
				}
			}
		});
	}

	setIsLoading(icon) {
		if (!this.cache[icon]) {
			this.cache[icon] = { state: 'LOADING', icon: null };
		}
	}

	setIcon(icon, svg) {
		this.cache[icon] = { state: 'CACHED', icon: svg };
	}
}

// Singleton
export default new GlyphIconCache();
