<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex">
				<employee-picture v-if="emp" :picture="emp.picture" :size="58" :spinner="true"></employee-picture>
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ $t('sms.header') }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ emp ? emp.name : $t('sms.toAnybody') }}</div>
				</div>
			</div>
		</template>

		<div style="margin-left:10px;margin-right:10px">
			<b-form-group :label="$t('sms.phoneNumber')" v-if="!emp">
				<b-form-input ref="SmsNumber" v-model="number" tabindex="19" size="sm" maxlength="400" :autofocus="emp==null"  />
			</b-form-group>

			<b-form-group :label="$t('sms.message')">
				<b-form-textarea ref="SmsMessage" class="sms-message" v-model="message" tabindex="20" size="sm" maxlength="400" :autofocus="emp!=null" rows="3" no-resize @keydown="keyDownHandler"></b-form-textarea>
			</b-form-group>

			<b-button-group size="sm" style="width:100%">
				<b-button variant="primary" @click="prefill1" style="width:50%" tabindex="30">{{ $t('sms.insertCallback') }}</b-button>
				<b-button variant="success" @click="prefill2" style="width:50%" tabindex="40">{{ $t('sms.insertCallme') }}</b-button>
			</b-button-group>

		</div>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" size="sm" @click="close()" tabindex="100">{{ $t('common.cancel') }}</b-button>
		<b-button variant="primary" style="height:30px" :disabled="!numberIsValid||!message||isSaving||(!emp&&!number)" size="sm" type="submit" @click="save()" tabindex="110">{{ $t('sms.send') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import EmployeePicture from '../EmployeePicture.vue';
import s from '../../settings';
import u from '../../utils/utils';
import employees from '../../data/employees';
import i18n from '../../utils/i18n';

export default {
	components: {
		EmployeePicture
	},
	data() {
		return {
			$t: i18n.t,
			emp: { presence: { state: 'BUSY' } },
			modalId: 'SendSmsModal' + Math.random().toString(),
			message: '',
			number: '',
			isSaving: false
		};
	},
	computed: {
		numberIsValid: function() {
			if (this.number.length == 0) { return null; }
			return this.number.match(/^\d{8,}$/) != null || this.number.match(/^\+\d{10,}$/) != null;
		}
	},
	methods: {
		keyDownHandler(e) {
			if (e.key == 'Enter' && (e.shiftKey || e.ctrlKey || e.altKey)) {
				this.save();
			}
		},
		show(employee, number) {
			this.isSaving = false;
			if (employee) {
				this.emp = u.extendObject({}, employee);
			} else {
				this.emp = null;
			}
			this.number = number || '';
			this.message = '';
			this.$bvModal.show(this.modalId);
			if (this.number.length !== 0) {
				setTimeout(() => { this.$refs.SmsMessage.focus(); }, 500);
			}
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
			if (!this.message || !this.number || this.number.length<8) { return; }
			if (!this.number.startsWith('+')) { this.number = s.pbxSettings.nationalPrefix + this.number; }
			this.isSaving = true;
			s.http.post('/sms', { type: 'SMS', message: this.message, to: this.number })
				.then(() => { this.close(); })
				.catch(err => {
					this.isSaving = false;
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					} else {
						if (err.status >= 400) {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('sms.errorHeader'), message: i18n.t('sms.errorUnknown') + ' ' + err.status });
						} else {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('sms.errorHeader'), message: i18n.t('sms.errorUnknown') + ` ${err.status} ${err.path}\n${err.message}` });
						}
					}
				});
		},
		prefill1() {
			employees.getEmployee(s.auth.employee).then(me => {
				const call = me.currentCall;
				if (call) {
					const caller = call.leg == 'O' ? call.caller : call.callee;
					if (caller.name) {
						this.message = i18n.t('sms.callbackMessage').replace('%%name%%', caller.name).replace('%%number%%', s.cutNationalPrefix(caller.number));
					} else {
						this.message = i18n.t('sms.callbackMessageNoName').replace('%%number%%', s.cutNationalPrefix(caller.number));
					}
					this.$refs.SmsMessage.focus();
				} else {
					this.message = i18n.t('sms.callbackMessageRaw');
					this.$refs.SmsMessage.focus();
				}
			});
		},
		prefill2() {
			let number = null;
			employees.getEmployee(s.auth.employee).then(me => {
				const pe = me.primaryExtension;
				if (pe.number) { number = pe.number; } 
				else if (pe.direct) { number = s.cutNationalPrefix(pe.direct); }
				else if (pe.mobile && pe.mobile.length > 0) { number = s.cutNationalPrefix(pe.mobile[0]); }

				if (number) {
					this.message = i18n.t('sms.callmeMessage').replace('%%name%%', me.name).replace('%%number%%', number);
					this.$refs.SmsMessage.focus();
				}
			});

		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
	.sms-message {
		height: auto !important;
	}
</style>
