/*
 * Dashboard Call History
 * 
 * - Holds the most recent inbound, outbound and missed calls
 * - Holds a counter for number of missed calls since last outbound/answered call
 * 
 * Created by Per Moeller <pm@telecomx.dk> on 2022-07-06
 */

//import u from '../utils/utils';
//import Vue from 'vue';
import employees from './employees';
import EventBus from './EventBus';
import s from '../settings';
import rt from '../data/realtime';
//import i18n from '../utils/i18n';
import logger from './logger';

class CallHistory {
	// #region Init, load, setup

	/**
	 * Initialize
	 */
	constructor() {
		/** Current calls on the extension */
		this.sessions = {};

		/** Number of missed calls */
		this.missed = 0;

		/** List of eventIds for call legs that are being transferred (= not not count as missed as we transferred the call)  */
		this.transfersInProgress = [];

		/** Call history - last 10 calls */
		this.calls = {
			outbound: [],
			inbound: [],
			missed: []
		};

		EventBus.$on('Realtime:Connected', () => { this.load(); });
		EventBus.$on('Realtime:Pbx:CALL_START', this.callStart.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_ANSWER', this.callAnswer.bind(this));
		EventBus.$on('Realtime:Pbx:CALL_END', this.callEnd.bind(this));
		EventBus.$on('AttendedTransferRequested', this.transferRequested.bind(this));
		EventBus.$on('BlindTransferRequested', this.transferRequested.bind(this));
		EventBus.$on('CallRejected', this.transferRequested.bind(this));
		EventBus.$on('OneSecond', this.cleanup.bind(this));

		if (rt.connected) { // otherwise it is invoked on Realtime:Connected
			this.load();
		}

		logger.registerGlobal('callHistory', this);
	}

	/**
	 * Cleanup that runs every second on transferInProgress (after 5 seconds they are removed)
	 */
	cleanup() {
		if (this.transfersInProgress.length === 0) { return; }
		const cutOff = new Date();
		cutOff.setSeconds(cutOff.getSeconds() - 5);
		this.transfersInProgress = this.transfersInProgress.filter(o => o.started < cutOff);
	}

	/**
	 * Register that call transfer for a call leg is in progress (so that we do not count the call as missed)
	 * Also handles when we activly reject a call
	 * @param {Object} data Transfer object
	 */
	transferRequested(data) {
		//console.log(data);
		//console.log('Logged that ' + data.source + ' is in transfer mode');
		this.transfersInProgress.push({ callId: data.source, created: new Date() });
	}

	/**
	 * Load latest 10 missed, inbound and outbound calls on the extension
	 */
	async load() {
		try {
			await s.ready();
			await employees.ready();
			this.me = await employees.getMe();

			const res = await s.http.get(`/pbx/cdr/latest?extension=${this.me.primaryExtension._id}`);
			['inbound','outbound','missed'].forEach(type => {
				res.data[type].forEach(call => {
					call.started = new Date(call.started);
					if (call.answered) { call.answered = new Date(call.answered); }
					if (call.ended) { call.ended = new Date(call.ended); }
				});
			});
			this.calls.outbound = res.data.outbound;
			this.calls.inbound = res.data.inbound;
			this.calls.missed = res.data.missed;
		}
		catch(_) { /* dont care */}
	}

	/**
	 * Invoked when a call starts on the customer this extension belongs to
	 * @param {Object} e Call event object
	 */
	callStart(e) {
		if (!this.me || e.extension !== this.me.primaryExtension._id) { return; }
		// console.log('CALL START');
		// console.log(JSON.stringify(e, null, 4));
		if (e.leg === 'O') { // I receive the call (O=out of PBX)
			// We only create session for calls we receive, as that is all we want to count missed calls
			let session = this.sessions[e.sessionId];
			if (!session) {
				session = { started: new Date(), devices: {} };
				this.sessions[e.sessionId] = session;
			}
			session.devices[e.device] = 'STARTED';
			session.queueId = e.queueId;
		}
		if (e.leg === 'I' && e.device === s.myRealPhoneId) { // I made the call from this communicator (I=into PBX)
			this.missed = 0; // reset counter - i started a call
		}
	}

	/**
	 * Invoked when a call is answered on the customer this extension belongs to
	 * @param {Object} e Call event object
	 */
	callAnswer(e) {
		if (!this.me || e.extension !== this.me.primaryExtension._id) { return; }
		// console.log('CALL ANSWER');
		// console.log(JSON.stringify(e, null, 4));
		if (e.leg === 'O') {
			let session = this.sessions[e.sessionId];
			if (!session) {
				session = { started: new Date(), devices: {} };
				this.sessions[e.sessionId] = session;
			}
			session.devices[e.device] = 'ANSWERED';
			session.answered = new Date();
			session.queueId = e.queueId;
		}
		this.missed = 0; // If a call is answered in or out we reset the counter
	}

	/**
	 * Invoked when a call has ended on the customer this extension belongs to
	 * @param {Object} e Call event object
	 */
	callEnd(e) {
		if (!this.me || e.extension !== this.me.primaryExtension._id) { return; }
		// console.log('CALL END');
		// console.log(JSON.stringify(e, null, 4));
		// Call has ended
		// If not answered add to unanswered count
		// If we received the call, it has not been answered and is not from a queue
		const session = this.sessions[e.sessionId];

		// If I received the call, we have a session for it, and it was not a queue call....
		if (e.leg === 'O' && session && !session.queueId) {
			// If we did not answer the call, and this call event is for the last device the call was sent to...
			if (!session.answered && Object.keys(session.devices).length === 1) {
				// ... and is not currently being transferred
				const inTransfer = this.transfersInProgress.find(o => o.callId == e.eventId);
				if (!inTransfer) {
					this.missed++; // Then it is a missed call
				}
			}
		}

		// If we have a session for this call event
		if (session) {
			delete session.devices[e.device];
			if (Object.keys(session.devices).length === 0) {
				// No more devices, delete the session and load the calls
				delete this.sessions[e.sessionId];
				this.load(); // Inbound call has ended, the last of the called devices has left - so we need to load to get the latest call
			}
		} else {
			// We are not tracking the call, because it is an outbound call - but we still need to update
			this.load(); // Outbound call has ended - we we need to load to get the latest call
		}
	}

}

// Singleton
export default new CallHistory();
