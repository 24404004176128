<template>
	<panel-draggable :data="data" :draggable="isDraggable">
		<div>
			<b-btn v-if="dashboards.editMode" size="sm" variant="danger" class="dash-delete-btn" @click="remove()">
				<glyph-icon icon="17-bin" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<b-btn v-if="!dashboards.editMode&&show&&data.close=='BUTTON'" size="sm" variant="danger" class="dash-delete-btn" @click="closeClicked()">
				<glyph-icon icon="373-times" color="#fff" size="16" style="margin-top:1px"></glyph-icon>
			</b-btn>
			<div class="dash-header" @mouseover="mouseOverHeader(true)" @mouseout="mouseOverHeader(false)" :editMode="dashboards.editMode||(show&&data.close=='BUTTON')">
				<glyph-icon icon="417-globe" color="#fff" size="14" style="flex-shrink:0;margin-top:-2px;margin-right:2px;"></glyph-icon>
				<div class="dotdotdot" style="margin-left:3px">{{ data.title }}</div>
			</div>

			<!-- EDIT MODE -->
			<div v-if="dashboards.editMode" class="settings-box">
				<b-form-group :label="$t('web.panelName')">
					<b-form-input type="text" maxlength="64" size="sm" v-model="data.title" :required="true"></b-form-input>
				</b-form-group>
				<b-container style="padding:0">
					<b-row>
						<b-col>
							<b-form-group :label="$t('web.width')" description="200 - 2000">
								<b-input-group append="px" size="sm">
									<b-form-input type="number" :no-wheel="true" size="sm" v-model="data.width" min="200" max="2000" :number="true"></b-form-input>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group :label="$t('web.height')" description="1 - 2000">
								<b-input-group append="px" size="sm">
									<b-form-input type="number" :no-wheel="true" size="sm" v-model="data.height" min="1" max="2000" :number="true"></b-form-input>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
				</b-container>
				<b-form-group :label="$t('web.type')">
					<b-form-select v-model="data.mode" :options="modes" size="sm"></b-form-select>
				</b-form-group>
				<b-form-group :label="$t('web.interval')" v-if="data.mode=='RELOADED'">
					<b-form-select v-model="data.interval" :options="intervals" size="sm"></b-form-select>
				</b-form-group>
				<b-form-group :label="$t('web.url')" :description="$t('web.urlDescription')">
					<b-form-input type="url" size="sm" v-model="data.url" :required="true" placeholder="https://"></b-form-input>
				</b-form-group>
				<b-form-group :label="$t('web.closeContent')">
					<b-form-select v-model="data.close" :options="closings" size="sm"></b-form-select>
				</b-form-group>
				<b-form-checkbox v-model="data.ignoreLocal" size="sm" switch><div class="section-label" style="margin-top:-2px">{{ $t('web.ignoreLocalCalls') }}</div></b-form-checkbox>
				<b-alert show variant="info" size="sm" style="padding:3px 10px;font-size:12px;line-height:12px;margin-top:10px">
					<div style="margin-left:-8px;margin-right:-8px;padding:5px 4px;font-weight:bold;background:#ffffffa0;border-radius:5px;margin-bottom:3px">{{ $t('web.variableHelp') }}</div>
					<table>
						<tr><th>%%A_NUMBER%%</th><td>=</td><td> {{ $t('web.aNumber') }}</td></tr>
						<tr><th>%%A_NUMBER_NO_PFX%</th><td>=</td><td> {{ $t('web.aNumberNoPrefix') }}</td></tr>
						<tr><th>%%B_NUMBER%%</th><td>=</td><td> {{ $t('web.bNumber') }}</td></tr>
						<tr><th>%%B_NUMBER_NO_PFX%%</th><td>=</td><td> {{ $t('web.bNumberNoPrefix') }}</td></tr>
						<tr><th>%%A_NAME%%</th><td>=</td><td> {{ $t('web.aName') }}</td></tr>
						<tr><th>%%B_NAME%%</th><td>=</td><td> {{ $t('web.bName') }}</td></tr>
						<tr><th>%%VARIABLE%%</th><td>=</td><td> {{ $t('web.variable') }}</td></tr>
						<tr><th>%%TCXTOKEN%%</th><td>=</td><td> {{ $t('web.tcxtoken') }}</td></tr>
						<tr><th>%%EMAIL%%</th><td>=</td><td> {{ $t('web.email') }}</td></tr>
						<tr><th>%%OTHER%%</th><td>=</td><td> {{ $t('web.other') }}</td></tr>
						<tr><th style="vertical-align:top">%%OTHER_NO_PFX%%</th><td style="vertical-align:top">=</td><td> {{ $t('web.otherNoPrefix') }}</td></tr>
					</table>
				</b-alert>
				<div style="height:4px"></div>
			</div>

			<!-- VIEW MODE -->
			<div class="settings-box" v-if="!dashboards.editMode">
				<iframe v-show="show" :height="data.height-35" :width="data.width-20" style="border:0" :id="frameId" title="3de parts indhold" />

			</div>
		</div>
	</panel-draggable>
</template>

<script>
import s from '../../settings';
import GlyphIcon from '../GlyphIcon.vue';
import dashboards from '../../data/dashboards';
import PanelDraggable from './PanelDraggable.vue';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import BindCache from '../../utils/BindCache';
import i18n from '../../utils/i18n';

export default {
	props: {
		data: Object
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			isDraggable: false,
			modes: [
				{ value: 'STATIC', text: i18n.t('web.types.static') },
				{ value: 'RELOADED', text: i18n.t('web.types.reloaded') },
				{ value: 'RINGING', text: i18n.t('web.types.ringing') },
				{ value: 'ANSWERED', text: i18n.t('web.types.answered') },
				{ value: 'RINGING_OR_OUT', text: i18n.t('web.types.callRing') },
				{ value: 'ANSWERED_OR_OUT', text: i18n.t('web.types.callAnswer') },
			],
			intervals: [
				{ value: 0, text: i18n.t('web.intervals.0') },
				{ value: 10, text: i18n.t('web.intervals.10') },
				{ value: 30, text: i18n.t('web.intervals.30') },
				{ value: 45, text: i18n.t('web.intervals.45') },
				{ value: 60, text: i18n.t('web.intervals.60') },
				{ value: 120, text: i18n.t('web.intervals.120') },
				{ value: 300, text: i18n.t('web.intervals.300') },
				{ value: 600, text: i18n.t('web.intervals.600') },
				{ value: 1800, text: i18n.t('web.intervals.1800') },
				{ value: 3600, text: i18n.t('web.intervals.3600') },
				{ value: 7200, text: i18n.t('web.intervals.7200') }
			],
			closings: [
				{ value: 'NEVER', text: i18n.t('web.closeContents.never') },
				{ value: 'ON_END', text: i18n.t('web.closeContents.onEnd') },
				{ value: 'BUTTON', text: i18n.t('web.closeContents.button') }
			],
			me: null,
			timer: null,
			frameId: null,
			show: false,
			currentCall: null,
			bindCache: new BindCache(this)
		};
	},
	methods: {
		remove() {
			dashboards.removeItem(this.data.column, this.data.position);
		},
		mouseOverHeader(itIs) {
			if (itIs && dashboards.editMode && !dashboards.dragItem) {
				this.isDraggable = true;
			} else {
				this.isDraggable = false;
			}
		},
		callInOrOut(e) {
			//console.log(`${e.extension} == ${this.me.primaryExtension._id} && ${e.device} == ${s.myRealPhoneId}`);
			//console.dir(e);
			if (['RINGING_OR_OUT','ANSWERED_OR_OUT'].includes(this.data.mode) && this.me && e.extension == this.me.primaryExtension._id && e.device == s.myRealPhoneId) {
				//console.log(e);
				if (
					(e.leg == 'I' && e.event == 'CALL_START') || // User is making a outbound call
					(e.leg == 'O' && this.data.mode == 'RINGING_OR_OUT' && e.event == 'CALL_RING') || // User got incoming ringing call
					(e.leg == 'O' && this.data.mode == 'ANSWERED_OR_OUT' && e.event == 'CALL_ANSWER')) { // User got incoming answered call
					const otherParty = e.leg == 'I' ? e.callee : e.caller;
					if (this.data.ignoreLocal && otherParty && (otherParty[0] != '+' || otherParty.length < 8)) { return; }
					setTimeout(() => { // To ensure employees has been updated with the call
						// Find calls that concerns my device only
						const myCalls = this.me.primaryExtension.calls.filter((o) => o.device == s.myRealPhoneId);
						if (myCalls.length == 1) { // If I have exactly 1 incoming call in the right state on the phone that I am using - then we have a call that we need to care about
							this.show = true;
							this.currentCall = myCalls[0];
							this.loadWeb(myCalls[0]);
						}
					}, 50);
				}
			}
		},
		callRing(e) {
			if (this.data.mode == 'RINGING' && this.me && e.extension == this.me.primaryExtension._id && e.device == s.myRealPhoneId && e.leg == 'O') {
				if (this.data.ignoreLocal && e.caller && (e.caller[0] != '+' || e.caller.length < 8)) { return; }
				setTimeout(() => { // To ensure employees has been updated with the call
					// Find calls that concerns my device only
					const myCalls = this.me.primaryExtension.calls.filter((o) => o.device == s.myRealPhoneId);
					if (myCalls.length == 1) {// If I have exactly 1 incoming call in the right state on the phone that I am using - then we have a call that we need to care about
						this.show = true;
						this.currentCall = myCalls[0];
						this.loadWeb(myCalls[0]);
					}
				}, 50);
			}
		},
		callAnswer(e) {
			if (this.data.mode == 'ANSWERED' && this.me && e.extension == this.me.primaryExtension._id && e.device == s.myRealPhoneId && e.leg == 'O') {
				if (this.data.ignoreLocal && e.caller && (e.caller[0] != '+' || e.caller.length < 8)) { return; }
				setTimeout(() => { // To ensure that employees has been updated with the call
					const myCalls = this.me.primaryExtension.calls.filter((o) => o.device == s.myRealPhoneId);
					if (myCalls.length == 1) {// If I have exactly 1 incoming call in the right state on the phone that I am using - then we have a call that we need to care about
						this.show = true;
						this.currentCall = myCalls[0];
						this.loadWeb(myCalls[0]);
					}
				}, 50);
			}
		},
		callEnd(e) {
			if (this.data.close != 'ON_END' || !this.currentCall) { return; }
			if (['RINGING','ANSWERED','RINGING_OR_OUT','ANSWERED_OR_OUT'].includes(this.data.mode) && this.me && e.extension == this.me.primaryExtension._id && e.device == s.myRealPhoneId && e.eventId == this.currentCall._id) {
				this.show = false;
				this.currentCall = null;
				const iframe = document.getElementById(this.frameId);
				if (iframe) {
					iframe.src = '';
				}
			}
		},
		closeClicked() {
			this.show = false;
			const iframe = document.getElementById(this.frameId);
			if (iframe) {
				iframe.src = '';
			}
		},
		async loadWeb(call) {
			const iframe = document.getElementById(this.frameId);
			if (iframe) {
				let url = this.data.url;
				if (call) {
					let otherParty = call.leg == 'I' ? call.callee.number : call.caller.number;
					url = url
						.replace('%%A_NUMBER%%', call.caller.number)
						.replace('%%A_NUMBER_NO_PFX%%', s.cutNationalPrefix(call.caller.number))
						.replace('%%B_NUMBER%%', call.callee.number)
						.replace('%%B_NUMBER_NO_PFX%%', s.cutNationalPrefix(call.callee.number))
						.replace('%%A_NAME%%', encodeURIComponent(call.caller.name))
						.replace('%%B_NAME%%', encodeURIComponent(call.callee.name))
						.replace('%%TCXTOKEN%%', s.token)
						.replace('%%EMAIL%%', this.me.emailAddress)
						.replace('%%OTHER%%', otherParty)
						.replace('%%OTHER_NO_PFX%%', s.cutNationalPrefix(otherParty));
					const variables = url.match(/%%[^%]+%%/g);
					if (variables) {
						for (let variable of variables) {
							try {
								const res = await s.http.get(`/pbx/variable/${call.id}/${variable.replace(/%/g, '')}`);
								if (res.data.value) {
									url.replace(variable, res.data.value);
								} else {
									url.replace(variable, '');
								}
							} catch (_) {}
						}
					}
				}
				//console.log(`URL: ${url}`);
				iframe.src = url;
			}
		}
	},
	components: {
		GlyphIcon,
		PanelDraggable
	},
	mounted() {
		this.frameId = `webframe${Math.random().toString()}`;
		EventBus.$on('Realtime:Pbx:CALL_RING', this.bindCache.bind('callRing', this.callRing));
		EventBus.$on('Realtime:Pbx:CALL_RING', this.bindCache.bind('callRing2', this.callInOrOut));
		EventBus.$on('Realtime:Pbx:CALL_START', this.bindCache.bind('callStart', this.callInOrOut));
		EventBus.$on('Realtime:Pbx:CALL_ANSWER', this.bindCache.bind('callAnswer', this.callAnswer));
		EventBus.$on('Realtime:Pbx:CALL_ANSWER', this.bindCache.bind('callAnswer2', this.callInOrOut));
		EventBus.$on('Realtime:Pbx:CALL_END', this.bindCache.bind('callEnd', this.callEnd));
		employees.getMe().then((me) => { this.me = me; }).catch(() => {});
		if (this.data.width < s.dashboardColumnWidth) {
			this.data.width = s.dashboardColumnWidth;
		}
		switch (this.data.mode) {
			case 'RELOADED':
				this.timer = setInterval(() => {
					this.reload();
				}, this.data.interval * 1000);
				this.show = true;
				setTimeout(() => { this.loadWeb();}, 1);
				break;

			case 'STATIC':
				this.show = true;
				setTimeout(() => { this.loadWeb();}, 1);
				break;
		}
	},
	destroyed() {
		if (this.timer) {
			clearInterval(this.timer);
			delete this.timer;
		}
		EventBus.$off('Realtime:Pbx:CALL_RING', this.bindCache.unbind('callRing'));
		EventBus.$off('Realtime:Pbx:CALL_RING', this.bindCache.unbind('callRing2'));
		EventBus.$off('Realtime:Pbx:CALL_START', this.bindCache.unbind('callStart'));
		EventBus.$off('Realtime:Pbx:CALL_ANSWER', this.bindCache.unbind('callAnswer'));
		EventBus.$off('Realtime:Pbx:CALL_ANSWER', this.bindCache.unbind('callAnswer2'));
		EventBus.$off('Realtime:Pbx:CALL_END', this.bindCache.unbind('callEnd'));
	}
};
</script>

<style scoped>
	.settings-box {
		margin: 5px 10px;
	}
</style>