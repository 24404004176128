<template>
	<b-modal :id="modalId" centered hide-header-close hide-footer>
		<template #modal-header>
			<div style="margin-left:-10px;margin-top:2px;display:flex">
				<employee-picture :picture="employee.picture" :size="58" :spinner="true"></employee-picture>
				<div style="margin-left:10px;display:flex;flex-direction:column">
					<div style="font-size:13px;font-weight:300;margin-top:8px">{{ $t('redirectModal.header') }}</div>
					<div style="font-size:24px;margin-top:-10px">{{ employee.name }}</div>
				</div>
			</div>
		</template>

		<div style="margin-left:10px;margin-right:10px" v-if="employee">
			<b-form-group :label="$t('redirectModal.number')">
				<b-form-input type="text" :state="numberIsValid" v-model="number" tabindex="20" size="sm" maxlength="20" :autofocus="true" @keydown="keyDownHandler"></b-form-input>
			</b-form-group>
		</div>

		<div class="modal-space-to-bottom"></div>

		<div class="modal-bottom">
			<b-button variant="secondary" style="height:30px" size="sm" @click="close()" tabindex="100">{{ $t('common.cancel') }}</b-button>
			<b-button variant="danger" style="height:30px" :disabled="!employee||!currentlyEnabled||isSaving" size="sm" type="submit" @click="clear()" tabindex="110">{{ $t('common.deactivate') }}</b-button>
			<b-button variant="primary" style="height:30px" :disabled="!employee||!numberIsValid||isSaving" size="sm" type="submit" @click="save()" tabindex="120">{{ currentlyEnabled ? $t('common.update') : $t('common.activate') }}</b-button>
		</div>

	</b-modal>
</template>

<script>
import EventBus from '../../data/EventBus';
import EmployeePicture from '../EmployeePicture.vue';
import s from '../../settings';
import u from '../../utils/utils';
import i18n from '../../utils/i18n';

export default {
	components: {
		EmployeePicture
	},
	data() {
		return {
			$t: i18n.t,
			employee: null,
			modalId: 'EmployeeRedirectModal' + Math.random().toString(),
			number: null,
			isSaving: false,
			currentlyEnabled: false
		};
	},
	computed: {
		numberIsValid: function() {
			if (this.number.length == 0) { return null; }
			return (u.isPhoneNumber(this.number) || this.number.match(/^\d{1,7}$/) != null);
		}
	},
	methods: {
		keyDownHandler(e) {
			if (e.key == 'Enter') {
				this.save();
			}
		},
		show(employee) {
			this.isSaving = false;
			this.employee = employee;
			this.currentlyEnabled = employee.primaryExtension.redirect != null;
			this.number = s.cutNationalPrefix(employee.primaryExtension.redirect || '');
			this.$bvModal.show(this.modalId);
		},
		close() {
			this.$bvModal.hide(this.modalId);
		},
		save() {
			if (!this.numberIsValid) { return; }
			this.isSaving = true;
			let number = this.number;
			if (number.length > 7) {
				if (number.startsWith('00')) { number = '+' + number.substr(2); }
				if (!number.startsWith('+')) { number = s.pbxSettings.nationalPrefix + number; }
			}
			s.http.get(`/pbx/extension/${this.employee.primaryExtension._id}/redirect?enable=true&number=${encodeURIComponent(number)}`)
				.then(() => { this.close(); })
				.catch(err => {
					this.isSaving = false;
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					} else {
						if (err.status >= 400) {
							if (err.status == 404) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorNotFound') });
							} else if (err.status == 422) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorInvalid') });
							} else if (err.status == 403) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorDenied') });
							} else {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorUnknown') + ' ' + err.status });
							}
						} else {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorUnknown') + ` ${err.status} ${err.path}\n${err.message}` });
						}
					}
				});
		},
		clear() {
			if (!this.currentlyEnabled) { return; }
			this.isSaving = true;
			s.http.get(`/pbx/extension/${this.employee.primaryExtension._id}/redirect?enable=false`)
				.then(() => { this.close(); })
				.catch(err => {
					this.isSaving = false;
					this.close();
					if (err.status == 0) {
						EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.commErrorHeader'), message: i18n.t('common.error.commErrorMessage') });
					} else {
						if (err.status >= 400) {
							if (err.status == 404) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorNotFound') });
							} else if (err.status == 422) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorInvalid') });
							} else if (err.status == 403) {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorDenied') });
							} else {
								EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorUnknown') + ' ' + err.status });
							}
						} else {
							EventBus.$emit('CommonErrorModal', { header: i18n.t('redirectModal.errorHeader'), message: i18n.t('redirectModal.errorUnknown') + ` ${err.status} ${err.path}\n${err.message}` });
						}
					}
				});
		}
	},
	mounted() {
		EventBus.$on('Auth:LoggedOut', this.close.bind(this));
		EventBus.$on('Realtime:Disconnected', this.close.bind(this));
	}
};
</script>

<style scoped>
	.employee-picture {
		position: absolute;
		top: 0px;
		left: 0px;
	}
</style>
