<template>
	<div v-if="member&&loaded" @contextmenu="showContextMenu">
		<call-draggable :data="enhancedMember" type="QueueMember" :target="false" :draggable="true">
			<div class="member-box">
					
				<div v-if="member.extension&&employee" class="employee-picture-box">
					<presence-icon v-if="employee.type==='EMPLOYEE'" :state="employee.presence.state" :size="16" class="presence-state" />
					<employee-picture :picture="employee.picture" :size="40" :spinner="true" :initials="employee.initials" />
				</div>
				<div v-if="!member.extension" class="external-name">{{ $t('queue.externalTag') }}</div>
				<div v-if="member.extension&&!employee" class="external-name" style="background-color:var(--pn-primary-5)">{{ extension.number }}</div>
				
				<div class="name-and-usage">
					<div v-if="employee" class="name dotdotdot">
						<glyph-icon v-if="member.permanent" icon="217-lock" size="17" color="#aa0" style="float:left;margin-top:-2px"></glyph-icon>
						{{ employee.name }}
					</div>
					<div v-if="!employee&&!member.extension" class="name dotdotdot">
						<glyph-icon v-if="member.permanent" icon="217-lock" size="17" color="#aa0" style="float:left;margin-top:-2px"></glyph-icon>
						{{ member.number | cutNationalPrefix }}
					</div>
					<div v-if="!employee&&member.extension&&extension" class="name dotdotdot">
						<glyph-icon v-if="member.permanent" icon="217-lock" size="17" color="#aa0" style="float:left;margin-top:-2px"></glyph-icon>
						{{ extension.name }}
					</div>

					<div class="usage-box">
						<div><span class="usage-label">{{ $t('queue.memberPriority') }}</span> <span class="usage-value">{{ member.priority }}</span></div>
						<div><span class="usage-label">{{ $t('queue.memberCalls') }}</span> <span class="usage-value">{{ member.calls }}</span></div>
						<div><span class="usage-label">{{ $t('queue.memberTime') }}</span> <span class="usage-value" v-if="member.seconds">{{ member.seconds | duration(false,true) }}</span><span class="usage-value" v-if="!member.seconds">0:00</span></div>
					</div>

				</div>

				<div style="display:flex;flex-direction:column;justify-content:center" v-if="employee">
					<div class="duration-box" :class="{'visible':employee&&employee.currentCall}">
						<glyph-icon
							:icon="employee.currentCallIcon"
							size="20"
							:color="employee.currentCallIconColor"
							style="margin: 0 auto;flex-shrink:0;text-align:center;"
							:class="{'ringing':employee&&employee.currentCall&&employee.currentCall.state=='CALL_RING','onhold':employee&&employee.currentCall&&employee.currentCall.state=='CALL_HOLD'}"
						></glyph-icon>
						<div :style="{'opacity':employee&&employee.currentCall&&employee.currentCall.state=='CALL_RING'?0:1}" style="text-align:center;color:var(--call-duration-color);font-family:RobotoMonoThin;font-size:var(--font-size-small);flex-shrink:0;margin-top:2px;margin-left:2px">{{ currentCallDuration | duration(true) }}</div>
					</div>
					<div class="duration-box" :class="{'visible':!(employee&&employee.currentCall)&&sinceLastCallEndedLabel!=''&&lastCallEndedToday}">
						<glyph-icon icon="call-idle" size="30" color="#aaaaaa" style="margin: 0 auto;flex-shrink:0;margin-top:-4px"></glyph-icon>
						<div style="text-align:center;color:#aaaaaa;font-size:var(--font-size-primary);font-weight:500;flex-shrink:0;margin-top:-15px;margin-left:2px">{{ sinceLastCallEndedTime }}<span style="font-size:var(--font-size-small);font-weight:300;padding-left:2px">{{ sinceLastCallEndedLabel }}</span></div>
					</div>
				</div>

			</div>
		</call-draggable>
		<div class="bottom-line" :class="{'visible':!last}"></div>

		<v-contextmenu :ref="queueId+member._id" v-if="canManage">
			<v-contextmenu-item @click="leave">{{ $t('queue.action.memberLeave') }}</v-contextmenu-item>
			<v-contextmenu-submenu :title="$t('queue.action.memberSetPriority')">
				<v-contextmenu-item @click="setPriority(pri)" v-for="pri in priorities" :key="pri" style="display:flex;padding-top:4px;padding-bottom:3px">
					<glyph-icon :icon="pri==member.priority?'299-circle-selected':'298-circle-empty'" size="15" color="black"></glyph-icon>
					<div style="margin-left:5px;margin-top:1px">{{ pri }}</div>
				</v-contextmenu-item>
			</v-contextmenu-submenu>
		</v-contextmenu>

	</div>
</template>

<script>
import s from '../../settings';
// import u from '../utils/utils';
import employees from '../../data/employees';
import GlyphIcon from '../GlyphIcon.vue';
import PresenceIcon from '../PresenceIcon.vue';
import EmployeePicture from '../EmployeePicture.vue';
import CallDraggable from './CallDraggable';
import BindCache from '../../utils/BindCache';
import EventBus from '../../data/EventBus';
import i18n from '../../utils/i18n';

export default {
	props: {
		member: Object,
		index: Number,
		queueId: String,
		last: Boolean
	},
	components: {
		GlyphIcon,
		PresenceIcon,
		EmployeePicture,
		CallDraggable
	},
	data() {
		return {
			$t: i18n.t,
			canManage: false,
			loaded: false,
			employee: null,
			extension: null,
			currentCallDuration: 0,
			sinceLastCallEndedTime: '',
			sinceLastCallEndedLabel: '',
			priorities: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
			bindCache: new BindCache(this)
		};
	},
	computed: {
		employeeOnACall: function() {
			return this.employee && this.employee.currentCall != null;
		},
		lastCallEndedToday: function() {
			const today = new Date();
			today.setHours(0,0,0,0);
			return this.member.lastCallEnded && this.member.lastCallEnded >= today;
		},
		enhancedMember: function() {
			return { member: this.member, queueId: this.queueId };
		}
	},
	methods: {
		showContextMenu(e) {
			e.preventDefault();
			if (this.canManage && !this.member.permanent) {
				setTimeout(() => { this.$refs[this.queueId+this.member._id].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
			}
		},
		async leave() {
			s.http.delete(`/pbx/queue/${this.queueId}/member/${this.member._id}`)
				.catch(err => {
					if (err.status != 404) {
						//console.dir(err);
						const name = this.employee ? this.employee.name : this.member._id;
						EventBus.$emit('CommonErrorModal', { header: i18n.t('queue.failedToRemoveMemberHeader'), message: i18n.t('failedToRemoveMemberMessage').replace('%%name%%', name) });
					}
					//console.dir(res.data);
				});
		},
		async setPriority(priority) {
			s.http.post(`/pbx/queue/${this.queueId}/member/${this.member._id}`, { priority: priority })
				.catch(err => {
					const name = this.employee ? this.employee.name : this.member._id;
					EventBus.$emit('CommonErrorModal', { header: i18n.t('queue.failedToSetPriorityHeader'), message: i18n.t('failedToSetPriorityMessage').replace('%%name%%', name) });
					//console.dir(res.data);
				});
		},
		refresh() {
			this.currentCallDuration = this.employee && this.employee.currentCall ? this.employee.currentCallDuration : 0;
			if (this.member.lastCallEnded) {
				const lce = typeof this.member.lastCallEnded == 'string' ? new Date(this.member.lastCallEnded) : this.member.lastCallEnded;
				const value = Math.floor((new Date() - lce) / 1000);
				let hours = Math.floor(value / 60 / 60);
				let minutes = Math.floor((value - (hours*60*60)) / 60);
				let seconds = value - (hours*60*60) - (minutes*60);
				//console.log(`${hours} - ${minutes} - ${seconds} - ${typeof this.member.lastCallEnded} - ${value}`);
				if (hours >= 24) {
					if (this.sinceLastCallEndedTime != '') {
						this.sinceLastCallEndedTime = '';
						this.sinceLastCallEndedLabel = '';
					}
				} else if (hours > 0) {
					this.sinceLastCallEndedTime = hours + Number((minutes/60).toString().substr(0,3));
					this.sinceLastCallEndedLabel = this.sinceLastCallEndedTime > 1 ? i18n.t('common.hours') : i18n.t('common.hour');
				} else if (minutes > 0) {
					this.sinceLastCallEndedTime = minutes;
					this.sinceLastCallEndedLabel = i18n.t('common.minutes-short');
				} else {
					this.sinceLastCallEndedTime = seconds;
					this.sinceLastCallEndedLabel = i18n.t('common.seconds-short');
				}
			} else {
				if (this.sinceLastCallEndedTime != '') {
					this.sinceLastCallEndedTime = '';
					this.sinceLastCallEndedLabel = '';
				}
			}
		}
	},
	created() {
		employees.getEmployee(s.auth.employee)
			.then(me => {
				if (me) {
					this.canManage = me.permissions.queueMember;
				}
			})
			.catch(() => {})
			.then(() => {
				if (this.member.extension) {
					employees.getEmployeeByExtensionId(this.member.extension).then(employee => {
						if (employee) {
							this.employee = employee;
							this.extension = employee.extensions.find(o => o._id == this.member.extension);
							this.currentCallDuration = this.employee && this.employee.currentCall ? this.employee.currentCallDuration : 0;
							this.loaded = true;
						} else {
							// No employee attached to extension lookup extension directly
							//console.log('Looking up extension on server');
							s.http.get(`/pbx/extension/${this.member.extension}`)
								.then(res => {
									//console.dir(res.data);
									this.extension = res.data;
									this.loaded = true;
								})
								.catch(err => {
									// TODO: Should report error back to server at some point
									//this.loaded = true;
									//alert(`Failed to load extension ${this.member.extension} - ${err.status} - ${err.code} - ${err.message}`);
								});
						}
					}).catch(() => {});
				} else {
					this.loaded = true;
				}

				EventBus.$on('OneSecond', this.bindCache.bind('refresh', this.refresh));
			})
			.catch(() => {});
	},
	mounted() {

	},
	destroyed() {
		EventBus.$off('OneSecond', this.bindCache.unbind('refresh'));
	}
};
</script>

<style scoped>
	.member-box {
		transition: 400ms;
		transition-delay: 1s;
		display: grid;
		grid-template-columns: 54px auto 70px;
		/* column-gap: 5px; */
		grid-template-rows: 40px;
		overflow: hidden;
		padding: 0;
		height: 40px;
	}
	.employee-picture-box {
		margin-left: 4px;
		margin-right: 4px;
		position: relative;
	}
	.presence-state {
		position: absolute;
		left: 30px;
		top: 24px;
		z-index: 1;
	}
	.employee-oncall {
		position: absolute;
		left: 30px;
		top: 0px;
		border: 1px solid var(--presence-busy);
		border-radius: 50%;
		z-index: 1;
		width: 16px;
		height: 16px;
		padding-top: -10px;
		background-color:var(--presence-busy);
		opacity: 0;
		transition: 1s;
	}
	.employee-oncall.active {
		opacity: 1;
	}
	.external-name {
		margin-left: 4px;
		margin-right: 4px;
		position: relative;
		background-color: var(--pn-beige-3);
		color: var(--pn-black-3);
		border: 1px solid #aaa;
		border-radius: 50%;
		text-transform: uppercase;
		font-weight: 400;
		text-align: center;
		font-size: var(--font-size-small);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
	}
	.name-and-usage {
		display: grid;
		grid-template-rows: 19px auto;
		/* align-content: center; */
	}
	.usage-box {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		margin-top: -4px;
	}
	.usage-label {
		font-size: 10px;
		/* text-align: center; */
		/* font-weight: 400; */
		line-height: 10px;
		color: var(--pn-black-3);
		padding-bottom: 2px;
	}
	.usage-value {
		font-size: var(--font-size-primary);
		line-height: var(--font-size-primary);
		color: var(--pn-primary-1);
		font-weight: 500;
		text-align: center;
	}

	.name {
		font-size: var(--font-size-primary);
		font-weight:300;
		line-height: var(--font-size-primary);
		color: var(--txt-color-primary);
		letter-spacing: 0.01em;
		margin-top: 4px;
	}
	.bottom-line {
		margin-left: 52px;
		margin-top: 2px;
		margin-bottom: 2px;
		border-bottom: var(--divider-line);
		opacity: 0;
		transition: 600ms;
		transition-delay: 200ms;
	}
	.bottom-line.visible {
		opacity: 1;
	}
	.duration-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		float: right;
		transition: 600ms;
		transition-delay: 300ms;
		overflow: hidden;
		margin-right: 3px;
		opacity: 0;
		max-height: 0px;
		min-height: 0px;
	}

	.duration-box.visible {
		opacity: 1;
		margin-top: 0px;
		max-height: 40px;
		min-height: 40px;
	}
	.duration-time {
		color: var(--call-duration-color);
		font-family: RobotoMonoThin;
		font-size: var(--font-size-small);
		flex-shrink: 0;
		margin: 0 auto;
	}
	.ringing {
		animation: shake 1s; 
		animation-iteration-count: infinite; 
		padding-top: 6px;
	}

	@keyframes shake {
		0% { transform: translate(2px, 0px); }
		10% { transform: translate(-2px, 1px); }
		20% { transform: translate(2px, -1px); }
		30% { transform: translate(-2px, 0px); }
		40% { transform: translate(2px, 0px); }
		50% { transform: translate(-2px, 0px); }
		60% { transform: translate(2px, 0px); }
		70% { transform: translate(-2px, 1px); }
		80% { transform: translate(2px, -1px); }
		90% { transform: translate(-2px, 0px); }
		100% { transform: translate(2px, 0px); }
	}
	.onhold {
		animation: holdanim 1s; 
		animation-iteration-count: infinite; 
	}
	@keyframes holdanim {
		0% { opacity: 1; }
		40% { opacity: 0.2; }
		70% { opacity: 1; }
	}
</style>