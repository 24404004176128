<template>
	<div class="column" :class="{'edit-mode':dashboards.editMode}" :style="{'width': dashboards.editMode ? '370px' : dashboards.getColumnWidth(index),'min-width': dashboards.editMode ? '370px' : dashboards.getColumnWidth(index)}">
		<div class="item-box" v-for="item in dashboards.getColumn(index)" :key="item.tag">
			<my-calls v-if="item.type=='MYCALLS'" :data="item"></my-calls>
			<queue v-if="item.type=='QUEUE'" :data="item"></queue>
			<extensions v-if="item.type=='EXTENSIONS'" :data="item"></extensions>
			<web v-if="item.type=='WEB'" :data="item" :style="{'height': dashboards.editMode ? 'auto' : item.height+'px','width': dashboards.editMode ? 'auto' : item.width+'px'}"></web>
			<switch-var v-if="item.type=='SWITCHVAR'" :data="item"></switch-var>
			<short-cuts v-if="item.type=='SHORTCUTS'" :data="item"></short-cuts>
		</div>
		<b-dropdown v-show="dashboards.editMode&&!dashboards.dragItem" block size="sm" variant="outline-primary" style="margin-bottom: 10px" :text="$t('layout.addPanel')" menu-class="w-100">
			<b-dropdown-item-button @click="dashboards.addItem('MYCALLS',index)">{{ $t('layout.type.MYCALLS') }}</b-dropdown-item-button>
			<b-dropdown-item-button @click="dashboards.addItem('QUEUE',index)">{{ $t('layout.type.QUEUE') }}</b-dropdown-item-button>
			<b-dropdown-item-button @click="dashboards.addItem('EXTENSIONS',index)">{{ $t('layout.type.EXTENSIONS') }}</b-dropdown-item-button>
			<b-dropdown-item-button @click="dashboards.addItem('WEB',index)">{{ $t('layout.type.WEB') }}</b-dropdown-item-button>
			<b-dropdown-item-button @click="dashboards.addItem('SWITCHVAR',index)">{{ $t('layout.type.SWITCHVAR') }}</b-dropdown-item-button>
			<b-dropdown-item-button @click="dashboards.addItem('SHORTCUTS',index)">{{ $t('layout.type.SHORTCUTS') }}</b-dropdown-item-button>
		</b-dropdown>
		<div
			class="bottom-drop-target"
			:class="{'bottom-drop-target-over':isDragOver}" 
			:style="{'height':dragOffset+'px'}"
			v-show="dashboards.dragItem" 
			@dragenter="dragOver" 
			@dragover="dragOver" 
			@dragleave="dragLeave" 
			@drop="dragDrop"
		>
			{{ $t('layout.moveHere') }}
		</div>
	</div>
</template>

<script>
import MyCalls from './MyCalls.vue';
import Queue from './Queue.vue';
import Extensions from './Extensions.vue';
import Web from './Web.vue';
import SwitchVar from './Switchvar.vue';
import ShortCuts from './Shortcuts.vue';
import dashboards from '../../data/dashboards';
import i18n from '../../utils/i18n';

export default {
	components: {
		MyCalls,
		Queue,
		Extensions,
		Web,
		SwitchVar,
		ShortCuts
	},
	props: {
		index: { type: Number, required: true }
	},
	data() {
		return {
			$t: i18n.t,
			dashboards: dashboards,
			isDragOver: false,
			dragOffset: 31,
			leaveTimer: null,
		};
	},
	methods: {
		dragOver(ev) {
			ev.preventDefault();
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			this.isDragOver = true;
			dashboards.dragTarget = { column: this.index, position: -1 };
			this.dragOffset = dashboards.dragItemHeight;
		},
		dragLeave() {
			dashboards.dragTarget = null;
			this.leaveTimer = setTimeout(() => {
				this.isDragOver = false;
				this.dragOffset = 31;
			}, 100);


		},
		dragDrop(ev) {
			ev.preventDefault();
			if (this.leaveTimer) { clearTimeout(this.leaveTimer); this.leaveTimer = null; }
			this.dragOffset = 31;
			dashboards.dragDropped();
		}
	},
	created() {
	}
};
</script>

<style scoped>
	.column {
		margin-left: 5px;
		margin-right: 5px;
		border-radius: 5px;
		transition: 500ms;
	}
	.column.edit-mode {
		margin-top: 40px;
	}
	.item-box {
		margin-bottom: 10px;
	}
	.bottom-drop-target {
		border: 1px dashed var(--border-color-dragdrop);
		height: 31px;
		color: var(--border-color-dragdrop);
		font-size: var(--font-size-primary);
		text-align: center;
		border-radius: 5px 5px;
		padding-top: 3px;
		transition: 200ms;
	}
	.bottom-drop-target-over {
		background-color: var(--bg-color-dragdrop);
		color: var(--txt-color-primary-highlight);
	}
</style>