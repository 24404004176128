import Vue from 'vue';
import i18n from '../utils/i18n';

// Pretty prints durations
// zeroO if true converts all zero's to O's - used with monospace RobotoMono font
// minMin if true and time is less than a minute, 0: is prefixed
Vue.filter('duration', function(value,zeroO,minMin) {
	if (value == null || value < 0) return '';

	const pad = function(string,pad,length) {
		return (new Array(length+1).join(pad)+string).slice(-length);
	};

	let hours = Math.floor(value / 60 / 60);
	let minutes = Math.floor((value - (hours*60*60)) / 60);
	let seconds = value - (hours*60*60) - (minutes*60);
	let res = '';
	if (hours) { res = `${hours}:${pad(minutes,0,2)}:${pad(seconds,0,2)}`; }
	else if (minutes) { res = `${minutes}:${pad(seconds,0,2)}`; }
	else { res = `${seconds}`; }
	if (zeroO) {
		res = res.replace(/0/g, 'O');
	}
	if (minMin && !minutes) {
		if (res < 10) { res = '0' + res; }
		res = '0:' + res;
	}
	return res;
});

Vue.filter('shortduration', function(value,label) {
	if (value == null || value < 0) return '';

	let hours = Math.floor(value / 60 / 60);
	let minutes = Math.floor((value - (hours*60*60)) / 60);
	let seconds = Math.floor(value - (hours*60*60) - (minutes*60));
	let res = '';
	let reslabel = '';
	if (hours) {
		const time = hours + Number((25/60).toString().substr(0,3));
		res = `${time}`;
		reslabel = time > 1 ? i18n.t('common.hours') : i18n.t('common.hour');
	}
	else if (minutes) {
		res = `${minutes}`;
		reslabel = i18n.t('common.minutes-short');
	}
	else {
		res = `${seconds}`;
		reslabel = i18n.t('common.seconds-short');
	}

	if (label) { return reslabel; } else { return res; }
});
