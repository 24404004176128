/**
 * Module for communicating with Jabra headsets
 * - will use Jabra browser plugin if available and not embedded
 * - will use localhost:61337 api if embedded
 */

import s from '../../settings';
import axios from 'axios';
import EventBus from '../../data/EventBus';
import employees from '../../data/employees';
import employee from '../../data/employee';
import u from '../utils';
import i18n from '../i18n';
import logger from '../../data/logger';
import browserDetect from '../browserdetect';
 
// axios.get(s.localUrl + '/get?client=MAIN&timeout=' + timeout, { timeout: 40000 })

class Kuando {
	constructor() {
		logger.debug('Kuando: Constructor');
		this.use = false;
		this.ringTone = 0;
		this.ringVolume = 0;
		/** @type employee */
		this.me = null;
		EventBus.$on('StatusRequest', () => {
			EventBus.$emit('StatusReport', { key: 'kuando', value: this.use });
		});
		this.init();

		logger.registerGlobal('kuando', this);
	}

	async init() {
		logger.info('Kuando: Initialzing');
		if (browserDetect.isSafari) {
			logger.info('Kuando: Safari detected - not supported - init canclled');
			return;
		}
		await s.ready();
		this.use = u.bool(localStorage.getItem('KuandoUse'));
		this.ringTone = Number(localStorage.getItem('KuandoRingTone')) || 0;
		this.ringVolume = Number(localStorage.getItem('KuandoRingVolume')) || 0;
		this.me = await employees.getMe();
		if (!this.me) {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noUserProfileHeader'), message: i18n.t('common.error.noUserProfileMessage') });
			s.logout();
			return;
		}
		window.addEventListener('beforeunload', () => {
			this.off();
		});
		if (this.me.currentRingingCall) {
			this.ringing();
		} else if (this.me.currentCall) {
			this.busy();
		} else {
			this.idle();
		}
	}

	save() {
		localStorage.setItem('KuandoUse', this.use);
		localStorage.setItem('KuandoRingTone', this.ringTone);
		localStorage.setItem('KuandoRingVolume', this.ringVolume);
		if (!this.use) { 
			this.off();
		} else {
			if (this.me.currentRingingCall) {
				this.ringing();
			} else if (this.me.currentCall) {
				this.busy();
			} else {
				this.idle();
			}
		}
	}

	/**
	 * Set ringing state
	 */
	ringing() {
		if (this.use) {
			logger.info('Kuando: Setting ringing state');
			if (this.ringTone > 0) {
				axios.get('http://localhost:8989/?action=alert&red=100&green=100&blue=0&sound=' + this.ringTone + '&volume=' + this.ringVolume, { timeout: 1000 }).catch(() => {});
			} else {
				axios.get('http://localhost:8989/?action=blink&red=100&green=100&blue=0', { timeout: 1000 }).catch(() => {});
			}
		}
	}

	/**
	 * Set calling out state
	 */
	calling() {
		if (this.use) {
			logger.info('Kuando: Setting calling state');
			axios.get('http://localhost:8989/?action=light&red=100&green=100&blue=0', { timeout: 1000 }).catch(() => {});
		}
	}

	/**
	 * Set busy state
	 */
	busy() {
		if (this.use) {
			logger.info('Kuando: Setting busy state');
			axios.get('http://localhost:8989/?action=light&red=100&green=0&blue=0', { timeout: 1000 }).catch(() => {});
		}
	}

	/**
	 * Set idle state
	 */
	async idle() {
		if (this.use) {
			if (!this.me) {
				this.me = await employees.getMe();
			}
			if (this.me && this.me.presence.state == 'BUSY') {
				logger.info('Kuando: Setting busy state');
				axios.get('http://localhost:8989/?action=light&red=100&green=0&blue=0', { timeout: 1000 }).catch(() => {});
			} else {
				logger.info('Kuando: Setting idle state');
				axios.get('http://localhost:8989/?action=light&red=0&green=100&blue=0', { timeout: 1000 }).catch(() => {});
			}
		}
	}

	/**
	 * Turn off
	 */
	off() {
		logger.info('Kuando: Turning off');
		axios.get('http://localhost:8989/?action=off', { timeout: 1000 }).catch(() => {});
	}

	/**
	 * Is Kuando API and busylight connected/available
	 * @returns {Promise<Boolean>} True if Kuando busylight is connected and ready, false if not
	 */
	async isConnected() {
		try {
			await axios.get('http://localhost:8989/?action=currentpresence', { timeout: 1000 });
			logger.info('Kuando: Is connected = true');
			return true;
		} catch {
			logger.info('Kuando: Is connected = false');
			return false;
		}
	}

}

// Singleton
export default new Kuando();

// MAC: https://doc.telecomx.dk/60deccc4bd0a7661d66471c6/BusylightMac.pkg
// Win: https://doc.telecomx.dk/60decd0abd0a7661d66471c7/BusyLightWin.msi