<template>
	<div class="bottom-panel default-font" id="status-panel">
		<div style="display:flex">
			<glyph-icon :icon="rt.connected?'634-circle-check':'633-circle-remove'" size="14" :color="rt.connected?'#00cc00':'#cc0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl">{{ $t('statuspanel.realtime') }}</div>

			<glyph-icon :icon="s.isAuthenticated?'634-circle-check':'633-circle-remove'" size="14" :color="s.isAuthenticated?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl">{{ $t('statuspanel.authentication') }}</div>

			<glyph-icon :icon="employees._ready?'634-circle-check':'633-circle-remove'" size="14" :color="employees._ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl">{{ $t('statuspanel.employees') }}</div>

			<glyph-icon :icon="dashboards.ready?'634-circle-check':'633-circle-remove'" size="14" :color="dashboards.ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl">{{ $t('statuspanel.layout') }}</div>

			<glyph-icon :icon="phonebook._ready?'634-circle-check':'633-circle-remove'" size="14" :color="phonebook._ready?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl">{{ $t('statuspanel.phonebook') }}</div>

			<glyph-icon v-if="s.isEmbedded" :icon="integration.localApiConnected?'634-circle-check':'633-circle-remove'" size="14" :color="integration.localApiConnected?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div v-if="s.isEmbedded" class="lbl">{{ $t('statuspanel.appApi') }}</div>

			<glyph-icon v-if="s.myPhoneId=='SOFTPHONE'" :icon="sipClient.registered?'634-circle-check':'633-circle-remove'" size="14" :color="sipClient.registered?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div class="lbl" v-if="s.myPhoneId=='SOFTPHONE'">{{ $t('statuspanel.sipClient') }}</div>

			<glyph-icon v-if="jabra.use&&sipClient.ua" :icon="'634-circle-check'" size="14" :color="jabra.use?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div v-if="jabra.use" class="lbl">{{ $t('statuspanel.headset') }}: {{ jabra.useDeviceName }}</div>

			<glyph-icon v-if="sennheiser.use&&sipClient.ua" :icon="'634-circle-check'" size="14" :color="sennheiser.use?'#00cc00':'#ff0000'" style="margin-top:-1px"></glyph-icon>
			<div v-if="sennheiser.use" class="lbl">{{ $t('statuspanel.headset') }}: {{ sennheiser.useDeviceName }}</div>

			<glyph-icon :icon="kuando.use?'634-circle-check':'633-circle-remove'" size="14" :color="kuando.use?'#00cc00':'#ff0000'" style="margin-top:-1px" v-if="kuando.use"></glyph-icon>
			<div class="lbl" v-if="kuando.use">{{ $t('statuspanel.busylight') }}</div>

		</div>
		<div style="font-weight:bold">{{ s.appName }} {{ s.version }}{{ s.isEmbedded ? '-' + s.wrapperVersion : '' }}</div>
	</div>
</template>

<script>
import s from '../settings';
import rt from '../data/realtime';
import employees from '../data/employees';
import dashboards from '../data/dashboards';
import phonebook from '../data/phonebook';
import GlyphIcon from './GlyphIcon.vue';
import integration from '../data/integration';
import sipClient from '../data/sipClient';
import jabra from '../utils/headsets/jabra';
import sennheiser from '../utils/headsets/sennheiser';
import kuando from '../utils/headsets/kuando';
import i18n from '../utils/i18n';

export default {
	components: {
		GlyphIcon
	},
	data() {
		return {
			$t: i18n.t,
			s: s,
			rt: rt,
			employees: employees,
			dashboards: dashboards,
			phonebook: phonebook,
			integration: integration,
			sipClient: sipClient,
			jabra: jabra,
			sennheiser: sennheiser,
			kuando: kuando
		};
	}
};
</script>

<style scoped>
	.bottom-panel {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		height: 20px;
		background-color: var(--status-panel-background);
		color: var(--status-panel-color);
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		border-top: var(--status-panel-border-top);
		font-size: var(--font-size-small);
		z-index: 50;
	}
	.lbl {
		padding-left: 1px;
		padding-right: 10px;
	}
</style>