<template>
	<div v-if="call" @contextmenu="showContextMenu" @dblclick="pickup">
		<call-draggable :data="enhancedCall" type="QueueCall" :target="false" :draggable="true">
			<div class="call-box">
				<div class="position-box" :style="{'background':stateColor}">{{ index + 1 }}</div>
				<div class="labels">
					<div v-if="call.name&&!call.privacy" class="name">{{ call.name }}</div>
					<div v-if="call.privacy" class="name">{{ $t('queue.hiddenNumber') }}</div>
					<div v-if="!call.name&&!call.privacy" clas="name">{{ call.number | cutNationalPrefix }}</div>
					<country-flag v-if="!call.name&&!call.privacy&&countryFlagName" :icon="countryFlagName" size="13" style="margin-left:3px;float:right"></country-flag>
					<div class="number">
						<div v-if="call.name&&!call.privacy">{{ call.number | cutNationalPrefix }}</div>
						<country-flag v-if="countryFlagName" :icon="countryFlagName" size="13" style="margin-left:3px"></country-flag>
						<div v-if="variableValue!=null&&call.name" style="margin-left:5px;margin-right:5px">•</div>
						<div v-if="variableValue!=null&&call.name" class="variable"><span>{{ variableName }}</span>&nbsp;{{ variableValue }}</div>
					</div>
					<div v-if="variableValue!=null&&!call.name" class="variable"><span>{{ variableName }}</span>&nbsp;{{ variableValue }}</div>
				</div>
				<div class="duration-box" :class="{'visible':call}">
					<div>{{ currentCallDuration | duration(true) }}</div>
				</div>
			</div>
		</call-draggable>
		<div class="bottom-line" :class="{'visible':!last}"></div>
		<v-contextmenu :ref="call._id">
			<v-contextmenu-item @click="pickup">{{ $t('queue.action.answer') }}</v-contextmenu-item>
			<v-contextmenu-item v-if="variableValue||(call.number&&!call.privacy)" divider></v-contextmenu-item>
			<v-contextmenu-item v-if="variableValue" @click="copyVariableToClipboard">{{ $t('queue.action.copyVariableToClipboard').replace('%%name%%', variableName.toLowerCase()) }}</v-contextmenu-item>
			<v-contextmenu-item v-if="call.number&&!call.privacy" @click="copyNumberToClipboard">{{ $t('queue.action.copyNumberToClipboard') }}</v-contextmenu-item>
		</v-contextmenu>
	</div>
</template>

<script>
import CountryFlag from '../CountryFlag.vue';
import s from '../../settings';
import u from '../../utils/utils';
import EventBus from '../../data/EventBus';
import CallDraggable from './CallDraggable';
import BindCache from '../../utils/BindCache';
import i18n from '../../utils/i18n';

export default {
	props: {
		call: Object,
		index: Number,
		variable: String,
		variableName: String,
		last: Boolean,
		serviceLevel: Number,
		serviceLevelCritical: Number,
		queueId: String
	},
	components: {
		CountryFlag,
		CallDraggable
	},
	data() {
		return {
			$t: i18n.t,
			currentCallDuration: null,
			stateColor: '#66C566',
			variableValue: null,
			bindCache: new BindCache(this)
		};
	},
	computed: {
		countryFlagName: function() {
			let number = this.call.number.substr(0, 6);
			let iso;
			while (!iso && number) {
				number = number.slice(0, -1);
				iso = u.ISO3166.prefix[number];
			}
			if (iso) { iso = iso.toLowerCase(); }
			if (number == s.pbxSettings.nationalPrefix) { iso = null; }
			return iso;
		},
		enhancedCall: function() {
			return { call: this.call, queueId: this.queueId };
		}
	},
	methods: {
		showContextMenu(e) {
			e.preventDefault();
			setTimeout(() => { this.$refs[this.call._id].show({ left: e.clientX + window.pageXOffset - 8, top: e.clientY + window.pageYOffset - 10 }); }, 1);
		},
		pickup() {
			EventBus.$emit('MyCallsAutoAnswerNextRinging', this.call._id);
			if (!s.myPhoneId) { return this.noPhoneSelected(); }
			s.http.get(`/pbx/app/action/queuepickup?device=${s.myRealPhoneId}&target=${this.call._id}`).catch(err => {
				EventBus.$emit('CommonErrorModal', { header: i18n.t('queue.failedAnswerHeader'), message: i18n.t('queue.failedAnswerMessage') });
			});
		},
		copyVariableToClipboard() {
			navigator.clipboard.writeText(this.variableValue);
		},
		copyNumberToClipboard() {
			navigator.clipboard.writeText(s.cutNationalPrefix(this.call.number));
		},
		refreshDuration() {
			if (this.call) {
				this.currentCallDuration = Math.round((new Date() - this.call.entered)/1000);
				const seconds = Math.round((new Date() - this.call.entered)/1000);
				if (seconds < this.serviceLevel) {
					if (this.stateColor != 'var(--queue-green)') { this.stateColor = 'var(--queue-green)'; }
				} else if (seconds < this.serviceLevelCritical) {
					if (this.stateColor != 'var(--queue-yellow)') { this.stateColor = 'var(--queue-yellow)'; }
				} else if (this.stateColor != 'var(--queue-red)') { this.stateColor = 'var(--queue-red)'; }
			} else {
				this.currentCallDuration = 0;
				this.stateColor = 'var(--queue-green)';
			}

		},
		noPhoneSelected() {
			EventBus.$emit('CommonErrorModal', { header: i18n.t('common.error.noPhoneHeader'), message: i18n.t('common.error.noPhoneMessage') });
		}
	},
	created() {
		EventBus.$on('OneSecond', this.bindCache.bind('refreshDuration', this.refreshDuration));
		setTimeout(() => { this.refreshDuration(); }, 100);
	},
	mounted() {
		if (this.variable) {
			s.http.get(`/pbx/callvariable/${this.call._id}/${this.variable}`)
				.then(res => {
					this.variableValue = res.data.value;
				})
				.catch(() => {}); // no variable present
		}
	},
	destroyed() {
		EventBus.$off('OneSecond', this.bindCache.unbind('refreshDuration'));
	}

};
</script>

<style scoped>
	.call-box {
		display: grid;
		grid-template-columns: 44px auto 65px;
		overflow: hidden;
		transition: 400ms;
		transition-delay: 1s;
		padding: 0;
		height: 40px;
	}
	.position-box {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		color: var(--queue-position);
		text-align: center;
		font-size: 20px;
		font-weight: 200;
		padding-top: 5px;
		margin-left: 4px;
	}
	.labels {
		padding-left: 8px;
		height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.labels DIV {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.name {
		font-size: var(--font-size-primary);
		font-weight: 300;
		line-height: var(--font-size-primary);
		color: var(--txt-color-primary);
		letter-spacing: 0.01em;
	}
	.number {
		font-size: var(--font-size-small);
		color: var(--label-message-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		letter-spacing: 0.01em;
	}
	.variable {
		font-size: var(--font-size-small);
		font-style: italic;
		color: var(--label-location-color);
		line-height: var(--font-size-small);
		display: inline-flex;
		transition: 400ms;
	}
	.variable > SPAN {
		font-weight: bold;
		margin-right: 5px;
	}
	.duration-box {
		height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		float: right;
		transition: 600ms;
		transition-delay: 300ms;
		max-width: 0px;
		min-width: 0px;
		overflow: hidden;
		margin-right: 4px;
	}
	.duration-box.visible {
		max-width: 65px;
		min-width: 40px;
	}
	.duration-box > DIV {
		color: var(--call-duration-color);
		font-family: RobotoMonoThin;
		font-size: var(--font-size-small);
		flex-shrink: 0;
		text-align: right;
	}
	.bottom-line {
		margin-left: 52px;
		margin-top: 2px;
		margin-bottom: 2px;
		border-bottom: var(--divider-line);;
		opacity: 0;
		transition: 600ms;
		transition-delay: 200ms;
	}
	.bottom-line.visible {
		opacity: 1;
	}

</style>
