<template>
	<div class="box" v-if="run">
		<div class="spinner" :style="{ width: size + 'px', height: size + 'px' }">
			<div v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12]" v-bind:key="i" :class="'bar' + i" :style="{ background: color, boxShadow: shadow }"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		size: Number,
		run: Boolean,
		color: { type: String, default: '#000' },
		shadowColor: { type: String, default: '#00000032' }
	},
	computed: {
		shadow: function() {
			return '0 0 5px ' + this.shadowColor;
		}
	}
};
</script>

<style scoped>
	.box { display: table; margin: 0 auto; }

	.spinner {
		position: relative;
		display: inline-block;
	}

	.spinner div {
		width: 9%;
		height: 26%;
		position: absolute;
		left: 44.5%;
		top: 37%;
		opacity: 0;
		-webkit-animation: fade 1s linear infinite;
		animation: fade 1s linear infinite;
		border-radius: 100px;
	}

	.bar1 {
		transform:rotate(0deg) translate(0, -142%);
		animation-delay: 0s !important;
	}
	.bar2 {
		transform:rotate(30deg) translate(0, -142%);
		animation-delay: -0.9167s !important;
	}
	.bar3 {
		transform:rotate(60deg) translate(0, -142%);
		animation-delay: -0.833s !important;
	}
	.bar4 {
		transform:rotate(90deg) translate(0, -142%);
		animation-delay: -0.75s !important;
	}
	.bar5 {
		transform:rotate(120deg) translate(0, -142%);
		animation-delay: -0.667s !important;
	}
	.bar6 {
		transform:rotate(150deg) translate(0, -142%);
		animation-delay: -0.5833s !important;
	}
	.bar7 {
		transform:rotate(180deg) translate(0, -142%);
		animation-delay: -0.5s !important;
	}
	.bar8 {
		transform:rotate(210deg) translate(0, -142%);
		animation-delay: -0.41667s !important;
	}
	.bar9 {
		transform:rotate(240deg) translate(0, -142%);
		animation-delay: -0.333s !important;
	}
	.bar10 {
		transform:rotate(270deg) translate(0, -142%);
		animation-delay: -0.25s !important;
	}
	.bar11 {
		transform:rotate(300deg) translate(0, -142%);
		animation-delay: -0.1667s !important;
	}
	.bar12 {
		transform:rotate(330deg) translate(0, -142%);
		animation-delay: -0.0833s !important;
	}

	@-webkit-keyframes fade {
		from {opacity: 1;}
		to {opacity: 0.25;}
	}

	@keyframes fade {
		from {opacity: 1;}
		to {opacity: 0.25;}
	}
</style>